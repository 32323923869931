import React, {
    useCallback, useMemo,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, Text, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Status from 'types/status'
import Department from 'requests/objects/department'
import styles from 'styles/components/pages/companies/department-list.module.scss'
import OpenWeekDays from 'components/pages/orders/[id]/client/common/openWeekDays'
import flattenObj from 'helpers/methods/flattenObj'

/**
 * @callback EditDepartmentCallback
 * @param {Department} department department
 */

/**
 * @callback DeleteDepartmentCallback
 * @param {Department} department department
 */

/**
 * DepartmentList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Department[]} props.items items
 * @param {{ key: string | number; text: string; }[]} props.countries countries
 * @param {Status} props.status status
 * @param {EditDepartmentCallback} props.editDepartment editDepartment
 * @param {DeleteDepartmentCallback} props.deleteDepartment deleteDepartment
 * @returns {JSX.Element} Element
 */
export default function DepartmentList({
    lang,
    items,
    status,
    countries,
    editDepartment,
    deleteDepartment,
}) {
    const { tKey } = useTranslate({ lang })

    const departments = useMemo(() => items.map(x => flattenObj(x)), [items])

    const ActionButtons = useCallback(
        department => (
            <div className={styles['action-buttons']}>
                <IconButton
                    iconProps={{ iconName: 'Edit' }}
                    title={tKey('edit')}
                    onClick={() => editDepartment(department)}
                />
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={tKey('delete')}
                    onClick={() => deleteDepartment(department)}
                />
            </div>
        ), [deleteDepartment, editDepartment, tKey],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'country',
            name: tKey('country'),
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: (/** @type {Department} */ departement) => countries.find(x => +x.key === departement.countryId)?.text ?? '',
        },
        {
            key: 'code',
            name: tKey('code'),
            fieldName: 'code',
            minWidth: 30,
            maxWidth: 50,
            isResizable: true,
        },
        {
            key: 'pickupZone',
            name: tKey('pickupZone'),
            fieldName: 'pickupZoneId',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: 'pickupDay',
            name: tKey('pickupDay'),
            minWidth: 130,
            maxWidth: 300,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: (/** @type {Department} */ departement) => (
                <OpenWeekDays
                    lang={lang}
                    openDays={{
                        monday: departement.mondayPickup,
                        tuesday: departement.tuesdayPickup,
                        wednesday: departement.wednesdayPickup,
                        thursday: departement.thursdayPickup,
                        friday: departement.fridayPickup,
                        saturday: departement.saturdayPickup,
                        sunday: departement.sundayPickup,
                    }}
                />
            ),
        },
        {
            key: 'deliveryZone',
            name: tKey('deliveryZone'),
            fieldName: 'deliveryZoneId',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: 'deliveryDay',
            name: tKey('deliveryDay'),
            minWidth: 130,
            maxWidth: 300,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: (/** @type {Department} */ departement) => (
                <OpenWeekDays
                    lang={lang}
                    openDays={{
                        monday: departement.mondayDelivery,
                        tuesday: departement.tuesdayDelivery,
                        wednesday: departement.wednesdayDelivery,
                        thursday: departement.thursdayDelivery,
                        friday: departement.fridayDelivery,
                        saturday: departement.saturdayDelivery,
                        sunday: departement.sundayDelivery,
                    }}
                />
            ),
        },
        {
            key: 'delete',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: ActionButtons,
        },
    ]), [tKey, ActionButtons, countries, lang])

    return (
        <div className={styles['department-list']}>
            <ShimmeredDetailsList
                items={departments}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
            />
            {!departments.length && status !== Status.PENDING
                && (
                    <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>
                        {tKey(status === Status.IDLE ? 'pleaseDoASearch' : 'noResultFound')}
                    </Text>
                )}
        </div>
    )
}
