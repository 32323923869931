import { Text } from '@fluentui/react'
import classNames from 'classnames'
import useTranslate from 'helpers/hooks/useTranslate'
import Time from 'helpers/methods/time'
import React, { useEffect, useRef, useState } from 'react'
import {
    Tag,
} from 'react-bulma-components'
import HomeArticle from 'requests/objects/homeArticle'
import styles from 'styles/components/pages/index/article.module.scss'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import {
    PayloadMessageBar, // eslint-disable-line import/named
} from 'redux/slices/common'
import FilesHandler from 'requests/handlers/filesHandler'
import EFileFolder from 'types/files/enums/fileFolder'

/**
 * @param {string} url url
 * @returns {string} img url
 */
function getYouTubeThumbnailImage(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const video = url.match(regExp)
    if (!video || !video[7])
        return null
    return `https://i3.ytimg.com/vi/${video[7]}/hqdefault.jpg` // pass 0,1,2,3 for different sizes like 0.jpg, 1.jpg
}

/**
 * Article
 * @param {object} props props
 * @param {HomeArticle} props.homeArticle homeArticle
 * @param {FilesHandler} props.handler handler
 * @param {string} props.lang lang
 * @param {function(PayloadMessageBar):void} props.setMessageBar setMessageBar
 * @returns {JSX.Element} JSX
 */
export default function Article({
    homeArticle, lang, handler, setMessageBar,
}) {
    const { tKey } = useTranslate({ lang })

    const [imgSrc, setImgSrc] = useState(null)

    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerImg = useRef(null)

    useEffect(() => {
        (async () => {
            try {
                const imgUrl = getYouTubeThumbnailImage(homeArticle.url)
                if (imgUrl) { setImgSrc(imgUrl) } else {
                    handlerImg.current = handler.getFile(EFileFolder.HomeArticle, homeArticle.fileId)
                    const img = await handlerImg.current.fetch()
                    setImgSrc(window.URL.createObjectURL(img))
                }
            } catch (error) {
                setMessageBar({ isDisplayed: false })
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })()
    }, [homeArticle, handler, setMessageBar])

    useEffect(() => () => {
        handlerImg?.current?.cancel()
    }, [])

    return (
        <article
            className={classNames(
                styles.article,
            )}
        >
            <Text
                className={styles['card-title']}
                as="h2"
                variant="mediumPlus"
            >
                <a
                    href={homeArticle.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    {homeArticle.title || ''}
                </a>
            </Text>
            <Text className={styles['card-date']}>
                {tKey('publishedOn')}
                {' '}
                {homeArticle.startDate ? Time(homeArticle.startDate).getCleanDate() : ''}
            </Text>
            <Tag.Group className={styles['card-tag-group']}>
                <Tag>
                    <Text>
                        {homeArticle.category || ''}
                    </Text>
                </Tag>
            </Tag.Group>
            <footer className={styles['card-footer']}>
                <div className={styles['card-footer-img-container']}>
                    <a
                        href={homeArticle.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {imgSrc
                            && (
                                <img
                                    src={imgSrc}
                                    alt={homeArticle.title}
                                />
                            )}
                    </a>
                </div>
                <Text as="p">
                    <a
                        href={homeArticle.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {tKey('seeMore')}
                    </a>
                </Text>
            </footer>
        </article>
    )
}
