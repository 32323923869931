import Base from 'requests/objects/_base'
import OrderSubHandling from 'requests/objects/order/orderSubHandling'
import Support from 'requests/objects/support'
import ProductTemperature from 'requests/objects/productTemperature'

/**
 * OrderHandling
 */
export default class OrderHandling extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderHandlingId orderHandlingId
     * @param {number=} data.deliveryContentDataId deliveryContentDataId
     * @param {number=} data.supportId supportId
     * @param {object=} data.support support
     * @param {string=} data.reference reference
     * @param {number=} data.weight weight
     * @param {string=} data.footage footage float
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     * @param {number=} data.quantity quantity
     * @param {number=} data.productTemperatureId productTemperatureId
     * @param {object=} data.productTemperature productTemperature
     * @param {string=} data.comment comment
     * @param {string=} data.kindOfGoods kindOfGoods
     * @param {object[]=} data.orderSubHandlings orderSubHandlings
     */
    constructor({
        orderHandlingId,
        deliveryContentDataId,
        supportId,
        support,
        reference,
        weight,
        footage,
        long,
        width,
        height,
        quantity,
        productTemperatureId,
        productTemperature,
        comment = '',
        kindOfGoods,
        orderSubHandlings = [],
        ...data

    }) {
        super(data)
        this.orderHandlingId = orderHandlingId
        this.deliveryContentDataId = deliveryContentDataId
        this.supportId = supportId
        this.support = support ? new Support(support) : null
        this.reference = reference
        this.weight = weight && !Number.isNaN(+weight) ? +weight : null
        this.footage = footage
        this.long = long && !Number.isNaN(+long) ? +long : null
        this.width = width && !Number.isNaN(+width) ? +width : null
        this.height = height && !Number.isNaN(+height) ? +height : null
        this.quantity = quantity && !Number.isNaN(+quantity) ? +quantity : null
        this.productTemperatureId = productTemperatureId
        this.productTemperature = productTemperature ? new ProductTemperature(productTemperature) : null
        this.comment = comment
        this.kindOfGoods = kindOfGoods
        this.orderSubHandlings = orderSubHandlings?.map(x => new OrderSubHandling(x)) ?? []
        this.maxQuantity = 0
    }
}
