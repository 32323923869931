import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import User, { ErrorUser } from 'requests/objects/user'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import parseJson from 'helpers/methods/parseJson'
import PhoneField from 'components/inputs/phoneField'
import { ParamElement } from 'requests/objects/param'
import EProfile from 'types/users/enums/profile'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdClientAccounts extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.userId,
            /** @type {User} Element find from API */
            item: new User(),
            /** @type {User} Initial element fond from API. Used to refresh */
            iniItem: new User(),
            /** @type {ErrorUser} Errors */
            errorField: new ErrorUser(),
            /** @type {ParamElement[]} clientProfiles */
            clientProfiles: [],
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.userId !== prevProps?.match?.params?.userId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.usersHandlerRemoveById?.cancel()
        this.usersHandlerGetById?.cancel()
        this.usersHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match } = this.props

        setBreadcrumb([
            { text: this.tKey('allClientAccounts'), key: 'all-user', href: '/client-accounts' },
            {
                text: match?.params?.userId ? `${this.tKey('clientAccount')} n°${match?.params?.userId}` : this.tKey('newClientAccount'),
                key: 'one-user',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, usersHandler, setMessageBar,
        } = this.props

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorUser(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.userId,
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteUser'),
                        subTitle: this.tKey('sureDeleteUser'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.usersHandlerRemoveById = usersHandler.removeById(match?.params?.userId)
                                    await this.usersHandlerRemoveById.fetch()
                                    history.push('/client-accounts')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.userId,
            },
        ]
    }

    /**
     * Get authorized client profiles
     * @param {number} clientId clientId
     * @returns {ParamElement[]} authorized client profiles
     */
    getAuthorizedClientProfiles(clientId) {
        const { param: { clientProfiles, clients } } = this.props
        const client = clients.find(c => c.key === clientId)

        if (!client)
            return []

        if (!client.canCreateOrder)
            return clientProfiles.filter(p => p.key !== EProfile.CustomOrderEntry)

        return clientProfiles
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, usersHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.userId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.user) // If object came from history push with a create
                    this.setState({
                        item: location.state?.user,
                        clientProfiles: this.getAuthorizedClientProfiles(location.state?.user.clientId),
                        iniItem: location.state?.user,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        this.usersHandlerGetById = usersHandler.getById(match?.params?.userId)
                        const user = await this.usersHandlerGetById.fetch()
                        this.setState({
                            item: user,
                            clientProfiles: this.getAuthorizedClientProfiles(user.clientId),
                            iniItem: user,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new User(),
                    clientProfiles: [],
                    iniItem: new User(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, usersHandler, match, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.usersHandlerUpsert = usersHandler.upsert({ ...item, isClient: true }, match?.params?.userId)
                const user = await this.usersHandlerUpsert.fetch()

                this.setState({
                    item: user,
                    iniItem: user,
                    status: Status.RESOLVED,
                    errorField: new ErrorUser(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.userId)
                    history.replace(`/client-accounts/${user.userId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            item, status, isReadOnly, errorField, clientProfiles,
        } = this.state
        const { param } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main>
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    <Card
                        title={this.tKey('information')}
                        iconName="PageData"
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('clients')}
                                    placeholder={!isReadOnly ? this.tKey('select') : ''}
                                    required={!isReadOnly}
                                    readOnly={isReadOnly}
                                    options={param.clients}
                                    selectedKey={item.clientIds}
                                    onChange={(_ev, option) => {
                                        const clientIds = this.getUpdatedList(item.clientIds, option)
                                        this.setState({
                                            item: {
                                                ...item,
                                                clientIds,
                                                // eslint-disable-next-line no-nested-ternary
                                                clientId: clientIds.includes(item.clientId) ? item.clientId : clientIds.length > 0 ? clientIds[0] : null,
                                            },
                                        })
                                    }}
                                    errorMessage={this.tObj(parseJson(errorField.clientIds))}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('currentClient')}
                                    readOnly={isReadOnly}
                                    options={param.clients.filter(x => item.clientIds.includes(+x.key))}
                                    selectedKey={item.clientId}
                                    onChange={(_ev, option) => this.setState({
                                        item: { ...item, clientId: option.key },
                                        clientProfiles: this.getAuthorizedClientProfiles(option.key),
                                    })}
                                    errorMessage={this.tObj(parseJson(errorField.clientId))}
                                    required={!isReadOnly}
                                    disabled={item.clientIds?.length < 1}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('profile')}
                                    readOnly={isReadOnly}
                                    options={param.clientProfiles.map(x => ({ ...x, text: this.tObj(parseJson(x.text)) }))}
                                    selectedKey={item.profileId}
                                    onChange={(_ev, option) => this.setState({ item: { ...item, profileId: option.key } })}
                                    errorMessage={this.tObj(parseJson(errorField.profileId))}
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('lastname')}
                                    placeholder={this.tKey('lastname')}
                                    value={item.lastname}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, lastname: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.lastname))}
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('firstname')}
                                    placeholder={this.tKey('firstname')}
                                    value={item.firstname}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, firstname: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.firstname))}
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('email')}
                                    placeholder={this.tKey('email')}
                                    value={item.email}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(_ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.email))}
                                    type="email"
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <PhoneField
                                    label={this.tKey('phoneNumber')}
                                    placeholder={this.tKey('phoneNumber')}
                                    value={item.phoneNumber}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(_ev, newVal) => this.setState({ item: { ...item, phoneNumber: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.phoneNumber))}
                                    type="tel"
                                />
                            </Columns.Column>
                        </Columns>
                    </Card>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
            </main>
        )
    }
}

IdClientAccounts.prototype.getUpdatedList = getUpdatedList
IdClientAccounts.prototype.isValidDate = isValidDate
IdClientAccounts.prototype.tKey = tKey
IdClientAccounts.prototype.tObj = tObj
