import Base from 'requests/objects/_base'
import User from 'requests/objects/user'
import WorkflowStep from 'requests/objects/workflowStep'

/**
 * Workflow Historic Object
 */
export default class WorkflowHistoric extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.workflowHistoricId workflowHistoricId
     * @param {boolean=} data.isActive isActive
     * @param {boolean=} data.isCompleted isCompleted
     * @param {object=} data.workflowStep workflowStep
     * @param {number=} data.workflowStepId workflowStepId
     * @param {object=} data.validatedBy validatedBy
     * @param {number=} data.validatedById validatedById
     * @param {string=} data.error error
     */
    constructor({
        workflowHistoricId,
        isActive,
        isCompleted,
        workflowStep,
        workflowStepId,
        validatedBy,
        validatedById,
        error,
        ...data
    } = {}) {
        super(data)
        this.workflowHistoricId = workflowHistoricId
        this.isActive = isActive
        this.isCompleted = isCompleted
        this.workflowStep = workflowStep ? new WorkflowStep(workflowStep) : undefined
        this.workflowStepId = workflowStepId
        this.validatedBy = validatedBy ? new User(validatedBy) : undefined
        this.validatedById = validatedById
        this.error = error
    }
}
