// @ts-nocheck
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'
import DeliveryTimeParams, { ErrorDeliveryTimeParams } from 'requests/objects/DeliveryTimeParams'

/**
 * ToolsHandler
 * @augments {ApiHandler<DeliveryTimeParams, ErrorDeliveryTimeParams>}
 */
export default class ToolsHandler extends ApiHandler {
    constructor() {
        super({ type: DeliveryTimeParams, errorType: ErrorDeliveryTimeParams, key: 'tools' })
    }

    /**
     * Estimate delivery time
     * @param {DeliveryTimeParams} data data
     * @returns {RequestApi<Date>} Request
     */
    estimateDeliveryTime(data) {
        const request = this.initFetchRequest({
            method: 'POST',
            data,
            url: ['estimate-delivery-time'],
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Date(res.data.deliveryDate))
                .then(date => (date === 'Invalid Date' ? null : date))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
