import {
    DefaultButton, Dialog, DialogFooter, DialogType, IconButton, MessageBar, MessageBarType, PrimaryButton, Text,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import HandleBlob from 'helpers/methods/blob'
import parseJson from 'helpers/methods/parseJson'
import React, { useCallback, useRef, useState } from 'react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import FilesHandler from 'requests/handlers/filesHandler'
import File from 'requests/objects/file'
// eslint-disable-next-line no-unused-vars
import Order from 'requests/objects/order'
import DeliveryData from 'requests/objects/order/deliveryData'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/file-list.module.scss'
import EFileFolder from 'types/files/enums/fileFolder'
import Status from 'types/status'

/**
 * DeliveryStepFileList
 * @param {object} props Props
 * @param {Order['deliveryDatas'][0]['deliveryFileData']} props.item Item
 * @param {DeliveryData['deliveryFileData']} props.deliveryFileData deliveryFileData
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {object} props.lang Lang
 * @param {(fileId: File['fileId']) => void} props.removeFile removeFile
 * @param {FilesHandler} props.handler handler
 * @returns {JSX.Element} Element
 */
export default function DeliveryStepFileList({
    item, isReadOnly, lang, deliveryFileData, removeFile, handler,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    /** Is modal visible */
    const [isModalVisible, setIsModalVisible] = useState(false)
    /** Current status of the component */
    const [status, setStatus] = useState(Status.IDLE)
    /** Error message */
    const [errorMessage, setErrorMessage] = useState('')
    /** File To Delete Id */
    const [fileToDeleteId, setFileToDeleteId] = useState(0)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<File>>} */
    const handlerRemoveFile = useRef(null)

    /**
     * Callback on download file
     */
    const onDownloadFile = useCallback(
        /**
         * @param {number} fileId Fileid
         */
        async fileId => {
            try {
                setStatus(Status.PENDING)
                setErrorMessage(null)
                handlerGetFile.current = handler.getFile(EFileFolder.OrderDocument, fileId)
                const blob = await handlerGetFile.current.fetch()
                HandleBlob.download(blob, deliveryFileData?.files.find(x => x.fileId === fileId)?.name)
                setStatus(Status.RESOLVED)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setStatus(Status.REJECTED)
                        setErrorMessage(error ?? 'anErrorHasOccurred')
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [deliveryFileData?.files, handler],
    )

    /**
     * Callback on delete file
     */
    const onDeleteFile = useCallback(async () => {
        if (isReadOnly)
            return

        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            handlerRemoveFile.current = handler.removeFile(item.deliveryFileDataId, EFileFolder.OrderDocument, fileToDeleteId)
            await handlerRemoveFile.current.fetch()
            removeFile(fileToDeleteId)
            setIsModalVisible(false)
            setFileToDeleteId(0)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error ?? 'anErrorHasOccurred')
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [fileToDeleteId, handler, isReadOnly, item.deliveryFileDataId, removeFile])

    return (
        <>
            <ul className={styles['delivery-step-file-list']}>
                {deliveryFileData?.files?.map((file, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${file.fileId}_${index}`}>
                        <Text>{`${tObj(parseJson(file.fileCategory.name))}: `}</Text>
                        {file.url && (
                            <Text>
                                <a
                                    href={file.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {file.url}
                                </a>
                            </Text>
                        )}
                        {!file.url && (
                            <Text
                                onClick={() => onDownloadFile(file.fileId)}
                            >
                                {file.name}
                            </Text>
                        )}
                        {/* <Text>
                            <Icon iconName="TextDocument" />
                            {' '}
                            {file.fileType?.name}
                        </Text> */}
                        {!isReadOnly && (
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => {
                                    setIsModalVisible(true)
                                    setFileToDeleteId(file.fileId)
                                }}
                                styles={{ root: { margin: '-5px 0px' } }}
                            />
                        )}
                    </li>
                ))}
            </ul>
            <Dialog
                hidden={!isModalVisible}
                onDismiss={() => {
                    setIsModalVisible(false)
                    setFileToDeleteId(0)
                }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: tKey('deleteDocument'),
                    subText: tKey('deleteDocumentSub'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
            >
                {errorMessage
                    && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                truncated
                                onDismiss={() => setErrorMessage(null)}
                            >
                                {(typeof errorMessage === 'string'
                                    ? tKey(/** @type {any} */(errorMessage))
                                    : tObj(errorMessage))
                                    || errorMessage?.toString()}
                            </MessageBar>
                            <br />
                        </>
                    )}
                <DialogFooter>
                    <PrimaryButton
                        onClick={onDeleteFile}
                        disabled={status === Status.PENDING}
                    >
                        {tKey('yes')}
                    </PrimaryButton>
                    <DefaultButton
                        onClick={() => {
                            setIsModalVisible(false)
                            setFileToDeleteId(0)
                        }}
                        disabled={status === Status.PENDING}
                        text={tKey('cancel')}
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}
