import Type from 'types/type'
import { Column } from 'types/column' // eslint-disable-line
import { tKey } from 'helpers/methods/translate'
import store from 'redux/store'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const homeArticlesColumns = [
    {
        fieldName: 'title',
        name: 'title',
        type: Type.STRING,
    },
    {
        fieldName: 'category',
        name: 'category',
        type: Type.STRING,
    },
    {
        fieldName: 'startDate',
        name: 'inProgress',
        type: Type.STRING,
        onRender: item => (new Date(item.startDate)?.getTime() < new Date()?.getTime() && new Date(item.endDate)?.getTime() > new Date()?.getTime()
            ? tKey.call({ props: { lang: store.getState().common?.lang } }, 'active')
            : tKey.call({ props: { lang: store.getState().common?.lang } }, 'noActive')),
    },
    {
        fieldName: 'createdAt',
        name: 'createdAt',
        type: Type.DATE,
    },
]

export default homeArticlesColumns
