import React, { PureComponent } from 'react'
import history from 'helpers/history'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import styles from 'styles/pages/transports/[id].module.scss'
import FolderData from 'components/pages/folderData'
import StatusData from 'components/pages/statusData'
import MerchandiseData from 'components/pages/marchandiseData'
import TransportData from 'components/pages/transportData'
import Card from 'components/containers/card'
import DocumentsData from 'components/pages/transports/documentsData'
import InvoiceData from 'components/pages/transports/invoiceData'
import AnomaliesData from 'components/pages/transports/anomaliesData'
import classNames from 'classnames'
import Transport from 'requests/objects/transport'
import DeliveryData from 'requests/objects/order/deliveryData'
import WorkflowLight from 'components/pages/workflowLight'
import parseJson from 'helpers/methods/parseJson'
import ShareTrackingLinkModal from 'components/pages/transports/shareTrackingLinkModal'
import getStepTypes from 'helpers/methods/getStepTypes'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdTransports extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Transport} Element find from API */
            item: new Transport({}),
            /** @type {boolean} Is share modal visible */
            isShareTrackingLinkModalVisible: false,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const {
            match, setCommand, lang,
        } = this.props

        if (match?.params?.transportId !== prevProps?.match?.params?.transportId)
            if (!prevProps?.match?.path?.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.transportsHandlerGetById?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props
        const { item } = this.state

        setBreadcrumb([
            { text: this.tKey('transport'), key: 'transport' },
            {
                text: `n°${item.transportTmsId}`,
                key: 'transportid',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'back',
                text: this.tKey('return'),
                iconProps: { iconName: 'Back' },
                onClick: () => history.goBack(),
                disabled: false,
            },
            {
                key: 'share',
                text: this.tKey('share'),
                iconProps: { iconName: 'Share' },
                onClick: () => this.setState({ isShareTrackingLinkModalVisible: true }),
                disabled: false,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, transportsHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.transportsHandlerGetById = transportsHandler.getById(match?.params?.transportId)
                const transport = await this.transportsHandlerGetById.fetch()
                this.setState({
                    item: transport,
                    status: Status.RESOLVED,
                }, () => {
                    setCommand([])
                    setCommand(this.commandRead)
                })
                this.setupBreadcrumb()
                this.setupCommandBar()
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        setCommand(this.commandRead)
                        this.setState({ status: Status.REJECTED })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, item, isShareTrackingLinkModalVisible,
        } = this.state
        const { lang, filesHandler, transportsHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <>
                <main className={styles.id}>
                    <WorkflowLight
                        steps={item.workflowTransportHistorics.map(wf => ({
                            label: this.tObj(parseJson(wf.workflowTransportStep?.text)) || wf.workflowTransportStep?.text || '',
                            isActive: wf.isActive,
                            isCompleted: wf.isCompleted,
                            isError: !!wf.error,
                            isWarning: wf.error === 'warning',
                            // description: wf.error || '',
                            isCanceled: wf.isCanceled,
                        }))}
                    />
                    <div className={styles['id-body']}>
                        <div className={styles['id-body-content']}>
                            <div className={styles['id-body-status']}>
                                <Card
                                    title={this.tKey('status')}
                                    iconName="Pinned"
                                    hasLitleDividerMarging
                                >
                                    <StatusData
                                        steps={this.getStepTypes(item.transportStatus)}
                                    />
                                </Card>
                            </div>
                            <div className={styles['id-body-merchandise']}>
                                <Card
                                    title={this.tKey('merchandise')}
                                    iconName="DeliveryTruck"
                                    hasLitleDividerMarging
                                >
                                    <MerchandiseData
                                        lang={lang}
                                        companyId={item.client.companyId}
                                        // We use this kind of workaround to use the same component
                                        items={[
                                            new DeliveryData({
                                                deliveryDataId: 1,
                                                deliveryContentData: {
                                                    orderHandlings: item.orderHandlings,
                                                },
                                                deliveryAddressData: {},
                                                deliveryComplementData: {},
                                                deliveryInstructionsData: {},
                                                deliveryDateData: {},
                                                deliveryFileData: {},
                                            }),
                                        ]}
                                    />
                                </Card>
                            </div>
                            <div className={styles['id-body-pickup']}>
                                <Card
                                    title={this.tKey('pickup')}
                                    iconName="DietPlanNotebook"
                                    hasLitleDividerMarging
                                >
                                    <TransportData
                                        address={item.pickupAddress}
                                        date={item.pickupDate}
                                        scheduledDate={item.pickupScheduledDate}
                                        lang={lang}
                                    />
                                </Card>
                            </div>
                            <div className={styles['id-body-delivery']}>
                                <Card
                                    title={this.tKey('delivery')}
                                    iconName="DietPlanNotebook"
                                    hasLitleDividerMarging
                                >
                                    <TransportData
                                        address={item.deliveryAddress}
                                        date={item.deliveryDate}
                                        scheduledDate={item.deliveryScheduledDate}
                                        lang={lang}
                                        isDelivery
                                        plannedThe={item.transportStatus.some(
                                            s => s.tmsTransportStatus?.eventType === '349' && s.tmsTransportStatus?.reasonType === '209',
                                        )}
                                    />
                                </Card>
                            </div>
                            <div
                                className={classNames(
                                    styles['id-body-footer'],
                                    { [styles['is-documents-only']]: !item.invoices[0]?.fileId && !item.transportAnomalies?.length },
                                    { [styles['is-documents-anomalies-only']]: !item.invoices[0]?.fileId && item.transportAnomalies?.length },
                                )}
                            >
                                <div className={styles['id-body-footer-folder']}>
                                    <Card
                                        title={this.tKey('case')}
                                        iconName="FolderOpen"
                                        hasLitleDividerMarging
                                    >
                                        <FolderData
                                            lang={lang}
                                            agency={item.agency}
                                            contractor={item.contractor}
                                            transport={item.transportTmsId}
                                            remittanceEntry={item.remittanceEntry}
                                            gedmouvRef={item.gedmouvRef}
                                        />
                                    </Card>
                                </div>

                                <div className={styles['id-body-footer-documents']}>
                                    <Card
                                        title={this.tKey('documents')}
                                        iconName="Documentation"
                                        hasLitleDividerMarging
                                    >
                                        <DocumentsData
                                            lang={lang}
                                            items={item.transportFiles}
                                            handler={filesHandler}
                                        />
                                    </Card>
                                </div>

                                <div className={styles['id-body-footer-invoice']}>
                                    <Card
                                        title={this.tKey('invoice')}
                                        iconName="Documentation"
                                        hasLitleDividerMarging
                                    >
                                        <InvoiceData
                                            handler={filesHandler}
                                            lang={lang}
                                            file={item.invoices[0]?.file}
                                            price={item.invoices[0]?.amountIncludingTax}
                                        />
                                    </Card>
                                </div>
                                <div className={styles['id-body-footer-anomalies']}>
                                    <Card
                                        title={this.tKey('anomalies')}
                                        iconName="Documentation"
                                        hasLitleDividerMarging
                                    >
                                        <AnomaliesData
                                            items={item.transportAnomalies}
                                            lang={lang}
                                        />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <ShareTrackingLinkModal
                    handler={transportsHandler}
                    isVisible={isShareTrackingLinkModalVisible}
                    setIsVisible={newVal => this.setState({ isShareTrackingLinkModalVisible: newVal })}
                    lang={lang}
                    transportId={item.transportId}
                />
            </>
        )
    }
}

IdTransports.prototype.tKey = tKey
IdTransports.prototype.tObj = tObj
IdTransports.prototype.getStepTypes = getStepTypes
