import EProfile from 'types/users/enums/profile'

/**
 * List of client profiles
 * @type {EProfile[]}
 * @readonly
 */
export const clientProfiles = [
    EProfile.CustomerAdmin,
    EProfile.CustomOrderEntry,
    EProfile.CustomOrderTracking,
]

/**
 * List of user profiles
 * @type {EProfile[]}
 * @readonly
 */
export const userProfiles = [
    EProfile.Admin,
    EProfile.Commercial,
    EProfile.Operator,
    EProfile.AdValorem,
]

/**
 * List of user profiles with super admin
 * @type {EProfile[]}
 * @readonly
 */
export const userProfilesWithSuperAdmin = [
    EProfile.SuperAdmin,
    ...userProfiles,
]

/**
 * List of profiles
 * @type {EProfile[]}
 * @readonly
 */
const profiles = [
    ...userProfilesWithSuperAdmin,
    ...clientProfiles,
]

export default profiles
