import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import User, { ErrorUser } from 'requests/objects/user'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import EProfile from 'types/users/enums/profile'
import parseJson from 'helpers/methods/parseJson'
import ECompany from 'types/companies/enums/company'

/** @debug {import('.').AppProps} */

/**
 * @augments {PureComponent<import('.').AppProps>}
 */
export default class IdUsers extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.userId,
            /** @type {User} Element find from API */
            item: new User(),
            /** @type {User} Initial element fond from API. Used to refresh */
            iniItem: new User(),
            /** @type {ErrorUser} Errors */
            errorField: new ErrorUser(),
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const {
            match, setCommand, lang, command,
        } = this.props

        if (match?.params?.userId !== prevProps?.match?.params?.userId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(command.find(x => x.key === 'edit') ? this.commandRead : this.commandEdit)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.usersHandlerRemoveById?.cancel()
        this.usersHandlerGetById?.cancel()
        this.usersHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match } = this.props

        setBreadcrumb([
            { text: this.tKey('users'), key: 'user', href: '/users' },
            {
                text: match?.params?.userId ? `${this.tKey('user')} n°${match?.params?.userId}` : this.tKey('newUser'),
                key: 'one-user',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, usersHandler, setMessageBar,
        } = this.props

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorUser(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.userId,
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteUser'),
                        subTitle: this.tKey('sureDeleteUser'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.usersHandlerRemoveById = usersHandler.removeById(match?.params?.userId)
                                    await this.usersHandlerRemoveById.fetch()
                                    history.push('/users')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.userId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, usersHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.userId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.user) // If object came from history push with a create
                    this.setState({
                        item: location.state?.user,
                        iniItem: location.state?.user,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        this.usersHandlerGetById = usersHandler.getById(match?.params?.userId)
                        const user = await this.usersHandlerGetById.fetch()
                        this.setState({
                            item: user,
                            iniItem: user,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new User(),
                    iniItem: new User(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, usersHandler, match, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.usersHandlerUpsert = usersHandler.upsert(item, match?.params?.userId)
                const user = await this.usersHandlerUpsert.fetch()

                this.setState({
                    item: user,
                    iniItem: user,
                    status: Status.RESOLVED,
                    errorField: new ErrorUser(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.userId)
                    history.replace(`/users/${user.userId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    // /**
    //  * Function called when company is selected to get the roles authorized on the company selected
    //  * @param {number} companyId companyId
    //  */
    // fetchCompanyData(companyId) {
    //     const {
    //         setCommand, companiesHandler,
    //     } = this.props

    //     this.setState({ status: Status.PENDING }, async () => {
    //         try {
    //             this.companiesHandlerUpsert = companiesHandler.getById(companyId)
    //             const company = await this.companiesHandlerUpsert.fetch()

    //             this.setState({
    //                 companyData: company,
    //                 status: Status.RESOLVED,
    //             })
    //         } catch (error) {
    //             this.setState({ status: Status.REJECTED }, () => {
    //                 switch (error?.constructor) {
    //                     case CancelRequestError:
    //                     case UnauthorizedError: break
    //                     case NotImplementedError:
    //                         // eslint-disable-next-line no-console
    //                         console.error(error)
    //                         break
    //                     case InvalidEntityError:
    //                         this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
    //                         setCommand(this.commandEdit)
    //                         break
    //                     default:
    //                         setCommand(this.commandEdit)
    //                         // eslint-disable-next-line no-console
    //                         console.error(error)
    //                         break
    //                 }
    //             })
    //         }
    //     })
    // }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField,
        } = this.state

        const { param: { userProfiles, clients, operatingCenters }, me: { company, profileId } } = this.props

        if (status === Status.PENDING)
            return <Loader />

        const optionProfiles = userProfiles.filter(profile => {
            if (isReadOnly)
                return true

            if (+profile.key === EProfile.SuperAdmin)
                return profileId === EProfile.SuperAdmin

            if (+profile.key === EProfile.AdValorem)
                return company.companyId === ECompany.JetFreeze

            return company.roleIds.includes(+profile.key)
        }).map(({ key, text }) => ({
            key,
            text: this.tObj(parseJson(text)),
        }))

        return (
            <main>
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    <Card
                        title={this.tKey('information')}
                        iconName="PageData"
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('email')}
                                    value={item.email}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(_ev, newVal) => this.setState({ item: { ...item, email: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.email))}
                                    type="email"
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('profile')}
                                    readOnly={isReadOnly}
                                    placeholder={!isReadOnly ? this.tKey('select') : ''}
                                    options={optionProfiles}
                                    selectedKey={item.profileId}
                                    onChange={(_ev, option) => this.setState({ item: { ...item, profileId: option.key } })}
                                    errorMessage={this.tObj(parseJson(errorField.profileId))}
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            {item.profileId === EProfile.Commercial && (
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('clients')}
                                        placeholder={!isReadOnly ? this.tKey('select') : ''}
                                        required={!isReadOnly}
                                        readOnly={isReadOnly}
                                        options={clients}
                                        selectedKey={item.clientIds}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, clientIds: this.getUpdatedList(item.clientIds, option) } })}
                                        errorMessage={this.tObj(parseJson(errorField.clientIds))}
                                        multiSelect
                                    />
                                </Columns.Column>
                            )}
                            {item.profileId === EProfile.Operator && (
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('operatingCenters')}
                                        placeholder={isReadOnly ? '' : this.tKey('select')}
                                        readOnly={isReadOnly}
                                        required={!isReadOnly}
                                        options={operatingCenters?.map(x => ({
                                            key: x.key,
                                            text: this.tObj(this.parseJson(x.text)),
                                        }))}
                                        selectedKey={item.operatingCenterIds}
                                        onChange={(ev, option) => {
                                            ev.preventDefault()
                                            this.setState({ item: { ...item, operatingCenterIds: this.getUpdatedList(item.operatingCenterIds, option) } })
                                        }}
                                        multiSelect
                                        errorMessage={this.tObj(parseJson(errorField.operatingCenterIds))}
                                    />
                                </Columns.Column>
                            )}
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('name')}
                                    value={item.lastname}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, lastname: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.lastname))}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('firstname')}
                                    value={item.firstname}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, firstname: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.firstname))}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('registrationNumber')}
                                    value={item.registrationNumber}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, registrationNumber: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.registrationNumber))}
                                />
                            </Columns.Column>

                        </Columns>
                    </Card>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
            </main>
        )
    }
}

IdUsers.prototype.getUpdatedList = getUpdatedList
IdUsers.prototype.isValidDate = isValidDate
IdUsers.prototype.tKey = tKey
IdUsers.prototype.tObj = tObj
IdUsers.prototype.parseJson = parseJson
