import React, { PureComponent } from 'react'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { tKey, tObj } from 'helpers/methods/translate'
import Loader from 'components/visuals/loader'
import styles from 'styles/pages/tracking/[id].module.scss'
import WorkflowLight from 'components/pages/workflowLight'
import Card from 'components/containers/card'
import CompanyData from 'components/pages/companyData'
import FolderData from 'components/pages/folderData'
import StatusData from 'components/pages/statusData'
import MerchandiseData from 'components/pages/marchandiseData'
import Address from 'requests/objects/address'
import TransportData from 'components/pages/transportData'
import Transport from 'requests/objects/transport'
import DeliveryData from 'requests/objects/order/deliveryData'
import parseJson from 'helpers/methods/parseJson'
import User from 'requests/objects/user'
import getStepTypes from 'helpers/methods/getStepTypes'
import DocumentsData from 'components/pages/transports/documentsData'

/** @debug {import('app').AppProps} */

/**
 * @augments {React.PureComponent<import('app').AppProps>}
 */
export default class Tracking extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Transport} Transport data */
            item: new Transport({}),
        }
    }

    componentDidMount() {
        const {
            setBreadcrumb,
        } = this.props

        setBreadcrumb([])
        setBreadcrumb([{
            key: 'index',
            text: this.tKey('transportTracking'),
        }])
        this.getData()
    }

    componentWillUnmount() {
        this.transportsHandlerGetTrackingData?.cancel()
    }

    /**
     * Get tracking data
     */
    getData() {
        const {
            transportsHandler, match, setBreadcrumb, setMe, me,
        } = this.props

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.transportsHandlerGetTrackingData = transportsHandler.getTrackingData(match.params.orderToken)
                const transport = await this.transportsHandlerGetTrackingData.fetch()
                this.setState({
                    item: transport,
                    status: Status.RESOLVED,
                })
                setBreadcrumb([{
                    key: 'index',
                    text: `${this.tKey('transportTracking')} ${transport.transportTmsId}`,
                }])
                // We put company here to display logo in header
                setMe({
                    me: {
                        ...(me ?? new User()),
                        client: {
                            ...(me.client ?? transport.client),
                        },
                    },
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                        case InvalidEntityError:
                        default:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const {
            status, item,
        } = this.state
        const {
            lang, filesHandler,
        } = this.props

        if ([Status.PENDING, Status.IDLE].includes(status))
            return <Loader />

        if (Status.REJECTED === status)
            return null

        return (
            <>
                <WorkflowLight
                    steps={item.workflowTransportHistorics.map(wf => ({
                        label: this.tObj(parseJson(wf.workflowTransportStep?.text)) || wf.workflowTransportStep?.text || '',
                        isActive: wf.isActive,
                        isCompleted: wf.isCompleted,
                        isError: !!wf.error,
                        isWarning: wf.error === 'warning',
                        // description: wf.error || '',
                        isCanceled: wf.isCanceled,
                    }))}
                />
                <div className={styles['tracking-body-content']}>
                    <div className={styles['tracking-body-content-top']}>
                        <div className={styles['tracking-body-content-top-contractor']}>
                            <Card
                                title={this.tKey('contractor')}
                                iconName="DietPlanNotebook"
                                hasLitleDividerMarging
                            >
                                <CompanyData
                                    logoId={item.client.logoId}
                                    filesHandler={filesHandler}
                                    address={new Address({
                                        label: item.client.name,
                                        street: item.client.street,
                                        zipCode: item.client.zipCode,
                                        city: item.client.city,
                                        contact: {
                                            phoneNumber: item.client.phoneNumber,
                                            email: item.client.email,
                                        },
                                    })}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-top-transport']}>
                            <Card
                                title={this.tKey('carrier')}
                                iconName="DietPlanNotebook"
                                hasLitleDividerMarging
                            >
                                <CompanyData
                                    logoId={item.operatingCenter?.logoId ?? item.client.company.logoId}
                                    filesHandler={filesHandler}
                                    address={new Address({
                                        label: item.operatingCenter?.name,
                                        street: item.operatingCenter?.street,
                                        zipCode: item.operatingCenter?.zipCode,
                                        city: item.operatingCenter?.city,
                                        contact: {
                                            phoneNumber: item.operatingCenter?.phone,
                                            email: item.operatingCenter?.email,
                                        },
                                    })}
                                    subName={item.operatingCenter?.shortName}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-top-status']}>
                            <Card
                                title={this.tKey('status')}
                                iconName="Pinned"
                                hasLitleDividerMarging
                            >
                                <StatusData
                                    steps={this.getStepTypes(item.transportStatus)}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-top-merchandise']}>
                            <Card
                                title={this.tKey('merchandise')}
                                iconName="DeliveryTruck"
                                hasLitleDividerMarging
                            >
                                <MerchandiseData
                                    lang={lang}
                                    companyId={item.client.companyId}
                                    // We use this kind of workaround to use the same component
                                    items={[
                                        new DeliveryData({
                                            deliveryDataId: 1,
                                            deliveryContentData: {
                                                orderHandlings: item.orderHandlings,
                                            },
                                            deliveryAddressData: {},
                                            deliveryComplementData: {},
                                            deliveryInstructionsData: {},
                                            deliveryDateData: {},
                                            deliveryFileData: {},
                                        }),
                                    ]}
                                />
                            </Card>
                        </div>
                    </div>
                    <div className={styles['tracking-body-content-bottom']}>
                        <div className={styles['tracking-body-content-bottom-pickup']}>
                            <Card
                                title={this.tKey('pickup')}
                                iconName="DietPlanNotebook"
                                hasLitleDividerMarging
                            >
                                <TransportData
                                    address={item.pickupAddress}
                                    date={item.pickupDate}
                                    scheduledDate={item.pickupScheduledDate}
                                    lang={lang}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-bottom-delivery']}>
                            <Card
                                title={this.tKey('delivery')}
                                iconName="DietPlanNotebook"
                                hasLitleDividerMarging
                            >
                                <TransportData
                                    address={item.deliveryAddress}
                                    date={item.deliveryDate}
                                    scheduledDate={item.deliveryScheduledDate}
                                    lang={lang}
                                    isDelivery
                                    plannedThe={item.transportStatus.some(
                                        s => s.tmsTransportStatus?.eventType === '349' && s.tmsTransportStatus?.reasonType === '209',
                                    )}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-bottom-folder']}>
                            <Card
                                title={this.tKey('case')}
                                iconName="FolderOpen"
                                hasLitleDividerMarging
                            >
                                <FolderData
                                    lang={lang}
                                    agency={item.agency}
                                    contractor={item.contractor}
                                    transport={item.transportTmsId}
                                    remittanceEntry={item.remittanceEntry}
                                    gedmouvRef={item.gedmouvRef}
                                />
                            </Card>
                        </div>
                        <div className={styles['tracking-body-content-bottom-documents']}>
                            <Card
                                title={this.tKey('documents')}
                                iconName="Documentation"
                                hasLitleDividerMarging
                            >
                                <DocumentsData
                                    lang={lang}
                                    items={item.transportFiles.filter(x => x.category !== 'TRA')}
                                    handler={filesHandler}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

Tracking.prototype.tKey = tKey
Tracking.prototype.tObj = tObj
Tracking.prototype.getStepTypes = getStepTypes
