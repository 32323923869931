import DeliveryLogo from 'assets/img/delivery.png'
import RecoveryLogo from 'assets/img/recovery.png'
import RedeliveryLogo from 'assets/img/redelivery.png'
import DocksideShedLogo from 'assets/img/dockside-shed.png'
import EOrderType from 'types/orders/enums/orderType'

/**
 * Get order type logo
 * @param {EOrderType} orderTypeId orderTypeId
 * @returns {string} logo
 */
export default function getOrderTypeLogo(orderTypeId) {
    switch (orderTypeId) {
        case EOrderType.Reprise:
            return RecoveryLogo
        case EOrderType.Redelivery:
            return RedeliveryLogo
        case EOrderType.DocksideShed:
            return DocksideShedLogo
        case EOrderType.Delivery:
        default:
            return DeliveryLogo
    }
}
