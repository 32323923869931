/**
 * Open days Object
 */
export default class OpenDays {
    /**
     * @param {object} data data
     * @param {boolean=} data.monday monday
     * @param {boolean=} data.tuesday tuesday
     * @param {boolean=} data.wednesday wednesday
     * @param {boolean=} data.thursday thursday
     * @param {boolean=} data.friday friday
     * @param {boolean=} data.saturday saturday
     * @param {boolean=} data.sunday sunday
     */
    constructor({
        monday = true,
        tuesday = true,
        wednesday = true,
        thursday = true,
        friday = true,
        saturday = true,
        sunday = false,
    } = {}) {
        this.monday = monday
        this.tuesday = tuesday
        this.wednesday = wednesday
        this.thursday = thursday
        this.friday = friday
        this.saturday = saturday
        this.sunday = sunday
    }
}
