import {
    Slice,
    PayloadAction,
    createSlice,
} from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from 'redux'
import User from 'requests/objects/user'
import Param, { ParamElement } from 'requests/objects/param'
import UsersHandler from 'requests/handlers/usersHandler'

/**
 * User middlewares, must have "getDefaultMiddleware"
 * @type {Middleware[]}
 */
const userMiddleware = []

/**
 * Payload Init
 * @typedef {object} PayloadInit
 * @property {User} me User data
 * @property {Param} param Param data
 * @property {Date} lastUpdate Param data
 */
/**
 * Payload SingIn
 * @typedef {object} PayloadSingIn Token
 * @property {string} accessToken accessToken
 * @property {string} refreshToken refreshToken
 */
/**
 * Payload Param
 * @typedef {object} PayloadParam
 * @property {keyof Param=} key Key of params type
 * @property {Partial<ParamElement>} value Object param
 */
/**
 * Payload Me
 * @typedef {object} PayloadMe
 * @property {User} me User data
 */
/**
 * Payload Params
 * @typedef {object} PayloadParams
 * @property {Param} params Param data
 */

/**
 * User State
 * @typedef {object} UserState
 * @property {boolean} isAuthenticated Is user authenticated
 * @property {User} me User informations
 * @property {Param} param Constants
 * @property {Date} lastUpdate Param data
 */

/**
 * User Slice
 * @type {Slice<UserState>}
 */
const userSlice = createSlice({
    name: 'user',
    /** @type {UserState} */
    initialState: {
        isAuthenticated: !!localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`),
        me: new User(),
        param: new Param(),
        lastUpdate: null,
    },
    reducers: {
        /**
         * Sign in
         * @param {UserState} state state
         * @param {PayloadAction<PayloadSingIn>} action action
         */
        signIn: (state, action) => {
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`, action.payload.accessToken)
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token_refresh`, action.payload.refreshToken)
            // eslint-disable-next-line no-param-reassign
            state.isAuthenticated = true
        },
        /**
         * Sign out
         * @param {UserState} state state
         */
        signOut: state => {
            if (localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token_refresh`)
                && localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`))
                new UsersHandler()
                    .logout({
                        refreshToken: localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token_refresh`),
                        accessToken: localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`),
                    })
                    .fetch()
                    .catch(console.error) // eslint-disable-line no-console

            // caches.delete(`${process.env.REACT_APP_BASE_STORAGE_KEY}-api`)
            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`)
            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token_refresh`)
            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`)
            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`)
            localStorage.removeItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`)
            // eslint-disable-next-line no-param-reassign
            state.isAuthenticated = false
        },
        /**
         * Init
         * @param {UserState} state state
         * @param {PayloadAction<PayloadInit>} action action
         */
        init: (state, action) => {
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`, JSON.stringify(action.payload?.me))
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`, JSON.stringify(action.payload?.param))
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_last_update`, action.payload?.lastUpdate?.toISOString())

            // eslint-disable-next-line no-param-reassign
            state.me = action.payload?.me
            // eslint-disable-next-line no-param-reassign
            state.param = action.payload?.param
            // eslint-disable-next-line no-param-reassign
            state.lastUpdate = action.payload?.lastUpdate
        },
        /**
         * setMe
         * @param {UserState} state state
         * @param {PayloadAction<PayloadMe>} action action
         */
        setMe: (state, action) => {
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_me`, JSON.stringify(action.payload?.me))

            // eslint-disable-next-line no-param-reassign
            state.me = action.payload?.me
        },
        /**
         * setParams
         * @param {UserState} state state
         * @param {PayloadAction<PayloadParams>} action action
         */
        setParams: (state, action) => {
            localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_param`, JSON.stringify(action.payload?.params))

            // eslint-disable-next-line no-param-reassign
            state.param = action.payload?.params
        },
        /**
         * Add item to constantes
         * @param {UserState} state state
         * @param {PayloadAction<PayloadParam>} action action
         */
        addParam: (state, action) => {
            if (action.payload?.key)
                // eslint-disable-next-line no-param-reassign
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key],
                    /** @type {ParamElement} */(action.payload?.value),
                ]
        },
        /**
         * Edit param from constant
         * @param {UserState} state state
         * @param {PayloadAction<PayloadParam>} action action
         */
        editParam: (state, action) => {
            if (action.payload?.key)
                // eslint-disable-next-line no-param-reassign
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key],
                ]
                    .map(x => (x.key === action.payload?.value?.key ? /** @type {ParamElement} */(action.payload?.value) : x))
        },
        /**
         * Remove param from constant
         * @param {UserState} state state
         * @param {PayloadAction<PayloadParam>} action action
         */
        removeParam: (state, action) => {
            if (action.payload?.key)
                // eslint-disable-next-line no-param-reassign
                state.param[action.payload?.key] = [
                    ...state.param[action.payload?.key],
                ]
                    .filter(x => x.key !== action.payload?.value.key)
        },
    },
})

const {
    init, signIn, signOut, addParam, editParam, removeParam, setMe, setParams,
} = userSlice.actions
const userReducer = userSlice.reducer

export {
    init, signIn, signOut, addParam, editParam, removeParam, setMe, setParams, // Reducers, used to call actions
    userReducer, // All reducers, used to create store
    userMiddleware, // Middleware
}
