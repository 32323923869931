import React, { PureComponent } from 'react'
import {
    TextField, PrimaryButton, Text, SpinnerSize, Spinner, MessageBarType,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import history from 'helpers/history'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import EAuthStrategy from 'types/users/enums/authStrategy'
import { tKey, tObj } from 'helpers/methods/translate'
import { GoogleLogin } from '@react-oauth/google'
import parseJson from 'helpers/methods/parseJson'

/** @debug {AppProps} */

/**
 * @augments {React.PureComponent<AppProps>}
 */
export default class Login extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {string} email */
            email: '',
            /** @type {string} Password */
            plainPassword: '',
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {ErrorUser} */
            errorField: new ErrorUser(),
        }
    }

    componentDidMount() {
        const {
            setBreadcrumb, setCommand, isAuthenticated, messageBar, setMessageBar,
        } = this.props

        setBreadcrumb([])
        setCommand([])

        if (messageBar.type !== MessageBarType.error)
            setMessageBar({ isDisplayed: false })

        if (isAuthenticated)
            history.push('/')
    }

    componentWillUnmount() {
        this.usersHandlerLogin?.cancel()
    }

    /**
     * @param {EAuthStrategy} strategy strategy
     * @param {string} googleToken googleToken
     */
    login(strategy, googleToken = '') {
        const { usersHandler, signIn } = this.props
        const { email, plainPassword } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.usersHandlerLogin = usersHandler.login({
                    email,
                    password: plainPassword,
                    strategy,
                    googleToken,
                })
                const user = await this.usersHandlerLogin.fetch()
                signIn({ accessToken: user.accessToken, refreshToken: user.refreshToken })
                history.push('/')
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            break
                        default:
                            this.setState({ errorField: new ErrorUser() })
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const {
            email, plainPassword, status, errorField,
        } = this.state
        const { setMessageBar } = this.props

        return (
            <main>
                <form
                    onSubmit={e => {
                        e.preventDefault()
                        this.login(EAuthStrategy.classic)
                    }}
                >
                    <TextField
                        label={this.tKey('email')}
                        placeholder={this.tKey('email')}
                        value={email}
                        onChange={ev => this.setState({ email: /** @type {HTMLInputElement} */(ev.target).value })}
                        // iconProps={{ iconName: 'Mail' }}
                        readOnly={status === Status.PENDING}
                        autoComplete="username"
                        errorMessage={this.tObj(parseJson(errorField.email))}
                        required
                    />
                    <br />
                    <TextField
                        label={this.tKey('password')}
                        placeholder={this.tKey('password')}
                        type="password"
                        value={plainPassword}
                        onChange={ev => this.setState({ plainPassword: /** @type {HTMLInputElement} */(ev.target).value })}
                        // iconProps={{ iconName: 'PasswordField' }}
                        readOnly={status === Status.PENDING}
                        autoComplete="current-password"
                        errorMessage={this.tObj(parseJson(errorField.password))}
                        required
                        canRevealPassword
                    />
                    <br />
                    <div className="flex-row">
                        <PrimaryButton
                            iconProps={{ iconName: 'FollowUser' }}
                            text={this.tKey('login')}
                            type="submit"
                            disabled={status === Status.PENDING}
                        >
                            {status === Status.PENDING && (
                                <Spinner size={SpinnerSize.small} />
                            )}
                        </PrimaryButton>
                    </div>
                    <Text
                        style={{ marginTop: '1rem', display: 'block' }}
                        as="p"
                    >
                        <Link
                            to="/reset-password"
                        >
                            {this.tKey('passwordForgotten')}
                        </Link>
                    </Text>
                    <hr />
                    <Text>{this.tKey('memberOfMousset')}</Text>
                    <div
                        className="flex-row"
                        style={{ marginTop: '.5rem' }}
                    >
                        <GoogleLogin
                            onSuccess={response => {
                                this.login(EAuthStrategy.google, response.credential)
                            }}
                            onError={() => {
                                setMessageBar({
                                    isDisplayed: true,
                                    type: MessageBarType.error,
                                    message: this.tKey('anErrorHasOccurred'),
                                })
                            }}
                        />
                    </div>
                </form>
            </main>
        )
    }
}

Login.prototype.tKey = tKey
Login.prototype.tObj = tObj
