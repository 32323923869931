import React, {
    useCallback, useMemo,
} from 'react'
import {
    DatePicker as FluentuiDatePicker, Calendar, defaultCalendarStrings, DayOfWeek,
} from '@fluentui/react'
import 'react-phone-input-2/lib/style.css'
import { createOpenDaysDateRange } from 'helpers/methods/createDateRange'
import Time from 'helpers/methods/time'
import useTranslate from 'helpers/hooks/useTranslate'
import OpenDays from 'requests/objects/openDays'

const calendarStrings = {
    en: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        goToToday: 'Go to today',
    },
    fr: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        goToToday: "Aller à aujourd'hui",
    },
    nl: {
        months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
        shortMonths: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        days: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
        shortDays: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
        goToToday: 'Ga naar vandaag',
    },
}

/**
 * @typedef {object} DatePickerProps
 * @property {Date=} date date
 * @property {(date: Date) => void} onChangeDate onChangeDate
 * @property {string=} label label
 * @property {Date=} minDate minDate
 * @property {string=} [lang] lang
 * @property {OpenDays} [openDays] openDays
 * @property {boolean=} [borderless] borderless
 * @property {boolean=} [required] required
 * @property {boolean=} [readOnly] readOnly
 * @property {string=} [errorMessage] errorMessage
 */

/**
 * Date Picker
 * @param {DatePickerProps} props props
 * @returns {JSX.Element} Element
 */
export default function DatePicker({
    date,
    onChangeDate,
    label,
    minDate = new Date(),
    lang = 'en',
    openDays = null,
    borderless = false,
    required = false,
    readOnly = false,
    errorMessage = '',
}) {
    const { tKey } = useTranslate({ lang })
    const [
        maxDate,
        dateRange,
    ] = useMemo(
        () => {
            const max = new Date(minDate)
            max.setMonth(max.getMonth() + 6) // max date is month later than min date

            return [
                max,
                openDays ? createOpenDaysDateRange(openDays, minDate, max) : [],
            ]
        },
        [minDate, openDays],
    )

    const CalendarAs = useCallback(calendarProps => (
        <Calendar
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...calendarProps}
            isMonthPickerVisible={false}
            autoNavigateOnSelection
            showCloseButton
            showMonthPickerAsOverlay
            showSixWeeksByDefault
            firstDayOfWeek={DayOfWeek.Monday}
            minDate={minDate}
            maxDate={maxDate}
            restrictedDates={dateRange}
            strings={{
                ...defaultCalendarStrings,
                ...calendarStrings[lang],
            }}
        />
    ), [dateRange, lang, maxDate, minDate])

    return (
        <FluentuiDatePicker
            placeholder={date ? Time(date).getStandardDateStringFromDate() : tKey('chooseDate')}
            value={date}
            formatDate={d => Time(d).getStandardDateStringFromDate()}
            calendarAs={CalendarAs}
            isRequired={required}
            styles={{
                root: readOnly ? { pointerEvents: 'none' } : {},
                icon: readOnly ? { display: 'none' } : {},
            }}
            onSelectDate={dt => {
                if (!date || (dt?.toDateString() !== date?.toDateString())) {
                    const newDate = Time(dt).set(7, 'hour')
                    onChangeDate(new Date(newDate))
                } else {
                    onChangeDate(undefined)
                }
            }}
            textField={{
                borderless,
                required,
                label,
                readOnly,
                errorMessage,
            }}
        />
    )
}
