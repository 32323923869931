/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * Profile Object
 */
export default class Profile extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.profileId profileId
     * @param {string=} data.name name
     */
    constructor({
        profileId = 0,
        name = '',
        ...data
    } = {}) {
        super(data)
        this.profileId = profileId
        this.name = name
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorProfile extends ErrorBase {
    constructor() {
        super()
        this.profileId = ''
        this.name = ''
    }
}
