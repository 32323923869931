import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Department, { ErrorDepartment } from 'requests/objects/department'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import usePrevious from 'helpers/hooks/usePrevious'
import Param from 'requests/objects/param'
import parseJson from 'helpers/methods/parseJson'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import OpenWeekDays from 'components/pages/orders/[id]/client/common/openWeekDays'

/**
 * department modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {Partial<Department>} props.departmentData departmentData must send departmentId prop at creation
 * @param {(department: Department) => void} props.onChange Callback to complete the department form
 * @param {string} props.lang lang
 * @param {Param} props.param param
 * @returns {JSX.Element} department modal form to create/update a department
 */
export default function DepartmentModal({
    isVisible,
    companiesHandler,
    departmentData,
    onChange,
    lang,
    param,
}) {
    const [department, setDepartment] = useState(new Department(departmentData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorDepartment())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<Department>>} */
    const upsertDepartmentHandler = useRef()

    const prevIsVisible = usePrevious(isVisible)

    // Set logistic constraint data for edition or creation. Ensure that data in modal are up to date
    useEffect(() => {
        if (isVisible && prevIsVisible !== isVisible) {
            setErrorMessage(null)
            setErrorField(new ErrorDepartment())
            setDepartment(new Department(departmentData))
        }
    }, [departmentData, isVisible, prevIsVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertDepartmentHandler.current = companiesHandler.upsertDepartment(department.companyId, department, department.departmentId)
            const departmentResult = await upsertDepartmentHandler.current.fetch()
            onChange(departmentResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorDepartment>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [companiesHandler, department, onChange])

    // On component did unmount
    useEffect(() => () => {
        upsertDepartmentHandler.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: department?.departmentId ? tKey('modalEditDepartmentTitle') : tKey('modalDepartmentTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                    style={{ display: 'flex', gap: '20px' }}
                />
                <TextField
                    label={tKey('code')}
                    placeholder={tKey('code')}
                    value={department.code || ''}
                    onChange={(ev, newVal) => setDepartment(prevState => ({ ...prevState, code: newVal }))}
                    required
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.code)}
                />
                <FilteredVirtualCombobox
                    label={tKey('country')}
                    placeholder={tKey('select')}
                    options={param.countries.map(({ key, text }) => ({
                        key,
                        text: tObj(parseJson(text)),
                    }))}
                    required
                    selectedKey={department.countryId}
                    disabled={status === Status.PENDING}
                    onChange={(_ev, option) => setDepartment(prev => ({ ...prev, countryId: option.key }))}
                    errorMessage={tObj(errorField.countryId)}
                />
                <FilteredVirtualCombobox
                    label={tKey('operatingCenter')}
                    placeholder={tKey('select')}
                    options={param.operatingCenters.map(({ key, text }) => ({
                        key,
                        text: tObj(parseJson(text)),
                    }))}
                    required
                    selectedKey={department.operatingCenterId}
                    disabled={status === Status.PENDING}
                    onChange={(_ev, option) => setDepartment(prev => ({ ...prev, operatingCenterId: option.key }))}
                    errorMessage={tObj(errorField.operatingCenterId)}
                />
                <TextField
                    label={tKey('pickupZone')}
                    placeholder={tKey('pickupZone')}
                    value={department.pickupZoneId?.toString() || ''}
                    type="number"
                    required
                    onChange={(ev, newVal) => setDepartment(prevState => ({ ...prevState, pickupZoneId: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.pickupZoneId)}
                />
                <OpenWeekDays
                    label={tKey('pickupDay')}
                    lang={lang}
                    isReadOnly={false}
                    openDays={{
                        monday: department.mondayPickup,
                        tuesday: department.tuesdayPickup,
                        wednesday: department.wednesdayPickup,
                        thursday: department.thursdayPickup,
                        friday: department.fridayPickup,
                        saturday: department.saturdayPickup,
                        sunday: department.sundayPickup,
                    }}
                    onChange={(day, value) => {
                        setDepartment(prev => ({ ...prev, [`${day}Pickup`]: value }))
                    }}
                />
                <TextField
                    label={tKey('deliveryZone')}
                    placeholder={tKey('deliveryZone')}
                    value={department.deliveryZoneId?.toString() || ''}
                    type="number"
                    required
                    onChange={(ev, newVal) => setDepartment(prevState => ({ ...prevState, deliveryZoneId: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.deliveryZoneId)}
                />
                <OpenWeekDays
                    label={tKey('deliveryDay')}
                    lang={lang}
                    isReadOnly={false}
                    openDays={{
                        monday: department.mondayDelivery,
                        tuesday: department.tuesdayDelivery,
                        wednesday: department.wednesdayDelivery,
                        thursday: department.thursdayDelivery,
                        friday: department.fridayDelivery,
                        saturday: department.saturdayDelivery,
                        sunday: department.sundayDelivery,
                    }}
                    onChange={(day, value) => {
                        setDepartment(prev => ({ ...prev, [`${day}Delivery`]: value }))
                    }}
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
