import Invoice, { ErrorInvoice } from 'requests/objects/invoice'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'

/**
 * InvoicesHandler
 * @augments {ApiHandler<Invoice, ErrorInvoice>}
 */
export default class InvoicesHandler extends ApiHandler {
    constructor() {
        super({ type: Invoice, errorType: ErrorInvoice, key: 'invoices' })
    }
}
