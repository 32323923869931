import Base from 'requests/objects/_base'

/**
 * WorkflowStep Object
 */
export default class WorkflowStep extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.workflowStepId workflowStepId
     * @param {string=} data.name name
     * @param {string=} data.text text
     * @param {string=} data.message message
     */
    constructor({
        workflowStepId,
        name,
        text,
        message,
        ...data
    } = {}) {
        super(data)
        this.workflowStepId = workflowStepId
        this.name = name
        this.text = text
        this.message = message
    }
}
