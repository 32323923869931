import { Text } from '@fluentui/react'
import classNames from 'classnames'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import FilesHandler from 'requests/handlers/filesHandler'
import Address from 'requests/objects/address'
import styles from 'styles/components/pages/company-data.module.scss'
import Status from 'types/status'

const defaultLogo = require('assets/img/logo.png').toString()

/**
 * CompanyData
 * @param {object} props props
 * @param {number} props.logoId logoId
 * @param {Address} props.address address
 * @param {string} [props.subName] subName
 * @param {FilesHandler} props.filesHandler filesHandler
 * @returns {JSX.Element} Returns
 */
export default function CompanyData({
    logoId,
    address,
    subName = null,
    filesHandler,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [logoSrc, setLogoSrc] = useState(null)

    /** @type {React.MutableRefObject<import("requests/apiHandler").RequestApi<Blob>>} */
    const getImgFilesHandler = useRef()

    /** Get logo from api */
    const fetchLogo = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            getImgFilesHandler.current = filesHandler.getPublicFileById(logoId, false)
            const img = await getImgFilesHandler.current.fetch()
            if (img)
                setLogoSrc(window.URL.createObjectURL(img))
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [filesHandler, logoId])

    // Get logo when company id change
    useEffect(() => {
        getImgFilesHandler.current?.cancel()
        if (logoId)
            fetchLogo()
    }, [fetchLogo, logoId])

    // On unmount
    useEffect(() => () => {
        getImgFilesHandler.current?.cancel()
    }, [])

    return (
        <div className={styles['company-data']}>
            <div className={styles['company-data-content']}>
                <div
                    className={classNames(
                        styles['company-data-content-img'],
                        { [styles['is-visible']]: ![Status.PENDING, Status.IDLE].includes(status) || logoSrc },
                    )}
                >
                    <img
                        src={logoSrc || defaultLogo}
                        alt="logo"
                    />
                </div>
                <Text className={styles['company-data-content-desc']}>
                    <b>{address?.label ?? ''}</b>
                    {subName && (
                        <>
                            <br />
                            <i>{subName}</i>
                        </>
                    )}
                    <br />
                    {address?.street ?? ''}
                    <br />
                    {`${address?.zipCode ?? ''}, ${address?.city ?? ''}`}
                </Text>
            </div>
            <Text className={styles['company-data-contact']}>
                <a href={`tel:${address?.contact?.phoneNumber ?? ''}`}>
                    +
                    {address?.contact?.phoneNumber ?? ''}
                </a>
                <br />
                <a href={`mailto:${address?.contact?.email ?? ''}`}>{address?.contact?.email ?? ''}</a>
            </Text>
        </div>
    )
}
