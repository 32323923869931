import User, { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'

/**
 * UsersHandler
 * @augments {ApiHandler<User, ErrorUser>}
 */
export default class ClientAccountsHandler extends ApiHandler {
    constructor() {
        super({ type: User, errorType: ErrorUser, key: 'client-accounts' })
        this.baseUrl = `${this.baseUrl}users/`
    }
}
