import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField, IconButton, Label,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import TransportsHandler from 'requests/handlers/transportsHandler'

/**
 * TrackingLinkModal
 * @param {object} props Props
 * @param {number} props.transportId transportId
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {string} props.lang lang
 * @param {TransportsHandler} props.handler handler
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function ShareTrackingLinkModal({
    transportId,
    isVisible,
    setIsVisible,
    lang,
    handler,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorField, setErrorField] = useState({})
    const { tKey, tObj } = useTranslate({ lang })
    const [emails, setEmails] = useState([''])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<any>>} */
    const shareTransportHandler = useRef()

    const updateEmail = useCallback(
        /**
         * @param {number} emailIndex index
         * @param {string} email email
         */
        (emailIndex, email) => {
            setEmails(prevEmails => {
                const newEmails = [...prevEmails]

                newEmails[emailIndex] = email

                return newEmails
            })
        }, [],
    )

    const sendEmails = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            shareTransportHandler.current = handler.share(transportId, emails)
            await shareTransportHandler.current.fetch()
            setEmails([''])
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<any>} */(error).errorField)
                    setErrorMessage(error?.message ?? error ?? 'anErrorHasOccurred')
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [emails, handler, setIsVisible, transportId])

    // On component did unmount
    useEffect(() => () => {
        shareTransportHandler.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: tKey('sendTrackingLink'),
                subText: tKey('shareTrackingLinkSubtitle'),
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="555px"
        >
            {errorMessage
                && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        truncated
                        onDismiss={() => setErrorMessage(null)}
                    >
                        {(typeof errorMessage === 'string'
                            ? tKey(/** @type {any} */(errorMessage))
                            : tObj(/** @type {any} */(errorMessage)))
                            || errorMessage?.toString()}
                    </MessageBar>
                )}
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    sendEmails()
                }}
            >
                {emails.map((email, index) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}
                    >
                        <div style={{ flex: '1' }}>
                            <TextField
                                label={tKey('emails')}
                                placeholder={tKey('email')}
                                value={email}
                                onChange={(ev, newVal) => updateEmail(index, newVal)}
                                disabled={status === Status.PENDING}
                                errorMessage={tObj(errorField[`emails.${index}.email`])}
                                type="email"
                                required
                                onRenderLabel={(props, defaultRender) => (index > 0 ? null : defaultRender(props))}
                            />
                        </div>
                        <div>
                            {index === 0 && <Label>&#8203;</Label>}
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => setEmails(prevEmails => prevEmails.filter((_, i) => i !== index))}
                                disabled={emails?.length <= 1}
                            />
                        </div>
                    </div>
                ))}
                <DefaultButton
                    iconProps={{ iconName: 'CirclePlus' }}
                    text={tKey('add')}
                    onClick={() => setEmails(prevEmails => [...prevEmails, ''])}
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text={tKey('send')}
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
