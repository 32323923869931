/**
 * @enum {number}
 */
const EdeliveryDateType = {
    CurrentWeek: 1,
    WeekPlusOne: 2,
    MandatoryDate: 3,
}

export default EdeliveryDateType
