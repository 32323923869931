import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField,
} from '@fluentui/react'
import history from 'helpers/history'
import HomeArticle, { ErrorHomeArticle } from 'requests/objects/homeArticle'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import parseJson from 'helpers/methods/parseJson'
import EProfile from 'types/users/enums/profile'
import PreviewFileInput from 'components/pages/home-articles/[id]/previewFileInput'
import FileInput from 'components/inputs/fileInput'
import EFileFolder from 'types/files/enums/fileFolder'
import DatePicker from 'components/inputs/datePicker'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdHomeArticles extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.homeArticleId,
            /** @type {HomeArticle} Element find from API */
            item: new HomeArticle(),
            /** @type {HomeArticle} Initial element fond from API. Used to refresh */
            iniItem: new HomeArticle(),
            /** @type {ErrorHomeArticle} Errors */
            errorField: new ErrorHomeArticle(),
            /** @type {globalThis.File} tempFile */
            tempFile: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const {
            match, setCommand, lang, command,
        } = this.props

        if (match?.params?.homeArticleId !== prevProps?.match?.params?.homeArticleId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(command.find(x => x.key === 'edit') ? this.commandRead : this.commandEdit)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.homeArticlesHandlerRemoveById?.cancel()
        this.homeArticlesHandlerGetById?.cancel()
        this.homeArticlesHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match } = this.props

        setBreadcrumb([
            { text: this.tKey('homeArticles'), key: 'homeArticle', href: '/home-articles' },
            {
                text: match?.params?.homeArticleId ? `${this.tKey('homeArticle')} n°${match?.params?.homeArticleId}` : this.tKey('newHomeArticle'),
                key: 'one-homeArticle',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, homeArticlesHandler, setMessageBar,
        } = this.props

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: false,
            },
        ]

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorHomeArticle(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.homeArticleId,
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteHomeArticle'),
                        subTitle: this.tKey('sureDeleteHomeArticle'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.homeArticlesHandlerRemoveById = homeArticlesHandler.removeById(match?.params?.homeArticleId)
                                    await this.homeArticlesHandlerRemoveById.fetch()
                                    history.push('/home-articles')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.homeArticleId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, homeArticlesHandler, setMessageBar,
        } = this.props

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.homeArticleId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.homeArticle) // If object came from history push with a create
                    this.setState({
                        item: location.state?.homeArticle,
                        iniItem: location.state?.homeArticle,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        setCommand(this.commandRead)
                    })
                else
                    try {
                        this.homeArticlesHandlerGetById = homeArticlesHandler.getById(match?.params?.homeArticleId)
                        const homeArticle = await this.homeArticlesHandlerGetById.fetch()
                        this.setState({
                            item: homeArticle,
                            iniItem: homeArticle,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new HomeArticle(),
                    iniItem: new HomeArticle(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, homeArticlesHandler, filesHandler, match, setMessageBar,
        } = this.props
        const { item, tempFile } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.homeArticlesHandlerUpsert = homeArticlesHandler.upsert(item, match?.params?.homeArticleId)
                const homeArticle = await this.homeArticlesHandlerUpsert.fetch()

                if (tempFile) {
                    setMessageBar({ isDisplayed: false })
                    this.handlerUploadFile = filesHandler.uploadFile(
                        homeArticle?.homeArticleId,
                        EFileFolder.HomeArticle,
                        tempFile,
                    )
                    homeArticle.file = await this.handlerUploadFile.fetch()
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' })
                }

                this.setState({
                    item: homeArticle,
                    iniItem: homeArticle,
                    status: Status.RESOLVED,
                    errorField: new ErrorHomeArticle(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.homeArticleId)
                    history.replace(`/home-articles/${homeArticle.homeArticleId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorHomeArticle>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, tempFile,
        } = this.state
        const { param, me, filesHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main>
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    <Card
                        title={this.tKey('information')}
                        iconName="PageData"
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('title')}
                                    placeholder={this.tKey('title')}
                                    value={item.title}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, title: newVal } })}
                                    errorMessage={errorField.title}
                                    required={!isReadOnly}

                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('category')}
                                    placeholder={this.tKey('category')}
                                    value={item.category}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, category: newVal } })}
                                    errorMessage={errorField.category}
                                    required={!isReadOnly}

                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <DatePicker
                                    date={item.startDate}
                                    label={tKey('startDate')}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChangeDate={newVal => {
                                        let startDate = new Date(newVal)
                                        if (!this.isValidDate(startDate))
                                            startDate = null

                                        this.setState({
                                            item: {
                                                ...item,
                                                startDate,
                                                endDate: null,
                                            },
                                        })
                                    }}
                                    required={!isReadOnly}
                                    lang={me.language}
                                    minDate={new Date()}
                                    errorMessage={!!errorField.startDate[0] && this.tKey('dateMustBeSelected')}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <DatePicker
                                    date={item.endDate}
                                    label={tKey('endDate')}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChangeDate={newVal => this.setState({
                                        item: { ...item, endDate: this.isValidDate(new Date(newVal)) ? new Date(newVal) : null },
                                    })}
                                    required={!isReadOnly}
                                    lang={me.language}
                                    minDate={item.startDate}
                                    errorMessage={!!errorField.endDate[0] && this.tKey('dateMustBeSelected')}
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('url')}
                                    placeholder={this.tKey('url')}
                                    value={item.url}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, url: newVal } })}
                                    errorMessage={errorField.url}
                                    required={!isReadOnly}
                                    type="url"
                                />
                            </Columns.Column>
                            {me.profileId === EProfile.SuperAdmin && (
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('companies')}
                                        readOnly={isReadOnly}
                                        options={param.companies.map(x => ({ ...x, text: this.tObj(parseJson(x.text)) }))}
                                        selectedKey={item.companyIds}
                                        onChange={(ev, option) => {
                                            this.setState({
                                                item: {
                                                    ...item,
                                                    companyIds: this.getUpdatedList(item.companyIds, option),
                                                },
                                            })
                                        }}
                                        errorMessage={errorField.companyIds}
                                        required={!isReadOnly}
                                        multiSelect
                                    />
                                </Columns.Column>
                            )}
                            <Columns.Column size="one-quarter">
                                {item.homeArticleId
                                    ? (
                                        <PreviewFileInput
                                            handler={filesHandler}
                                            fileFolderId={EFileFolder.HomeArticle}
                                            entityId={item.homeArticleId}
                                            isReadOnly={isReadOnly}
                                            file={item.file}
                                            updateItem={newFile => this.setState({ item: { ...item, file: newFile } })}
                                            isRequired={!isReadOnly}
                                        />
                                    )
                                    : (
                                        <FileInput
                                            label="Image"
                                            isReadOnly={false}
                                            isDisabled={status === Status.PENDING}
                                            fileName={tempFile?.name}
                                            tooltipContent=""
                                            onDownload={() => null}
                                            onUpload={file => Promise.resolve(this.setState({ tempFile: file }))}
                                            onDelete={() => Promise.resolve(this.setState({ tempFile: null }))}
                                            isRequired={!isReadOnly}
                                        />
                                    )}
                            </Columns.Column>
                        </Columns>
                    </Card>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
            </main>
        )
    }
}

IdHomeArticles.prototype.getUpdatedList = getUpdatedList
IdHomeArticles.prototype.isValidDate = isValidDate
IdHomeArticles.prototype.tKey = tKey
IdHomeArticles.prototype.tObj = tObj
