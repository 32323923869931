/**
 * @enum {number}
 */
const EWorkflowStep = {
    Creation: 1,
    AddressValidation: 2,
    OperatorsValidation: 3,
    OrderPlacedInImagine: 4,
}

export default EWorkflowStep
