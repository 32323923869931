/**
 * @enum {number}
 */
const EFileFolder = {
    HomeArticle: 1,
    CompanyLogo: 2,
    ClientLogo: 3,
    OrderDocument: 4,
    OperatingCenterLogo: 5,
    Invoice: 6,
    Document: 7,
    TransportFile: 8,
}

export default EFileFolder
