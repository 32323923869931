import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField, Toggle,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Article, { ErrorArticle } from 'requests/objects/article'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import ClientsHandler from 'requests/handlers/clientsHandler'

/**
 * article modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {number} props.clientId clientId
 * @param {ClientsHandler} props.handler handler
 * @param {Partial<Article>} [props.articleData] articleData must send articleId prop at creation
 * @param {(article: Article) => void} props.onChange Callback to complete the article form
 * @param {string} props.lang lang
 * @param {boolean} [props.isReadOnly] isReadOnly
 * @returns {JSX.Element} article modal form to create/update a article
 */
export default function ArticleModal({
    isVisible,
    clientId,
    handler,
    articleData,
    onChange,
    lang,
    isReadOnly = false,
}) {
    const [article, setArticle] = useState(new Article(articleData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorArticle())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<Article>>} */
    const upsertArticleHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<Article>>} */
    const getarticleHandler = useRef()

    useEffect(() => {
        if (isVisible && ((articleData && articleData.articleId !== article?.articleId) || articleData.articleId !== article.articleId))
            setArticle(new Article(articleData))
    }, [setArticle, article, articleData, isVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertArticleHandler.current = handler.upsertArticle(clientId, article)
            const articleResult = await upsertArticleHandler.current.fetch()
            onChange(articleResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorArticle>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [handler, clientId, article, onChange])

    // On component did unmount
    useEffect(() => () => {
        upsertArticleHandler.current?.cancel()
        getarticleHandler.current?.cancel()
    }, [])
    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: article?.articleId ? tKey('modalEditArticleTitle') : tKey('modalArticleTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                />
                <TextField
                    label={tKey('name')}
                    placeholder={tKey('name')}
                    required
                    value={article.name}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, name: newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.name)}
                />
                <TextField
                    label={tKey('tmsCode')}
                    placeholder={tKey('tmsCode')}
                    value={article.articleTmsId}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, articleTmsId: newVal }))}
                    required
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.articleTmsId)}
                />
                <TextField
                    label={tKey('footage')}
                    placeholder={tKey('footage')}
                    value={article.footage?.toString() || ''}
                    type="number"
                    min={0.01}
                    step={0.01}
                    required
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, footage: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.footage)}
                    suffix={tKey('linearMeterSuffix')}
                />
                <TextField
                    label={tKey('weight')}
                    placeholder={tKey('weight')}
                    value={article.weight?.toString() || ''}
                    type="number"
                    required
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, weight: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.weight)}
                    suffix="KG"
                />
                <TextField
                    label={tKey('long')}
                    placeholder={tKey('long')}
                    value={article.long?.toString() || ''}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, long: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.long)}
                    type="number"
                    suffix="cm"
                />
                <TextField
                    label={tKey('width')}
                    placeholder={tKey('width')}
                    value={article.width?.toString() || ''}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, width: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.width)}
                    type="number"
                    suffix="cm"
                />
                <TextField
                    label={tKey('height')}
                    placeholder={tKey('height')}
                    value={article.height?.toString() || ''}
                    readOnly={isReadOnly}
                    borderless={isReadOnly}
                    onChange={(ev, newVal) => setArticle(prevState => ({ ...prevState, height: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.height)}
                    type="number"
                    suffix="cm"
                />
                <Toggle
                    onText={tKey('yes')}
                    offText={tKey('no')}
                    label={tKey('isVisible')}
                    styles={{
                        root: { display: 'flex', justifyContent: 'space-between' },
                        container: { alignItems: 'center' },
                    }}
                    checked={article.isVisible}
                    onChange={(ev, checked) => setArticle(prevState => ({ ...prevState, isVisible: checked }))}
                />
                <Toggle
                    onText={tKey('yes')}
                    offText={tKey('no')}
                    label={tKey('calcFootage')}
                    styles={{
                        root: { display: 'flex', justifyContent: 'space-between' },
                        container: { alignItems: 'center' },
                    }}
                    checked={article.calcFootage}
                    onChange={(ev, checked) => setArticle(prevState => ({ ...prevState, calcFootage: checked }))}
                />
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                        disabled={
                            status === Status.PENDING || !article.name
                            || !article.articleTmsId || !article.weight
                        }
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
