/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import File from 'requests/objects/file'
import Client from 'requests/objects/client'

/**
 * Document Object
 */
export default class Document extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.documentId documentId
     * @param {number=} data.companyId companyId
     * @param {string=} data.name name
     * @param {string=} data.documentTmsId documentTmsId
     * @param {string=} data.startDate startDate
     * @param {string=} data.endDate endDate
     * @param {number=} data.documentTypeId documentTypeId
     * @param {object=} data.documentType documentType
     * @param {object[]=} data.clients clients
     * @param {string=} data.url url
     * @param {number=} data.fileId fileId
     * @param {object=} data.file file
     * @param {string=} data.typeOfDocument typeOfDocument
     * @param {string=} data.priceCode priceCode
     * @param {string=} data.priceType priceType
     * @param {string=} data.excelFileUrl excelFileUrl
     * @param {string=} data.priceListName priceListName
     * @param {boolean=} data.allTheClients allTheClients
     */
    constructor({
        documentId = 0,
        companyId,
        documentTmsId = '',
        name = '',
        startDate = '',
        endDate = '',
        documentTypeId = 1,
        documentType,
        clients,
        fileId,
        url = '',
        file,
        typeOfDocument,
        priceCode,
        priceType,
        excelFileUrl,
        priceListName,
        allTheClients = false,
        ...data
    } = {}) {
        super(data)
        this.documentId = documentId
        this.companyId = companyId
        this.documentTmsId = documentTmsId
        this.name = name
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
        this.documentTypeId = documentTypeId
        this.documentType = documentType
        this.clients = clients?.map(x => new Client(x)) ?? []
        this.clientIds = this.clients.map(x => x.clientId)
        this.clients = clients
        this.url = url
        this.fileId = fileId
        this.file = file ? new File(file) : undefined
        this.typeOfDocument = typeOfDocument
        this.priceCode = priceCode
        this.priceType = priceType
        this.excelFileUrl = excelFileUrl
        this.priceListName = priceListName
        this.allTheClients = allTheClients
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorDocument extends ErrorBase {
    constructor() {
        super()
        this.documentId = ''
        this.documentTmsId = ''
        this.name = ''
        this.startDate = ''
        this.endDate = ''
        this.documentTypeId = ''
        this.clientIds = ''
        this.typeOfDocument = ''
        this.priceCode = ''
        this.url = ''
        this.priceType = ''
        this.excelFileUrl = ''
        this.priceListName = ''
        this.allTheClients = ''
    }
}
