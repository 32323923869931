import React, { PureComponent } from 'react'
import { Menu as BulmaMenu } from 'react-bulma-components'
import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
// eslint-disable-next-line import/named
import { MenuElement } from 'pages/_layout'
import { Link } from 'react-router-dom'
import User from 'requests/objects/user'
import EProfile from 'types/users/enums/profile'

/**
 * @typedef {object} MenuProps
 * @property {MenuElement[]} menu Menu to display
 * @property {string} selectedKeyURL Selected Key URL
 * @property {boolean} isLeftMenuOpen Is menu open
 * @property {(isLeftMenuOpen: boolean) => void} setIsLeftMenuOpen Change state of is menu open to parent componenet
 * @property {User} me me
 * @augments {PureComponent<MenuProps>}}
 */
export default class Menu extends PureComponent {
    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            menu, selectedKeyURL, setIsLeftMenuOpen, isLeftMenuOpen, me,
        } = this.props

        return (
            <BulmaMenu>
                <div
                    className={classNames('navbar-burger', { 'is-active': isLeftMenuOpen })}
                    role="button"
                    tabIndex={0}
                    onClick={() => setIsLeftMenuOpen(!isLeftMenuOpen)}
                    onKeyPress={() => setIsLeftMenuOpen(!isLeftMenuOpen)}
                >
                    <span />
                    <span />
                    <span />
                </div>
                <hr />
                {
                    menu.map((x, i) => {
                        /** @type {string} Variable to match subroutes when needed */
                        const selectedKey = (() => {
                            /**
                             * Exemple: "/project/[id]/item"
                             */
                            if (selectedKeyURL.match(/\/home-articles\/.*/g))
                                return '/home-articles'
                            if (selectedKeyURL.match(/\/home-actualities\/.*/g))
                                return '/home-actualities'
                            if (selectedKeyURL.match(/\/clients\/.*/g)) {
                                if (me.profileId === EProfile.CustomerAdmin)
                                    return selectedKeyURL
                                return '/clients'
                            }
                            if (selectedKeyURL.match(/\/client-accounts\/.*/g))
                                return '/client-accounts'
                            if (selectedKeyURL.match(/\/users\/.*/g))
                                return '/users'
                            if (selectedKeyURL.match(/\/tools\/.*/g))
                                return '/tools'
                            if (selectedKeyURL.match(/\/contacts\/.*/g))
                                return '/contacts'
                            if (selectedKeyURL.match(/\/documents\/.*/g))
                                return '/documents'
                            if (selectedKeyURL.match(/\/invoices\/.*/g))
                                return '/invoices'
                            if (selectedKeyURL.match(/\/orders\/.*/g))
                                return '/orders'
                            if (selectedKeyURL.match(/\/companies\/.*/g)) {
                                if (me.profileId === EProfile.Admin)
                                    return selectedKeyURL
                                return '/companies'
                            }
                            if (selectedKeyURL.match(/\/transports\/all/g))
                                return '/transports/all'
                            if (selectedKeyURL.match(/\/transports\/waiting/g))
                                return '/transports/waiting'
                            if (selectedKeyURL.match(/\/transports\/progress/g))
                                return '/transports/progress'
                            if (selectedKeyURL.match(/\/transports\/completed/g))
                                return '/transports/completed'
                            if (selectedKeyURL.match(/\/transports\/refused/g))
                                return '/transports/refused'
                            if (selectedKeyURL.match(/\/transports\/.*/g))
                                return '/transports'

                            return selectedKeyURL
                        })()
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={i}>
                                {

                                    <BulmaMenu.List title={x.name ? x.name : ''}>
                                        {
                                            x?.links.map((y, j) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={`${i}_${j}`}>
                                                    <Link // BulmaMenu.List.Item
                                                        to={y.key}
                                                        className={classNames({ 'is-active': y.key === selectedKey })}
                                                        title={y.title}
                                                    >
                                                        <Text variant="medium">
                                                            <Icon
                                                                iconName={y.icon}
                                                            />
                                                            <span>
                                                                {' '}
                                                                {y.title}
                                                            </span>
                                                        </Text>
                                                    </Link>
                                                    {y?.links?.length
                                                        && (
                                                            <BulmaMenu.List>
                                                                {
                                                                    y?.links.map((z, k) => (
                                                                        <Link // BulmaMenu.List.Item
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            key={`${i}_${j}_${k}`}
                                                                            to={z.key}
                                                                            className={classNames({ 'is-active': z.key === selectedKey })}
                                                                            title={y.title}
                                                                        >
                                                                            <Text variant="medium">
                                                                                <Icon
                                                                                    iconName={z.icon}
                                                                                />
                                                                                <span>
                                                                                    {' '}
                                                                                    {z.title}
                                                                                </span>
                                                                            </Text>
                                                                        </Link>
                                                                    ))
                                                                }
                                                            </BulmaMenu.List>
                                                        )}
                                                </li>
                                            ))
                                        }
                                    </BulmaMenu.List>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </BulmaMenu>
        )
    }
}
