import {
    Icon,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import React, { useMemo } from 'react'
import styles from 'styles/components/pages/pagination.module.scss'
import { TranslationsType } from "types/translations" // eslint-disable-line

const PREVIOUS_PAGE = 'PREVIOUS_PAGE'
const NEXT_PAGE = 'NEXT_PAGE'

/**
 * StatusData
 * @param {number} from from
 * @param {number} to to
 * @param {number} [step] step
 * @returns {any[]} Returns
 */
function getRange(from, to, step = 1) {
    const range = []

    for (let i = from; i <= to; i += step)
        range.push(i)

    return range
}

/**
 * data types of pagination
 * @typedef {object} PagingationType
 * @property {number} currentPage currentPage
 * @property {number} totalPages totalPages
 * @property {number} totalRecords totalRecords
 */

/**
 * Pagination
 * @param {object} props props
 * @param {PagingationType} props.data data
 * @param {number} [props.pageNeighbours] pageNeighbours
 * @param {(page: number) => void} props.onPageChange onPageChange
 * @param {string} props.lang lang
 * @param {TranslationsType} [props.itemName] itemName
 * @returns {JSX.Element} Returns
 */
export default function Pagination({
    data: {
        totalRecords,
        currentPage,
        totalPages,
    },
    pageNeighbours = 2,
    onPageChange,
    lang = 'fr',
    itemName = 'order',
}) {
    const { tKey } = useTranslate({ lang })
    const pages = useMemo(() => {
        const totalNumbers = (pageNeighbours * 2) + 3
        const totalBlocks = totalNumbers + 2

        if (totalPages < totalBlocks)
            return getRange(1, totalPages)

        const startPage = Math.max(2, currentPage - pageNeighbours)
        const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

        let pagesRes /** @type {any[]} */ = getRange(startPage, endPage)

        const hasPreviousButton = startPage > 2
        const hasNextButton = (totalPages - endPage) > 1
        const offSet = totalNumbers - (pagesRes.length + 1)

        if (hasPreviousButton && hasNextButton) {
            pagesRes = [PREVIOUS_PAGE, ...pagesRes, NEXT_PAGE]
        } else if (hasPreviousButton) {
            const extraPages = getRange(startPage - offSet, startPage - 1)
            pagesRes = [PREVIOUS_PAGE, ...extraPages, ...pagesRes]
        } else {
            const extraPages = getRange(endPage + 1, endPage + offSet)
            pagesRes = [...pagesRes, ...extraPages, NEXT_PAGE]
        }

        return [1, ...pagesRes, totalPages]
    }, [pageNeighbours, totalPages, currentPage])

    if (totalRecords < 1)
        return null
    return (
        <div className={styles.container}>
            <div className={styles.total}>
                <div>
                    {tKey('page')}
                    {' '}
                    {currentPage}
                    {' / '}
                    {totalPages}
                </div>
                <div>
                    {`${totalRecords} ${tKey(itemName)}${totalRecords > 1 ? 's' : ''}`}
                </div>
            </div>
            <div className={styles.pagination}>
                {pages.map((page, index) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={styles.item}
                    >
                        <button
                            disabled={currentPage === page}
                            type="button"
                            onClick={() => {
                                let pageToGo = page

                                if (page === PREVIOUS_PAGE)
                                    pageToGo = currentPage - (pageNeighbours + 1)

                                if (page === NEXT_PAGE)
                                    pageToGo = currentPage + (pageNeighbours + 1)
                                onPageChange(pageToGo)
                            }}
                        >
                            {Number.isInteger(page) ? page : (
                                <Icon
                                    styles={{ root: { marginTop: '5px' } }}
                                    iconName={page === PREVIOUS_PAGE ? 'Previous' : 'Next'}
                                />
                            )}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}
