// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'
import Address from 'requests/objects/address'
import OrderHandling from 'requests/objects/order/orderHandling'
import File from 'requests/objects/file'
import ComfourData from 'requests/objects/order/comfourData'
import Order from 'requests/objects/order'
import DeliveryType from 'requests/objects/order/deliveryType'
import Accessibility from 'requests/objects/accessibility'

/**
 * DeliveryData
 */
export default class DeliveryData extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.deliveryDataId deliveryDataId
     * @param {number=} data.orderId orderId
     * @param {object=} data.order order
     * @param {boolean=} data.hasRequiredFile hasRequiredFile
     * @param {boolean=} data.temporarySaved temporarySaved
     * @param {string=} data.trackingToken trackingToken
     * @param {object} data.deliveryAddressData deliveryData
     * @param {object=} data.deliveryAddressData.address address
     * @param {number=} data.deliveryAddressData.addressId addressId
     * @param {string=} data.deliveryAddressData.requirement requirement
     * @param {object} data.deliveryComplementData deliveryComplementData
     * @param {string=} data.deliveryComplementData.orderingPartyReference orderingPartyReference
     * @param {string=} data.deliveryComplementData.merchandiseComment merchandiseComment
     * @param {string=} data.deliveryComplementData.invoiceComment invoiceComment
     * @param {object} data.deliveryInstructionsData deliveryInstructionsData
     * @param {object=} data.deliveryInstructionsData.deliveryType deliveryType
     * @param {number=} data.deliveryInstructionsData.deliveryTypeId deliveryTypeId
     * @param {object=} data.deliveryInstructionsData.deliveryLogisticConstraints deliveryLogisticConstraints
     * @param {boolean=} data.deliveryInstructionsData.isTelephoneAppointment isTelephoneAppointment
     * @param {string=} data.deliveryInstructionsData.commentAppointment commentAppointment
     * @param {boolean=} data.deliveryInstructionsData.isAgainstReimbursement isAgainstReimbursement
     * @param {string=} data.deliveryInstructionsData.seller seller
     * @param {string=} data.deliveryInstructionsData.amount amount
     * @param {string=} data.deliveryInstructionsData.otNumber otNumber
     * @param {object=} data.deliveryInstructionsData.comfourData comfourData
     * @param {string=} data.deliveryInstructionsData.appointmentReference appointmentReference
     * @param {number=} data.deliveryInstructionsData.accessibilityId accessibilityId
     * @param {object=} data.deliveryInstructionsData.accessibilities accessibilities
     * @param {number=} data.deliveryInstructionsData.vehicleEquipmentId vehicleEquipmentId
     * @param {object} data.deliveryDateData deliveryDateData
     * @param {number=} data.deliveryDateData.desiredWeekNumber desiredWeekNumber
     * @param {string=} data.deliveryDateData.wishedDateOfDelivery wishedDateOfDelivery
     * @param {number=} data.deliveryDateData.deliveryTime deliveryTime
     * @param {object} data.deliveryContentData deliveryContentData
     * @param {object[]=} data.deliveryContentData.orderHandlings deliveryContentData
     * @param {object} data.deliveryFileData deliveryFileData
     * @param {number=} data.deliveryFileData.deliveryFileDataId deliveryFileDataId
     * @param {object[]=} data.deliveryFileData.files deliveryFileData
     * @param {string=} data.generationStatus generationStatus
     */
    constructor({
        deliveryDataId,
        orderId,
        order,
        hasRequiredFile,
        temporarySaved,
        trackingToken,
        deliveryAddressData = {},
        deliveryComplementData = {},
        deliveryInstructionsData = {},
        deliveryDateData = {},
        deliveryContentData = {},
        deliveryFileData = {},
        generationStatus = '',
        ...data
    }) {
        super(data)
        this.deliveryDataId = deliveryDataId
        this.orderId = orderId
        this.order = order ? new Order(order) : undefined
        this.hasRequiredFile = hasRequiredFile
        this.temporarySaved = temporarySaved
        this.trackingToken = trackingToken
        this.deliveryAddressData = {
            address: deliveryAddressData?.address ? new Address(deliveryAddressData.address) : undefined,
            addressId: deliveryAddressData?.addressId,
            requirement: deliveryAddressData?.requirement || '',
        }
        this.deliveryComplementData = {
            orderingPartyReference: deliveryComplementData.orderingPartyReference || '',
            merchandiseComment: deliveryComplementData.merchandiseComment || '',
            invoiceComment: deliveryComplementData.invoiceComment || '',
        }
        this.deliveryInstructionsData = {
            deliveryType: deliveryInstructionsData?.deliveryType ? new DeliveryType(deliveryInstructionsData?.deliveryType) : undefined,
            deliveryTypeId: deliveryInstructionsData?.deliveryTypeId || null,
            deliveryLogisticConstraints: deliveryInstructionsData?.deliveryLogisticConstraints ?? [],
            deliveryLogisticConstraintIds: deliveryInstructionsData?.deliveryLogisticConstraints?.map(x => x.logisticConstraintId) ?? [],
            isTelephoneAppointment: deliveryInstructionsData?.isTelephoneAppointment || false,
            commentAppointment: deliveryInstructionsData?.commentAppointment || '',
            isAgainstReimbursement: deliveryInstructionsData?.isAgainstReimbursement || false,
            amount: deliveryInstructionsData?.amount || '',
            otNumber: deliveryInstructionsData?.otNumber || '',
            comfourData: deliveryInstructionsData?.comfourData ? new ComfourData(deliveryInstructionsData.comfourData) : undefined,
            appointmentReference: deliveryInstructionsData?.appointmentReference || '',
            accessibilityId: deliveryInstructionsData?.accessibilityId || null,
            accessibilities: deliveryInstructionsData?.accessibilities?.map(x => new Accessibility(x)) ?? [],
            accessibilityIds: deliveryInstructionsData?.accessibilities?.map(x => x.accessibilityId) ?? [],
            vehicleEquipmentId: deliveryInstructionsData?.vehicleEquipmentId || null,
            seller: deliveryInstructionsData?.seller ?? '',
        }
        this.deliveryDateData = {
            desiredWeekNumber: deliveryDateData.desiredWeekNumber,
            wishedDateOfDelivery: deliveryDateData.wishedDateOfDelivery ? new Date(deliveryDateData.wishedDateOfDelivery) : undefined,
            deliveryTime: deliveryDateData.deliveryTime || 0,
        }
        this.deliveryContentData = {
            orderHandlings: deliveryContentData.orderHandlings?.map(x => new OrderHandling(x)) ?? [],
        }
        this.deliveryFileData = {
            deliveryFileDataId: deliveryDataId,
            files: deliveryFileData?.files?.map(x => new File(x)) ?? [],
        }
        this.generationStatus = generationStatus || ''
    }
}

/**
 * Delivery data Object used to bind error message
 */
export class ErrorDeliveryData extends ErrorBase {
    constructor() {
        super()
        this.deliveruDataId = ''
        this.orderId = ''
        this.order = ''
        this.hasRequiredFile = ''
        this.trackingToken = ''
        this.deliveryAddressData = {
            address: '',
            addressId: '',
            requirement: '',
        }
        this.deliveryComplementData = {
            orderingPartyReference: '',
            merchandiseComment: '',
            invoiceComment: '',
        }
        this.deliveryInstructionsData = {
            deliveryType: '',
            deliveryTypeId: '',
            deliveryLogisticConstraints: '',
            deliveryLogisticConstraintIds: '',
            isTelephoneAppointment: '',
            commentAppointment: '',
            isAgainstReimbursement: '',
            amount: '',
            otNumber: '',
            comfourData: '',
            appointmentReference: '',
            accessibilityId: '',
            accessibilities: '',
            accessibilityIds: '',
            vehicleEquipmentId: '',
        }
        this.deliveryDateData = ''
        this.deliveryContentData = {
            orderHandlings: '',
        }
        this.deliveryFileData = {
            deliveryFileDataId: '',
            files: '',
        }
    }
}
