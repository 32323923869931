import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React from 'react'
import Status from 'types/status'
import parseJson from 'helpers/methods/parseJson'
import LogisticConstraint from 'requests/objects/logisticConstraint'
import useLogisticConstraintModal from 'helpers/hooks/components/pages/useLogisticConstraintModal'
import LogisticConstraintsHandler from 'requests/handlers/logisticConstraintsHandler'

/**
 * LogisticConstraint modal
 * @param {object} props Props
 * @param {Partial<LogisticConstraint>=} props.logisticConstraintdata logisticConstraintdata
 * @param {boolean} props.isVisible isVisible
 * @param {object} props.lang Lang
 * @param {LogisticConstraintsHandler} props.handler handler
 * @param {(logisticConstraint: LogisticConstraint, isDeletion?: false) => void} props.onChange onChange
 * @param {boolean} [props.isReadOnly] isReadOnly
 * @param {boolean} [props.isDeletion] isDeletion
 * @param {number} props.companyId companyId
 * @returns {JSX.Element} Modal
 */
export default function LogisticConstraintModal({
    logisticConstraintdata,
    isVisible,
    // setIsDeletion,
    lang,
    handler,
    onChange,
    isReadOnly = false,
    isDeletion = false,
    companyId,
}) {
    const {
        tKey,
        tObj,
        errorMessage,
        setErrorMessage,
        onSubmit,
        logisticConstraint,
        setLogisticConstraint,
        errorField,
        status,
        dialogTitle,
        deleteLogisticConstraint,
    } = useLogisticConstraintModal({
        lang,
        handler,
        isVisible,
        onChange,
        data: logisticConstraintdata,
        companyId,
        isDeletion,
    })

    if (!isVisible)
        return (null)

    return (
        <Dialog
            hidden={!isVisible}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: dialogTitle,
            }}
            modalProps={{
                isBlocking: true,
            }}
            // maxWidth="100%"
            // minWidth="1000px"
            maxWidth="555px"
            minWidth="400px"
            styles={{
                main: {
                    width: '100%',
                    maxHeight: '100%',
                    maxWidth: '100%',
                },
            }}
        >
            {errorMessage
                && (
                    <>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                        <br />
                    </>
                )}
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    if (isDeletion)
                        deleteLogisticConstraint()
                    else
                        onSubmit()
                }}
            >
                {isDeletion ? tKey('sureDeleteLogisticConstraint') : (
                    <>
                        <TextField
                            label={tKey('nameFr')}
                            placeholder={tKey('nameFr')}
                            value={parseJson(logisticConstraint.name)?.fr || ''}
                            readOnly={isReadOnly}
                            borderless={isReadOnly}
                            errorMessage={tObj(parseJson(errorField.name))}
                            onChange={(ev, newVal) => {
                                setLogisticConstraint({
                                    ...logisticConstraint,
                                    name: JSON.stringify({ ...parseJson(logisticConstraint.name), fr: newVal }),
                                })
                            }}
                            required={!isReadOnly}
                        />
                        <TextField
                            label={tKey('nameEn')}
                            placeholder={tKey('nameEn')}
                            value={parseJson(logisticConstraint.name)?.en || ''}
                            readOnly={isReadOnly}
                            borderless={isReadOnly}
                            errorMessage={tObj(parseJson(errorField.name))}
                            onChange={(ev, newVal) => {
                                setLogisticConstraint({
                                    ...logisticConstraint,
                                    name: JSON.stringify({ ...parseJson(logisticConstraint.name), en: newVal }),
                                })
                            }}
                            required={!isReadOnly}
                        />
                        <TextField
                            label={tKey('nameNl')}
                            placeholder={tKey('nameNl')}
                            value={parseJson(logisticConstraint.name)?.nl || ''}
                            readOnly={isReadOnly}
                            borderless={isReadOnly}
                            errorMessage={tObj(parseJson(errorField.name))}
                            onChange={(ev, newVal) => {
                                setLogisticConstraint({
                                    ...logisticConstraint,
                                    name: JSON.stringify({ ...parseJson(logisticConstraint.name), nl: newVal }),
                                })
                            }}
                            required={!isReadOnly}
                        />
                        <br />
                        <TextField
                            label={tKey('code')}
                            placeholder={tKey('code')}
                            value={logisticConstraint.logisticConstraintTmsId || ''}
                            readOnly={isReadOnly}
                            borderless={isReadOnly}
                            errorMessage={tObj(parseJson(errorField.logisticConstraintTmsId))}
                            onChange={(ev, newVal) => {
                                setLogisticConstraint({
                                    ...logisticConstraint,
                                    logisticConstraintTmsId: newVal,
                                })
                            }}
                            required={!isReadOnly}
                        />
                    </>
                )}
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        type="submit"
                        text={tKey(isDeletion ? 'delete' : 'save')}
                        disabled={
                            status === Status.PENDING
                        }
                    />
                </DialogFooter>
            </form>
        </Dialog>
    )
}
