/* eslint-disable no-unused-vars */
import { Icon } from '@fluentui/react'
import React from 'react'
import styles from 'styles/components/containers/banner.module.scss'
import Card from 'components/containers/card'
import classNames from 'classnames'

/**
 * Banner card item
 * @param {object} data data
 * @param {string} data.text text
 * @param {string} [data.iconName] iconName
 * @param {boolean} [data.isRed] isRed
 * @returns {JSX.Element} Element
 */
export default function Banner({
    text,
    iconName = 'Warning',
    isRed = false,
}) {
    return (
        <Card
            className={classNames(styles.banner, { [styles['is-red']]: isRed })}
        >
            <Icon iconName={iconName} />
            <span>
                {' '}
                {text}
            </span>
        </Card>
    )
}
