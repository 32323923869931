import Base from 'requests/objects/_base'
import Article from 'requests/objects/article'

/**
 * OrderSubHandling
 */
export default class OrderSubHandling extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderSubHandlingId orderSubHandlingId
     * @param {number=} data.orderHandlingId orderHandlingId
     * @param {number=} data.articleId articleId
     * @param {object=} data.article article
     * @param {string=} data.reference reference
     * @param {number=} data.weight weight
     * @param {string=} data.footage footage
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     * @param {number=} data.quantity quantity
     * @param {string=} data.comment ref
     */
    constructor({
        orderSubHandlingId,
        orderHandlingId,
        articleId,
        article,
        reference = '',
        weight,
        footage,
        long,
        width,
        height,
        quantity,
        comment = '',
        ...data

    }) {
        super(data)
        this.orderSubHandlingId = orderSubHandlingId
        this.orderHandlingId = orderHandlingId
        this.articleId = articleId
        this.article = article ? new Article(article) : null
        this.reference = reference
        this.weight = weight && !Number.isNaN(+weight) ? +weight : null
        this.footage = footage
        this.long = long && !Number.isNaN(+long) ? +long : null
        this.width = width && !Number.isNaN(+width) ? +width : null
        this.height = height && !Number.isNaN(+height) ? +height : null
        this.quantity = quantity && !Number.isNaN(+quantity) ? +quantity : null
        this.comment = comment
    }
}
