/* eslint-disable max-len */
import React, { PureComponent } from 'react'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import { tKey, tObj } from 'helpers/methods/translate'
import styles from 'styles/pages/legal-mentions/index.module.scss'
import { Text } from '@fluentui/react'

/** @debug {AppProps} */

/**
 * @augments {React.PureComponent<AppProps>}
 */
export default class LegalMentions extends PureComponent {
    componentDidMount() {
        const {
            setBreadcrumb, setCommand,
        } = this.props

        setBreadcrumb([])
        setCommand([])
    }

    render() {
        return (
            <div className={styles.index}>
                <main className={styles['index-body']}>
                    <Text variant="xLarge">Mentions légales</Text>
                    <br />
                    <Text>
                        Le GROUPE MOUSSET est à votre disposition pour toutes remarques ou suggestions.
                    </Text>
                    <br />
                    <Text variant="large">Gestion éditoriale</Text>
                    <br />
                    <Text>
                        GROUPE MOUSSET
                        <br />
                        Service Informatique
                        <br />
                        RD 160
                        <br />
                        85140 SAINTE FLORENCE
                        <br />
                        Tél. : 02 51 66 01 13
                        <br />
                        Email :
                        {' '}
                        <a href="https://jetransporte.com/contact/">formulaire de contact</a>
                        <br />
                        Siret : 51960293200013
                        <br />
                        TVA : FR 87 519 602 932
                        <br />
                        Immatriculée au RCS le 21-01-2010 (La Roche sur Yon – 85)
                        <br />
                        Capital social 18 725 294,00 €
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Création et conception du site Internet</Text>
                    <br />
                    <Text>
                        Toutes les coordonnées concernant la création du site internet sont disponibles via le lien suivant :
                        <br />
                        Site développé par
                        {' '}
                        <a
                            href="https://www.next-decision.fr/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Next Decision
                        </a>
                        <br />
                        Next Decision n'est pas responsable du RGPD.
                        <br />
                        Site hébergé par
                        {' '}
                        <a
                            href="https://www.aviti.fr/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Aviti
                        </a>
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Conditions d'utilisation</Text>
                    <br />
                    <Text>
                        L'utilisateur du site reconnaît avoir pris connaissance de la présente notice et s'engage à la respecter.
                        <br />
                        Il reconnaît également disposer des moyens et des compétences nécessaires à l'accès et à l'utilisation de ce site.
                        <br />
                        L'utilisateur s'engage à ne pas communiquer à un tiers les identifiants et mots de passe permettant l'accès à ce site.
                        <br />
                        Limite de responsabilité
                        <br />
                        Les informations présentes sur ce site sont données à titre indicatif.
                        <br />
                        Bien que l'éditeur s'efforce d'apporter une information de qualité, il ne peut en garantir l'exactitude.
                        <br />

                        L'éditeur décline toute responsabilité pour tout dommage lié à l'utilisation de ce site.
                        <br />
                        Ainsi, il ne peut être tenu responsable de l'inexactitude ou l'omission d'informations, la perte de données, l'intrusion frauduleuse d'un tiers, la présence de virus, le dysfonctionnement ou l'indisponibilité du service.
                        <br />
                        L'éditeur se réserve le droit de suspendre ou d'arrêter le site à tout moment et sans préavis.
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Droits et devoirs des utilisateurs</Text>
                    <br />
                    <Text>
                        Les informations diffusées sur ce site sont la propriété du Groupe MOUSSET.
                        <br />
                        Toute copie totale ou partielle ne peut se faire sans l’accord du Groupe MOUSSET. Les utilisateurs sont responsables des interrogations qu’ils formulent ainsi que de l’interprétation et de l’utilisation qu’ils font des résultats. Il leur appartient d’en faire un usage conforme aux réglementations en vigueur et aux recommandations de la Commission Nationale de l’Informatique et des Libertés (CNIL) lorsque les données ont un caractère nominatif.
                        <br />
                        En particulier, ils doivent savoir que les informations ne doivent être utilisées qu’à des fins strictement personnelles, que la capture des écrans pour reconstitution ou enrichissement d’une base de données nominatives est contraire à la loi française et donc interdite, ainsi que l’utilisation à des fins commerciales ou publicitaires.
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Protections des données</Text>
                    <br />
                    <Text>
                        La protection des personnes physiques à l’égard du traitement des données à caractère personnel est un droit fondamental. A cet effet, le Règlement Général sur la Protection des Données (RGPD) vise à contribuer à la réalisation d’un espace de liberté, de sécurité et de justice.
                        <br />
                        Déjà investi dans le respect de la Loi « Informatique & Libertés », le Groupe MOUSSET a pris la dimension du règlement européen et s’est engagé dans un processus de mise en conformité. Cet engagement est une préoccupation forte du Groupe qui a d’ailleurs créé un comité spécialement dédié à la Protection des Données.
                        <br />
                        Que ce soit vis-à-vis de ses clients, fournisseurs, collaborateurs ou particuliers, le Groupe MOUSSET respecte les principes généraux régissant la mise en œuvre des traitements de données, c’est pourquoi l'utilisateur dispose d'un droit d'accès, de rectification ou de suppression concernant les données que nous pourrions être amenés à recueillir.
                        <br />
                        Toute information supplémentaire relative à la protection des données personnelles se trouve sur la page dédiée :
                        {' '}
                        <a href="https://jetransporte.com/protections-des-donnees/">Protection des données</a>
                        .
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Liens</Text>
                    <br />
                    <Text>
                        La mise en place de liens hypertextes par des tiers vers des pages ou des documents diffusés sur ce site, pouvant de fait constituer une atteinte aux droits d’auteur, un acte relevant du parasitisme ou de la diffamation, elle doit faire l’objet d’une autorisation du Groupe MOUSSET qui peut être sollicitée directement auprès de l’administrateur du site.
                        <br />
                        L’autorisation correspondante sera accordée si les liens ne contreviennent pas aux intérêts du Groupe MOUSSET, et, s’ils garantissent la possibilité pour l’utilisateur d’identifier l’origine Groupe MOUSSET sur le document, notamment dans le cas de liens hypertextes profonds, de cadrage ou d’insertion par liens.
                        <br />
                        De même, le Groupe MOUSSET a sollicité par écrit l’autorisation de créer des liens vers son site de la part de tous les serveurs indiqués : les informations restent la propriété pleine et entière de ces serveurs et ne sauraient engager la responsabilité de la société de quelconque manière.
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Droit applicable</Text>
                    <br />
                    <Text>
                        Les présentes mentions légales sont soumises au droit interne français. La langue des mentions légales est la langue française. En cas de litige, les tribunaux français seront seuls compétents.
                        <br />
                    </Text>
                    <br />
                    <Text variant="large">Crédits</Text>
                    <br />
                    <Text>
                        L’ensemble des contenus (photos, textes, vidéo, son) consultables sur ce site demeure la propriété exclusive du Groupe MOUSSET et/ou de ses partenaires et relève de la législation française sur les droits d’auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations iconographiques et photographiques.
                        <br />
                        Toute extraction et/ou reproduction d’une partie substantielle des informations diffusées sur le site est interdite sans l’autorisation préalable de la société Groupe MOUSSET et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du code de la propriété intellectuelle.
                        <br />
                        Le code de la propriété intellectuelle précise qu’il faut citer la source ou demander l’autorisation de l’auteur des images. Il ne joue que dans le cadre du respect du droit d’auteur. En tant que création de l’esprit, les images (même les copies d’écran) sont donc protégées par le droit d’auteur (art. L112-2-9 du code de propriété intellectuelle).
                        <br />
                        Le fait de reproduire ou de représenter, sans autorisation, des images, textes ou documents est punissable sur le plan civil par une condamnation à des dommages et intérêts. Ces dommages et intérêts sont calculés au cas par cas. Cela dépend du manque à gagner de la victime, du préjudice subi dû à la dénaturation de la façon dont est présenté le site et des répercussions qu’a pu en avoir l’auteur du site, de la façon dont l’image a été mise en avant et de celle qu’elle a pu rapporter éventuellement en termes financiers au concepteur.
                        <br />
                        <br />
                        Crédits photos et vidéos :
                        <br />
                        Groupe Mousset
                        <br />
                    </Text>
                </main>
            </div>
        )
    }
}

LegalMentions.prototype.tKey = tKey
LegalMentions.prototype.tObj = tObj
