import {
    DefaultButton, DirectionalHint, Icon, IconButton, Label, Text, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import useTranslate from 'helpers/hooks/useTranslate'
import React, { useCallback } from 'react'
import { Columns } from 'react-bulma-components'
import { ErrorDeliveryData } from 'requests/objects/order/deliveryData'
import OrderHandling from 'requests/objects/order/orderHandling'
import OrderSubHandling from 'requests/objects/order/orderSubHandling'
import Param from 'requests/objects/param'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/content.module.scss'
import classNames from 'classnames'
import ECompany from 'types/companies/enums/company'
import parseJson from 'helpers/methods/parseJson'
import useCompany from 'helpers/hooks/useCompany'
import RequiredDisplay from 'components/visuals/required-display'
import TextFieldWithTooltip from 'components/inputs/textFieldWithTooltip'
import Client from 'requests/objects/client'

/**
 * DeliveryStepContent
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {OrderHandling[]} props.items Items
 * @param {(orderHandlings: OrderHandling[]) => void} props.onUpdate onUpdate
 * @param {Param} props.param Param
 * @param {boolean} props.isReadOnly Param
 * @param {ErrorDeliveryData} props.errorField errorField
 * @param {number} [props.companyId] companyId
 * @param {Client} props.client client
 * @returns {JSX.Element} Element
 */
export default function DeliveryStepContent({
    lang, items = [], onUpdate, isReadOnly, param, errorField, companyId = ECompany.Raud, client,
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const { isRaud, isJetFreeze, isPineau } = useCompany({ companyId })
    const removeOrderHandling = useCallback(
        /**
         * @param {number} orderHandlingIndex Index
         */
        orderHandlingIndex => {
            onUpdate(items.filter((_, i) => i !== orderHandlingIndex))
        }, [items, onUpdate],
    )

    const updateOrderHandling = useCallback(
        /**
         * @param {number} orderHandlingIndex Index
         * @param {Partial<OrderHandling>} data Data
         */
        (orderHandlingIndex, data) => {
            const newItems = items.map((orderHandling, i) => {
                if (i !== orderHandlingIndex)
                    return orderHandling
                if (data.supportId && data.supportId !== orderHandling.supportId) {
                    const {
                        footage, weight, height, width, long,
                    } = param.supports.find(s => s.key === data.supportId)

                    return {
                        ...orderHandling,
                        ...data,
                        footage: footage ? footage.toString() : null,
                        weight: weight ? +weight : null,
                        height: height ? +height : null,
                        width: width ? +width : null,
                        long: long ? +long : null,
                    }
                }
                // const quantity = (() => {
                //     if (Number.isNaN(data.quantity))
                //         return orderHandling.quantity
                //     if (data.quantity < orderHandling.maxQuantity)
                //         return data.quantity
                //     return orderHandling.maxQuantity
                // })()

                return {
                    ...orderHandling,
                    ...data,
                    // quantity,
                }
            })
            // if (isJetFreeze) {
            //     const quantityCount = newItems.reduce((acc, curr) => {
            //         const support = curr.supportId && param.supports.find(s => s.key === curr.supportId)
            //         if (!support || !support.maxQuantity || !curr.quantity)
            //             return acc
            //         return acc + (curr.quantity / support.maxQuantity)
            //     }, 0)

            //     newItems = newItems.map(x => {
            //         const support = x.supportId && param.supports.find(s => s.key === x.supportId)
            //         if (!support || !support.maxQuantity)
            //             return x

            //         const max = support.maxQuantity - (quantityCount * support.maxQuantity)

            //         return {
            //             ...x,
            //             maxQuantity: Math.floor(max) + (x.quantity ?? 0),
            //         }
            //     })
            // }

            onUpdate(newItems)
        }, [items, onUpdate, param.supports],
    )

    const addOrderSubHandling = useCallback(
        /**
         * @param {number} orderHandlingIndex Index
         */
        orderHandlingIndex => {
            onUpdate(items.map((orderHandling, i) => (i !== orderHandlingIndex
                ? orderHandling
                : { ...orderHandling, orderSubHandlings: [...orderHandling.orderSubHandlings, new OrderSubHandling({})] })))
        }, [items, onUpdate],
    )

    const removeOrderSubHandling = useCallback(
        /**
         * @param {number} orderHandlingIndex Index
         * @param {number} orderSubHandlingId Index
         */
        (orderHandlingIndex, orderSubHandlingId) => {
            onUpdate(items.map((orderHandling, i) => (i !== orderHandlingIndex
                ? orderHandling
                : { ...orderHandling, orderSubHandlings: orderHandling.orderSubHandlings.filter((_, j) => j !== orderSubHandlingId) })))
        }, [items, onUpdate],
    )

    const updateOrderSubHandling = useCallback(
        /**
         * @param {number} orderHandlingIndex Index
         * @param {number} orderSubHandlingId Index
         * @param {Partial<OrderSubHandling>} data Data
         */
        (orderHandlingIndex, orderSubHandlingId, data) => {
            onUpdate(items.map((orderHandling, i) => (i !== orderHandlingIndex
                ? orderHandling
                : {
                    ...orderHandling,
                    orderSubHandlings: orderHandling.orderSubHandlings
                        .map((orderSubHandling, j) => {
                            if (j !== orderSubHandlingId)
                                return orderSubHandling
                            if (data.articleId && data.articleId !== orderSubHandling.articleId) {
                                const {
                                    footage,
                                    weight,
                                    height,
                                    width,
                                    long,
                                    calcFootage,
                                } = param.articles.find(a => a.key === data.articleId)
                                return {
                                    ...orderSubHandling,
                                    ...data,
                                    footage: calcFootage && width && long
                                        ? (((Number(long) / 100) * (Number(width) / 100)) / 2.4).toFixed(2)
                                        : footage?.toString() ?? null,
                                    weight: weight ? +weight : null,
                                    height: height ? +height : null,
                                    width: width ? +width : null,
                                    long: long ? +long : null,
                                }
                            }
                            if ((data.long > 0 && orderSubHandling.width > 0) || (data.width > 0 && orderSubHandling.long > 0)) {
                                const article = param.articles.find(x => x.key === orderSubHandling.articleId)
                                if (article?.calcFootage) {
                                    const long = data.long ?? orderSubHandling.long
                                    const width = data.width ?? orderSubHandling.width
                                    const footage = (((Number(long) / 100) * (Number(width) / 100)) / 2.4).toFixed(2)
                                    return { ...orderSubHandling, ...data, footage }
                                }
                            } else if (data.long === 0 || data.width === 0) {
                                const article = param.articles.find(x => x.key === data.articleId)
                                if (article)
                                    return { ...orderSubHandling, ...data, footage: article.footage?.toString() ?? null }
                            }
                            return { ...orderSubHandling, ...data }
                        }),
                })))
        }, [items, onUpdate, param.articles],
    )

    return (
        <div className={styles['delivery-step-content']}>
            {items.map((orderHandling, orderHandlingIndex) => {
                // eslint-disable-next-line max-len
                const emptySubHandlingError = errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings`]
                    ? tKey('emptySubHandlingError') : ''

                const supportIdError = tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.supportId`])
                    ? tKey('aSupportMustBeSelected') : ''
                const support = orderHandling.supportId && param.supports.find(s => s.key === orderHandling.supportId)
                const isWithoutReferences = support?.isWithoutReferences
                const isWithoutDimensions = support?.isWithoutDimensions

                return (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${orderHandling.orderHandlingId}_${orderHandlingIndex}`}
                        className={styles['delivery-step-content-main']}
                    >
                        <Label>
                            {tKey('handlingUnit')}
                            {' '}
                            {orderHandlingIndex + 1}
                        </Label>
                        <Columns
                            className={classNames(
                                'is-gapless',
                                styles['delivery-step-content-main-support'],
                            )}
                        >
                            <Columns.Column>
                                <FilteredVirtualCombobox
                                    placeholder={tKey('select')}
                                    options={param.supports.map(s => ({
                                        key: s.key,
                                        text: tObj(parseJson(s.text)),
                                        data: s.subText,
                                    }))}
                                    selectedKey={orderHandling.supportId}
                                    readOnly={isReadOnly || isRaud}
                                    onChange={(ev, option) => updateOrderHandling(orderHandlingIndex, {
                                        supportId: option.key,
                                    })}
                                    errorMessage={emptySubHandlingError && supportIdError
                                        ? emptySubHandlingError.concat(', ', supportIdError)
                                        : `${emptySubHandlingError}${supportIdError}`}
                                />
                            </Columns.Column>
                            <RequiredDisplay isReadOnly={isReadOnly} />
                            {isWithoutDimensions && (
                                <Columns.Column
                                    style={{ minWidth: '35px', maxWidth: '35px' }}
                                >
                                    {(!isReadOnly && orderHandlingIndex > 0) && (
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            disabled={isReadOnly}
                                            onClick={() => removeOrderHandling(orderHandlingIndex)}
                                        />
                                    )}
                                </Columns.Column>
                            )}
                            {isPineau && (
                                <Columns.Column>
                                    <TextField
                                        placeholder={tKey('type')}
                                        value={orderHandling.kindOfGoods || ''}
                                        readOnly={isReadOnly}
                                        onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                            kindOfGoods: newVal,
                                        })}
                                        // eslint-disable-next-line max-len
                                        errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.kindOfGoods`])}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                            )}
                            {!isWithoutReferences && (isPineau || isRaud) && (
                                <Columns.Column>
                                    <TextField
                                        placeholder={tKey('reference')}
                                        value={orderHandling.reference?.toString() || ''}
                                        readOnly={isReadOnly}
                                        onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                            reference: newVal?.length > 100 ? newVal.substring(0, 100) : newVal,
                                        })}
                                        errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.reference`])}
                                    />
                                </Columns.Column>
                            )}
                            {isJetFreeze && (
                                <>
                                    <Columns.Column>
                                        <FilteredVirtualCombobox
                                            placeholder={tKey('temperature')}
                                            options={param.productTemperatures.map(pt => ({
                                                key: pt.key,
                                                text: tObj(parseJson(pt.text)),
                                            }))}
                                            selectedKey={orderHandling.productTemperatureId}
                                            readOnly={isReadOnly}
                                            onChange={(ev, option) => updateOrderHandling(orderHandlingIndex, {
                                                productTemperatureId: option.key,
                                            })}
                                            // eslint-disable-next-line max-len
                                            errorMessage={!!errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.productTemperatureId`] && tKey('temperatureIsRequired')}
                                        />
                                    </Columns.Column>
                                    <RequiredDisplay isReadOnly={isReadOnly} />
                                </>
                            )}
                            {!isWithoutDimensions && isRaud && (
                                <Columns.Column>
                                    <TextField
                                        placeholder={tKey('footage')}
                                        value={orderHandling.footage?.toString() || ''}
                                        readOnly={isReadOnly}
                                        onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                            footage: newVal,
                                        })}
                                        // eslint-disable-next-line max-len
                                        errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.footage`])}
                                        required={!isReadOnly}
                                        type="number"
                                        min={0.01}
                                        step={0.01}
                                        suffix={tKey('linearMeterSuffix')}
                                    />
                                </Columns.Column>
                            )}
                            <Columns.Column
                                className="is-hidden-mobile"
                                style={{ minWidth: '35px', maxWidth: '35px' }}
                            />
                        </Columns>
                        {!isWithoutDimensions && (
                            <Columns
                                className={classNames(
                                    'is-gapless',
                                    styles['delivery-step-content-main-data'],
                                )}
                            >
                                {(isPineau || isRaud) && (
                                    <Columns.Column className={styles['delivery-step-content-main-size']}>
                                        <TextField
                                            placeholder={tKey('long')}
                                            value={orderHandling.long?.toString() || ''}
                                            readOnly={isReadOnly}
                                            onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                                long: +newVal > 700 ? 700 : +newVal,
                                            })}
                                            // eslint-disable-next-line max-len
                                            errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.long`])}
                                            required={!isReadOnly}
                                            type="number"
                                        />
                                        <TextField
                                            placeholder={tKey('width')}
                                            value={orderHandling.width?.toString() || ''}
                                            readOnly={isReadOnly}
                                            onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                                width: +newVal > 245 ? 245 : +newVal,
                                            })}
                                            // eslint-disable-next-line max-len
                                            errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.width`])}
                                            required={!isReadOnly}
                                            type="number"
                                        />
                                        <TextField
                                            placeholder={tKey('height')}
                                            value={orderHandling.height?.toString() || ''}
                                            readOnly={isReadOnly}
                                            onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                                height: +newVal > 700 ? 700 : +newVal,
                                            })}
                                            // eslint-disable-next-line max-len
                                            errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.height`])}
                                            type="number"
                                        />
                                        <TextField
                                            suffix="cm"
                                            styles={{
                                                field: { display: 'none' },
                                            }}
                                        />
                                    </Columns.Column>
                                )}
                                <Columns.Column
                                    style={{ marginRight: '10px' }}
                                >
                                    <TextField
                                        placeholder={tKey('weight')}
                                        value={orderHandling.weight?.toString() || ''}
                                        readOnly={isReadOnly}
                                        onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                            weight: +newVal,
                                        })}
                                        // eslint-disable-next-line max-len
                                        errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.weight`])}
                                        required={!isReadOnly}
                                        type="number"
                                        suffix="KG"
                                    />
                                </Columns.Column>
                                {isJetFreeze && (
                                    <Columns.Column
                                        className={classNames({
                                            [styles['is-warned']]: support?.maxQuantity && support?.maxQuantity <= orderHandling.quantity,
                                        })}
                                    >
                                        <TextFieldWithTooltip
                                            placeholder={`${tKey('quantity')}${support?.maxQuantity ? ` (max: ${support.maxQuantity})` : ''}`}
                                            value={orderHandling.quantity?.toString() || ''}
                                            readOnly={isReadOnly}
                                            onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                                quantity: +newVal,
                                            })}
                                            errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.quantity`])}
                                            required={!isReadOnly}
                                            type="number"
                                            suffix="Q."
                                            min={0}
                                            max={support?.maxQuantity ?? 9999}
                                            tooltipMessage={support?.maxQuantity && support?.maxQuantity < orderHandling.quantity
                                                ? 'La quantité maximum a été atteinte, dois passer en vérif'
                                                : `${tKey('quantity')}${support?.maxQuantity ? ` (max: ${support.maxQuantity})` : ''}`}
                                        />
                                    </Columns.Column>
                                )}
                                <Columns.Column
                                    className="is-hidden-mobile"
                                    style={{ minWidth: '35px', maxWidth: '35px' }}
                                >
                                    {!isReadOnly && (
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            disabled={isReadOnly}
                                            onClick={() => removeOrderHandling(orderHandlingIndex)}
                                        />
                                    )}
                                </Columns.Column>
                            </Columns>
                        )}
                        {
                            isRaud && orderHandling.orderSubHandlings.map((orderSubHandling, orderSubHandlingIndex) => {
                                const article = orderSubHandling.articleId && param.articles.find(s => s.key === orderSubHandling.articleId)
                                return (
                                    <div
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={`${orderSubHandling.orderSubHandlingId}_${orderSubHandlingIndex}`}
                                        className={styles['delivery-step-content-main-sub']}
                                    >
                                        <Columns
                                            className={classNames(
                                                'is-gapless',
                                                styles['delivery-step-content-sub-main-article'],
                                            )}
                                        >
                                            <Columns.Column>
                                                <FilteredVirtualCombobox
                                                    placeholder={orderSubHandling?.article?.name
                                                        ? tObj(parseJson(orderSubHandling.article.name)) : tKey('article')}
                                                    options={param.articles.map(s => ({
                                                        key: s.key,
                                                        text: tObj(parseJson(s.text)),
                                                        data: s.subText,
                                                    }))}
                                                    selectedKey={orderSubHandling.articleId}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, option) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        articleId: option.key,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.articleId`])}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>
                                            <RequiredDisplay isReadOnly={isReadOnly} />
                                            <Columns.Column
                                                style={{ marginRight: '10px' }}
                                            >
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('reference')}
                                                    placeholder={isReadOnly ? undefined : tKey('ref')}
                                                    value={orderSubHandling.reference || ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        reference: newVal?.length > 100 ? newVal.substring(0, 100) : newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.reference`])}
                                                />
                                            </Columns.Column>
                                            <Columns.Column>
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('quantity')}
                                                    placeholder={tKey('quantity')}
                                                    value={orderSubHandling.quantity?.toString() || ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        quantity: +newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.quantity`])}
                                                    required={!isReadOnly}
                                                    type="number"
                                                    min={1}
                                                    step={1}
                                                    suffix="Q"
                                                />
                                            </Columns.Column>
                                            <div className="is-hidden-mobile">
                                                {!isReadOnly && (
                                                    <IconButton
                                                        iconProps={{ iconName: 'Delete' }}
                                                        disabled={isReadOnly}
                                                        onClick={() => removeOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex)}
                                                    />
                                                )}
                                            </div>
                                        </Columns>
                                        <Columns
                                            className={classNames(
                                                'is-gapless',
                                                styles['delivery-step-content-sub-main-data'],
                                            )}
                                        >
                                            <Columns.Column>
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('long')}
                                                    placeholder={tKey('long')}
                                                    value={orderSubHandling.long?.toString() || ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        long: +newVal > 700 ? 700 : +newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.long`])}
                                                    // required={!isReadOnly}
                                                    type="number"
                                                />
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('width')}
                                                    placeholder={tKey('width')}
                                                    value={orderSubHandling.width?.toString() || ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        width: +newVal > 245 ? 245 : +newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.width`])}
                                                    // required={!isReadOnly}
                                                    type="number"
                                                />
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('height')}
                                                    placeholder={tKey('height')}
                                                    value={orderSubHandling.height?.toString() || ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        height: +newVal > 240 ? 240 : +newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.height`])}
                                                    type="number"
                                                />
                                                <TextField
                                                    suffix="cm"
                                                    styles={{
                                                        field: { display: 'none' },
                                                    }}
                                                />
                                            </Columns.Column>
                                            <RequiredDisplay isReadOnly={isReadOnly} />
                                            <Columns.Column
                                                className={classNames(styles['calc-footage'], {
                                                    [styles['is-warned']]: article?.calcFootage && orderSubHandling.long && orderSubHandling.width,
                                                })}
                                            >
                                                <div
                                                    className={classNames(styles['info-tool-tip'], {
                                                        [styles['show-tooltip']]: article?.calcFootage && orderSubHandling.long && orderSubHandling.width,
                                                    })}
                                                >
                                                    <TooltipHost
                                                        content={tKey('calculatedFootage')}
                                                        directionalHint={DirectionalHint.topCenter}
                                                        delay={TooltipDelay.zero}
                                                    >
                                                        <Icon
                                                            iconName="Info"
                                                        />
                                                    </TooltipHost>
                                                </div>
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('unitLinearFootage')}
                                                    placeholder={tKey('footage')}
                                                    value={orderSubHandling.footage?.toString() || ''}
                                                    readOnly={isReadOnly || !client.isFootageInformable}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        footage: newVal,
                                                    })}
                                                    errorMessage={tObj(
                                                        errorField[`deliveryContentData.orderHandlings
                                                        .${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.footage`],
                                                    )}
                                                    type="number"
                                                    min={0.01}
                                                    step={0.01}
                                                    suffix={tKey('linearMeterSuffix')}
                                                />
                                            </Columns.Column>
                                            <RequiredDisplay isReadOnly={isReadOnly} />
                                            <Columns.Column
                                                style={{ marginRight: '10px' }}
                                            >
                                                <TextFieldWithTooltip
                                                    tooltipMessage={tKey('weight')}
                                                    placeholder={tKey('weight')}
                                                    value={orderSubHandling.weight ? orderSubHandling.weight.toString() : ''}
                                                    readOnly={isReadOnly}
                                                    onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex, {
                                                        weight: +newVal,
                                                    })}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.${orderSubHandlingIndex}.weight`])}
                                                    required={!isReadOnly}
                                                    type="number"
                                                    suffix="KG"
                                                    max={9999}
                                                />
                                            </Columns.Column>
                                            <div
                                                className={classNames(
                                                    styles['delivery-step-content-dimensions-warning'],
                                                    { [styles['is-visible']]: orderSubHandling.long >= 500 },
                                                )}
                                            >
                                                <TooltipHost
                                                    content={tKey('careForDimensions')}
                                                    directionalHint={DirectionalHint.leftCenter}
                                                    delay={TooltipDelay.zero}
                                                >
                                                    <div
                                                        style={{ pointerEvents: 'none' }}
                                                    >
                                                        <IconButton
                                                            iconProps={{ iconName: 'Info', style: { fontSize: '20px', color: '#f14668' } }}
                                                        />
                                                    </div>
                                                </TooltipHost>
                                            </div>
                                        </Columns>
                                        <div className="is-hidden-tablet">
                                            {!isReadOnly && (
                                                <DefaultButton
                                                    text={tKey('delete')}
                                                    iconProps={{ iconName: 'Delete' }}
                                                    disabled={isReadOnly}
                                                    onClick={() => removeOrderSubHandling(orderHandlingIndex, orderSubHandlingIndex)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {isJetFreeze && orderHandling.orderSubHandlings.length > 0 && (
                            <div className={styles['delivery-step-content-main-sub']}>
                                <Columns
                                    className={classNames(
                                        'is-gapless',
                                        styles['delivery-step-content-sub-parcel'],
                                    )}
                                >
                                    <Columns.Column
                                        style={{ marginRight: '10px' }}
                                    >
                                        <Label style={{ paddingLeft: '2rem' }}>{tKey('parcel')}</Label>
                                    </Columns.Column>
                                    <Columns.Column>
                                        <TextField
                                            placeholder={tKey('quantity')}
                                            value={orderHandling.orderSubHandlings[0].quantity?.toString() || ''}
                                            readOnly={isReadOnly}
                                            onChange={(ev, newVal) => updateOrderSubHandling(orderHandlingIndex, 0, {
                                                quantity: +newVal,
                                            })}
                                            // eslint-disable-next-line max-len
                                            errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.orderSubHandlings.0.quantity`])}
                                            required={!isReadOnly}
                                            type="number"
                                            suffix="Q."
                                        />
                                    </Columns.Column>
                                    <div
                                        className="is-hidden-mobile"
                                        style={{ minWidth: '35px', maxWidth: '35px' }}
                                    >
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            disabled={isReadOnly}
                                            onClick={() => removeOrderSubHandling(orderHandlingIndex, 0)}
                                        />
                                    </div>
                                </Columns>
                                <div className="is-hidden-tablet">
                                    <DefaultButton
                                        text={tKey('delete')}
                                        iconProps={{ iconName: 'Delete' }}
                                        disabled={isReadOnly}
                                        onClick={() => removeOrderSubHandling(orderHandlingIndex, 0)}
                                    />
                                </div>
                            </div>
                        )}
                        {(isRaud || (isJetFreeze && !orderHandling.orderSubHandlings.length)) && (
                            <Columns
                                className={classNames(
                                    'is-gapless',
                                    styles['add-article-container'],
                                )}
                            >
                                <Columns.Column />
                                <Columns.Column>
                                    {!isReadOnly && (
                                        <button
                                            type="button"
                                            className={styles['delivery-step-content-main-sub-add-btn']}
                                            onClick={() => addOrderSubHandling(orderHandlingIndex)}
                                            disabled={isReadOnly}
                                        >
                                            <Text variant="smallPlus">
                                                <Icon iconName="CirclePlus" />
                                                {' '}
                                                {tKey(isJetFreeze ? 'addParcel' : 'addArticle')}
                                            </Text>
                                        </button>
                                    )}
                                </Columns.Column>
                            </Columns>
                        )}
                        <Columns className="is-gapless">
                            <Columns.Column>
                                <TextField
                                    placeholder={isReadOnly ? undefined : tKey('comment')}
                                    value={orderHandling.comment ?? ''}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => updateOrderHandling(orderHandlingIndex, {
                                        comment: newVal,
                                    })}
                                    // eslint-disable-next-line max-len
                                    errorMessage={tObj(errorField[`deliveryContentData.orderHandlings.${orderHandlingIndex}.comment`])}
                                />
                            </Columns.Column>
                        </Columns>
                        {(!isReadOnly && orderHandlingIndex > 0) && (
                            <div className="is-hidden-tablet">
                                <DefaultButton
                                    text="Supprimer"
                                    iconProps={{ iconName: 'Delete' }}
                                    disabled={isReadOnly}
                                    onClick={() => removeOrderHandling(orderHandlingIndex)}
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}
