import React, { PureComponent } from 'react'
import {
    Text,
} from '@fluentui/react'
// import history from 'helpers/history'
import Status from 'types/status'
import Company from 'requests/objects/company'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/pages/companies/index.module.scss'
// import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import CompanyList from 'components/pages/clients/company-list'

/** @debug {import('app').AppProps>} */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexCompanies extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            // /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            // columns: [],
            /** @type {Company[]} Items found in API */
            items: [],
            /** @type {object} Image sources of companies logo */
            imgSources: {},
        }

        this.imgRefs = {}

        // this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang } = this.props

        if (lang !== prevProps.lang)
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        const { imgSources } = this.state

        Object.keys(imgSources).forEach(key => this.imgRefs[key]?.cancel())

        this.companiesHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('companies'), key: 'company', isCurrentItem: true },
        ])
    }

    // /**
    //  * Setup commandbar elements
    //  */
    // setupCommandBar() {
    //     const { setCommand } = this.props

    //     setCommand(
    //         [
    //             {
    //                 key: 'new',
    //                 text: this.tKey('new'),
    //                 iconProps: { iconName: 'Add' },
    //                 onClick: () => history.push(`/companies/${NEW_PATH}`),
    //                 disabled: false,
    //             },
    //         ],
    //     )
    // }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        // this.setupCommandBar()

        this.search()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { companiesHandler } = this.props
            try {
                this.companiesHandlerGetAll = companiesHandler.getAll()
                const companies = await this.companiesHandlerGetAll.fetch()
                this.setState({
                    items: companies,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const { status, items } = this.state

        const { lang, filesHandler, setMessageBar } = this.props

        return (
            <main className={styles.index}>
                <Card>
                    <CompanyList
                        companies={items}
                        lang={lang}
                        status={status}
                        setMessageBar={setMessageBar}
                        filesHandler={filesHandler}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                </Card>
            </main>
        )
    }
}

IndexCompanies.prototype.tKey = tKey
IndexCompanies.prototype.tObj = tObj
