/**
 * @enum {number}
 */
const EOrderType = {
    Delivery: 1,
    Reprise: 2,
    Redelivery: 3,
    DocksideShed: 4,
}

export default EOrderType
