import React, {
    useMemo, useState,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DefaultButton, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList, TextField,
} from '@fluentui/react'
import Card from 'components/containers/card'
import Support, { Reference } from 'requests/objects/support'
import { Columns } from 'react-bulma-components'
import parseJson from 'helpers/methods/parseJson'

/**
 * Support ReferencesCard
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {Reference[]} props.references references
 * @returns {JSX.Element} Element
 */
function ReferencesCard({ references, lang }) {
    const { tKey } = useTranslate({ lang })

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: tKey('name'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'referenceTmsId',
            name: tKey('tmsCode'),
            fieldName: 'referenceTmsId',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
    ]), [tKey])

    return (
        <Card
            title={tKey('references')}
            iconName="PageData"
        >
            <ShimmeredDetailsList
                items={references}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
            />
        </Card>
    )
}

/**
 * Support Detail
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Support} props.item item
 * @param {Function} props.onReturn onReturn
 * @returns {JSX.Element} Element
 */
function SupportDetail({
    lang,
    item,
    onReturn,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    return (
        <>
            <Card
                title={tKey('support')}
                iconName="PageData"
                headerComponent={(
                    <DefaultButton
                        onClick={() => onReturn()}
                        text={tKey('return')}
                    />
                )}
            >
                <Columns>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label={tKey('name')}
                            value={tObj(parseJson(item.name)) ?? ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label={tKey('tmsCode')}
                            value={item.supportTmsId ?? ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                    <Columns.Column size="one-quarter">
                        <TextField
                            label={tKey('tmsTypeCode')}
                            value={item.supportTypeTmsId ?? ''}
                            readOnly
                            borderless
                        />
                    </Columns.Column>
                </Columns>

            </Card>
            <br />
            <ReferencesCard
                references={item.references}
                lang={lang}
            />
        </>
    )
}

/**
 * PivotSupports
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Support[]} props.supports supports
 * @returns {JSX.Element} Element
 */
export default function PivotSupports({
    lang, supports,
}) {
    const { tKey: translateKey, tObj } = useTranslate({ lang })
    const [selectedSupportId, setSelectedSupportId] = useState(null)

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: translateKey('name'),
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (<span>{tObj(parseJson(data.name))}</span>),

        },
        {
            key: 'References',
            name: translateKey('references'),
            minWidth: 300,
            maxWidth: 450,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (<span>{data.references.map(ref => ref.name).join(', ')}</span>),
        },
    ]), [tObj, translateKey])

    return (
        selectedSupportId ? (
            <SupportDetail
                lang={lang}
                item={supports.find(x => x.supportId === selectedSupportId)}
                onReturn={() => setSelectedSupportId(null)}
            />
        ) : (
            <Card
                title={translateKey('supports')}
                iconName="PageData"
            >
                <ShimmeredDetailsList
                    items={supports}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderRow={(props, defaultRender) => (
                        <button
                            style={{ all: 'unset' }}
                            type="button"
                            onClick={e => {
                                e.preventDefault()
                                setSelectedSupportId(props.item.supportId)
                            }}
                        >
                            <div>
                                {defaultRender(props)}
                            </div>
                        </button>
                    )}
                />
            </Card>
        )
    )
}
