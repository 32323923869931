import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/clients/client-list.module.scss'
import useTranslate from 'helpers/hooks/useTranslate'
import Client from 'requests/objects/client'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilesHandler from 'requests/handlers/filesHandler'
import EFileFolder from 'types/files/enums/fileFolder'

/**
 * ClientList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Client[]} props.clients clients
 * @param {Status} props.status status
 * @param {function(import('redux/slices/common').PayloadMessageBar):void} props.setMessageBar setMessageBar
 * @param {FilesHandler} props.filesHandler filesHandler
 * @returns {JSX.Element} Element
 */
export default function ClientList({
    lang, clients, status, setMessageBar, filesHandler,
}) {
    const { tKey: translateKey } = useTranslate({ lang })
    const [imgSources, setImgSources] = useState({})

    /** @type {object} */
    const imgRefs = useRef({})

    useEffect(() => {
        (async () => {
            try {
                const res = await clients.reduce(async (promise, client) => {
                    const results = await promise
                    const fileId = client['logo.fileId']
                    if (fileId) {
                        imgRefs.current[fileId] = filesHandler.getFile(EFileFolder.ClientLogo, fileId)
                        const img = await imgRefs.current[fileId].fetch()
                        if (img)
                            results[fileId] = window.URL.createObjectURL(img)
                    }
                    return results
                }, Promise.resolve({}))
                setImgSources(res)
            } catch (error) {
                setMessageBar({ isDisplayed: false })
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })()
    }, [clients, setMessageBar, setImgSources, filesHandler])

    const renderLogo = useCallback(
        /**
         * @param {Client} client client
         * @returns {JSX.Element} Element
         */
        client => {
            if (imgSources[client['logo.fileId']])
                return (
                    <img
                        src={imgSources[client['logo.fileId']]}
                        alt={client['logo.name']}
                    />

                )
            return (<div />)
        },
        [imgSources],
    )

    // const renderIsRisky = useCallback(
    //     /**
    //      * @param {Client} client client
    //      * @returns {JSX.Element} Element
    //      */
    //     client => (client.isRisky ? (
    //         <Icon
    //             iconName="CheckMark"
    //             styles={{ root: { color: '#0b6a0b', fontWeight: 'bold', marginLeft: '.5em' } }}
    //         />
    //     ) : (
    //         <Icon
    //             iconName="Clear"
    //             styles={{ root: { color: '#a4262c', fontWeight: 'bold', marginLeft: '.5em' } }}
    //         />
    //     )), [],
    // )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: translateKey('name'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 400,
            isResizable: true,
        },
        {
            key: 'code',
            name: translateKey('tmsCode'),
            fieldName: 'clientTmsId',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        // {
        //     key: 'isRisky',
        //     name: translateKey('isClientRisky'),
        //     fieldName: 'isRisky',
        //     minWidth: 150,
        //     maxWidth: 200,
        //     isResizable: true,
        //     onRender: client => renderIsRisky(client),
        // },
        {
            key: 'logo',
            name: translateKey('logo'),
            fieldName: 'logo',
            minWidth: 250,
            maxWidth: 500,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: client => renderLogo(client),
        },
    ]), [translateKey, renderLogo])

    useEffect(() => () => {
        if (imgRefs?.current)
            Object.keys(imgRefs.current).forEach(key => imgRefs.current[key]?.cancel())
    }, [imgSources])

    return (
        <div className={styles['client-list']}>
            <ShimmeredDetailsList
                items={clients}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                // eslint-disable-next-line react/no-unstable-nested-components
                onRenderRow={(props, defaultRender) => (
                    <Link to={`/clients/${props.item?.clientId}`}>
                        <div className={styles['client-list-row']}>
                            {defaultRender(props)}
                        </div>
                    </Link>
                )}
            />
        </div>
    )
}
