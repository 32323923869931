// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects/client'

/**
 * Company Object
 */
export default class DeliveryDateType extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.deliveryDateTypeId deliveryDateTypeId
     * @param {string=} data.name name
     * @param {object[]=} data.clients clients
     */
    constructor({
        deliveryDateTypeId = 0,
        name = '',
        clients = [],
        ...data
    } = {}) {
        super(data)
        this.deliveryDateTypeId = deliveryDateTypeId
        this.name = name
        this.clients = clients?.map(x => new Client(x)) ?? []
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorCompany extends ErrorBase {
    constructor() {
        super()
        this.deliveryDateTypeId = ''
        this.name = ''
    }
}
