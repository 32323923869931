import DeliveryType, { ErrorDeliveryType } from 'requests/objects/deliveryType'
import ApiHandler from 'requests/apiHandler'
import NotImplementedError from 'requests/errors/notImplementedError'

/**
 * DeliveryTypesHandler
 * @augments {ApiHandler<DeliveryType, ErrorDeliveryType>}
 */
export default class DeliveryTypesHandler extends ApiHandler {
    constructor() {
        super({
            type: DeliveryType, errorType: ErrorDeliveryType, key: 'delivery-types', automicErrorMessageDisplay: false,
        })
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getAll() {
        throw new NotImplementedError()
    }

    // eslint-disable-next-line jsdoc/require-returns-check
    /**
     * @override
     * @returns {any} any
     */
    // eslint-disable-next-line class-methods-use-this
    getById() {
        throw new NotImplementedError()
    }
}
