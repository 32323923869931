/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'
import WorkflowStep from 'requests/objects/workflowStep'
import EWorkflowStep from 'types/orders/enums/workflowStep'

/**
 * Company rule workflow step
 */
class CompanyRuleWorkflowStep {
    /**
     * @param {object} data data
     * @param {number=} data.workflowStepId workflowStepId
     * @param {object} data.workflowStep workflowStep
     * @param {number=} data.companyRuleId companyRuleId
     * @param {number=} data.position position
     */
    constructor({
        workflowStepId,
        workflowStep,
        companyRuleId,
        position,
    }) {
        this.workflowStepId = workflowStepId
        this.workflowStep = workflowStep ? new WorkflowStep(workflowStep) : undefined
        this.companyRuleId = companyRuleId
        this.position = position
    }
}

/**
 * Company rules Object
 */
export default class CompanyRule extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {boolean=} data.areAddressTimeSlotsMandatory areAddressTimeSlotsMandatory
     * @param {boolean=} data.isOperatingCenterRequired isOperatingCenterRequired
     * @param {object[]=} data.companyRuleWorkflowSteps companyRuleWorkflowSteps
     */
    constructor({
        companyId,
        company,
        areAddressTimeSlotsMandatory = false,
        isOperatingCenterRequired = false,
        companyRuleWorkflowSteps = [],
        ...data
    } = {}) {
        super(data)
        this.companyId = companyId
        this.companyName = company?.name ?? ''
        this.areAddressTimeSlotsMandatory = areAddressTimeSlotsMandatory
        this.isOperatingCenterRequired = isOperatingCenterRequired
        this.companyRuleWorkflowSteps = companyRuleWorkflowSteps?.map(x => new CompanyRuleWorkflowStep(x)) ?? []
        this.companyRuleWorkflowStepIds = companyRuleWorkflowSteps.length
            ? companyRuleWorkflowSteps.map(x => x.workflowStepId)
            : [EWorkflowStep.Creation, EWorkflowStep.OrderPlacedInImagine]
    }
}
