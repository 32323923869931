import React, {
    PureComponent,
} from 'react'
import {
    DefaultButton, DirectionalHint, Label, Text, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
import Client from 'requests/objects/client'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/clients/index.module.scss'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import ClientList from 'components/pages/clients/client-list'
import { Columns } from 'react-bulma-components'

/** @debug {import('app').AppProps} */
/**
 * @typedef {object} SearchParamsType
 * @property {string} name name
 * @property {string} code code
 */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexClients extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Client[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                name: '',
                code: '',
            },
        }

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    name: queryParams.get('name'),
                    code: queryParams.get('code'),
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang } = this.props
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        if (lang !== prevProps.lang)
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.clientsHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('clients'), key: 'client', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand(
            [
                {
                    key: 'new',
                    text: this.tKey('new'),
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/clients/${NEW_PATH}`),
                    disabled: false,
                },
            ],
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()
        this.search()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { clientsHandler } = this.props
            const { searchParams } = this.state
            try {
                this.clientsHandlerGetAll = clientsHandler.getAll(searchParams)
                const clients = await this.clientsHandlerGetAll.fetch()
                this.setState({
                    items: clients.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const { status, items, searchParams } = this.state

        const { lang, filesHandler, setMessageBar } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                >
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('name')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.name}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, name: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('tmsCode')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.code}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, code: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter" />
                            <Columns.Column size="one-quarter">
                                <div
                                    className={styles['action-filter-buttons']}
                                >
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <DefaultButton
                                            text={this.tKey('research')}
                                            primary
                                            disabled={status === Status.PENDING}
                                            onClick={() => this.submitInput.current.click()}
                                            iconProps={{ iconName: 'Search' }}
                                        />
                                    </div>
                                    <div className={styles.separator} />
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <TooltipHost
                                            content={this.tKey('resetFilters')}
                                            directionalHint={DirectionalHint.topAutoEdge}
                                            delay={TooltipDelay.medium}
                                        >
                                            <DefaultButton
                                                disabled={status === Status.PENDING}
                                                styles={{
                                                    root: {
                                                        minWidth: '40px',
                                                        maxWidth: '40px',
                                                    },
                                                }}
                                                iconProps={{ iconName: 'ClearFilter' }}
                                                onClick={() => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        name: '',
                                                        code: '',
                                                    },
                                                })}
                                            />
                                        </TooltipHost>
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>

                        <Columns />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </Card>
                <br />

                <Card>
                    <ClientList
                        clients={items}
                        lang={lang}
                        status={status}
                        setMessageBar={setMessageBar}
                        filesHandler={filesHandler}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                </Card>
            </main>
        )
    }
}

IndexClients.prototype.onColumnClick = onColumnClick
IndexClients.prototype.flattenObj = flattenObj
IndexClients.prototype.handleRenderColumn = handleRenderColumn
IndexClients.prototype.tKey = tKey
IndexClients.prototype.tObj = tObj
