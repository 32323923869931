import Type from 'types/type'
import { Column } from 'types/column' // eslint-disable-line
import { tObj } from 'helpers/methods/translate'
import store from 'redux/store'
import parseJson from 'helpers/methods/parseJson'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const userBaseColumns = [
    {
        fieldName: 'email',
        name: 'email',
        type: Type.STRING,
    },
    {
        fieldName: 'lastname',
        name: 'lastname',
        type: Type.STRING,
    },
    {
        fieldName: 'firstname',
        name: 'firstname',
        type: Type.STRING,
    },
    {
        fieldName: 'phoneNumber',
        name: 'phoneNumber',
        type: Type.STRING,
    },

    {
        fieldName: 'profile.name',
        name: 'profile',
        type: Type.STRING,
        onRender: item => tObj.call({ props: { lang: store.getState().common?.lang } }, parseJson(item['profile.name'])),
    },
]

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const userColumns = [
    {
        fieldName: 'registrationNumber',
        name: 'registrationNumber',
        type: Type.STRING,
    },
    ...userBaseColumns,
]

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
export const clientAccountColumns = [
    {
        fieldName: 'client.name',
        name: 'client',
        type: Type.STRING,
    },
    ...userBaseColumns,
]

export default userColumns
