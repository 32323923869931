/**
 * @enum {number}
 */
const ECompany = {
    JetFreeze: 1,
    Raud: 2,
    Pineau: 3,
}

export default ECompany
