import React, {
    useCallback, useEffect, useRef,
} from 'react'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import HandleBlob from 'helpers/methods/blob'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FileInput from 'components/inputs/fileInput'
import FilesHandler from 'requests/handlers/filesHandler'
import File from 'requests/objects/file'
import EFileFolder from 'types/files/enums/fileFolder'

/**
 * Files list
 * @param {object} props Props
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {boolean=} props.isDisabled isDisabled
 * @param {number} props.entityId entityId
 * @param {FilesHandler} props.handler filesHandler
 * @param {EFileFolder} props.fileFolderId fileFolderId
 * @param {(file: File) => void} props.updateItem updateItem
 * @param {File} props.file file
 * @param {string=} props.label label
 * @param {boolean=} props.isRequired isRequired
 * @returns {JSX.Element} Returns
 */
export default function PreviewFileInput({
    isReadOnly = false,
    isDisabled = false,
    entityId = 0,
    handler,
    fileFolderId,
    updateItem = () => null,
    file,
    label = 'Image',
    isRequired = true,
}) {
    /** @type {React.MutableRefObject<RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerUploadFile = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<File>>} */
    const handlerRemoveFile = useRef(null)

    const download = useCallback(async () => {
        try {
            handlerGetFile.current = handler.getFile(fileFolderId, file.fileId)
            const blob = await handlerGetFile.current.fetch()
            HandleBlob.download(blob, file?.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [file, fileFolderId, handler])

    const upload = useCallback(
        /**
         * @param {globalThis.File} newFile newFile
         */
        async newFile => {
            try {
                handlerUploadFile.current = handler.uploadFile(entityId, fileFolderId, newFile)
                const itemFile = await handlerUploadFile.current.fetch()
                updateItem(itemFile)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [entityId, fileFolderId, handler, updateItem],
    )

    const remove = useCallback(async () => {
        try {
            handlerRemoveFile.current = handler.removeFile(entityId, fileFolderId, file?.fileId)
            await handlerRemoveFile.current.fetch()
            updateItem(new File())
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [entityId, file?.fileId, fileFolderId, handler, updateItem])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerGetFile?.current?.cancel()
        handlerUploadFile?.current?.cancel()
        handlerRemoveFile?.current?.cancel()
    }, [])

    return (
        <FileInput
            label={label}
            isReadOnly={isReadOnly}
            isDisabled={!entityId || isDisabled}
            fileName={file?.name}
            tooltipContent=""
            onDownload={() => download()}
            // onOpen={() => download(true)}
            onUpload={newFile => upload(newFile)}
            onDelete={() => remove()}
            isRequired={isRequired}
        />
    )
}
