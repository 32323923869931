import axios from 'axios' // eslint-disable-line
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import { signOut } from 'redux/slices/user'
import { setMessageBar } from 'redux/slices/common'
import { MessageBarType } from '@fluentui/react'
import store from 'redux/store'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import UsersHandler from 'requests/handlers/usersHandler'
import parseJson from 'helpers/methods/parseJson'

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`)
        const language = localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_language`)
        if (token)
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`
        if (language)
            // eslint-disable-next-line no-param-reassign
            config.headers.Language = language
        return config
    },
    error => Promise.reject(error),
)

axios.interceptors.response.use(
    res => res,
    /**
     * @param {import('axios').AxiosError & { config : { retry: boolean }}} err Error
     * @returns {Promise<any>} Returns
     */
    async err => {
        const originalConfig = err.config

        if (
            !originalConfig?.url?.includes('users/refresh')
            && err.response?.status === 401
            && !originalConfig.retry
        ) {
            originalConfig.retry = true

            try {
                const { accessToken } = await new UsersHandler()
                    .refresh({
                        refreshToken: localStorage.getItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token_refresh`) ?? 'example',
                    })
                    .fetch()

                localStorage.setItem(`${process.env.REACT_APP_BASE_STORAGE_KEY}_token`, accessToken)

                return axios.request(originalConfig)
            } catch (error) {
                return Promise.reject(error)
            }
        }

        return Promise.reject(err)
    },
)

/**
 * @template R
 * @typedef {{ fetch: () => Promise<R>; cancel: () => void; }} RequestApi
 */

/**
 * @template R
 * @typedef {{ fetch: () => Promise<{items: R[], totalRecords: number, totalPages: number}>; cancel: () => void; }} RequestPageApi
 */

/**
 * @template T, E
 * @abstract
 */
export default class ApiHandler {
    /**
     * @param {object} settings settings
     * @param {object} settings.type Class to use
     * @param {object} settings.errorType Class to use for error
     * @param {object} settings.key Object name used for base url and retrieve result
     * @param {boolean} [settings.automicErrorMessageDisplay] Display error message automatically
     */
    constructor(settings) {
        /**
         * Base URL used for each API call
         * @protected
         * @type {string}
         */
        this.baseUrl = process.env.REACT_APP_API_URL || `${origin}/api/`
        /**
         * Type of object to return from API call
         * @protected
         * @type {T & object}
         */
        this.type = settings.type
        /**
         * Type of error object to return from API call when fields are invalid
         * @private
         * @type {E & object}
         */
        this.errorType = settings.errorType
        /**
         * Key to find in API call results
         * @protected
         * @type {string}
         */
        this.objectName = settings.key

        /**
         * List of cancel tokens that can be canceled
         * @protected
         * @type {Object<string, import('axios').CancelTokenSource>}
         */
        this.cancelTokens = {}

        /**
         * Display error message automatically
         * @protected
         * @type {boolean}
         * @default true
         */
        this.automicErrorMessageDisplay = settings.automicErrorMessageDisplay ?? true
    }

    /**
     * @protected
     * @param {import('axios').AxiosError<any>} err err
     * @param {boolean} displayErrorMessage displayErrorMessage
     * @returns {any} returns
     */
    handleError(err, displayErrorMessage = true) {
        /**
         * Error Messages
         * If using NestJs 😸
         * @returns {string} string
         */
        const getErrDesc = () => {
            if (typeof err.response.data !== 'object')
                return null
            if (err.response.data instanceof ArrayBuffer && err.response.data.byteLength !== undefined) {
                const decoder = new TextDecoder()
                const decoded = decoder.decode(err.response.data)
                const decodedObj = parseJson(decoded)
                if (decodedObj && decodedObj.message)
                    return decodedObj.message
                return decoded
            }

            const { error, message } = err.response.data

            if (typeof message === 'string')
                return message
            if (error?.fr || typeof error === 'string')
                return error
            return message
        }

        /**
         * Set error
         * If using NestJs 😸
         * @param {object} props props
         * @param {MessageBarType=} props.type type
         * @param {string=} props.message message
         * @returns {any} returns
         */
        const setMessage = ({
            type = MessageBarType.error,
            message = getErrDesc() ?? 'Une erreur est survenue',
        } = {}) => displayErrorMessage && this.automicErrorMessageDisplay && store.dispatch(setMessageBar({ isDisplayed: true, type, message }))

        if (axios.isCancel(err))
            return new CancelRequestError(err.message)
        if (err?.response) {
            switch (err.response.status) {
                case 400:
                    // If using NestJs 😸
                    if (JSON.stringify(err.response.data?.error)?.includes('Certains champs ne sont pas valides')) {
                        setMessage()
                        return new InvalidEntityError({
                            content: err.response.data?.message,
                            errorType: this.errorType,
                            message: err.response.data?.error?.[store.getState().common?.lang],
                        })
                    }
                    setMessage()
                    return getErrDesc() ?? err.response?.data?.errors
                case 401:
                    setMessage({ type: MessageBarType.blocked, message: getErrDesc() ?? "Vous n'êtes pas autorisé à faire cette action" })
                    store.dispatch(signOut())
                    return new UnauthorizedError('Unauthorized')
                case 403:
                    setMessage({ type: MessageBarType.blocked, message: getErrDesc() ?? "Vous n'êtes pas autorisé à faire cette action" })
                    return getErrDesc() ?? err.response?.data?.errors
                case 404:
                    setMessage({ message: getErrDesc() ?? "L'élément n'a pas été trouvé" })
                    return getErrDesc() ?? err.response?.data?.errors
                case 500:
                    setMessage({ message: getErrDesc() ?? 'Une erreur est survenue' })
                    return getErrDesc() ?? err.response?.data
                default:
                    setMessage({ message: getErrDesc() ?? 'Une erreur est survenue' })
                    return getErrDesc() ?? err.response?.data?.errors
            }
        } else if (err?.request) {
            setMessage({ message: err?.request?.toString() ?? 'Une erreur est survenue' })
            return err?.request?.toString()
        } else {
            setMessage({ message: err?.request?.toString() ?? 'Une erreur est survenue' })
            return err?.message?.toString()
        }
    }

    /**
     * @typedef {object} HandlerRequest HandlerRequest
     * @property {Promise<import('axios').AxiosResponse>} fetchRequest fetchRequest
     * @property {import('axios').CancelTokenSource} cancelToken cancelToken
     * @returns {HandlerRequest} HandlerRequest
     */
    /**
     * Init a new request
     * @protected
     * @param {object} params params
     * @param {(string | number)[]=} params.url url
     * @param {import('axios').AxiosRequestConfig['method']=} params.method method
     * @param {import('axios').AxiosRequestConfig['data']=} params.data data
     * @param {import('axios').AxiosRequestConfig['params']=} params.params params
     * @param {import('axios').AxiosRequestConfig['responseType']=} params.responseType responseType
     * @param {import('axios').AxiosRequestConfig['headers']=} params.headers headers
     * @returns {HandlerRequest} HandlerRequest
     */
    initFetchRequest({
        url = [], method = 'GET', data = {}, params = {}, responseType = 'json', headers = {},
    }) {
        /** @type {import('axios').CancelTokenSource} */
        const cancelToken = axios.CancelToken.source()

        return {
            fetchRequest: axios.request({
                baseURL: this.baseUrl,
                url: `${this.objectName}${url.length ? `/${url.filter(x => x).join('/')}` : ''}`,
                method,
                cancelToken: cancelToken.token,
                data,
                params,
                responseType,
                headers,
            }),
            cancelToken,
        }
    }

    /**
     * @protected
     * @param {() => Promise<any>} req req
     * @param {import('axios').CancelTokenSource} cancelToken cancelToken
     * @returns {RequestApi<any>} Request
     */
    // eslint-disable-next-line class-methods-use-this
    getRequestApi(req, cancelToken) {
        return {
            fetch: () => req(),
            cancel: () => cancelToken.cancel('Operation canceled by the user.'),
        }
    }

    /**
     * Get one by ID
     * @param {number} id Id
     * @returns {RequestApi<T>} Request
     */
    getById(id = undefined) {
        const request = this.initFetchRequest({ url: [id] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new (this.type)(res.data[this.objectName]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {RequestApi<T[]>} Request
     */
    getAll(params = {}) {
        const request = this.initFetchRequest({ params })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => /** @type {any} */(res.data[this.objectName])?.map(x => new (this.type)(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create
     * @param {T} obj Obj
     * @returns {RequestApi<T>} Request
     */
    // eslint-disable-next-line new-cap
    create(obj = new (this.type)()) {
        const request = this.initFetchRequest({ method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' }))
                    // eslint-disable-next-line new-cap
                    return new (this.type)(res.data[this.objectName])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update
     * @param {T} obj Obj
     * @param {number} id Id
     * @returns {RequestApi<T>} Request
     */
    // eslint-disable-next-line new-cap
    updateById(obj = new (this.type)(), id = undefined) {
        const request = this.initFetchRequest({ url: [id], method: 'PUT', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementhasBeenUpdated' }))
                    // eslint-disable-next-line new-cap
                    return new (this.type)(res.data[this.objectName])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {T} obj Request
     * @param {number=} id id
     * @returns {RequestApi<T>} Request
     */
    // eslint-disable-next-line new-cap
    upsert(obj = new (this.type)(), id = undefined) {
        if (id)
            return this.updateById(obj, id)

        return this.create(obj)
    }

    /**
     * Delete
     * @param {number} id id
     * @returns {RequestApi<T>} Request
     */
    removeById(id = undefined) {
        const request = this.initFetchRequest({ url: [id], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'modalDeleteMessage' }))
                    // eslint-disable-next-line new-cap
                    return new (this.type)(res.data[this.objectName])
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
