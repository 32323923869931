import React, {
    useCallback, useMemo,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, Text, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Status from 'types/status'
import DeliveryTime from 'requests/objects/deliveryTime'
import styles from 'styles/components/pages/companies/delivery-times/delivery-time-list.module.scss'
import flattenObj from 'helpers/methods/flattenObj'

/**
 * @callback EditDeliveryTimeCallback
 * @param {DeliveryTime} deliveryTime deliveryTime
 */

/**
 * @callback DeleteDeliveryTimeCallback
 * @param {DeliveryTime} deliveryTime deliveryTime
 */

/**
 * DeliveryTimeList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {DeliveryTime[]} props.items items
 * @param {Status} props.status status
 * @param {EditDeliveryTimeCallback} props.editDeliveryTime editDeliveryTime
 * @param {DeleteDeliveryTimeCallback} props.deleteDeliveryTime deleteDeliveryTime
 * @returns {JSX.Element} Element
 */
export default function DeliveryTimeList({
    lang,
    items,
    status,
    editDeliveryTime,
    deleteDeliveryTime,
}) {
    const { tKey } = useTranslate({ lang })

    const deliveryTimes = useMemo(() => items.map(x => flattenObj(x)), [items])

    const ActionButtons = useCallback(
        deliveryTime => (
            <div className={styles['action-buttons']}>
                <IconButton
                    iconProps={{ iconName: 'Edit' }}
                    title={tKey('edit')}
                    onClick={() => editDeliveryTime(deliveryTime)}
                />
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={tKey('delete')}
                    onClick={() => deleteDeliveryTime(deliveryTime)}
                />
            </div>
        ), [deleteDeliveryTime, editDeliveryTime, tKey],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'pickupZone',
            name: tKey('pickupZone'),
            fieldName: 'pickupZoneId',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'deliveryZone',
            name: tKey('deliveryZone'),
            fieldName: 'deliveryZoneId',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'time',
            name: tKey('time'),
            fieldName: 'time',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: 'delete',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: ActionButtons,
        },
    ]), [tKey, ActionButtons])

    return (
        <div className={styles['delivery-time-list']}>
            <ShimmeredDetailsList
                items={deliveryTimes}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
            />
            {!deliveryTimes.length && status !== Status.PENDING
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey(Status.IDLE ? 'pleaseDoASearch' : 'noResultFound')}</Text>}
        </div>
    )
}
