/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'

/**
 * OperatingCenter Object
 */
export default class OperatingCenter extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {string=} data.name name
     * @param {string=} data.phone phone
     * @param {string=} data.shortName shortName
     * @param {string=} data.email email
     * @param {string=} data.street street
     * @param {string=} data.city city
     * @param {string=} data.zipCode zipCode
     * @param {number=} data.logoId logoId
     */
    constructor({
        operatingCenterId,
        name,
        phone,
        shortName,
        email,
        street,
        city,
        zipCode,
        logoId,
        ...data
    } = {}) {
        super(data)
        this.operatingCenterId = operatingCenterId
        this.name = name
        this.phone = phone
        this.shortName = shortName
        this.email = email
        this.street = street
        this.city = city
        this.zipCode = zipCode
        this.logoId = logoId
    }
}
