import React from 'react'
import classNames from 'classnames'
import styles from 'styles/components/pages/workflow-light.module.scss'

/**
 * @typedef {object} Step
 * @property {string} label Label
 * @property {string=} description Description
 * @property {string=} message Message in tooltip
 * @property {boolean=} isCompleted Is step completed
 * @property {boolean=} isActive Is step active
 * @property {boolean=} isCanceled Is step canceled
 * @property {boolean=} isWarning Is step warning
 * @property {boolean=} isError Is step error
 */

/**
 * @param {object} props props
 * @param {Step[]} props.steps All steps
 * @param {string=} props.className Class to add
 * @returns {JSX.Element} Element
 */
function WorkflowLight({ steps, className }) {
    return (
        <div className={classNames(styles.steps, 'steps', className)}>
            {
                steps?.map((row, i) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className={classNames(
                            'step-item',
                            styles['step-item'],
                            { 'is-active': row.isActive },
                            { [styles['is-active']]: row.isActive },
                            { 'is-completed': row.isCompleted },
                            { [styles['is-completed']]: row.isCompleted },
                            { 'is-error': row.isError },
                            { [styles['is-error']]: row.isError },
                            { 'is-canceled': row.isCanceled },
                            { [styles['is-canceled']]: row.isCanceled },
                            { 'is-warning': row.isWarning },
                            { [styles['is-warning']]: row.isWarning },
                        )}
                    >
                        <div
                            className={classNames(
                                'step-marker',
                                styles['step-marker'],
                            )}
                        />
                        <div
                            className={classNames(
                                styles['step-details'],
                                'step-details',
                            )}
                        >
                            <p
                                className={classNames(
                                    styles['step-title'],
                                    'step-title',
                                )}
                                style={{ marginBottom: row.description?.length ? '1em' : '0' }}
                            >
                                {row.label}
                            </p>
                            <p className="is-hidden-touch">{row.description}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default WorkflowLight
