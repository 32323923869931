/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import File from 'requests/objects/file'
import Transport from 'requests/objects/transport'
import Client from 'requests/objects/client'

/**
 * Invoice Object
 */
export default class Invoice extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.invoiceId invoiceId
     * @param {string=} data.invoiceNumber invoiceNumber
     * @param {string=} data.date date
     * @param {string=} data.label label
     * @param {string=} data.deadlineDate deadlineDate
     * @param {string=} data.amountIncludingTax amountIncludingTax
     * @param {string=} data.amountExcludingTax amountExcludingTax
     * @param {number=} data.transportId transportId
     * @param {object=} data.transport transport
     * @param {number=} data.fileId fileId
     * @param {object=} data.file file
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {string=} data.url url
     */
    constructor({
        invoiceId = 0,
        invoiceNumber = '',
        date = '',
        label = '',
        deadlineDate = '',
        amountIncludingTax = '',
        amountExcludingTax,
        transportId,
        transport = '',
        fileId,
        file,
        clientId,
        client,
        url = '',
        ...data
    } = {}) {
        super(data)
        this.invoiceId = invoiceId
        this.invoiceNumber = invoiceNumber
        this.date = date ? new Date(date) : undefined
        this.label = label
        this.deadlineDate = deadlineDate ? new Date(deadlineDate) : undefined
        this.amountIncludingTax = amountIncludingTax ? parseFloat(amountIncludingTax) : undefined
        this.amountExcludingTax = amountExcludingTax ? parseFloat(amountExcludingTax) : undefined
        this.transportId = transportId
        this.transport = transport ? new Transport(transport) : undefined
        this.fileId = fileId
        this.file = file ? new File(file) : undefined
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.url = url
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorInvoice extends ErrorBase {
    constructor() {
        super()
        this.invoiceId = ''
        this.invoiceNumber = ''
        this.date = ''
        this.label = ''
        this.deadlineDate = ''
        this.amountIncludingTax = ''
        this.amountExcludingTax = ''
        this.transportId = ''
        this.transport = ''
        this.fileId = ''
        this.file = ''
        this.clientId = ''
        this.url = ''
    }
}
