import React, {
    PureComponent,
} from 'react'
import {
    ConstrainMode,
    DefaultButton, DetailsListLayoutMode, DirectionalHint, IconButton, SelectionMode, ShimmeredDetailsList, Text, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
import Address from 'requests/objects/address'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/addresses/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import parseJson from 'helpers/methods/parseJson'
import AddressModal from 'components/pages/addressModal'
import IsValidIcon from 'components/visuals/is-valid-icon'
import Department from 'requests/objects/department'

/** @debug {import('app').AppProps} */
/**
 * @typedef {object} SearchParamsType
 * @property {string} address address
 * @property {number} clientId clientId
 * @property {number} operatingCenterId operatingCenterId
 */

/**
 * @typedef {object} ItemType
 * @property {Address} address address
 * @property {Department} department department
 */

/**
 * @type {SearchParamsType}
 */
const defaultSearchParams = {
    address: null,
    clientId: null,
    operatingCenterId: null,
}

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexAddresses extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {ItemType[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: defaultSearchParams,
            /** @type {Address} Params to search */
            address: new Address(),
            /** @type {boolean} Is adress modal visible */
            isAdressModalVisible: false,
            /** @type {import('@fluentui/react').IColumn[]} */
            columns: [],
        }

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null

        this.submitInput = React.createRef()
        this.toolTipRef = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    address: queryParams.get('address') || null,
                    clientId: +queryParams.get('clientId') > 0 ? +queryParams.get('clientId') : null,
                    operatingCenterId: +queryParams.get('operatingCenterId') > 0 ? +queryParams.get('operatingCenterId') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang } = this.props
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        if (lang !== prevProps.lang)
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.addressesHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('addressesToBeValidated'), key: 'address', isCurrentItem: true },
        ])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setState({ columns: this.buildColumns() }, () => this.search())
    }

    /**
     * Init <DetailList>
     * @returns {import('@fluentui/react').IColumn[]} Columns
     */
    buildColumns() {
        const { param } = this.props

        return [
            {
                key: 'validateAddress',
                name: this.tKey('validateAddress'),
                minWidth: 50,
                maxWidth: 90,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        title={this.tKey('edit')}
                        onClick={e => {
                            e.preventDefault()
                            this.setState({
                                isAdressModalVisible: true,
                                address: item.address,
                            })
                        }}
                    />
                ),
            },
            {
                key: 'address',
                name: this.tKey('location'),
                fieldName: 'address.label',
                minWidth: 150,
                maxWidth: 450,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <div
                        style={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingRight: '2em',
                        }}
                    >
                        <b>
                            {item.address?.label}
                        </b>
                        <span>
                            {item.address?.street}
                            {' '}
                            {item.address?.zipCode}
                            {' '}
                            {item.address?.city}
                            {' '}
                            {item.address.country?.code || ''}
                        </span>
                    </div>
                ),
            },
            {
                key: 'client',
                name: this.tKey('client'),
                minWidth: 50,
                maxWidth: 120,
                isResizable: true,
                onRender: item => this.tObj(param.clients.find(x => x.key === item.address?.clientId)?.text),
            },
            {
                key: 'operatingCenter',
                name: this.tKey('operatingCenter'),
                minWidth: 50,
                maxWidth: 120,
                isResizable: true,
                onRender: item => this.tObj(param.operatingCenters.find(x => x.key === item.department?.operatingCenterId)?.text),
            },
            {
                key: 'isValidated',
                name: this.tKey('isValidated'),
                minWidth: 50,
                maxWidth: 100,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <IsValidIcon
                        isValid={item.address?.isValidated}
                    />
                ),
            },

        ]
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { addressesHandler } = this.props
            const { searchParams } = this.state
            try {
                this.addressesHandlerGetAll = addressesHandler.getAllToValidated(searchParams)
                const items = await this.addressesHandlerGetAll.fetch()
                this.setState({
                    items,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, searchParams, address, isAdressModalVisible, columns,
        } = this.state

        const {
            lang, addressesHandler, me: { company: { companyRule } }, param,
        } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                    headerComponent={(
                        <div
                            className={styles['action-filter-buttons']}
                        >
                            <div>
                                <DefaultButton
                                    text={this.tKey('research')}
                                    primary
                                    disabled={status === Status.PENDING}
                                    onClick={() => this.submitInput.current.click()}
                                    iconProps={{ iconName: 'Search' }}
                                />
                            </div>
                            <div className={styles.separator} />
                            <div>
                                <TooltipHost
                                    content={this.tKey('resetFilters')}
                                    directionalHint={DirectionalHint.topAutoEdge}
                                    delay={TooltipDelay.medium}
                                    componentRef={this.toolTipRef}
                                >
                                    <DefaultButton
                                        disabled={status === Status.PENDING}
                                        styles={{
                                            root: {
                                                minWidth: '40px',
                                                maxWidth: '40px',
                                            },
                                        }}
                                        iconProps={{ iconName: 'ClearFilter' }}
                                        onClick={() => this.setState({ searchParams: defaultSearchParams })}
                                    />
                                </TooltipHost>
                            </div>
                        </div>
                    )}
                >
                    {/* {isFilterCardReduced && ( */}
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('client')}
                                    options={param.clients.map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.clientId}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            clientId: option.key,
                                        },
                                    })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('operatingCenter')}
                                    options={param.operatingCenters.map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.operatingCenterId}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            operatingCenterId: option.key,
                                        },
                                    })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('address')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.address ?? ''}
                                    onChange={(ev, newVal) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            address: newVal,
                                        },
                                    })}
                                />
                            </Columns.Column>
                        </Columns>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </Card>
                <br />
                <Card
                    iconName="MapPin"
                    title={this.tKey('addresses')}
                >
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
                    />
                    {!items.length && status !== Status.PENDING
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}

                </Card>
                <AddressModal
                    addressData={address}
                    isVisible={isAdressModalVisible}
                    handler={addressesHandler}
                    isReadOnly={false}
                    isValidation
                    onChange={addressReturned => {
                        if (addressReturned) {
                            const newItems = items.map(x => (x.address.addressId === addressReturned.addressId
                                ? { ...x, address: addressReturned } : x))
                            this.setState({ items: newItems })
                        }
                        this.setState({ isAdressModalVisible: false })
                    }}
                    lang={lang}
                    param={param}
                    companyRules={companyRule}
                    isAdmin
                />
            </main>
        )
    }
}

IndexAddresses.prototype.onColumnClick = onColumnClick
IndexAddresses.prototype.flattenObj = flattenObj
IndexAddresses.prototype.handleRenderColumn = handleRenderColumn
IndexAddresses.prototype.tKey = tKey
IndexAddresses.prototype.tObj = tObj
IndexAddresses.prototype.parseJson = parseJson
