import Base from 'requests/objects/_base'

/**
 * Country Object
 */
export default class Country extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.countryId countryId
     * @param {string=} data.name name
     * @param {string=} data.code code
     */
    constructor({
        countryId = 0,
        name = '',
        code = '',
        ...data
    } = {}) {
        super(data)
        this.countryId = countryId
        this.name = name
        this.code = code
    }
}
