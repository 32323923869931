import Base from 'requests/objects/_base'

/**
 * OrderStatus
 */
export default class OrderStatus extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderStatusId orderStatusId
     * @param {string=} data.name name
     */
    constructor({
        orderStatusId,
        name,
        ...data
    }) {
        super(data)
        this.orderStatusId = orderStatusId
        this.name = name
    }
}
