import {
    // eslint-disable-next-line import/named
    init, signIn, signOut, PayloadInit, PayloadSingIn, UserState, PayloadMe, PayloadParam, PayloadParams, setMe, addParam, editParam, removeParam, setParams,
} from 'redux/slices/user'
import {
    setBreadcrumb,
    setCommand,
    setMessageBar,
    setModal,
    setUrl,
    PayloadMessageBar, // eslint-disable-line import/named
    PayloadUrl, // eslint-disable-line import/named
    PayloadBreadcrumb, // eslint-disable-line import/named
    PayloadCommand, // eslint-disable-line import/named
    PayloadModal, // eslint-disable-line import/named
    CommonState, // eslint-disable-line import/named
    setLang,
    PayloadLang, // eslint-disable-line import/named
} from 'redux/slices/common'

/** @debug {UserState|CommonState} */

/**
 * @typedef {object} ReduxProps
 * @property {function(PayloadInit):void} init Init
 * @property {function(PayloadMe):void} setMe setMe
 * @property {function(PayloadParams):void} setParams setParams
 * @property {function(PayloadSingIn):void} signIn Sign in
 * @property {function():void} signOut Sign out
 * @property {function(PayloadParam):void} addParam Add param
 * @property {function(PayloadParam):void} editParam Edit param from constant
 * @property {function(PayloadParam):void} removeParam Remove param from constant
 * @property {function(PayloadLang):void} setLang Set lang
 * @property {function(PayloadMessageBar):void} setMessageBar Set MessageBar
 * @property {function(PayloadCommand):void} setCommand Set CommandBar
 * @property {function(PayloadBreadcrumb):void} setBreadcrumb Set Breadcrumb
 * @property {function(PayloadModal):void} setModal Set Modal
 * @property {function(PayloadUrl):void} setUrl Set Current Url
 * @property {UserState["isAuthenticated"]} isAuthenticated Is user authenticated
 * @property {UserState["me"]} me User informations
 * @property {UserState["param"]} param Constants
 * @property {CommonState["selectedKeyURL"]} selectedKeyURL Current URL in application
 * @property {CommonState["messageBar"]} messageBar Messagebar
 * @property {CommonState["breadcrumb"]} breadcrumb Breadcrumb items
 * @property {CommonState["command"]} command Command items
 * @property {CommonState["modal"]} modal Modal
 * @property {CommonState["lang"]} lang lang
 */

const mapDispatchToProps = dispatch => ({
    init: ({
        me, param, lastUpdate,
    }) => dispatch(init({
        me, param, lastUpdate,
    })),
    setMe: ({ me }) => dispatch(setMe({ me })),
    setParams: ({ params }) => dispatch(setParams({ params })),
    signIn: token => dispatch(signIn(token)),
    signOut: () => dispatch(signOut(undefined)),
    addParam: data => dispatch(addParam(data)),
    editParam: ({ key, value }) => dispatch(editParam({ key, value })),
    removeParam: ({ key, value }) => dispatch(removeParam({ key, value })),
    setLang: lang => dispatch(setLang(lang)),

    setMessageBar: ({ isDisplayed, type, message }) => dispatch(setMessageBar({ isDisplayed, type, message })),
    setCommand: commandBarItems => dispatch(setCommand(commandBarItems)),
    setBreadcrumb: data => dispatch(setBreadcrumb(data)),
    setModal: ({
        show, title, subTitle, callback, content,
    }) => dispatch(setModal({
        show, title, subTitle, callback, content,
    })),
    setUrl: selectedKeyURL => dispatch(setUrl(selectedKeyURL)),
})

const mapStateToProps = state => ({
    isAuthenticated: state.user.isAuthenticated,
    me: state.user.me,
    param: state.user.param,
    selectedKeyURL: state.common.selectedKeyURL,
    messageBar: state.common.messageBar,
    breadcrumb: state.common.breadcrumb,
    command: state.common.command,
    modal: state.common.modal,
    lang: state.common.lang,
})

export { mapDispatchToProps, mapStateToProps }
