// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * Logistic Constraint Object
 */
export default class LogisticConstraint extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.logisticConstraintId logisticConstraintId
     * @param {string=} data.logisticConstraintTmsId logisticConstraintTmsId
     * @param {string=} data.name name
     * @param {number=} data.companyId companyId
     */
    constructor({
        logisticConstraintId,
        logisticConstraintTmsId = '',
        name = '',
        companyId,
        ...data
    } = {}) {
        super(data)
        this.logisticConstraintId = logisticConstraintId
        this.logisticConstraintTmsId = logisticConstraintTmsId
        this.name = name
        this.companyId = companyId
    }
}

/**
 * Logistic constraint Object used to bind error message
 */
export class ErrorLogisticConstraint extends ErrorBase {
    constructor() {
        super()
        this.LogisticConstraintId = ''
        this.logisticConstraintTmsId = ''
        this.name = ''
        this.companyId = ''
    }
}
