import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
import Time from 'helpers/methods/time'
import React from 'react'
import styles from 'styles/components/pages/status-data.module.scss'

/**
 * @typedef {object} StepType
 * @property {string} label Label
 * @property {string} subLabel SubLabel
 * @property {Date} date Date
 * @property {'normal' | 'error' | 'warning' | 'canceled'=} type Status
 */

/**
 * StatusData
 * @param {object} props props
 * @param {StepType[]} props.steps steps
 * @returns {JSX.Element} Returns
 */
export default function StatusData({
    steps = [],
}) {
    return (
        <div className={styles['status-data']}>
            <ul>
                {steps.map((step, i) => (
                    <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className={classNames(
                            styles['status-data-element'],
                            {
                                [styles['is-error']]: ['error', 'canceled'].includes(step?.type),
                                [styles['is-warning']]: step?.type === 'warning',
                            },
                        )}
                    >
                        {step?.type !== 'normal' && (
                            <Icon
                                iconName={step?.type === 'canceled' ? 'Cancel' : 'Warning'}
                                className={styles['status-data-element-icon']}
                            />
                        )}
                        <Text>
                            <b>{step?.label}</b>
                            <br />
                            <i>{step?.subLabel}</i>
                        </Text>
                        <Text>
                            {Time(step?.date).getCleanDate({ month: '2-digit', day: '2-digit' })}
                            <br />
                            {step?.date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    )
}
