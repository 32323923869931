import {
    DefaultButton,
    Dialog, DialogFooter, Text, Icon, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField, IconButton,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/tracking-link-modal.module.scss'
import OrdersHandler from 'requests/handlers/ordersHandler'
import { ParamElement } from 'requests/objects/param'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * Tracking ling data for modal usage
 * @typedef {object} TrackingLinkData
 * @property {number} orderId order id
 */

/**
 * TrackingLinkModal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {TrackingLinkData} props.data trackingLinkData
 * @param {string} props.lang lang
 * @param {OrdersHandler} props.ordersHandler ordersHandler
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function TrackingLinkModal({
    isVisible,
    setIsVisible,
    data,
    lang,
    ordersHandler,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [errorMessage, setErrorMessage] = useState('')
    const { tKey } = useTranslate({ lang })
    const [emails, setEmails] = useState([])
    const [deliveryAddressParams, setDeliveryAddressParams] = useState([])
    const [selectedId, setSelectedId] = useState(null)

    /** @type {React.MutableRefObject<RequestApi<ParamElement[]>>} */
    const getDeliveryAddressListhandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<any>>} */
    const sendEmailsHandler = useRef()

    const updateEmail = useCallback(
        /**
         * @param {number} emailIndex index
         * @param {string} email email
         */
        (emailIndex, email) => {
            setEmails(prevEmails => {
                const newEmails = [...prevEmails]

                newEmails[emailIndex] = email

                return newEmails
            })
        }, [],
    )

    const fetchDeliveryAddressParams = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            getDeliveryAddressListhandler.current = ordersHandler.getDeliveryAddressList(data.orderId)
            const params = await getDeliveryAddressListhandler.current.fetch()
            setStatus(Status.RESOLVED)
            setDeliveryAddressParams(params)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                case InvalidEntityError:
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
            }
        }
    }, [data.orderId, ordersHandler])

    useEffect(() => {
        if (isVisible === true)
            fetchDeliveryAddressParams()
    }, [fetchDeliveryAddressParams, isVisible])

    const sendEmails = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            sendEmailsHandler.current = ordersHandler.sendEmails(data.orderId, selectedId, emails)
            await sendEmailsHandler.current.fetch()
            setEmails([])
            setSelectedId(null)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError: {
                    const errorMsg = (error).errorField?.emails[0] // TODO help message display only in english see dto on server
                    if (errorMsg)
                        setErrorMessage(errorMsg)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                }
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [ordersHandler, data.orderId, selectedId, emails, setIsVisible])

    // On component did unmount
    useEffect(() => () => {
        sendEmailsHandler.current?.cancel()
    }, [])

    const setDefaultEmail = useCallback(email => {
        if (email.length) {
            const newEmails = emails
            newEmails[0] = email
            setEmails(newEmails)
        } else { setEmails([email]) }
    }, [emails])

    return (
        <div className={styles['tracking-link-modal']}>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: tKey('sendTrackingLink'),
                    subText: tKey('sendTrackingLinkSubtitle'),
                    styles: {
                        content: { minHeight: '400px' },
                        innerContent: { height: '100%' },
                    },
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {errorMessage}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        sendEmails()
                    }}
                />
                <FilteredVirtualCombobox
                    label={tKey('assiociatedDelivery')}
                    options={deliveryAddressParams}
                    selectedKey={selectedId}
                    onChange={(ev, option) => {
                        ev.preventDefault()
                        setSelectedId(option.key)
                        setDefaultEmail(option.email)
                    }}
                    // errorMessage={errorField.assiociatedDelivery}
                    required
                />
                <br />
                {emails.map((email, index) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className={styles.emails}
                        style={{ display: 'flex', flexDirection: 'row', marginBottom: '0.5rem' }}
                    >
                        <div style={{ flex: '1' }}>
                            <TextField
                                // eslint-disable-next-line no-nested-ternary
                                label={index === 0 ? tKey('emails') : undefined}
                                placeholder={tKey('email')}
                                value={email}
                                onChange={(ev, newVal) => {
                                    ev.preventDefault()
                                    updateEmail(index, newVal)
                                }}
                                disabled={status === Status.PENDING}
                            />
                        </div>
                        {index > 0 ? (
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={() => setEmails(prevEmails => prevEmails.filter((_, i) => i !== index))}
                            />
                        ) : (
                            <IconButton
                                disabled
                                styles={{
                                    root: { color: 'transparent', backgroundColor: 'transparent' },
                                    rootDisabled: { color: 'transparent', backgroundColor: 'transparent' },
                                }}
                            />
                        )}
                    </div>
                ))}
                {!!emails.length && (
                    <button
                        type="button"
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            marginLeft: '0.25rem',
                        }}
                        // className={styles['delivery-step-content-main-sub-add-btn']}
                        onClick={() => setEmails(prevEmails => [...prevEmails, ''])}
                    >
                        <Text variant="smallPlus">
                            <Icon iconName="CirclePlus" />
                            {' '}
                            {tKey('addEmail')}
                        </Text>
                    </button>
                )}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            sendEmails()
                        }}
                        text={tKey('send')}
                        disabled={status === Status.PENDING || !selectedId}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
