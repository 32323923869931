import React from 'react'
import styles from 'styles/components/visuals/divider.module.scss'

/**
 * Card item
 * @param {object} props props
 * @param {object=} props.style Style
 * @returns {JSX.Element} Element
 */
function Divider({ style = {} }) {
    return (
        <div
            className={styles.divider}
            style={style}
        >
            <span />
            <div />
        </div>
    )
}

export default Divider
