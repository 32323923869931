import usePrevious from 'helpers/hooks/usePrevious'
import useTranslate from 'helpers/hooks/useTranslate'
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import LogisticConstraint, { ErrorLogisticConstraint } from 'requests/objects/logisticConstraint'
import Status from 'types/status'
import LogisticConstraintsHandler from 'requests/handlers/logisticConstraintsHandler'
import parseJson from 'helpers/methods/parseJson'

/**
 * useLogisticConstraintModal
 * @param {object} props Props
 * @param {Partial<LogisticConstraint>=} props.data LogisticConstraint data
 * @param {boolean} props.isVisible isVisible
 * @param {boolean} [props.isDeletion] isDeletion
 * @param {object} props.lang Lang
 * @param {LogisticConstraintsHandler} props.handler handler
 * @param {(logisticConstraint: LogisticConstraint, isDeletion?: boolean) => void} props.onChange onChange
 * @param {number} props.companyId companyId
 * @returns {{
 *  tKey: (key: import('types/translations').TranslationsType, args?: { [key: string]: string; }) => string,
 *  tObj: (obj: import('types/translations').TranslationsObjectType | string | [string], args?: { [key: string]: string; }) => string,
 *  errorMessage: string,
 *  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
 *  onSubmit: () => Promise<void>,
 *  deleteLogisticConstraint: () => Promise<void>,
 *  logisticConstraint: LogisticConstraint,
 *  setLogisticConstraint: React.Dispatch<React.SetStateAction<LogisticConstraint>>,
 *  errorField: ErrorLogisticConstraint,
 *  status: string,
 *  dialogTitle: string,
 * }} Returns
 */
export default function useLogisticConstraintModal({
    lang,
    data,
    handler,
    isVisible,
    isDeletion = false,
    onChange,
    companyId,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    const [errorMessage, setErrorMessage] = useState('')
    const [errorField, setErrorField] = useState(new ErrorLogisticConstraint())
    const [status, setStatus] = useState(Status.IDLE)
    const [logisticConstraint, setLogisticConstraint] = useState(new LogisticConstraint(data))

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<LogisticConstraint>>} */
    const handlerUpsert = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<LogisticConstraint>>} */
    const handlerDelete = useRef()

    const prevIsVisible = usePrevious(isVisible)

    // Set logistic constraint data for edition or creation. Ensure that data in modal are up to date
    useEffect(() => {
        if (prevIsVisible !== isVisible)

            if (isVisible) { setLogisticConstraint(prevState => ({ ...prevState, ...data })) } else {
                setErrorMessage(null)
                setLogisticConstraint(new LogisticConstraint({ companyId }))
            }
    }, [companyId, data, isVisible, prevIsVisible])

    /**
     * Save the address
     */
    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpsert.current = handler.upsert(logisticConstraint, data?.logisticConstraintId)
            const res = await handlerUpsert.current.fetch()

            onChange(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError: break
                case NotImplementedError: break
                case InvalidEntityError:
                    // eslint-disable-next-line no-case-declarations
                    const err = /** @type {InvalidEntityError<ErrorLogisticConstraint>} */(error)
                    setErrorField(err.errorField)
                    setErrorMessage(err.message)
                    break
                default:
                    setErrorMessage(error.message || error)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [data?.logisticConstraintId, handler, logisticConstraint, onChange])

    /**
     * Delete the logistic constraint
     */
    const deleteLogisticConstraint = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerDelete.current = handler.removeById(data?.logisticConstraintId)
            const res = await handlerDelete.current.fetch()

            onChange(res, true)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                case InvalidEntityError:
                    break
                default:
                    setErrorMessage(error.message || error)
                    break
            }
        }
    }, [data?.logisticConstraintId, handler, onChange])

    const dialogTitle = useMemo(() => {
        if (isDeletion)
            return tKey('deleteLogisticConstraint', { name: tObj(parseJson(logisticConstraint.name)) })

        if (logisticConstraint.logisticConstraintId)
            return tKey('modalEditLogisticConstraintTitle')

        return tKey('modalLogisticConstraintTitle')
    }, [isDeletion, tKey, tObj, logisticConstraint.name, logisticConstraint.logisticConstraintId])

    // Clean some stuff
    useEffect(() => {
        if (isVisible)
            setErrorField(new ErrorLogisticConstraint())
    }, [isVisible])

    // On component did unmount
    useEffect(() => () => {
        handlerUpsert.current?.cancel()
        handlerDelete.current?.cancel()
    }, [])

    return {
        tKey,
        tObj,
        errorMessage,
        setErrorMessage,
        onSubmit,
        deleteLogisticConstraint,
        logisticConstraint,
        setLogisticConstraint,
        errorField,
        status,
        dialogTitle,
    }
}
