/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Client from 'requests/objects/client'
import Company from 'requests/objects/company'
import Profile from 'requests/objects/profile'
import OperatingCenter from 'requests/objects/operatingCenter'
import EProfile from 'types/users/enums/profile'

/**
 * User Object
 */
export default class User extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.userId userId
     * @param {string=} data.email email
     * @param {string=} data.firstname firstname
     * @param {string=} data.lastname lastname
     * @param {string=} data.accessToken accessToken
     * @param {string=} data.refreshToken refreshToken
     * @param {string=} data.registrationNumber registrationNumber
     * @param {string=} data.phoneNumber phoneNumber
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {number=} data.profileId profileId
     * @param {object=} data.profile profile
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {object=} data.operatingCenter client
     * @param {object[]=} data.clients clients
     * @param {object[]=} data.operatingCenters operatingCenters
     * @param {import('types/translations').LangsType=} data.language language
     */
    constructor({
        userId,
        email = '',
        firstname = '',
        lastname = '',
        accessToken = '',
        refreshToken = '',
        registrationNumber = '',
        phoneNumber = '',
        companyId,
        company = {},
        profileId,
        profile,
        clientId,
        client,
        operatingCenterId,
        operatingCenter,
        clients = [],
        operatingCenters = [],
        language = 'fr',
        ...data
    } = {}) {
        super(data)
        this.userId = userId
        this.email = email
        this.firstname = firstname
        this.lastname = lastname
        this.accessToken = accessToken
        this.refreshToken = refreshToken
        this.registrationNumber = registrationNumber
        this.phoneNumber = phoneNumber
        this.companyId = companyId
        this.company = new Company(company)
        this.profileId = profileId
        this.profile = profile ? new Profile(profile) : undefined
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.operatingCenterId = operatingCenterId
        this.operatingCenter = operatingCenter ? new OperatingCenter(operatingCenter) : undefined
        this.isClient = [EProfile.CustomerAdmin, EProfile.CustomOrderTracking, EProfile.CustomOrderEntry].includes(profileId)
        this.isAdmin = [EProfile.Admin, EProfile.SuperAdmin].includes(profileId)
        this.clients = clients?.map(x => new Client(x)) ?? []
        this.clientIds = this.clients.map(x => x.clientId)
        this.operatingCenters = operatingCenters?.map(x => new OperatingCenter(x)) ?? []
        this.operatingCenterIds = this.operatingCenters.map(x => x.operatingCenterId)
        this.language = language
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorUser extends ErrorBase {
    constructor() {
        super()
        this.userId = ''
        this.email = ''
        this.firstname = ''
        this.lastname = ''
        this.accessToken = ''
        this.refreshToken = ''
        this.registrationNumber = ''
        this.phoneNumber = ''
        this.companyId = ''
        this.profileId = ''
        this.clientId = ''
        this.password = ''
        this.plainPassword = ''
        this.plainPasswordTwo = ''
        this.operatingCenterIds = ''
        this.clientIds = ''
    }
}
