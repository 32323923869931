/**
 * @enum {number}
 */
const EOrderStatus = {
    Draft: 1,
    WaitingForTMS: 2,
    Validated: 3,
    ValidDraft: 4,
    Rejected: 5,
}

export default EOrderStatus
