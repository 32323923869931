import React, { PureComponent } from 'react'
import history from 'helpers/history'
import {
    getUpdatedList, handleRenderColumn, isValidDate,
} from 'helpers/methods/common'
import Status from 'types/status'
import Order from 'requests/objects/order'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import time from 'helpers/methods/time'
import styles from 'styles/pages/orders/index.module.scss'
// import { Columns } from 'react-bulma-components'
import { tKey, tObj } from 'helpers/methods/translate'
import OrderList from 'components/pages/orders/[id]/client/order-list'
import {
    TextField, DefaultButton, DirectionalHint, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { Columns } from 'react-bulma-components'
import parseJson from 'helpers/methods/parseJson'
import Card from 'components/containers/card'
import ContentResponsive from 'components/containers/contentResponsive'
import OrderCardList from 'components/pages/orders/[id]/client/orderCardList'
import EProfile from 'types/users/enums/profile'
import Pagination from 'components/pages/Pagination'
import MaintenancePortalInfo from 'components/pages/maintenancePortalInfo'
import ChooseOrderType from 'components/modals/choose-order-type'
import HandleBlob from 'helpers/methods/blob'

/**
 * @typedef {object} SearchParamsType
 * @property {number[]} orderStatusIds orderStatusIds
 * @property {number[]} pickupAddressIds pickupAddressIds
 * @property {number[]} deliveryAddressIds deliveryAddressIds
 * @property {Date} creationDate creationDate
 * @property {Date} deliveryDate deliveryDate
 * @property {Date} pickupDate pickupDate
 * @property {string} orderId orderId
 * @property {number} workflowStepId workflowStepId
 * @property {number} clientId clientId
 * @property {number} operatingCenterId operatingCenterId
 * @property {string} orderBy orderBy
 */

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexOrders extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Order[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                orderStatusIds: [],
                pickupAddressIds: [],
                deliveryAddressIds: [],
                creationDate: null,
                deliveryDate: null,
                pickupDate: null,
                orderId: null,
                workflowStepId: null,
                clientId: null,
                operatingCenterId: null,
                orderBy: null,
            },
            /** @type {import('components/pages/Pagination').PagingationType} pagination data */
            pagination: {
                currentPage: 1,
                totalPages: 1,
                totalRecords: 0,
            },
            /** @type {boolean} Is filter card reduced ? */
            // isFilterCardReduced: false,
            /** @type {boolean} isOrderModalOpen */
            isOrderModalOpen: false,
        }

        this.submitInput = React.createRef()
        this.toolTipRef = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang, me } = this.props
        const { searchParams, pagination: { currentPage } } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
            // eslint-disable-next-line max-len
        } else if ((lang !== prevProps.lang) || (me.companyId !== prevProps.me.companyId)) { this.init() } else if (prevState?.pagination?.currentPage && prevState.pagination.currentPage !== currentPage) { this.search() }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.ordersHandlerGetAll?.cancel()
        this.ordersHandlerPrintLoadingSheet?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('myOrders'), key: 'all-order', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand, me: { profileId, client, company } } = this.props

        if (company.isOperational && [EProfile.CustomerAdmin, EProfile.CustomOrderEntry].includes(profileId) && client?.canCreateOrder)
            setCommand(
                [
                    {
                        key: 'new',
                        text: this.tKey('newEntry'),
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            // if (param.deliveryTypes.some(x => x.shortName === 'REP'))
                            this.setState({ isOrderModalOpen: true })
                            // else
                            // history.push(`/orders/${NEW_PATH}`)
                        },
                        className: 'is-bold',
                    },
                ],
            )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            const timestamp = Date.parse(queryParams.get('creationDate'))

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    orderStatusIds: queryParams.getAll('orderStatusIds') ? queryParams.getAll('orderStatusIds').map(x => +x) : [],
                    pickupAddressIds: queryParams.getAll('pickupAddressIds') ? queryParams.getAll('pickupAddressIds').map(x => +x) : [],
                    deliveryAddressIds: queryParams.getAll('deliveryAddressIds') ? queryParams.getAll('deliveryAddressIds').map(x => +x) : [],
                    creationDate: !Number.isNaN(timestamp) ? new Date(timestamp) : null,
                    deliveryDate: queryParams.get('deliveryDate') ? new Date(queryParams.get('deliveryDate')) : null,
                    pickupDate: queryParams.get('pickupDate') ? new Date(queryParams.get('pickupDate')) : null,
                    orderId: queryParams.get('orderId') ? queryParams.get('orderId') : null,
                    workflowStepId: queryParams.get('workflowStepId') ? +queryParams.get('workflowStepId') : null,
                    clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                    operatingCenterId: queryParams.get('operatingCenterId') ? +queryParams.get('operatingCenterId') : null,
                    orderBy: queryParams.get('orderBy') ? queryParams.get('orderBy') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * printLoadingSheet
     */
    printLoadingSheet() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { ordersHandler } = this.props
            const { searchParams } = this.state
            try {
                this.ordersHandlerPrintLoadingSheet = ordersHandler.printLoadingSheet(searchParams)
                const { fileName, blob } = await this.ordersHandlerPrintLoadingSheet.fetch()
                HandleBlob.download(blob, fileName)
                this.setState({ status: Status.RESOLVED })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError:
                    case NotImplementedError:
                    default:
                        this.setState({ status: Status.REJECTED })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Search elements
     * @param {string} orderBy orderBy
     */
    search(orderBy = undefined) {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { ordersHandler } = this.props
            const { searchParams, pagination: { currentPage } } = this.state
            try {
                this.ordersHandlerGetAll?.cancel()
                this.ordersHandlerGetAll = ordersHandler.getAllFromPage({ ...searchParams, orderBy }, currentPage)
                const res = await this.ordersHandlerGetAll.fetch()
                this.setState({
                    items: res.items.map(x => this.flattenObj(x)),
                    pagination: {
                        currentPage: res.items.length === 0 ? 1 : currentPage,
                        totalPages: res.totalPages,
                        totalRecords: res.totalRecords,
                    },
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    // headerComponent={(
    //     <button
    //         type="button"
    //         onClick={() => this.setState({ isFilterCardReduced: !isFilterCardReduced })}
    //         className={styles['reduce-button-container']}
    //     >
    //         <div className={styles['reduce-button']}>
    //             <Text>
    //                 {isFilterCardReduced ? this.tKey('reduce') : this.tKey('extend')}
    //             </Text>
    //             <Text>
    //                 <Icon iconName={isFilterCardReduced ? 'ChevronUp' : 'ChevronDown'} />
    //             </Text>
    //         </div>

    //     </button>
    // )}

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, searchParams, pagination, isOrderModalOpen,
        } = this.state

        const { lang, param, me } = this.props

        if (!me.company.isOperational)
            return (<MaintenancePortalInfo lang={lang} />)

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                    headerComponent={(
                        <div
                            className={styles['action-filter-buttons']}
                        >
                            <div>
                                <DefaultButton
                                    text={this.tKey('research')}
                                    primary
                                    split
                                    disabled={status === Status.PENDING}
                                    onClick={() => this.submitInput.current.click()}
                                    iconProps={{ iconName: 'Search' }}
                                    menuProps={{
                                        items: [
                                            {
                                                key: 'Search',
                                                text: this.tKey('research'),
                                                iconProps: { iconName: 'Search' },
                                                onClick: () => this.submitInput.current.click(),
                                            },
                                            {
                                                key: 'PrintLoadingSheet',
                                                text: this.tKey('printLoadingSheet'),
                                                iconProps: { iconName: 'Export' },
                                                onClick: () => this.printLoadingSheet(),
                                                disabled: !searchParams.pickupDate,
                                            },
                                        ],
                                    }}
                                />
                            </div>
                            <div className={styles.separator} />
                            <div>
                                <TooltipHost
                                    content={this.tKey('resetFilters')}
                                    directionalHint={DirectionalHint.topAutoEdge}
                                    delay={TooltipDelay.medium}
                                    componentRef={this.toolTipRef}
                                >
                                    <DefaultButton
                                        disabled={status === Status.PENDING}
                                        styles={{
                                            root: {
                                                minWidth: '40px',
                                                maxWidth: '40px',
                                            },
                                        }}
                                        iconProps={{ iconName: 'ClearFilter' }}
                                        onClick={() => this.setState({
                                            /** @type {SearchParamsType} */
                                            searchParams: {
                                                orderStatusIds: [],
                                                pickupAddressIds: [],
                                                deliveryAddressIds: [],
                                                creationDate: null,
                                                deliveryDate: null,
                                                pickupDate: null,
                                                orderId: null,
                                                workflowStepId: null,
                                                clientId: null,
                                                operatingCenterId: null,
                                                orderBy: null,
                                            },
                                        })}
                                    />
                                </TooltipHost>
                            </div>
                        </div>
                    )}

                >
                    {/* {isFilterCardReduced && ( */}
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('entryOrderNumber')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.orderId || ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, orderId: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('pickupAddress')}
                                    options={param.addresses.filter(x => x.isVisible).map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.pickupAddressIds}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            pickupAddressIds: this.getUpdatedList(searchParams.pickupAddressIds, option),
                                        },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('deliveryAddress')}
                                    options={param.addresses.filter(x => x.isVisible).map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.deliveryAddressIds}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: {
                                            ...searchParams,
                                            deliveryAddressIds: this.getUpdatedList(searchParams.deliveryAddressIds, option),
                                        },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('status')}
                                    options={param.orderStatuses.map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.orderStatusIds}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, orderStatusIds: this.getUpdatedList(searchParams.orderStatusIds, option) },
                                    })}
                                    multiSelect
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('creationDate')}
                                    placeholder={this.tKey('creationDate')}
                                    value={searchParams.creationDate ? this.time(searchParams.creationDate).getLocaleDateString() : ''}
                                    onChange={(ev, newVal) => {
                                        ev.preventDefault()
                                        const date = new Date(newVal)
                                        this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                creationDate: this.isValidDate(date) ? date : null,
                                            },
                                        })
                                    }}
                                    type="date"
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('pickupDate')}
                                    placeholder={this.tKey('pickupDate')}
                                    value={searchParams.pickupDate ? this.time(searchParams.pickupDate).getLocaleDateString() : ''}
                                    onChange={(ev, newVal) => {
                                        ev.preventDefault()
                                        const date = new Date(newVal)
                                        this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                pickupDate: this.isValidDate(date) ? date : null,
                                            },
                                        })
                                    }}
                                    type="date"
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('deliveryDate')}
                                    placeholder={this.tKey('deliveryDate')}
                                    value={searchParams.deliveryDate ? this.time(searchParams.deliveryDate).getLocaleDateString() : ''}
                                    onChange={(ev, newVal) => {
                                        ev.preventDefault()
                                        const date = new Date(newVal)
                                        this.setState({
                                            searchParams: {
                                                ...searchParams,
                                                deliveryDate: this.isValidDate(date) ? date : null,
                                            },
                                        })
                                    }}
                                    type="date"
                                />
                            </Columns.Column>
                        </Columns>
                        {me.isAdmin && (
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('workflowStep')}
                                        options={param.workflowSteps.map(({ key, text }) => ({
                                            key,
                                            text: this.tObj(this.parseJson(text)),
                                        }))}
                                        selectedKey={searchParams.workflowStepId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: { ...searchParams, workflowStepId: option.key },
                                        })}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('client')}
                                        options={param.clients.map(({ key, text }) => ({
                                            key,
                                            text: this.tObj(this.parseJson(text)),
                                        }))}
                                        selectedKey={searchParams.clientId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: { ...searchParams, clientId: option.key },
                                        })}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('operatingCenter')}
                                        options={param.operatingCenters.map(({ key, text }) => ({
                                            key,
                                            text: this.tObj(this.parseJson(text)),
                                        }))}
                                        selectedKey={searchParams.operatingCenterId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: { ...searchParams, operatingCenterId: option.key },
                                        })}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                    {/* )} */}
                </Card>
                <br />
                <ContentResponsive min={768}>
                    <OrderList
                        lang={lang}
                        orders={items}
                        status={status}
                        isAdmin={me.isAdmin}
                        onOrderByChange={orderBy => this.search(orderBy)}
                    />
                </ContentResponsive>
                <ContentResponsive max={768}>
                    <OrderCardList
                        lang={lang}
                        orders={items}
                        status={status}
                    />
                </ContentResponsive>
                <Pagination
                    data={pagination}
                    lang={lang}
                    onPageChange={page => this.setState({ pagination: { ...pagination, currentPage: page } })}
                />
                {!!me.client && (
                    <ChooseOrderType
                        isOrderModalOpen={isOrderModalOpen}
                        orderTypes={me.client.orderTypes}
                        lang={lang}
                        cancel={() => this.setState({ isOrderModalOpen: false })}
                    />
                )}
            </main>
        )
    }
}

IndexOrders.prototype.handleRenderColumn = handleRenderColumn
IndexOrders.prototype.time = time
IndexOrders.prototype.isValidDate = isValidDate
IndexOrders.prototype.parseJson = parseJson
IndexOrders.prototype.getUpdatedList = getUpdatedList
IndexOrders.prototype.flattenObj = flattenObj
IndexOrders.prototype.tKey = tKey
IndexOrders.prototype.tObj = tObj
