/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import User from 'requests/objects/user'
import Order from 'requests/objects/order'

/**
 * OrderVisitHistoric Object
 */
export default class OrderVisitHistoric extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderVisitHistoricId orderVisitHistoricId
     * @param {number=} data.userId userId
     * @param {object=} data.user user
     * @param {number=} data.orderId orderId
     * @param {object=} data.order order
     */
    constructor({
        orderVisitHistoricId = 0,
        userId = 0,
        user = {},
        orderId = 0,
        order = {},
        ...data
    } = {}) {
        super(data)
        this.orderVisitHistoricId = orderVisitHistoricId
        this.userId = userId
        this.user = user ? new User(user) : undefined
        this.orderId = orderId
        this.order = order ? new Order(order) : undefined
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorOrderVisitHistoric extends ErrorBase {
    constructor() {
        super()
        this.orderVisitHistoricId = ''
    }
}
