/**
 * @enum {number}
 */
const ESupportContact = {
    AccountManager: 1,
    BusinessManager: 2,
    BillingOfficer: 3,
    Support: 4,
}

export default ESupportContact
