/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Address from 'requests/objects/address'

/**
 * Contact Object
 */
export default class Contact extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.contactId contactId
     * @param {string=} data.firstname firstname
     * @param {string=} data.lastname lastname
     * @param {string=} data.email email
     * @param {number=} data.addressId addressId
     * @param {object=} data.address address
     * @param {string=} data.phoneNumber phoneNumber
     * @param {string=} data.phoneNumber2 phoneNumber2
     */
    constructor({
        contactId,
        firstname = '',
        lastname = '',
        email = '',
        addressId,
        address,
        phoneNumber = '',
        phoneNumber2 = '',
        ...data
    } = {}) {
        super(data)
        this.contactId = contactId
        this.firstname = firstname
        this.lastname = lastname
        this.email = email
        this.addressId = addressId
        this.address = address ? new Address(address) : undefined
        this.phoneNumber = phoneNumber
        this.phoneNumber2 = phoneNumber2
    }
}

/**
 * Contact Object used to bind error message
 */
export class ErrorContact extends ErrorBase {
    constructor() {
        super()
        this.contactId = ''
        this.firstname = ''
        this.lastname = ''
        this.email = ''
        this.addressId = ''
        this.address = ''
        this.phoneNumber = ''
        this.phoneNumber2 = ''
    }
}
