import React, {
    useMemo, useState,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, IconButton, DefaultButton, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Card from 'components/containers/card'
import Article from 'requests/objects/article'
import ArticleModal from 'components/pages/articleModal'
import ClientsHandler from 'requests/handlers/clientsHandler'
import IsValidIcon from 'components/visuals/is-valid-icon'

/**
 * PivotArticles
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {number} props.clientId clientId
 * @param {ClientsHandler} props.clientHanlder clientHanlder
 * @param {Article[]} props.articles articles
 * @param {boolean} [props.isAdmin] isAdmin
 * @returns {JSX.Element} Element
 */
export default function PivotArticles({
    lang, articles, clientHanlder, clientId, isAdmin = true,
}) {
    const [displayedArticles, setDisplayedArticles] = useState(articles)

    const { tKey: translateKey } = useTranslate({ lang })
    const [selectedArticleId, setSelectedArticleId] = useState(null)
    const [isArticleModalVisible, setIsArticleModalVisible] = useState(false)

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: translateKey('name'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 280,
            isResizable: true,
        },
        {
            key: 'articleTmsId',
            name: translateKey('tmsCode'),
            fieldName: 'articleTmsId',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
        {
            key: 'footage',
            name: translateKey('footage'),
            fieldName: 'footage',
            minWidth: 50,
            maxWidth: 85,
            isResizable: true,
        },
        {
            key: 'weight',
            name: translateKey('weight'),
            fieldName: 'weight',
            minWidth: 50,
            maxWidth: 85,
            isResizable: true,
        },
        {
            key: 'long',
            name: translateKey('long'),
            fieldName: 'long',
            minWidth: 50,
            maxWidth: 85,
            isResizable: true,
        },
        {
            key: 'width',
            name: translateKey('width'),
            fieldName: 'width',
            minWidth: 50,
            maxWidth: 85,
            isResizable: true,
        },
        {
            key: 'height',
            name: translateKey('height'),
            fieldName: 'height',
            minWidth: 50,
            maxWidth: 85,
            isResizable: true,
        },
        {
            key: 'isVisible',
            name: translateKey('isVisible'),
            fieldName: 'isVisible',
            minWidth: 50,
            maxWidth: 90,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: item => (
                <IsValidIcon
                    isValid={item.isVisible}
                />
            ),
        },
        {
            key: 'calcFootage',
            name: translateKey('calcFootage'),
            fieldName: 'calcFootage',
            minWidth: 50,
            maxWidth: 235,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: item => (
                <IsValidIcon
                    isValid={item.calcFootage}
                />
            ),
        },
        {
            key: 'edit',
            name: '',
            minWidth: 50,
            maxWidth: 70,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: item => (
                isAdmin && (
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        title={translateKey('edit')}
                        onClick={e => {
                            e.preventDefault()
                            setIsArticleModalVisible(true)
                            setSelectedArticleId(item.articleId)
                        }}
                    />
                )
            ),
        },
    ]), [isAdmin, translateKey])

    return (
        <>
            <Card
                title={translateKey('articles')}
                iconName="PageData"
                headerComponent={isAdmin && (
                    <DefaultButton
                        onClick={() => {
                            setSelectedArticleId(null)
                            setIsArticleModalVisible(true)
                        }}
                        text={translateKey('add')}
                    />
                )}
            >
                <ShimmeredDetailsList
                    items={displayedArticles}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderRow={(props, defaultRender) => (
                        <div>
                            {defaultRender({ styles: { cell: { alignSelf: 'center' } }, ...props })}
                        </div>
                    )}
                />
            </Card>
            <ArticleModal
                isVisible={isArticleModalVisible}
                handler={clientHanlder}
                articleData={displayedArticles.find(
                    x => x.articleId === selectedArticleId,
                ) ?? { articleId: 0 }}
                lang={lang}
                clientId={clientId}
                onChange={newData => {
                    if (newData) {
                        const articlesRes = [...displayedArticles]
                        const index = articlesRes.findIndex(x => x.articleId === newData.articleId)
                        if (index >= 0)
                            articlesRes[index] = newData
                        else
                            articlesRes.push(newData)
                        setDisplayedArticles(articlesRes)
                    }
                    setIsArticleModalVisible(false)
                }}
            />
        </>

    )
}
