/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import OperatingCenter from 'requests/objects/operatingCenter'
import Country from 'requests/objects/country'

/**
 * Department Object
 */
export default class Department extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.departmentId departmentId
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {object=} data.operatingCenter operatingCenter
     * @param {number=} data.countryId countryId
     * @param {object=} data.country country
     * @param {number=} data.companyId companyId
     * @param {string=} data.code code
     * @param {string=} data.name name
     * @param {number=} data.pickupZoneId pickupZoneId
     * @param {number=} data.deliveryZoneId deliveryZoneId
     * @param {boolean=} data.mondayPickup mondayPickup
     * @param {boolean=} data.tuesdayPickup tuesdayPickup
     * @param {boolean=} data.wednesdayPickup wednesdayPickup
     * @param {boolean=} data.thursdayPickup thursdayPickup
     * @param {boolean=} data.fridayPickup fridayPickup
     * @param {boolean=} data.saturdayPickup saturdayPickup
     * @param {boolean=} data.sundayPickup sundayPickup
     * @param {boolean=} data.mondayDelivery mondayDelivery
     * @param {boolean=} data.tuesdayDelivery tuesdayDelivery
     * @param {boolean=} data.wednesdayDelivery wednesdayDelivery
     * @param {boolean=} data.thursdayDelivery thursdayDelivery
     * @param {boolean=} data.fridayDelivery fridayDelivery
     * @param {boolean=} data.saturdayDelivery saturdayDelivery
     * @param {boolean=} data.sundayDelivery sundayDelivery
     */
    constructor({
        departmentId = 0,
        operatingCenterId = 0,
        operatingCenter,
        countryId = 0,
        country,
        companyId = 0,
        code,
        name,
        deliveryZoneId,
        pickupZoneId,
        mondayPickup = true,
        tuesdayPickup = true,
        wednesdayPickup = true,
        thursdayPickup = true,
        fridayPickup = true,
        saturdayPickup = false,
        sundayPickup = false,
        mondayDelivery = true,
        tuesdayDelivery = true,
        wednesdayDelivery = true,
        thursdayDelivery = true,
        fridayDelivery = true,
        saturdayDelivery = false,
        sundayDelivery = false,
        ...data
    } = {}) {
        super(data)
        this.departmentId = departmentId
        this.operatingCenterId = operatingCenterId
        this.operatingCenter = operatingCenter ? new OperatingCenter(operatingCenter) : undefined
        this.countryId = countryId
        this.country = country ? new Country(country) : undefined
        this.companyId = companyId
        this.code = code
        this.name = name
        this.pickupZoneId = pickupZoneId
        this.deliveryZoneId = deliveryZoneId
        this.mondayPickup = mondayPickup
        this.tuesdayPickup = tuesdayPickup
        this.wednesdayPickup = wednesdayPickup
        this.thursdayPickup = thursdayPickup
        this.fridayPickup = fridayPickup
        this.saturdayPickup = saturdayPickup
        this.sundayPickup = sundayPickup
        this.mondayDelivery = mondayDelivery
        this.tuesdayDelivery = tuesdayDelivery
        this.wednesdayDelivery = wednesdayDelivery
        this.thursdayDelivery = thursdayDelivery
        this.fridayDelivery = fridayDelivery
        this.saturdayDelivery = saturdayDelivery
        this.sundayDelivery = sundayDelivery
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorDepartment extends ErrorBase {
    constructor() {
        super()
        this.departmentId = ''
        this.operatingCenterId = ''
        this.countryId = ''
        this.companyId = ''
        this.code = ''
        this.name = ''
        this.pickupZoneId = ''
        this.deliveryZoneId = ''
        this.mondayPickup = ''
        this.tuesdayPickup = ''
        this.wednesdayPickup = ''
        this.thursdayPickup = ''
        this.fridayPickup = ''
        this.saturdayPickup = ''
        this.sundayPickup = ''
        this.mondayDelivery = ''
        this.tuesdayDelivery = ''
        this.wednesdayDelivery = ''
        this.thursdayDelivery = ''
        this.fridayDelivery = ''
        this.saturdayDelivery = ''
        this.sundayDelivery = ''
    }
}
