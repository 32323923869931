import React, {
    PureComponent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { Navbar } from 'react-bulma-components'
import { MessageBar, Breadcrumb } from '@fluentui/react'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import styles from 'styles/pages/_basicLayout.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import { LangSelector } from 'components/_layout/header'
import { Link } from 'react-router-dom'
import Company from 'requests/objects/company'
import FilesHandler from 'requests/handlers/filesHandler'
import Status from 'types/status'
import useTranslate from 'helpers/hooks/useTranslate'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'

const defaultLogo = require('assets/img/logo.png').toString()

/**
 * @param {object} props Props
 * @param {FilesHandler} props.filesHandler filesHandler
 * @param {Company} props.company company
 * @param {string} props.lang lang
 * @returns {JSX.Element} Returns
 */
export function HeaderLogo({
    filesHandler, company = new Company(), lang,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [companyLogoSrc, setCompanyLogoSrc] = useState(null)
    const { tKey: _tKey } = useTranslate({ lang })

    /** @type {React.MutableRefObject<import("requests/apiHandler").RequestApi<Blob>>} */
    const getImgFilesHandler = useRef()

    /** Get logo from api */
    const fetchLogo = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            getImgFilesHandler.current = filesHandler.getPublicFileById(company.logoId, false)
            const img = await getImgFilesHandler.current.fetch()
            if (img)
                setCompanyLogoSrc(window.URL.createObjectURL(img))
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [company.logoId, filesHandler])

    // Get logo when company id change
    useEffect(() => {
        getImgFilesHandler.current?.cancel()
        if (company.companyId)
            fetchLogo()
    }, [fetchLogo, company.companyId])

    // On unmount
    useEffect(() => () => {
        getImgFilesHandler.current?.cancel()
    }, [])

    const appName = useMemo(() => `${_tKey('appName')} - ${company.name?.toLocaleUpperCase()}`, [company.name, _tKey])

    return (
        <>
            <img
                src={status === Status.PENDING || !companyLogoSrc ? defaultLogo : companyLogoSrc}
                alt={appName}
            />
            <span>
                {' '}
                {appName}
            </span>
        </>
    )
}

/** @debug {AppProps} */

/**
 * @typedef {object} BasicLayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * @augments {PureComponent<AppProps & BasicLayoutProps>}}
 */
export default class BasicLayout extends PureComponent {
    render() {
        const {
            isDisplay, children, messageBar, setMessageBar, lang, setLang, filesHandler, breadcrumb, me,
        } = this.props

        if (!isDisplay)
            return children

        return (
            <>
                <Navbar
                    active={false}
                    transparent={false}
                >
                    <Navbar.Brand>
                        <Navbar.Item>
                            <HeaderLogo
                                company={me?.client?.company}
                                filesHandler={filesHandler}
                                lang={lang}
                            />
                        </Navbar.Item>
                        <LangSelector
                            lang={lang}
                            setLang={setLang}
                            className="is-hidden-desktop"
                        />
                    </Navbar.Brand>
                    <Navbar.Menu>
                        <Navbar.Container
                            align="right"
                            className="is-hidden-touch"
                        >
                            <Navbar.Item
                                className="is-tab"
                            >
                                <LangSelector
                                    lang={lang}
                                    setLang={setLang}
                                />
                            </Navbar.Item>
                        </Navbar.Container>
                    </Navbar.Menu>
                </Navbar>
                <div className={styles.main}>
                    <main className={styles['main-body']}>
                        <Breadcrumb
                            items={breadcrumb}
                            maxDisplayedItems={5}
                            tooltipHostProps={{ content: undefined }}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            onRenderItem={(props, defaultRender) => (props.href
                                ? (
                                    <Link to={props.href}>
                                        {defaultRender({ ...props, href: undefined })}
                                    </Link>
                                )
                                : defaultRender(props))}
                        />
                        {messageBar?.isDisplayed
                            && (
                                <>
                                    <MessageBar
                                        messageBarType={messageBar.type}
                                        isMultiline={false}
                                        truncated
                                        onDismiss={() => setMessageBar({ isDisplayed: false })}
                                    >
                                        {(typeof messageBar.message === 'string'
                                            ? this.tKey(/** @type {any} */(messageBar.message))
                                            : this.tObj(/** @type {any} */(messageBar.message)))
                                            || messageBar.message?.toString()}
                                    </MessageBar>
                                    <br />
                                </>
                            )}
                        {children}
                        <footer>
                            <Link
                                to="/legal-mentions"
                            >
                                {this.tKey('trackingFooter', { name: me?.client?.company?.name ?? 'Mousset' })}
                            </Link>
                        </footer>
                    </main>
                </div>
            </>
        )
    }
}

BasicLayout.prototype.tKey = tKey
BasicLayout.prototype.tObj = tObj
