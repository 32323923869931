// // If using NestJs 😸
// /**
//  * @typedef {object} InvalidEntityContent
//  * @property {string} property property
//  * @property {string} constraints constraints
//  * @property {InvalidEntityContent[]} children children
//  */

// /**
//  * Invalid Entity Error, thrown when fields are missing or wrong
//  * @template E
//  */
// export default class InvalidEntityError extends Error {
//     /**
//      *
//      * @param {object} data data
//      * @param {object} data.errorType Class to use for error
//      * @param {InvalidEntityContent[]} data.content Content of the errors
//      * @param {string} data.message Message
//      */
//     constructor({ content, errorType, message }) {
//         super(message)
//         this.name = this.constructor.name

//         /**
//          * Object containing all the errors
//          * @type {E}
//          */
//         // eslint-disable-next-line new-cap
//         this.errorField = new (errorType)()

//         // Init errors from API into the object
//         // eslint-disable-next-line no-restricted-syntax
//         for (const validationResult of content)
//             this.errorField[validationResult.property] = (() => {
//                 try {
//                     const obj = Object.values(validationResult?.constraints)?.map(x => JSON.parse(x)) // [{fr: 'aze'}]
//                     return {
//                         fr: obj.map(x => x.fr)?.join(', '),
//                         en: obj.map(x => x.en)?.join(', '),
//                         nl: obj.map(x => x.nl)?.join(', '),
//                     }
//                 } catch (error) {
//                     return Object.values(validationResult?.constraints)?.join(', ')
//                 }
//             })()
//     }

//     /**
//      * Init errors from API into the object
//      * @private
//      * @param {InvalidEntityContent[]} validationResults validationResults
//      * @param {string=} key key
//      */
//     setErrorFields(validationResults, key) {
//         // eslint-disable-next-line no-restricted-syntax
//         for (const validationResult of validationResults) {
//             const newKey = [key, validationResult.property].filter(x => !!x).join('.')
//             this.errorField[newKey] = validationResult.constraints
//             if (validationResult.children)
//                 this.setErrorFields(validationResult.children, newKey)
//         }
//     }
// }

// If using NestJs 😸
/**
 * @typedef {object} InvalidEntityContent
 * @property {string} property property
 * @property {string} constraints constraints
 * @property {InvalidEntityContent[]} children children
 */

/**
 * Invalid Entity Error, thrown when fields are missing or wrong
 * @template E
 */
export default class InvalidEntityError extends Error {
    /**
     *
     * @param {object} data data
     * @param {object} data.errorType Class to use for error
     * @param {InvalidEntityContent[]} data.content Content of the errors
     * @param {string=} data.message message
     */
    constructor({ content, errorType, message }) {
        super(message ?? 'Certains champs ne sont pas valides')
        this.name = this.constructor.name

        /**
         * Object containing all the errors
         * @type {E}
         */
        // eslint-disable-next-line new-cap
        this.errorField = errorType ? new (errorType)() : {}

        this.setErrorFields(content)
    }

    /**
     * Init errors from API into the object
     * @private
     * @param {InvalidEntityContent[]} validationResults validationResults
     * @param {string=} key key
     */
    setErrorFields(validationResults, key) {
        // eslint-disable-next-line no-restricted-syntax
        for (const validationResult of validationResults) {
            const newKey = [key, validationResult.property].filter(x => !!x).join('.')
            this.errorField[newKey] = validationResult.constraints
            if (validationResult.children)
                this.setErrorFields(validationResult.children, newKey)
        }
    }
}
