import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/orders/[id]/client/pickup-step.module.scss'
import Card from 'components/containers/card'
import useTranslate from 'helpers/hooks/useTranslate'
import Order, { ErrorOrder } from 'requests/objects/order'
import { Columns } from 'react-bulma-components'
import {
    DefaultButton, Label, TextField, Toggle, PrimaryButton,
} from '@fluentui/react'
import DatePicker from 'components/inputs/datePicker'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import DetailInfo from 'components/pages/orders/[id]/client/common/detailInfo'
import { isValidDate } from 'helpers/methods/common'
import Param from 'requests/objects/param'
import AddressModal from 'components/pages/addressModal'
import AddressesHandler from 'requests/handlers/addressesHandler'
import EAddressType from 'types/addresses/enums/addressType'
import ContactModal from 'components/pages/contactModal'
import Address from 'requests/objects/address'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import Contact from 'requests/objects/contact'
import InstructionsModal from 'components/pages/orders/[id]/client/common/instructionsModal'
import Status from 'types/status'
import EProfile from 'types/users/enums/profile'
import TextFieldWithTooltip from 'components/inputs/textFieldWithTooltip'
import CompanyRule from 'requests/objects/companyRule'
import OpenWeekDays from 'components/pages/orders/[id]/client/common/openWeekDays'
import OpenDays from 'requests/objects/openDays'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import useCompany from 'helpers/hooks/useCompany'
import { ClientActivity } from 'requests/objects/client'
import Department from 'requests/objects/department'
import usePrevious from 'helpers/hooks/usePrevious'
import EOrderType from 'types/orders/enums/orderType'
import parseJson from 'helpers/methods/parseJson'

/**
 * PickupStep
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Order} props.item Item
 * @param {CompanyRule} props.companyRules companyRules
 * @param {ErrorOrder} props.errorField errorField
 * @param {(order: Partial<Order>) => void} props.onChange onChange
 * @param {Param} props.param param
 * @param {AddressesHandler} props.addressesHandler AddressesHandler
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.editParam editParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.addParam addParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.removeParam removeParam
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {number} props.profileId profileId
 * @param {ClientActivity[]} [props.clientActivities] clientActivities
 * @returns {JSX.Element} Element
 */
export default function PickupStep({
    lang, item, companyRules, errorField, onChange, param, addressesHandler, editParam, addParam, removeParam,
    profileId, isReadOnly, clientActivities = [],
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const addressTypeIds = (() => {
        switch (item.orderTypeId) {
            case EOrderType.Reprise:
                return [EAddressType.Delivery]
            case EOrderType.Redelivery:
                return [EAddressType.PickUp, EAddressType.Redelivery]
            case EOrderType.Delivery:
            default:
                return [EAddressType.PickUp]
        }
    })()
    const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
    const [isContactModalVisible, setIsContactModalVisible] = useState(false)
    const [isComplementModalVisible, setIsComplementModalVisible] = useState(false)
    const [addressModalData, setAddressModalData] = useState(new Address({ addressTypeIds }))
    const [status, setStatus] = useState(Status.IDLE)
    const [department, setDepartment] = useState(null)

    const { areAddressTimeSlotsMandatory, companyId } = companyRules

    const { isJetFreeze, isRaud, isPineau } = useCompany({ companyId })

    const { pickupAddressData, pickupDateData, pickupInstructionData } = useMemo(() => item, [item])

    const optionAddresses = useMemo(() => {
        switch (item.orderTypeId) {
            // delivery addresses
            case EOrderType.Redelivery:
            case EOrderType.Reprise:
                return param.addresses.filter(addr => !addr.isPlatform && addr.types.includes(EAddressType.Delivery))
            case EOrderType.DocksideShed:
                // only platforms
                return param.addresses.filter(addr => addr.isPlatform && addr.isVisible)
            case EOrderType.Delivery:
            default:
                // only pickup addresses and no platforms
                return param.addresses.filter(addr => !addr.isPlatform && addr.types.includes(EAddressType.PickUp))
        }
    }, [item.orderTypeId, param.addresses])

    const [isAddressReadOnly, isTimeSlotsReadOnly] = useMemo(() => {
        let addROnly = isReadOnly
        let timeROnly = isReadOnly
        if (item.isAddressValidation)
            addROnly = ![EProfile.Admin, EProfile.SuperAdmin, EProfile.Commercial].includes(profileId)
        if (item.isOperatorsValidation)
            addROnly = true
        if (item.isCreation && companyRules.areAddressTimeSlotsMandatory)
            timeROnly = true
        return [addROnly, timeROnly]
    }, [isReadOnly, item.isAddressValidation, item.isOperatorsValidation, item.isCreation, profileId, companyRules.areAddressTimeSlotsMandatory])

    const previousAddressId = usePrevious(pickupAddressData.addressId)

    const openDays = useMemo(() => new OpenDays({
        monday: department?.mondayPickup,
        tuesday: department?.tuesdayPickup,
        wednesday: department?.wednesdayPickup,
        thursday: department?.thursdayPickup,
        friday: department?.fridayPickup,
        saturday: department?.saturdayPickup,
        sunday: department?.sundayPickup,
    }), [department])

    const pickupStepErrorField = useMemo(() => errorField, [errorField])

    /** @type {React.MutableRefObject<RequestApi<Address>>} */
    const getAddressHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<Department>>} */
    const getDepartmentHandler = useRef()

    const onUpdate = useCallback(
        /**
         * @param {Partial<Order['pickupAddressData'] | Order['pickupDateData'] | Order['pickupInstructionData']>} data Data
         * @param {'pickupAddressData' | 'pickupDateData' | 'pickupInstructionData'} key Key
         */
        (data, key) => {
            switch (key) {
                case 'pickupAddressData':
                    onChange({ pickupAddressData: { ...pickupAddressData, ...data } })
                    break
                case 'pickupDateData':
                    onChange({ pickupDateData: { ...pickupDateData, ...data } })
                    break
                case 'pickupInstructionData':
                    onChange({ pickupInstructionData: { ...pickupInstructionData, ...data } })
                    break
                default:
                    break
            }
        }, [onChange, pickupAddressData, pickupDateData, pickupInstructionData],
    )

    // fetch address  data
    const setPickupAddressDataByAddressId = useCallback(async addressId => {
        try {
            setStatus(Status.PENDING)
            if (!addressId) {
                onChange({
                    ...item,
                    pickupAddressData: {
                        ...item.pickupAddressData,
                        address: undefined,
                        addressId: null,
                    },
                })
                return
            }
            getAddressHandler.current = addressesHandler.getById(addressId)
            const address = await getAddressHandler.current.fetch()
            const newItem = {
                ...item,
                pickupAddressData: {
                    ...pickupAddressData,
                    addressId: address?.addressId,
                    address,
                },
                pickupInstructionData: {
                    ...pickupInstructionData,
                    accessibilityId: address?.accessibilityId ? address.accessibilityId : 1,
                    vehicleEquipmentId: address?.vehicleEquipmentId ? address.vehicleEquipmentId : item.pickupInstructionData.vehicleEquipmentId,
                },
            }
            if (address?.startTime && address?.endTime) {
                newItem.pickupDateData.startPickupTime = address.startTime
                newItem.pickupDateData.endPickupTime = address.endTime
            }
            onChange(newItem)
            setStatus(Status.RESOLVED)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            setStatus(Status.PENDING)
        }
    }, [addressesHandler, item, onChange, pickupAddressData, pickupInstructionData])

    useEffect(() => {
        if (status !== Status.PENDING && pickupAddressData.addressId && pickupAddressData.addressId !== pickupAddressData.address?.addressId)
            setPickupAddressDataByAddressId(pickupAddressData.addressId)
    }, [status, setPickupAddressDataByAddressId, pickupAddressData.addressId, pickupAddressData.address?.addressId])

    useEffect(() => {
        if (isJetFreeze && previousAddressId !== pickupAddressData.addressId)
            if (pickupAddressData.addressId) {
                getDepartmentHandler.current = addressesHandler.getDepartement(pickupAddressData.addressId)

                getDepartmentHandler.current.fetch()
                    .then(dep => setDepartment(dep))
                    // eslint-disable-next-line no-console
                    .catch(e => console.log(e))
            } else {
                setDepartment(null)
            }
    }, [addressesHandler, previousAddressId, isJetFreeze, item.pickupAddressData.addressId, onUpdate, pickupAddressData.addressId])

    useEffect(() => () => {
        getAddressHandler?.current?.cancel()
        getDepartmentHandler?.current?.cancel()
    }, [])
    return (
        <>
            <div className={styles['pickup-step']}>
                <div className={styles['pickup-step-address']}>
                    <Card
                        title={tKey('address')}
                        iconName="CityNext"
                        hasLitleDividerMarging
                    >
                        <Columns>
                            <Columns.Column size="half">
                                <FilteredVirtualCombobox
                                    label={tKey(item.orderTypeId === EOrderType.DocksideShed ? 'dockingPlatform' : 'pickupAddress')}
                                    placeholder={item.pickupAddressData?.address?.label ?? tKey('research')}
                                    options={optionAddresses}
                                    selectedKey={pickupAddressData?.addressId}
                                    readOnly={isReadOnly || !item.isCreation}
                                    borderless={isReadOnly || !item.isCreation}
                                    onChange={
                                        (ev, option) => setPickupAddressDataByAddressId(option.key)
                                    }
                                    required={!isReadOnly && item.isCreation}
                                />
                                {pickupAddressData?.address?.addressId && (
                                    <DetailInfo
                                        onEdit={() => {
                                            setAddressModalData(new Address({ addressId: pickupAddressData.address.addressId }))
                                            setIsAddressModalVisible(true)
                                        }}
                                        isReadOnly={isAddressReadOnly || pickupAddressData.address.isPlatform}
                                        lang={lang}
                                    >
                                        <b>{pickupAddressData.address.label}</b>
                                        <br />
                                        {[
                                            pickupAddressData.address.street,
                                            pickupAddressData.address.zipCode,
                                            pickupAddressData.address.city,
                                            pickupAddressData.address.country?.code,
                                        ].filter(x => x).join(', ')}
                                    </DetailInfo>
                                )}
                            </Columns.Column>
                            <Columns.Column
                                size="half"
                                style={{ textAlign: 'right' }}
                            >
                                <Label>&#8203;</Label>
                                {
                                    !isAddressReadOnly && item.isCreation
                                    && (!isRaud || (isRaud && [EOrderType.Redelivery, EOrderType.Reprise].includes(item.orderTypeId)))
                                    && (
                                        <DefaultButton
                                            text={tKey('addAddress')}
                                            onClick={() => {
                                                const [startTime, endTime] = isPineau ? ['08:00', '18:00'] : ['00:00', '23:59']
                                                setAddressModalData(new Address({
                                                    addressTypeIds,
                                                    startTime,
                                                    endTime,
                                                }))
                                                setIsAddressModalVisible(true)
                                            }}
                                            iconProps={{ iconName: 'Add' }}
                                        />
                                    )
                                }
                                {!isAddressReadOnly && item.isAddressValidation && (
                                    <DefaultButton
                                        text={tKey(item.pickupAddressData.address.isValidated ? 'validatedAddress' : 'validateAddress')}
                                        disabled={item.pickupAddressData.address.isValidated}
                                        onClick={() => {
                                            setAddressModalData(new Address({ addressId: pickupAddressData.addressId }))
                                            setIsAddressModalVisible(true)
                                        }}
                                        iconProps={{ iconName: 'CheckMark' }}
                                    />
                                )}
                            </Columns.Column>
                        </Columns>
                        {!!pickupAddressData?.address && (
                            <>
                                <Columns>
                                    <Columns.Column size="half">
                                        <DetailInfo
                                            onEdit={() => setIsComplementModalVisible(true)}
                                            label={tKey('pickupInstruction')}
                                            isReadOnly={isAddressReadOnly || pickupAddressData.address.isPlatform}
                                            lang={lang}
                                            infoBubbleText={tKey('helperBubbleInstruction')}
                                        >
                                            {pickupAddressData.address.instructions || (isReadOnly && tKey('noInstruction')) || ''}
                                        </DetailInfo>
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size="half">
                                        <TextFieldWithTooltip
                                            label={tKey('pickupRequirement')}
                                            placeholder={isReadOnly ? tKey('noRequirement') : tKey('writeAnImperative')}
                                            readOnly={isAddressReadOnly}
                                            borderless={isAddressReadOnly}
                                            value={pickupAddressData.requirement}
                                            onChange={(_ev, newVal) => onUpdate({
                                                requirement: newVal?.length > 255 ? newVal.substring(0, 255) : newVal,
                                            }, 'pickupAddressData')}
                                            errorMessage={tObj(pickupStepErrorField['pickupAddressData.requirement'])}
                                            multiline
                                            autoAdjustHeight
                                            resizable={false}
                                            tooltipMessage={tKey('helperBubblePickupRequirement')}
                                        />
                                    </Columns.Column>
                                </Columns>
                                <Columns>
                                    <Columns.Column size="half">
                                        {
                                            // eslint-disable-next-line no-nested-ternary
                                            pickupAddressData.address?.contact?.contactId ? (
                                                <DetailInfo
                                                    onEdit={() => setIsContactModalVisible(true)}
                                                    label={tKey('contact')}
                                                    isReadOnly={isReadOnly || pickupAddressData.address.isPlatform}
                                                    lang={lang}
                                                >
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        {
                                                            // eslint-disable-next-line max-len
                                                            `${pickupAddressData.address.contact.firstname} ${pickupAddressData.address.contact.lastname.toUpperCase()}`
                                                        }
                                                    </span>
                                                    <br />
                                                    {pickupAddressData.address.contact.email}
                                                    <br />
                                                    {!!pickupAddressData.address.contact.phoneNumber && `+${pickupAddressData.address.contact.phoneNumber}`}
                                                    <br />
                                                    {!!pickupAddressData.address.contact.phoneNumber2 && `+${pickupAddressData.address.contact.phoneNumber2}`}
                                                </DetailInfo>
                                            ) : !isReadOnly && !item.isAddressValidation ? (
                                                <>
                                                    <Label
                                                        required
                                                        styles={{ root: { paddingBottom: 0, marginBottom: 0 } }}
                                                    >
                                                        {tKey('contact')}
                                                    </Label>
                                                    <TextField
                                                        styles={{
                                                            fieldGroup: {
                                                                border: 'none', padding: 0, margin: 0, height: 0,
                                                            },
                                                        }}
                                                        errorMessage={tObj(pickupStepErrorField['pickupAddressData.addressId'])}
                                                    />
                                                    <PrimaryButton
                                                        style={{ marginTop: '10px' }}
                                                        text={tKey('addContact')}
                                                        disabled={isAddressReadOnly}
                                                        onClick={() => {
                                                            onUpdate({
                                                                address: {
                                                                    ...pickupAddressData.address,
                                                                    contact: new Contact({ addressId: pickupAddressData.addressId }),
                                                                },
                                                            }, 'pickupAddressData')
                                                            setIsContactModalVisible(true)
                                                        }}
                                                        iconProps={{ iconName: 'Add' }}
                                                    />
                                                </>
                                            ) : null
                                        }
                                    </Columns.Column>
                                </Columns>
                            </>
                        )}
                    </Card>
                </div>
                <div className={styles['pickup-step-date']}>
                    <Card
                        title={tKey('date')}
                        iconName="Calendar"
                        hasLitleDividerMarging
                    >
                        <Columns>
                            <Columns.Column size="half">
                                <DatePicker
                                    date={pickupDateData.pickupDate}
                                    label={tKey(item.orderTypeId === EOrderType.DocksideShed ? 'docksidePickupDate' : 'pickupDate')}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChangeDate={newDate => onUpdate({
                                        pickupDate: isValidDate(new Date(newDate)) ? /** @type {any} */(new Date(newDate)) : '',
                                    }, 'pickupDateData')}
                                    errorMessage={pickupStepErrorField['pickupDateData.pickupDate'] ? tKey('missingDate') : ''}
                                    required={!isReadOnly}
                                    // minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                                    minDate={new Date()}
                                    lang={lang}
                                    openDays={openDays}
                                />
                            </Columns.Column>
                            <Columns.Column size="half">
                                {isJetFreeze && (
                                    <OpenWeekDays
                                        lang={lang}
                                        openDays={openDays}
                                        label={tKey('pickupDay')}
                                    />
                                )}
                            </Columns.Column>
                        </Columns>
                        {(isRaud || isPineau) && (
                            <Columns>
                                <Columns.Column size="half">
                                    <TextFieldWithTooltip
                                        label={tKey('pickupStartWindow')}
                                        placeholder={tKey('pickupStartWindow')}
                                        readOnly={isTimeSlotsReadOnly}
                                        borderless={isTimeSlotsReadOnly}
                                        value={pickupDateData.startPickupTime || ''}
                                        onChange={(ev, newVal) => onUpdate({ startPickupTime: newVal }, 'pickupDateData')}
                                        type="time"
                                        errorMessage={tObj(pickupStepErrorField['pickupDateData.startPickupTime'])}
                                        tooltipMessage={!areAddressTimeSlotsMandatory ? tKey('pickupWindowTooltip') : ''}
                                    />
                                </Columns.Column>
                                <Columns.Column size="half">
                                    <TextFieldWithTooltip
                                        label={tKey('pickupEndWindow')}
                                        placeholder={tKey('pickupEndWindow')}
                                        readOnly={isTimeSlotsReadOnly}
                                        borderless={isTimeSlotsReadOnly}
                                        value={pickupDateData.endPickupTime || ''}
                                        onChange={(ev, newVal) => onUpdate({ endPickupTime: newVal }, 'pickupDateData')}
                                        type="time"
                                        errorMessage={tObj(pickupStepErrorField['pickupDateData.endPickupTime'])}
                                        tooltipMessage={!areAddressTimeSlotsMandatory ? tKey('pickupWindowTooltip') : ''}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {isJetFreeze && (
                            <Columns>
                                <Columns.Column size="half">
                                    <TextField
                                        label={tKey('loadingStartWindow')}
                                        readOnly={isTimeSlotsReadOnly}
                                        borderless={isTimeSlotsReadOnly}
                                        value={pickupAddressData.address?.startTime || ''}
                                        type="time"
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {isJetFreeze && (
                            <Columns>
                                <Columns.Column size="half">
                                    <TextField
                                        label={tKey('loadingEndWindow')}
                                        readOnly={isTimeSlotsReadOnly}
                                        borderless={isTimeSlotsReadOnly}
                                        value={pickupAddressData.address?.endTime || ''}
                                        type="time"
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                    </Card>
                </div>
                <div className={styles['pickup-step-instruction']}>
                    <Card
                        title={tKey('instructions')}
                        iconName="NumberedListText"
                        hasLitleDividerMarging
                    >
                        {isPineau && (
                            <Columns>
                                <Columns.Column>
                                    <TextField
                                        label={tKey('logisticConstraints')}
                                        placeholder={!isReadOnly ? tKey('logisticConstraints') : tKey('noLogisticConstraint')}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        value={pickupInstructionData.logisticalConstraints}
                                        onChange={(_ev, newVal) => onUpdate({ logisticalConstraints: newVal }, 'pickupInstructionData')}
                                        errorMessage={tObj(pickupStepErrorField['pickupInstructionData.logisticalConstraints'])}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {/* {isRaud && (
                            <Columns>
                                <Columns.Column>
                                    <FilteredVirtualCombobox
                                        label={tKey('logisticConstraints')}
                                        placeholder={!isReadOnly ? tKey('select') : tKey('noLogisticConstraint')}
                                        options={param.logisticConstraints}
                                        readOnly={isReadOnly}
                                        selectedKey={pickupInstructionData.pickupLogisticConstraintIds}
                                        onChange={(ev, option) => onUpdate({
                                            pickupLogisticConstraintIds: getUpdatedList(pickupInstructionData.pickupLogisticConstraintIds, option),
                                        }, 'pickupInstructionData')}
                                        errorMessage={tObj(pickupStepErrorField['pickupInstructionData.pickupLogisticConstraintIds'])}
                                        multiSelect
                                    />
                                </Columns.Column>
                            </Columns>
                        )} */}
                        {(isJetFreeze || isPineau) && (
                            <Columns>
                                <Columns.Column size="half">
                                    <Toggle
                                        onText={tKey('yes')}
                                        offText={tKey('no')}
                                        label={tKey('meetingMandatory')}
                                        styles={{
                                            root: { display: 'flex', justifyContent: 'space-between', marginBottom: '-1rem' },
                                            container: { alignItems: 'center' },
                                        }}
                                        checked={pickupInstructionData.isMeetingMandatory}
                                        onChange={(ev, checked) => onUpdate({
                                            isMeetingMandatory: checked,
                                        }, 'pickupInstructionData')}
                                        disabled={isReadOnly}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {isJetFreeze && pickupInstructionData.isMeetingMandatory && (
                            <Columns>
                                <Columns.Column size="half">
                                    <TextField
                                        label={tKey('appointmentReference')}
                                        placeholder={isReadOnly ? undefined : tKey('writeReference')}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        required
                                        value={pickupInstructionData.appointmentReference}
                                        onChange={(_ev, newVal) => onUpdate({ appointmentReference: newVal }, 'pickupInstructionData')}
                                        errorMessage={tObj(pickupStepErrorField['pickupInstructionData.appointmentReference'])}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                        {(isJetFreeze || isPineau) && pickupInstructionData.isMeetingMandatory && (
                            <Columns>
                                <Columns.Column size="half">
                                    {pickupInstructionData.isMeetingMandatory && (
                                        <TextField
                                            label={tKey('commentAppointment')}
                                            placeholder={tKey('commentAppointment')}
                                            value={pickupInstructionData.commentAppointment}
                                            onChange={(_ev, newVal) => onUpdate({ commentAppointment: newVal }, 'pickupInstructionData')}
                                            errorMessage={tObj(pickupStepErrorField['pickupInstructionData.commentAppointment'])}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                        />
                                    )}
                                </Columns.Column>
                            </Columns>
                        )}
                        {isRaud && (
                            <Columns>
                                {isRaud && [EOrderType.Redelivery, EOrderType.Reprise].includes(item.orderTypeId) && (
                                    <Columns.Column size="half">
                                        <FilteredVirtualCombobox
                                            label={tKey('vehicleEquipment')}
                                            placeholder={tKey('select')}
                                            options={param.vehicleEquipments.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                            readOnly={isReadOnly}
                                            selectedKey={pickupInstructionData.vehicleEquipmentId}
                                            onChange={(ev, option) => onUpdate({ vehicleEquipmentId: option.key }, 'pickupInstructionData')}
                                            errorMessage={tObj(errorField['pickupInstructionData.vehicleEquipmentId'])}
                                            required={!isReadOnly}
                                        />
                                    </Columns.Column>
                                )}
                                {clientActivities.length > 1 && (
                                    <Columns.Column size="half">
                                        <FilteredVirtualCombobox
                                            label={tKey('activityCode')}
                                            placeholder={tKey('select')}
                                            options={clientActivities.map(({ clientActivityId, code }) => ({ key: clientActivityId, text: code }))}
                                            readOnly={isReadOnly}
                                            selectedKey={pickupInstructionData.clientActivityId}
                                            onChange={(ev, option) => onUpdate({ clientActivityId: option.key }, 'pickupInstructionData')}
                                            errorMessage={tObj(pickupStepErrorField['pickupInstructionData.clientActivityId'])}
                                            required
                                        />
                                    </Columns.Column>
                                )}
                                {
                                    isRaud && pickupInstructionData.vehicleEquipmentId !== 2
                                    && [EOrderType.Redelivery, EOrderType.Reprise].includes(item.orderTypeId) && (
                                        <Columns.Column size="half">
                                            <FilteredVirtualCombobox
                                                label={tKey('accessibility')}
                                                placeholder={tKey('select')}
                                                options={param.accessibilities.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                // readOnly={isReadOnly || (!!pickupAddressData.address?.accessibilityId && !!pickupAddressData.addressId)}
                                                readOnly={isReadOnly}
                                                selectedKey={pickupInstructionData.accessibilityId}
                                                onChange={(ev, option) => onUpdate({ accessibilityId: option.key }, 'pickupInstructionData')}
                                                errorMessage={tObj(errorField['pickupInstructionData.accessibilityId'])}
                                                required={!isReadOnly}
                                            />
                                        </Columns.Column>
                                    )
                                }
                            </Columns>
                        )}
                    </Card>
                </div>
            </div>
            <AddressModal
                addressData={addressModalData}
                handler={addressesHandler}
                isVisible={isAddressModalVisible}
                lang={lang}
                addParam={addParam}
                editParam={editParam}
                removeParam={removeParam}
                param={param}
                onChange={newData => {
                    if (newData) {
                        const newItem = {
                            ...item,
                            pickupAddressData: {
                                ...pickupAddressData,
                                addressId: newData?.addressId,
                                address: newData,
                            },
                            pickupInstructionData: {
                                ...pickupInstructionData,
                                accessibilityId: newData?.accessibilityId ? newData.accessibilityId : 1,
                                vehicleEquipmentId: newData?.vehicleEquipmentId ? newData.vehicleEquipmentId : item.pickupInstructionData.vehicleEquipmentId,
                            },
                        }
                        if (newData?.startTime && newData?.endTime) {
                            newItem.pickupDateData.startPickupTime = newData.startTime
                            newItem.pickupDateData.endPickupTime = newData.endTime
                        }
                        onChange(newItem)
                    }
                    setIsAddressModalVisible(false)
                }}
                addressTypeDisableIds={addressTypeIds}
                companyRules={companyRules}
                isValidation={item.currentWorkflowStepId === EWorkflowStep.AddressValidation}
                addressCanBeOptional={false}
            />
            <ContactModal
                isVisible={isContactModalVisible}
                handler={addressesHandler}
                contactData={pickupAddressData.address?.contact}
                lang={lang}
                onChange={newData => {
                    if (newData)
                        onUpdate({
                            address: { ...pickupAddressData.address, contact: newData },
                        }, 'pickupAddressData')
                    setIsContactModalVisible(false)
                }}
            />
            <InstructionsModal
                addressType={EAddressType.PickUp}
                isVisible={isComplementModalVisible}
                setIsVisible={newVal => setIsComplementModalVisible(newVal)}
                instructionsData={pickupAddressData?.address?.instructions}
                addressId={pickupAddressData?.address?.addressId}
                handler={addressesHandler}
                onChange={newData => onUpdate({
                    address: { ...pickupAddressData.address, instructions: newData },
                }, 'pickupAddressData')}
                lang={lang}
            />
        </>
    )
}
