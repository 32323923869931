/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * File Object
 */
export default class File extends Base {
    /**
     * @param {object} props props
     * @param {number=} props.fileId fileId
     * @param {string=} props.name name
     * @param {string=} props.nameOnDisk nameOnDisk
     * @param {Buffer=} props.data data
     * @param {string=} props.url url
     * @param {number=} props.fileCategoryId fileCategoryId
     * @param {object=} props.fileCategory fileCategory
     */
    constructor({
        fileId = 0,
        name = '',
        nameOnDisk = '',
        data = null,
        url = '',
        fileCategoryId,
        fileCategory = {},
        ...props
    } = {}) {
        super(props)
        this.fileId = fileId
        this.name = name
        this.nameOnDisk = nameOnDisk
        this.data = data
        this.url = url
        this.fileCategoryId = fileCategoryId
        this.fileCategory = fileCategory
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorFile extends ErrorBase {
    constructor() {
        super()
        this.fileId = ''
        this.name = ''
        this.nameOnDisk = ''
        this.url = ''
        this.fileCategoryId = ''
        this.fileCategory = ''
    }
}
