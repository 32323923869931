import { Icon, Text } from '@fluentui/react'
import classNames from 'classnames'
import Time from 'helpers/methods/time'
import React from 'react'
import HomeActuality from 'requests/objects/homeActuality'
import styles from 'styles/components/pages/index/actuality.module.scss'

/**
 * Actuality
 * @param {object} props props
 * @param {HomeActuality} props.homeActuality homeActuality
 * @returns {JSX.Element} JSX
 */
export default function Actuality({ homeActuality }) {
    return (
        <div
            className={classNames(
                styles.actuality,
                { [styles['is-info']]: homeActuality.homeActualityStatusId === 1 },
                { [styles['is-warning']]: homeActuality.homeActualityStatusId === 2 },
                { [styles['is-error']]: homeActuality.homeActualityStatusId === 3 },
            )}
        >
            <Text variant="mediumPlus">
                {homeActuality.homeActualityStatusId === 1 && (
                    <Icon iconName="Info" />
                )}
                {homeActuality.homeActualityStatusId === 2 && (
                    <Icon iconName="Warning" />
                )}
                {homeActuality.homeActualityStatusId === 3 && (
                    <Icon iconName="Error" />
                )}
                {' '}
                {homeActuality.title || ''}
            </Text>
            <Text
                variant="xSmall"
                className={styles.date}
            >
                {homeActuality.startDate ? Time(homeActuality.startDate).getCleanDate() : ''}
            </Text>
        </div>
    )
}
