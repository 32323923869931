/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import OrderVisitHistoric from 'requests/objects/orderVisitHistoric'
import Address from 'requests/objects/address'
import DeliveryData from 'requests/objects/order/deliveryData'
import EOrderStatus from 'types/orders/enums/orderStatus'
import OrderStatus from 'requests/objects/order/orderStatus'
import Client, { ClientActivity } from 'requests/objects/client'
import OperatingCenter from 'requests/objects/operatingCenter'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import WorkflowHistoric from 'requests/objects/workflowHistoric'
import WorkflowStep from 'requests/objects/workflowStep'
import EOrderType from 'types/orders/enums/orderType'
import OrderType from 'requests/objects/orderType'
import Accessibility from 'requests/objects/accessibility'

/**
 * Order Object
 */
export default class Order extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderId orderId
     * @param {string=} data.orderTmsId orderTmsId
     * @param {boolean=} data.savedTemporarily savedTemporarily
     * @param {boolean=} data.validatedByClient validatedByClient
     * @param {number=} data.orderStatusId orderStatusId
     * @param {object=} data.orderStatus orderStatus
     * @param {number=} data.orderTypeId orderTypeId
     * @param {object=} data.orderType orderType
     * @param {number=} data.currentPageIndex currentPageIndex
     * @param {object[]=} data.orderVisitHistorics orderVisitHistorics
     * @param {object} data.pickupAddressData pickupAddressData
     * @param {number=} data.pickupAddressData.orderId orderId
     * @param {object=} data.pickupAddressData.address address
     * @param {number=} data.pickupAddressData.addressId addressId
     * @param {string=} data.pickupAddressData.requirement requirement
     * @param {object} data.pickupDateData pickupDateData
     * @param {number=} data.pickupDateData.orderId orderId
     * @param {string=} data.pickupDateData.pickupDate pickupDate
     * @param {string=} data.pickupDateData.startPickupTime startPickupTime
     * @param {string=} data.pickupDateData.endPickupTime endPickupTime
     * @param {object} data.pickupInstructionData pickupInstructionData
     * @param {object[]=} data.pickupInstructionData.pickupLogisticConstraints pickupLogisticConstraints
     * @param {number=} data.pickupInstructionData.orderId orderId
     * @param {boolean=} data.pickupInstructionData.isMeetingMandatory isMeetingMandatory
     * @param {string=} data.pickupInstructionData.commentAppointment commentAppointment
     * @param {string=} data.pickupInstructionData.appointmentReference appointmentReference
     * @param {string=} data.pickupInstructionData.logisticalConstraints logisticalConstraints
     * @param {number=} data.pickupInstructionData.clientActivityId clientActivityId
     * @param {object=} data.pickupInstructionData.clientActivity clientActivity
     * @param {number=} data.pickupInstructionData.accessibilityId accessibilityId
     * @param {object=} data.pickupInstructionData.accessibility accessibility
     * @param {number=} data.pickupInstructionData.vehicleEquipmentId vehicleEquipmentId
     * @param {object[]} data.deliveryDatas deliveryDatas
     * @param {object=} data.client client
     * @param {number=} data.clientId clientId
     * @param {object=} data.operatingCenter operatingCenter
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {number=} data.currentWorkflowStepId currentWorkflowStepId
     * @param {object=} data.currentWorkflowStep currentWorkflowStep
     * @param {object[]=} data.workflowHistorics workflowHistorics
     * @param {object[]=} data.transports transports
     */
    constructor({
        orderId = 0,
        orderTmsId,
        savedTemporarily,
        validatedByClient,
        orderStatusId = EOrderStatus.Draft,
        orderStatus,
        orderTypeId = EOrderType.Delivery,
        orderType,
        currentPageIndex = 0,
        orderVisitHistorics = [],
        pickupAddressData = {},
        pickupDateData = {},
        pickupInstructionData = {},
        deliveryDatas = [],
        client = null,
        clientId = 0,
        operatingCenter = null,
        operatingCenterId = 0,
        currentWorkflowStepId,
        currentWorkflowStep,
        workflowHistorics = [],
        transports = [],
        ...data
    } = {
            pickupAddressData: {}, // eslint-disable-line indent
            pickupDateData: {}, // eslint-disable-line indent
            pickupInstructionData: {}, // eslint-disable-line indent
            deliveryDatas: [], // eslint-disable-line indent
        }) { // eslint-disable-line indent
        super(data)
        this.orderId = orderId
        this.client = client ? new Client(client) : undefined
        this.clientId = clientId
        this.orderTmsId = orderTmsId || NaN
        this.savedTemporarily = savedTemporarily
        this.validatedByClient = validatedByClient
        this.orderStatusId = orderStatusId
        this.orderStatus = orderStatus ? new OrderStatus(orderStatus) : undefined
        this.orderTypeId = orderTypeId
        this.orderType = orderType ? new OrderType(orderType) : undefined
        this.currentPageIndex = currentPageIndex
        this.orderVisitHistorics = orderVisitHistorics ? orderVisitHistorics?.map(x => new OrderVisitHistoric(x)) : []
        this.pickupAddressData = {
            orderId: pickupAddressData.orderId,
            address: pickupAddressData.address ? new Address(pickupAddressData.address) : undefined,
            addressId: pickupAddressData.addressId,
            requirement: pickupAddressData.requirement || '',
        }
        this.pickupDateData = {
            orderId: pickupDateData.orderId,
            pickupDate: pickupDateData.pickupDate ? new Date(pickupDateData.pickupDate) : undefined,
            startPickupTime: pickupDateData.startPickupTime || '',
            endPickupTime: pickupDateData.endPickupTime || '',
        }
        this.pickupInstructionData = {
            orderId: pickupInstructionData.orderId,
            pickupLogisticConstraints: pickupInstructionData.pickupLogisticConstraints ?? [],
            pickupLogisticConstraintIds: pickupInstructionData.pickupLogisticConstraints?.map(x => x.logisticConstraintId) ?? [],
            isMeetingMandatory: pickupInstructionData.isMeetingMandatory ?? false,
            commentAppointment: pickupInstructionData.commentAppointment ?? '',
            appointmentReference: pickupInstructionData.appointmentReference ?? '',
            logisticalConstraints: pickupInstructionData.logisticalConstraints ?? '',
            clientActivityId: pickupInstructionData.clientActivityId ?? 0,
            clientActivity: pickupInstructionData.clientActivity ? new ClientActivity(pickupInstructionData.clientActivity) : null,
            accessibilityId: pickupInstructionData.accessibilityId ?? 0,
            accessibility: pickupInstructionData.accessibility ? new Accessibility(pickupInstructionData.accessibility) : null,
            vehicleEquipmentId: pickupInstructionData.vehicleEquipmentId ?? 0,
        }
        this.deliveryDatas = deliveryDatas?.length > 0
            ? deliveryDatas.map(x => new DeliveryData(x))
            : [new DeliveryData({
                deliveryAddressData: {},
                deliveryComplementData: {},
                deliveryInstructionsData: {},
                deliveryDateData: {},
                deliveryContentData: {},
                deliveryFileData: {},
            })]

        this.deliveryDatasLength = deliveryDatas.filter(x => !!x.deliveryAddressData?.addressId)?.length || 0
        this.operatingCenter = operatingCenter ? new OperatingCenter(operatingCenter) : undefined
        this.operatingCenterId = operatingCenterId
        this.workflowHistorics = workflowHistorics?.map(x => new WorkflowHistoric(x)) ?? []
        this.currentWorkflowStepId = currentWorkflowStepId
        this.currentWorkflowStep = currentWorkflowStep ? new WorkflowStep(currentWorkflowStep) : undefined
        this.isCreation = !currentWorkflowStepId || currentWorkflowStepId === EWorkflowStep.Creation
        this.isAddressValidation = currentWorkflowStepId === EWorkflowStep.AddressValidation
        this.isOperatorsValidation = currentWorkflowStepId === EWorkflowStep.OperatorsValidation
        this.isOrderPlacedInImagine = currentWorkflowStepId === EWorkflowStep.OrderPlacedInImagine
        this.currentWorkflowStepName = currentWorkflowStep?.text ?? ''
        this.canPrintLabels = transports?.length === deliveryDatas?.length
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorOrder extends ErrorBase {
    constructor() {
        super()
        this.orderId = ''
        this.isRecovery = ''
        this.orderTypeId = ''
        this.pickupAddressData = {
            addressId: '',
            requirement: '',
        }
        this.pickupDateData = {
            pickupDate: '',
            startPickupTime: '',
            endPickupTime: '',
        }
        this.pickupInstructionData = {
            pickupLogisticConstraintIds: '',
            clientActivityId: '',
        }
    }
}
