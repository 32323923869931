import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.scss'
import App from 'app'
import { Provider } from 'react-redux'
import store from 'redux/store'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import ErrorBoundary from 'pages/_errorBoundary'
import { GoogleOAuthProvider } from '@react-oauth/google'

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <App />
            </GoogleOAuthProvider>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
