/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import HomeActuality from 'requests/objects/homeActuality'
import HomeArticle from 'requests/objects/homeArticle'
import OrderVisitHistoric from 'requests/objects/orderVisitHistoric'
import Transport from 'requests/objects/transport'

/**
 * Home Object
 */
export default class Home extends Base {
    /**
     * @param {object} data data
     * @param {object[]=} data.homeArticles homeArticles
     * @param {object[]=} data.homeActualities homeActualities
     * @param {object[]=} data.orderVisitHistorics orderVisitHistorics
     * @param {object[]=} data.transports transports
     */
    constructor({
        homeArticles = [],
        homeActualities = [],
        orderVisitHistorics = [],
        transports = [],
        ...data
    } = {}) {
        super(data)
        this.homeArticles = homeArticles ? homeArticles?.map(x => new HomeArticle(x)) : []
        this.homeActualities = homeActualities ? homeActualities?.map(x => new HomeActuality(x)) : []
        this.orderVisitHistorics = orderVisitHistorics ? orderVisitHistorics?.map(x => new OrderVisitHistoric(x)) : []
        this.transports = transports ? transports.map(x => new Transport(x)) : []
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorHome extends ErrorBase {
    constructor() {
        super()
        this.homeArticles = ''
        this.homeActualities = ''
        this.orderVisitHistorics = ''
    }
}
