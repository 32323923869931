import {
    ChoiceGroup,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Icon,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text,
    TextField,
} from '@fluentui/react'
import React, {
    useCallback, useRef, useState,
} from 'react'
import classNames from 'classnames'
import Status from 'types/status'
import useTranslate from 'helpers/hooks/useTranslate'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/advanced-file-input.module.scss'
import File from 'requests/objects/file'
import FilesHandler from 'requests/handlers/filesHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
// eslint-disable-next-line no-unused-vars
import Order from 'requests/objects/order'
import EFileFolder from 'types/files/enums/fileFolder'

/**
 * AdvancedFileInput
 * @param {object} props Props
 * @param {Order['deliveryDatas'][0]['deliveryFileData']} props.item Item
 * @param {object} props.lang Lang
 * @param {{id: number, text: string, disabled: boolean}[]} props.options options
 * @param {(file: File) => void} props.addFile addFile
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {FilesHandler} props.handler handler
 * @returns {JSX.Element} Element
 */
export default function AdvancedFileInput({
    lang, options, addFile, isReadOnly, handler, item,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    /** Is a file dragging */
    const [isDragging, setIsDragging] = useState(false)
    /** File */
    const [file, setFile] = useState(/** @type {globalThis.File} */(null))
    /** Is modal visible */
    const [isModalVisible, setIsModalVisible] = useState(false)
    /** Id file to upload */
    const [fileTypeId, setFileTypeId] = useState(null)
    /** Current status of the component */
    const [status, setStatus] = useState(Status.IDLE)
    /** Error message */
    const [errorMessage, setErrorMessage] = useState('')
    /** Url in input */
    const [fileUrl, setFileUrl] = useState('')
    /** Is Url valid */
    const [isUrlValid, setIsUrlValid] = useState(false)

    const uploadFileInput = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<File>>} */
    const handlerUploadFile = useRef(null)

    /**
     * On Change for input file
     */
    const onChange = useCallback(
        /**
         * @param {React.ChangeEvent<HTMLInputElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            setIsModalVisible(true)
            setFile(ev.target?.files?.[0])
        }, [],
    )

    /**
     * On drop for drop zone
     */
    const onDrop = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            if (isReadOnly)
                return

            setIsModalVisible(true)
            setIsDragging(false)
            setFile(ev.dataTransfer.items ? ev.dataTransfer.items[0]?.getAsFile() : ev.dataTransfer.files[0])
        }, [isReadOnly],
    )

    /**
     * On drag over for drop zone
     */
    const onDragOver = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            if (isReadOnly)
                return

            setIsDragging(true)
        }, [isReadOnly],
    )

    /**
     * On drag leave for drop zone
     */
    const onDragLeave = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            if (isReadOnly)
                return

            setIsDragging(false)
        }, [isReadOnly],
    )

    /**
     * Clear input
     */
    const clear = useCallback(() => {
        uploadFileInput.current.value = null
        setIsModalVisible(false)
        setFile(null)
        setFileTypeId(null)
        setStatus(Status.RESOLVED)
        setErrorMessage(null)
        setFileUrl('')
        setIsUrlValid(false)
    }, [])

    /**
     * On save file
     */
    const onSave = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            handlerUploadFile.current = handler.uploadFile(item?.deliveryFileDataId, EFileFolder.OrderDocument, file, fileTypeId, fileUrl)
            const newFile = await handlerUploadFile.current.fetch()
            addFile(newFile)
            clear()
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error ?? 'anErrorHasOccurred')
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, item?.deliveryFileDataId, file, fileTypeId, fileUrl, addFile, clear])

    return (
        <>
            <div className={classNames(styles['delivery-step-advanced-file-input'])}>
                <div
                    className={classNames(
                        styles['delivery-step-advanced-file-input-file'],
                        { [styles['is-dragging']]: isDragging },
                    )}
                    onDrop={ev => onDrop(ev)}
                    onDragOver={ev => onDragOver(ev)}
                    onDragLeave={ev => onDragLeave(ev)}
                    onClick={() => {
                        if (isReadOnly)
                            return
                        uploadFileInput.current.value = null
                        uploadFileInput.current.click()
                    }}
                    onKeyDown={() => {
                        if (isReadOnly)
                            return
                        uploadFileInput.current.value = null
                        uploadFileInput.current.click()
                    }}
                    role="button"
                    tabIndex={0}
                >
                    <Text>
                        <Icon
                            iconName="PageAdd"
                        />
                        <br />
                        <strong>{tKey('addAFile')}</strong>
                        <br />
                        {tKey('clickOrDragYourFileHere')}
                    </Text>
                    <input
                        type="file"
                        ref={uploadFileInput}
                        onChange={ev => onChange(ev)}
                    />
                </div>
                <div
                    className={classNames(styles['is-divider'])}
                    data-content={tKey('or')}
                />
                <div
                    className={classNames(styles['delivery-step-advanced-file-input-url'])}
                >
                    <Text>
                        <Icon
                            iconName="PageLink"
                        />
                        <br />
                        <strong>{tKey('addUrl')}</strong>
                        <br />
                    </Text>
                    <div className={styles['delivery-step-advanced-file-input-url-field']}>
                        <TextField
                            type="url"
                            placeholder="https://example.com"
                            value={fileUrl}
                            onChange={(ev, newVal) => {
                                setFileUrl(newVal)
                                setIsUrlValid(ev.currentTarget.checkValidity())
                            }}
                            disabled={isReadOnly}
                        />
                        <IconButton
                            iconProps={{ iconName: 'Add' }}
                            disabled={!fileUrl || isReadOnly || !isUrlValid}
                            type="button"
                            onClick={() => setIsModalVisible(true)}
                        />
                    </div>
                </div>
            </div>

            <Dialog
                hidden={!isModalVisible}
                onDismiss={() => clear()}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: tKey('documentType'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
            >
                {errorMessage
                    && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                truncated
                                onDismiss={() => setErrorMessage(null)}
                            >
                                {(typeof errorMessage === 'string'
                                    ? tKey(/** @type {any} */(errorMessage))
                                    : tObj(errorMessage))
                                    || errorMessage?.toString()}
                            </MessageBar>
                            <br />
                        </>
                    )}
                <ChoiceGroup
                    options={options.map(option => ({
                        key: option.id?.toString(),
                        text: option.text,
                        disabled: option.disabled,
                        iconProps: { iconName: 'TextDocument' },
                    }))}
                    onChange={(ev, option) => setFileTypeId(Number.parseInt(option.key, 10))}
                    required
                    disabled={status === Status.PENDING}
                />
                <DialogFooter>
                    <PrimaryButton
                        onClick={onSave}
                        disabled={!fileTypeId || status === Status.PENDING}
                    >
                        {tKey('save')}
                        {' '}
                        {status === Status.PENDING && (
                            <>
                                &nbsp;&nbsp;
                                <Spinner
                                    size={SpinnerSize.small}
                                    labelPosition="right"
                                />
                            </>
                        )}
                    </PrimaryButton>
                    <DefaultButton
                        onClick={() => clear()}
                        disabled={status === Status.PENDING}
                        text={tKey('cancel')}
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}
