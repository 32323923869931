/* eslint-disable max-classes-per-file */
import { ErrorBase } from 'requests/objects/_base'

/**
 * DeliveryTimeParams Object
 */
export default class DeliveryTimeParams {
    /**
     * @param {object} data data
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {Date=} data.pickupDate pickupDate
     * @param {string=} data.pickupCode pickupCode
     * @param {string=} data.deliveryCode deliveryCode
     */
    constructor({
        operatingCenterId,
        pickupDate,
        pickupCode = '',
        deliveryCode = '',
    } = {}) {
        this.operatingCenterId = operatingCenterId
        this.pickupDate = pickupDate
        this.pickupCode = pickupCode
        this.deliveryCode = deliveryCode
    }
}

/**
 * DeliveryTimeParams Object used to bind error message
 */
export class ErrorDeliveryTimeParams extends ErrorBase {
    constructor() {
        super()
        this.operatingCenterId = ''
        this.pickupDate = ''
        this.pickupCode = ''
        this.deliveryCode = ''
    }
}
