import useTranslate from 'helpers/hooks/useTranslate'
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import DeliveryType, { ErrorDeliveryType } from 'requests/objects/deliveryType'
import Status from 'types/status'
import parseJson from 'helpers/methods/parseJson'
import DeliveryTypesHandler from 'requests/handlers/deliveryTypesHandler'
import styles from 'styles/pages/delivery-types/delivery-types.module.scss'
import {
    IconButton,
} from '@fluentui/react'
import IsValidIcon from 'components/visuals/is-valid-icon'
/**
 * useLogisticConstraintModal
 * @param {object} props Props
 * @param {DeliveryTypesHandler} props.deliveryTypesHandler deliveryTypesHandler
 * @param {DeliveryType[]} props.deliveryTypes deliveryTypes
 * @param {object} props.lang Lang
 * @param {(deliveryType: DeliveryType, isDeletion: boolean) => void} props.onChange onChange
 * @param {number} props.companyId companyId
 * @returns {{
 *  tKey: (key: import('types/translations').TranslationsType, args?: { [key: string]: string; }) => string,
 *  tObj: (obj: import('types/translations').TranslationsObjectType | string | [string], args?: { [key: string]: string; }) => string,
 *  errorMessage: string,
 *  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
 *  isVisible: boolean,
 *  addDeliveryType: () => void,
 *  columns: import('@fluentui/react').IColumn[],
 *  deleteDeliveryType: () => Promise<void>,
 *  upsertDeliveryType: () => Promise<void>,
 *  deliveryType: DeliveryType,
 *  errorField: ErrorDeliveryType,
 *  setDeliveryType: React.Dispatch<React.SetStateAction<DeliveryType>>,
 *  updateDeliveryTypeList: (dt: DeliveryType) => void,
 *  isDeletion: boolean,
 *  status: string,
 *  dialogTitle: string,
 * }} Returns
 */
export default function usePivotdeliveryTypes({
    lang,
    deliveryTypes,
    deliveryTypesHandler,
    onChange,
    companyId,
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const [selectedId, setSelectedId] = useState(null)
    const [isVisible, setIsVisible] = useState(false)
    const [isDeletion, setIsDeletion] = useState(false)
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorDeliveryType())
    const [errorMessage, setErrorMessage] = useState('')
    const [deliveryType, setDeliveryType] = useState(new DeliveryType({ companyId }))

    // modal data
    useEffect(() => {
        if (selectedId)
            setDeliveryType(deliveryTypes.find(x => x.deliveryTypeId === selectedId))
        else
            setDeliveryType(new DeliveryType({ companyId }))
    }, [companyId, deliveryTypes, selectedId])

    const addDeliveryType = useCallback(() => {
        setSelectedId(null)
        setIsVisible(true)
    }, [])

    const updateDeliveryTypeList = useCallback(
        /**
         * @param {DeliveryType} dt deliveryType
         */
        dt => {
            if (dt)
                onChange(dt, isDeletion)
            if (isDeletion)
                setIsDeletion(false)
            setIsVisible(false)
        }, [onChange, isDeletion],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'shortName',
            name: tKey('abbreviation'),
            fieldName: 'shortName',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            className: styles['list-item'],

        },
        {
            key: 'name',
            name: tKey('name'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 400,
            isResizable: true,
            className: styles['list-item'],
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (<span>{tObj(parseJson(data.name))}</span>),
        },
        {
            key: 'isComfourDataRequired',
            name: tKey('isComfourDataRequired'),
            isResizable: true,
            minWidth: 50,
            maxWidth: 120,
            className: styles['list-item'],
            onRender: data => (
                <IsValidIcon
                    isValid={data.hasComfourDataRequired}
                    style={{ paddingLeft: '1em' }}
                />
            ),
        },
        {
            key: 'isOtNumberRequired',
            name: tKey('isOtNumberRequired'),
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            className: styles['list-item'],
            onRender: data => (
                <IsValidIcon
                    isValid={data.hasOtNumberRequired}
                    style={{ paddingLeft: '1em' }}
                />
            ),
        },
        {
            key: 'isRdvRequired',
            name: tKey('isRdvRequired'),
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            className: styles['list-item'],
            onRender: data => (
                <IsValidIcon
                    isValid={data.hasRdvRequired}
                    style={{ paddingLeft: '1em' }}
                />
            ),
        },
        {
            key: 'orderType',
            name: tKey('orderType'),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            className: styles['list-item'],
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (<span>{tObj(parseJson(data.orderType?.name ?? ''))}</span>),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 100,
            maxWidth: 100,
            isResizable: false,
            className: styles['list-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                dt => (
                    <div className={styles['actions-buttons']}>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title={tKey('edit')}
                            onClick={e => {
                                e.preventDefault()
                                setSelectedId(dt.deliveryTypeId)
                                setIsVisible(true)
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={e => {
                                e.preventDefault()
                                setSelectedId(dt.deliveryTypeId)
                                setIsDeletion(true)
                                setIsVisible(true)
                            }}
                        />
                    </div>
                ),
        },
    ]), [tKey, tObj])

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryType>>} */
    const handlerUpsert = useRef()

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryType>>} */
    const handlerDelete = useRef()
    /**
     * Delete the logistic constraint
     */
    const deleteDeliveryType = useCallback(async () => {
        try {
            setErrorMessage('')
            setStatus(Status.PENDING)
            handlerDelete.current = deliveryTypesHandler.removeById(deliveryType.deliveryTypeId)
            const res = await handlerDelete.current.fetch()
            setSelectedId(null)
            updateDeliveryTypeList(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                case InvalidEntityError:
                    break
                default:
                    setErrorMessage(error.message || error)
                    break
            }
        }
    }, [deliveryType.deliveryTypeId, deliveryTypesHandler, updateDeliveryTypeList])

    const upsertDeliveryType = useCallback(async () => {
        try {
            setErrorField(new ErrorDeliveryType())
            setErrorMessage('')
            setStatus(Status.PENDING)
            handlerUpsert.current = deliveryTypesHandler.upsert(deliveryType, deliveryType.deliveryTypeId)
            const res = await handlerUpsert.current.fetch()
            updateDeliveryTypeList(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                case InvalidEntityError:
                    setErrorField(error.errorField)
                    setErrorMessage(error.message || error)
                    break
                default:
                    setErrorMessage(error.message || error)
                    break
            }
        }
    }, [deliveryType, deliveryTypesHandler, updateDeliveryTypeList])

    const dialogTitle = useMemo(() => {
        if (isDeletion)
            return tKey('deleteLogisticConstraint', { name: tObj(parseJson(deliveryType.name)) })

        if (deliveryType.deliveryTypeId)
            return tKey('modalEditDeliveryTypeTitle')

        return tKey('modalDeliveryTypeTitle')
    }, [deliveryType.deliveryTypeId, deliveryType.name, isDeletion, tKey, tObj])

    // On component did unmount
    useEffect(() => () => {
        handlerUpsert.current?.cancel()
        handlerDelete.current?.cancel()
    }, [])

    return {
        tKey,
        tObj,
        errorMessage,
        setErrorMessage,
        errorField,
        status,
        isVisible,
        isDeletion,
        deliveryType,
        setDeliveryType,
        columns,
        addDeliveryType,
        deleteDeliveryType,
        upsertDeliveryType,
        dialogTitle,
        updateDeliveryTypeList,
    }
}
