import { useLayoutEffect, useState } from 'react'

/**
 * ContentResponsive
 * @param {object} props props
 * @param {object} props.children children
 * @param {number=} props.min min
 * @param {number=} props.max max
 * @returns {JSX.Element} JSX.Element
 */
export default function ContentResponsive({
    children, min = 0, max = Infinity,
}) {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth)

    useLayoutEffect(() => {
        const listen = () => {
            setInnerWidth(window.innerWidth)
        }
        window.addEventListener('resize', listen)
        return () => window.removeEventListener('resize', listen)
    })

    if (innerWidth > min && innerWidth <= max)
        return children

    return null
}
