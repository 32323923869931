import HomeArticle, { ErrorHomeArticle } from 'requests/objects/homeArticle'
import ApiHandler from 'requests/apiHandler'

/**
 * HomeArticlesHandler
 * @augments {ApiHandler<HomeArticle, ErrorHomeArticle>}
 */
export default class HomeArticlesHandler extends ApiHandler {
    constructor() {
        super({ type: HomeArticle, errorType: ErrorHomeArticle, key: 'home-articles' })
    }
}
