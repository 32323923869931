// eslint-disable-next-line max-classes-per-file
import Base, { ErrorBase } from 'requests/objects/_base'

/**
 *accessibility Object
 */
export default class Accessibility extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.accessibilityId accessibilityId
     * @param {string=} data.accessibilityTmsId accessibilityTmsId
     * @param {string=} data.name name
     * @param {number=} data.companyId companyId
     */
    constructor({
        accessibilityId = 0,
        accessibilityTmsId = '',
        name = '',
        companyId = 0,
        ...data
    } = {}) {
        super(data)
        this.accessibilityId = accessibilityId
        this.accessibilityTmsId = accessibilityTmsId
        this.name = name
        this.companyId = companyId
    }
}

/**
 * Accessibility Object used to bind error message
 */
export class ErrorAccessibility extends ErrorBase {
    constructor() {
        super()
        this.accessibilityId = ''
        this.companyId = ''
        this.name = ''
        this.accessibilityTmsId = ''
    }
}
