import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import PhoneField from 'components/inputs/phoneField'
import SupportContact, { ErrorSupportContact } from 'requests/objects/supportContact'
import SupportContactsHandler from 'requests/handlers/supportClientsHandler'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { ParamElement } from 'requests/objects/param'
import ESupportContact from 'types/supportContact/enums/supportContact'

/**
 * Contact modal
 * @param {object} props Props
 * @param {Partial<SupportContact>} props.defaultData defaultData
 * @param {boolean} props.isVisible isVisible
 * @param {function(SupportContact):void} props.onChange Callback to complete the contact form
 * @param {function():void} props.onError Callback called when an error occurs
 * @param {string} props.lang lang
 * @param {SupportContactsHandler} props.supportContactsHandler SupportContactsHandler
 * @param {ParamElement[]} [props.availableContactTypes] availableContactTypes
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function SupportContactModal({
    defaultData,
    isVisible,
    onChange,
    onError,
    lang,
    supportContactsHandler,
    availableContactTypes = [],
}) {
    const [contact, setContact] = useState(new SupportContact(defaultData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorSupportContact())
    const { tKey, tObj } = useTranslate({ lang })

    useEffect(() => {
        if (isVisible && defaultData.supportContactId !== contact.supportContactId) {
            setErrorMessage('')
            setErrorField(new ErrorSupportContact())
            setContact(new SupportContact(defaultData))
        }
    }, [setContact, contact, defaultData, isVisible])

    /** @type {React.MutableRefObject<RequestApi<SupportContact>>} */
    const upsertSupportContactHandler = useRef()

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorField(new ErrorSupportContact())
            setErrorMessage('')
            upsertSupportContactHandler.current = supportContactsHandler.upsert(contact, contact.supportContactId)
            const contactResult = await upsertSupportContactHandler.current.fetch()
            setContact(contactResult)
            onChange(contactResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            onError()
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorSupportContact>} */(error).errorField)
                    setErrorMessage(error.message)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [supportContactsHandler, contact, onChange, onError])

    // On component did unmount
    useEffect(() => () => {
        upsertSupportContactHandler.current?.cancel()
    }, [])

    return (
        <Dialog
            hidden={!isVisible}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: contact?.supportContactId ? tKey('modalEditContactTitle') : tKey('modalContactTitle'),
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="400px"
        >
            {errorMessage
                && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        truncated
                        onDismiss={() => setErrorMessage(null)}
                    >
                        {tObj(errorMessage)}
                    </MessageBar>
                )}
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    onSubmit()
                }}
            />
            {!contact.supportContactId && (
                <FilteredVirtualCombobox
                    label={tKey('contactType')}
                    placeholder={tKey('research')}
                    options={availableContactTypes}
                    selectedKey={contact.supportContactTypeId}
                    onChange={(ev, option) => setContact(prevState => ({ ...prevState, supportContactTypeId: option.key }))}
                    errorMessage={tObj(errorField.supportContactTypeId)}
                    required
                    disabled={status === Status.PENDING}
                />
            )}
            <TextField
                label={tKey('email')}
                placeholder={tKey('email')}
                value={contact.email}
                onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, email: newVal }))}
                required
                disabled={status === Status.PENDING}
                errorMessage={tObj(errorField.email)}
            />
            {
                contact.supportContactTypeId !== ESupportContact.Support && (
                    <>
                        <TextField
                            label={tKey('firstname')}
                            placeholder={tKey('firstname')}
                            value={contact.firstname}
                            onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, firstname: newVal }))}
                            required
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.firstname)}
                        />
                        <TextField
                            label={tKey('lastname')}
                            placeholder={tKey('lastname')}
                            value={contact.lastname}
                            onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, lastname: newVal }))}
                            required
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.lastname)}
                        />
                        <PhoneField
                            label={tKey('phoneNumber')}
                            placeholder={tKey('phoneNumber')}
                            value={contact.phone}
                            onChange={(ev, newVal) => setContact(prevState => ({ ...prevState, phone: newVal }))}
                            required
                            disabled={status === Status.PENDING}
                            errorMessage={tObj(errorField.phone)}
                            type="tel"
                        />
                    </>
                )
            }
            <br />
            <DialogFooter>
                <DefaultButton
                    onClick={() => onChange(null)}
                    text={tKey('cancel')}
                    disabled={status === Status.PENDING}
                />
                <PrimaryButton
                    onClick={e => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    text={tKey('save')}
                    disabled={
                        status === Status.PENDING
                    }
                />
            </DialogFooter>
        </Dialog>
    )
}
