import SupportContact, { ErrorSupportContact } from 'requests/objects/supportContact'
import ApiHandler from 'requests/apiHandler'

/**
 * SupportContactsHandler
 * @augments {ApiHandler<SupportContact, ErrorSupportContact>}
 */
export default class SupportContactsHandler extends ApiHandler {
    constructor() {
        super({ type: SupportContact, errorType: ErrorSupportContact, key: 'support-contacts' })
    }
}
