import React, {
    PureComponent,
} from 'react'
import {
    TextField, Text, PrimaryButton,
} from '@fluentui/react'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/pages/tools/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import DeliveryTimeParams, { ErrorDeliveryTimeParams } from 'requests/objects/DeliveryTimeParams'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import { Columns } from 'react-bulma-components'
import { isValidDate } from 'helpers/methods/common'
import Time from 'helpers/methods/time'
import parseJson from 'helpers/methods/parseJson'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class EstimateDeliveryTime extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {DeliveryTimeParams} item */
            item: new DeliveryTimeParams(),
            /** @type {ErrorDeliveryTimeParams} item */
            errorField: new ErrorDeliveryTimeParams(),
            /** @type {Date} deliveryDate */
            deliveryDate: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang, me } = this.props

        if (lang !== prevProps.lang)
            this.init()
        if (me.companyId !== prevProps.me.companyId)
            this.init()
    }

    componentWillUnmount() {
        this.estimateDeliveryTimeHandler?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('tools'), key: 'tools', href: '/tools' },
            { text: this.tKey('estimateDeliveryDate'), key: 'estimateDeliveryDate', isCurrentItem: true },
        ])
    }

    /**
     * Function called when valide form is submitted
     */
    getEstimateDeliveryTime() {
        const {
            setCommand, toolsHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.estimateDeliveryTimeHandler = toolsHandler.estimateDeliveryTime(item)
                const deliveryDate = await this.estimateDeliveryTimeHandler.fetch()

                this.setState({ deliveryDate, status: Status.RESOLVED })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorDeliveryTimeParams>} */(error).errorField })
                            break
                        default:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, item, errorField, deliveryDate,
        } = this.state
        const { param } = this.props

        return (
            <main className={styles.index}>
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.getEstimateDeliveryTime()
                    }}
                >
                    <Card
                        title={this.tKey('estimateDeliveryDateTitle')}
                        iconName="PageData"
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('agency')}
                                    options={param.operatingCenters}
                                    selectedKey={item.operatingCenterId}
                                    onChange={(_ev, option) => this.setState({
                                        item: { ...item, operatingCenterId: option.key },
                                    })}
                                    errorMessage={this.tObj(parseJson(errorField.operatingCenterId?.[0]))}
                                    required
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={tKey('pickupDate')}
                                    placeholder={tKey('pickupDate')}
                                    value={item.pickupDate ? Time(item.pickupDate).getLocaleDateString() : ''}
                                    onChange={(ev, newVal) => this.setState({
                                        item: { ...item, pickupDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' },
                                    })}
                                    type="date"
                                    errorMessage={this.tObj(parseJson(errorField.pickupDate?.[0]))}
                                    required
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('pickupCode')}
                                    value={item.pickupCode}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, pickupCode: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.pickupCode?.[0]))}
                                    required
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('deliveryCode')}
                                    value={item.deliveryCode}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, deliveryCode: newVal } })}
                                    errorMessage={this.tObj(parseJson(errorField.deliveryCode?.[0]))}
                                    required
                                />
                            </Columns.Column>
                        </Columns>
                        <Columns>
                            <Columns.Column size="three-quarters" />
                            <Columns.Column size="one-quarter">
                                <PrimaryButton
                                    onClick={() => this.submitInput.current.click()}
                                    text={this.tKey('estimate')}
                                    disabled={status === Status.PENDING}
                                />
                            </Columns.Column>
                        </Columns>
                    </Card>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                    <br />
                    {!!deliveryDate && (
                        <Card>
                            <Text>{this.tKey('deliveryDateResult', { date: Time(deliveryDate).getLocaleDateString() })}</Text>
                        </Card>
                    )}
                </form>
            </main>
        )
    }
}

EstimateDeliveryTime.prototype.tKey = tKey
EstimateDeliveryTime.prototype.tObj = tObj
