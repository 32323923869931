/* eslint-disable max-classes-per-file */
import Company from 'requests/objects/company'
import { ErrorBase } from 'requests/objects/_base'

/**
 * deliveryTime Object
 */
export default class DeliveryTime {
    /**
     * @param {object} data data
     * @param {number=} data.deliveryTimeId deliveryTimeId
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {number=} data.pickupZoneId pickupZoneId
     * @param {number=} data.deliveryZoneId deliveryZoneId
     * @param {number=} data.time time
     */
    constructor({
        deliveryTimeId = 0,
        companyId = 0,
        company,
        pickupZoneId,
        deliveryZoneId,
        time,
    } = {}) {
        this.deliveryTimeId = deliveryTimeId
        this.companyId = companyId
        this.company = company ? new Company(company) : undefined
        this.pickupZoneId = pickupZoneId
        this.deliveryZoneId = deliveryZoneId
        this.time = time
    }
}

/**
 * DeliveryTime Object used to bind error message
 */
export class ErrorDeliveryTime extends ErrorBase {
    constructor() {
        super()
        this.deliveryTimeId = ''
        this.companyId = ''
        this.pickupZoneId = ''
        this.deliveryTimeId = ''
        this.deliveryZoneId = ''
        this.time = ''
    }
}
