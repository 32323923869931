/**
 * @enum {number}
 */
const EProfile = {
    SuperAdmin: 1,
    Admin: 2,
    Commercial: 3,
    Operator: 4,
    CustomerAdmin: 5,
    CustomOrderTracking: 6,
    CustomOrderEntry: 7,
    AdValorem: 8,
}

export default EProfile
