/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Company from 'requests/objects/company'

/**
 * HomeActuality Object
 */
export default class HomeActuality extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.homeActualityId homeActualityId
     * @param {string=} data.title title
     * @param {number=} data.homeActualityStatusId homeActualityStatusId
     * @param {object=} data.homeActualityStatus homeActualityStatus
     * @param {string=} data.startDate startDate
     * @param {string=} data.endDate endDate
     * @param {object[]=} data.companies companies
     */
    constructor({
        homeActualityId = 0,
        title = '',
        homeActualityStatusId = 0,
        homeActualityStatus = {},
        startDate,
        endDate,
        companies = [],
        ...data
    } = {}) {
        super(data)
        this.homeActualityId = homeActualityId
        this.title = title
        this.homeActualityStatusId = homeActualityStatusId
        this.homeActualityStatus = homeActualityStatus
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
        this.companies = companies?.map(x => new Company(x)) ?? []
        this.companyIds = this.companies.map(x => x.companyId)
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorHomeActuality extends ErrorBase {
    constructor() {
        super()
        this.homeActualityId = ''
        this.title = ''
        this.homeActualityStatusId = ''
        this.homeActualityStatus = ''
        this.startDate = ''
        this.endDate = ''
        this.companyIds = ''
    }
}
