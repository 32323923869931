/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * AddressType Object
 */
export default class AddressType extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.addressTypeId addressTypeId
     * @param {string=} data.name name
     */
    constructor({
        addressTypeId = 0,
        name = '',
        ...data
    } = {}) {
        super(data)
        this.addressTypeId = addressTypeId
        this.name = name
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorAddressType extends ErrorBase {
    constructor() {
        super()
        this.addressTypeId = ''
        this.name = ''
    }
}
