import React, { useState, useCallback, useMemo } from 'react'
import {
    ShimmeredDetailsList, SelectionMode, DetailsListLayoutMode, ConstrainMode, Text,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import Stock from 'requests/objects/stock'
import Status from 'types/status'
import Card from 'components/containers/card'
import Time from 'helpers/methods/time'

/**
 * stockList
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {Stock[]} props.stocks stocks
 * @param {Status} props.status status
 * @param {(orderBy: string) => void} props.onOrderByChange onOrderByChange
 * @returns {JSX.Element} Element
 */
export default function StockList({
    lang, stocks, status, onOrderByChange,
}) {
    const { tKey } = useTranslate({ lang })
    const [columnSort, setColumnSort] = useState(['', 'desc'])

    const onColumnClickHandler = useCallback((ev, column) => {
        const sort = columnSort[0] !== column.key || column.isSortedDescending ? 'asc' : 'desc'
        const newColumnSort = [column.key, sort]
        setColumnSort(newColumnSort)
        onOrderByChange(newColumnSort.join('-'))
    }, [columnSort, onOrderByChange])

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'address',
            name: tKey('platform'),
            fieldName: 'address.label',
            minWidth: 75,
            maxWidth: 250,
            isResizable: true,
            isSorted: columnSort[0] === 'address',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: onColumnClickHandler,
        },
        {
            key: 'containerCode',
            name: tKey('containerCode'),
            fieldName: 'containerCode',
            minWidth: 75,
            maxWidth: 175,
            isResizable: true,
            isSorted: columnSort[0] === 'containerCode',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: onColumnClickHandler,
        },
        {
            key: 'article',
            name: tKey('article'),
            fieldName: 'name',
            minWidth: 75,
            maxWidth: 300,
            isResizable: true,
            isSorted: columnSort[0] === 'article',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: onColumnClickHandler,
        },
        {
            key: 'quantity',
            name: tKey('quantity'),
            fieldName: 'quantity',
            minWidth: 75,
            maxWidth: 125,
            isResizable: true,
            isSorted: columnSort[0] === 'quantity',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: onColumnClickHandler,

        },
        {
            key: 'updatedAt',
            name: tKey('date'),
            fieldName: 'updatedAt',
            minWidth: 75,
            maxWidth: 125,
            isResizable: true,
            onRender: data => (data.updatedAt ? Time(data.updatedAt).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
        },
    ]), [columnSort, onColumnClickHandler, tKey])

    return (
        <Card>
            <ShimmeredDetailsList
                items={stocks}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                onRenderRow={(props, defaultRender) => defaultRender(props)}
            />
            {!stocks?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}

        </Card>
    )
}
