import React, {
    PureComponent,
} from 'react'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import styles from 'styles/pages/tools/user-guide.module.scss'
import { tKey } from 'helpers/methods/translate'
import Loader from 'components/visuals/loader'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class UserGuide extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {string} pdfUrl */
            pdfUrl: null,
        }
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang } = this.props

        if (lang !== prevProps.lang)
            this.init()
    }

    componentWillUnmount() {
        this.filesHandlerGetFile?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('tools'), key: 'tools', href: '/tools' },
            { text: this.tKey('userGuide'), key: 'userguide', isCurrentItem: true },
        ])
    }

    /**
     * Get user Guide
     */
    getUserGuide() {
        try {
            this.setState({ status: Status.PENDING }, async () => {
                const { lang } = this.props
                const { filesHandler } = this.props
                this.filesHandlerGetFile = filesHandler.getUserGuide(lang)
                const { blob } = await this.filesHandlerGetFile.fetch()
                this.setState({
                    pdfUrl: window.URL.createObjectURL(blob),
                    status: Status.RESOLVED,
                })
            })
        } catch (error) {
            this.setState({ status: Status.REJECTED })
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.getUserGuide()
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, pdfUrl,
        } = this.state

        if (status === Status.PENDING)
            return <Loader />
        if (!pdfUrl)
            return (<div />)
        return (
            <div
                className={styles.container}
            >
                <iframe
                    title="cv"
                    // src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    src={`${pdfUrl}#zoom=80`}
                />
            </div>
        )
    }
}

UserGuide.prototype.tKey = tKey
