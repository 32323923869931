import React, {
    PureComponent,
} from 'react'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import { tKey, tObj } from 'helpers/methods/translate'
import SupportContacts from 'components/pages/supportContacts'
import SupportContact from 'requests/objects/supportContact'
import Loader from 'components/visuals/loader'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexContacts extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {SupportContact[]} Items found in API */
            items: [],
        }

        // this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang, me } = this.props

        if (lang !== prevProps.lang)
            this.init()

        if (me.companyId !== prevProps.me.companyId)
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.contactsHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('contacts'), key: 'contacts', isCurrentItem: true },
        ])
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.search()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { supportContactsHandler } = this.props
            try {
                this.contactsHandlerGetAll = supportContactsHandler.getAll()
                const supportContacts = await this.contactsHandlerGetAll.fetch()
                this.setState({
                    items: supportContacts,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            items, status,
        } = this.state
        const { lang, supportContactsHandler } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <SupportContacts
                supportContacts={items}
                lang={lang}
                supportContactsHandler={supportContactsHandler}
            />
        )
    }
}

IndexContacts.prototype.onColumnClick = onColumnClick
IndexContacts.prototype.flattenObj = flattenObj
IndexContacts.prototype.handleRenderColumn = handleRenderColumn
IndexContacts.prototype.tKey = tKey
IndexContacts.prototype.tObj = tObj
