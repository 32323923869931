import { Icon, Text } from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import React from 'react'
import styles from 'styles/components/pages/folder-data.module.scss'

/**
 * FolderData
 * @param {object} props props
 * @param {string} props.lang lang
 * @param {string} props.agency agency
 * @param {string} props.contractor contractor
 * @param {string} props.transport transport
 * @param {string} props.remittanceEntry remittanceEntry
 * @param {string} props.gedmouvRef gedmouvRef
 * @returns {JSX.Element} Returns
 */
export default function FolderData({
    lang,
    agency,
    contractor,
    transport,
    remittanceEntry,
    gedmouvRef,
}) {
    const { tKey } = useTranslate({ lang })

    return (
        <div className={styles['folder-data']}>
            <div className={styles['folder-data-agency']}>
                <Text
                    className={styles['folder-data-agency-title']}
                >
                    <Icon iconName="CityNext2" />
                    {' '}
                    {tKey('agency')}
                </Text>
                <Text
                    className={styles['folder-data-agency-content']}
                >
                    {agency}
                </Text>
            </div>
            <div className={styles['folder-data-references']}>
                <Text
                    className={styles['folder-data-references-title']}
                >
                    <Icon iconName="TextDocument" />
                    {' '}
                    {tKey('references')}
                </Text>
                <Text
                    className={styles['folder-data-references-content']}
                >
                    <ul>
                        <li>
                            <span>{tKey('contractor')}</span>
                            <span>{contractor}</span>
                        </li>
                        <li>
                            <span>{tKey('transport')}</span>
                            <span>{transport}</span>
                        </li>
                        {remittanceEntry && (
                            <li>
                                <span>{tKey('remittanceEntry')}</span>
                                <span>{remittanceEntry}</span>
                            </li>
                        )}
                        {gedmouvRef && (
                            <li>
                                <span>{tKey('gedmouv')}</span>
                                <span>{gedmouvRef}</span>
                            </li>
                        )}
                    </ul>
                </Text>
            </div>
        </div>
    )
}
