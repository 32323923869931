import {
    Label,
} from '@fluentui/react'
import classNames from 'classnames'
import useTranslate from 'helpers/hooks/useTranslate'
import React from 'react'
import OpenDays from 'requests/objects/openDays'
import styles from 'styles/components/pages/orders/[id]/client/open-week-days.module.scss'

/**
 * DetailInfo
 * @param {object} props props
 * @param {string=} [props.label] label
 * @param {object=} props.lang Lang
 * @param {OpenDays=} props.openDays openDays
 * @param {boolean=} [props.isReadOnly] isReadOnly
 * @param {((day: string, value: boolean) => void)=} [props.onChange] onChange
 * @returns {JSX.Element} Element
 */
export default function OpenWeekDays({
    label = '', lang, openDays, isReadOnly = true, onChange = undefined,
}) {
    const { tKey } = useTranslate({ lang })

    if (!openDays)
        return null

    return (
        <>
            {label && (<Label>{label}</Label>)}
            <div className={styles['week-days']}>
                {Object.keys(openDays).map(key => (
                    <button
                        type="button"
                        key={key}
                        disabled={isReadOnly}
                        className={classNames(
                            { [styles['is-open']]: openDays[key] === true },
                            { [styles['is-undefined']]: openDays[key] === undefined },
                        )}
                        onClick={() => {
                            if (!isReadOnly)
                                onChange(key, !openDays[key])
                        }}
                    >
                        {tKey(
                            // @ts-ignore
                            `${key}Short`,
                        )}
                    </button>
                ))}
            </div>
        </>
    )
}
