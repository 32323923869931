import React, {
    useState, useCallback, useRef,
} from 'react'
import {
    DefaultButton,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import Accessibility from 'requests/objects/accessibility'
import Card from 'components/containers/card'
import styles from 'styles/components/pages/companies/accessibilities/pivot-accessibilities.module.scss'
import AccessibilityList from 'components/pages/company/accessibility/accessibility-list'
import AccessibilityModal from 'components/pages/company/accessibility/accessibility-modal'

/**
 * Pivot accessibilities
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {number=} props.companyId companyId
 * @param {Accessibility[]=} props.accessibilities accessibilities
 * @param {(accessibility: Accessibility, isDeletion?: boolean) => void} props.onChange onChange
 * @param {function(import('redux/slices/common').PayloadModal):void} props.setModal Set Modal
 * @returns {JSX.Element} Element
 */
export default function PivotAccessibilities({
    lang,
    companiesHandler,
    companyId,
    setModal,
    accessibilities,
    onChange,
}) {
    const { tKey } = useTranslate({ lang })
    const [isAccessibilityModalVisible, setIsAccessibilityModalVisible] = useState(false)
    const [accessibility, setAccessibility] = useState(new Accessibility())

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Accessibility>>} */
    const deleteAccessibilityHandler = useRef(null)

    const editAccessibility = useCallback(
        /**
         * Edit accessibility
         * @param {Accessibility} accessibilityToEdit accessibilityToEdit
         */
        accessibilityToEdit => {
            setAccessibility(accessibilityToEdit)
            setIsAccessibilityModalVisible(true)
        }, [],
    )

    const deleteAccessibility = useCallback(
        /**
         * Delete accessibility
         * @param {Accessibility} accessibilityToDelete accessibilityToDelete
         */
        accessibilityToDelete => {
            setModal({
                show: true,
                title: tKey('removeAccessibility'),
                subTitle: tKey('sureRemoveAccessibility'),
                callback: async () => {
                    try {
                        deleteAccessibilityHandler.current = companiesHandler.removeAccessibilityById(companyId, accessibilityToDelete.accessibilityId)
                        const acc = await deleteAccessibilityHandler.current.fetch()
                        onChange(acc, true)
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }
                },
            })
        }, [companiesHandler, companyId, onChange, setModal, tKey],
    )

    const addAccessibility = useCallback(() => {
        setAccessibility(new Accessibility({ companyId }))
        setIsAccessibilityModalVisible(true)
    }, [companyId])

    return (
        <main className={styles['pivot-accessibilities']}>
            <Card
                iconName="MapPin"
                title={tKey('accessibilities')}
                headerComponent={(
                    <DefaultButton
                        onClick={e => {
                            e.preventDefault()
                            addAccessibility()
                        }}
                        text={tKey('add')}
                    />
                )}
                hasDividerMargin={false}
            >
                <AccessibilityList
                    editAccessibility={editAccessibility}
                    deleteAccessibility={deleteAccessibility}
                    items={accessibilities}
                    lang={lang}
                />
            </Card>
            <AccessibilityModal
                accessibilityData={accessibility}
                isVisible={isAccessibilityModalVisible}
                companiesHandler={companiesHandler}
                onChange={accessibilityReturned => {
                    if (accessibilityReturned)
                        onChange(accessibilityReturned)
                    setIsAccessibilityModalVisible(false)
                }}
                lang={lang}
            />
        </main>
    )
}
