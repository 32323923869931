import Company, { ErrorCompany } from 'requests/objects/company'
import ApiHandler from 'requests/apiHandler'
import Department from 'requests/objects/department'
import { setMessageBar } from 'redux/slices/common'
import store from 'redux/store'
import { MessageBarType } from '@fluentui/react'
import DeliveryTime from 'requests/objects/deliveryTime'
import Accessibility from 'requests/objects/accessibility'

/**
 * CompaniesHandler
 * @augments {ApiHandler<Company, ErrorCompany>}
 */
export default class CompaniesHandler extends ApiHandler {
    constructor() {
        super({ type: Company, errorType: ErrorCompany, key: 'companies' })
    }

    /**
     * Get file from entity, require the folder name assiociated
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<Company>} Request
     */
    setIsOperational(companyId) {
        const request = this.initFetchRequest({
            method: 'GET',
            url: [companyId, 'operational'],
        })

        return this.getRequestApi(
            () => request.fetchRequest.then(res => new Company(res.data.companies))
                .catch(err => { throw this.handleError(err) }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {number} companyId companyId
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<Department[]>} Request
     */
    getAllDepartments(companyId, params = {}) {
        const request = this.initFetchRequest({ url: [companyId, 'departments'], params })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data.departments?.map(x => new Department(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create
     * @param {number} companyId companyId
     * @param {Department} obj Obj
     * @returns {import('requests/apiHandler').RequestApi<Department>} Request
     */
    createDepartment(companyId, obj = new Department()) {
        const request = this.initFetchRequest({ url: [companyId, 'departments'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' }))
                    return new Department(res.data.departments)
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update
     * @param {number} companyId companyId
     * @param {Department} obj Obj
     * @param {number} id Id
     * @returns {import('requests/apiHandler').RequestApi<Department>} Request
     */
    updateDepartmentById(companyId, obj = new Department(), id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'departments', id], method: 'PUT', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementhasBeenUpdated' }))
                    return new Department(res.data.departments)
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Delete
     * @param {number} companyId companyId
     * @param {number} id id
     * @returns {import('requests/apiHandler').RequestApi<Department>} Request
     */
    removeDepartmentById(companyId, id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'departments', id], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'modalDeleteMessage' }))
                    return new Department(res.data.departments)
                })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {number} companyId companyId
     * @param {Department} obj Request
     * @param {number=} id id
     * @returns {import('requests/apiHandler').RequestApi<Department>} Request
     */
    // eslint-disable-next-line new-cap
    upsertDepartment(companyId, obj = new Department(), id = undefined) {
        if (id)
            return this.updateDepartmentById(companyId, obj, id)

        return this.createDepartment(companyId, obj)
    }

    /**
     * Get all
     * @param {number} companyId companyId
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime[]>} Request
     */
    getAllDeliveryTimes(companyId, params = {}) {
        const request = this.initFetchRequest({ url: [companyId, 'delivery-times'], params })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data['delivery-times']?.map(x => new DeliveryTime(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create
     * @param {number} companyId companyId
     * @param {DeliveryTime} obj Obj
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime>} Request
     */
    createDeliveryTime(companyId, obj = new DeliveryTime()) {
        const request = this.initFetchRequest({ url: [companyId, 'delivery-times'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' }))
                    return new DeliveryTime(res.data['delivery-times'])
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update
     * @param {number} companyId companyId
     * @param {DeliveryTime} obj Obj
     * @param {number} id Id
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime>} Request
     */
    updateDeliveryTimeById(companyId, obj = new DeliveryTime(), id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'delivery-times', id], method: 'PUT', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementhasBeenUpdated' }))
                    return new DeliveryTime(res.data['delivery-times'])
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Delete
     * @param {number} companyId companyId
     * @param {number} id id
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime>} Request
     */
    removeDeliveryTimeById(companyId, id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'delivery-times', id], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'modalDeleteMessage' }))
                    return new DeliveryTime(res.data['delivery-times'])
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {number} companyId companyId
     * @param {DeliveryTime} obj Request
     * @param {number=} id id
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime>} Request
     */
    // eslint-disable-next-line new-cap
    upsertDeliveryTime(companyId, obj = new DeliveryTime(), id = undefined) {
        if (id)
            return this.updateDeliveryTimeById(companyId, obj, id)

        return this.createDeliveryTime(companyId, obj)
    }

    /**
     * Import
     * @param {globalThis.File} file file
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<DeliveryTime[]>} Returns
     */
    importDeliveryTimes(file, companyId) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [companyId, 'delivery-times', 'import'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'dataIntegrationCompleted' }))
                    return res.data['delivery-times']?.map(a => new DeliveryTime(a)) ?? []
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Download template
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    downloadDeliveryTimeTemplate(companyId) {
        const request = this.initFetchRequest({
            method: 'GET',
            url: [companyId, 'delivery-times', 'import', 'template'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Import importDepartments
     * @param {globalThis.File} file file
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<Department[]>} Returns
     */
    importDepartments(file, companyId) {
        const formData = new FormData()
        formData.append('file', file)

        const request = this.initFetchRequest({
            url: [companyId, 'departments', 'import'],
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data;',
            },
        })
        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'dataIntegrationCompleted' }))
                    return res.data.departments?.map(a => new Department(a)) ?? []
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Download template
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    downloadDepartmentsTemplate(companyId) {
        const request = this.initFetchRequest({
            method: 'GET',
            url: [companyId, 'departments', 'import', 'template'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * downloadDepartmentsExample
     * @param {number} companyId companyId
     * @returns {import('requests/apiHandler').RequestApi<Blob>} Request
     */
    downloadDepartmentsExample(companyId) {
        const request = this.initFetchRequest({
            method: 'GET',
            url: [companyId, 'departments', 'import', 'example'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {number} companyId companyId
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {import('requests/apiHandler').RequestApi<Accessibility[]>} Request
     */
    getAllAccessibilities(companyId, params = {}) {
        const request = this.initFetchRequest({ url: [companyId, 'accessibilities'], params })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data.accessibilities?.map(x => new Accessibility(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Create
     * @param {number} companyId companyId
     * @param {Accessibility} obj Obj
     * @returns {import('requests/apiHandler').RequestApi<Accessibility>} Request
     */
    createAccessibility(companyId, obj = new Accessibility()) {
        const request = this.initFetchRequest({ url: [companyId, 'accessibilities'], method: 'POST', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' }))
                    return new Accessibility(res.data.accessibilities)
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Update
     * @param {number} companyId companyId
     * @param {Accessibility} obj Obj
     * @param {number} id Id
     * @returns {import('requests/apiHandler').RequestApi<Accessibility>} Request
     */
    updateAccessibilityById(companyId, obj = new Accessibility(), id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'accessibilities', id], method: 'PUT', data: obj })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementhasBeenUpdated' }))
                    return new Accessibility(res.data.accessibilities)
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Delete
     * @param {number} companyId companyId
     * @param {number} id id
     * @returns {import('requests/apiHandler').RequestApi<Accessibility>} Request
     */
    removeAccessibilityById(companyId, id = undefined) {
        const request = this.initFetchRequest({ url: [companyId, 'accessibilities', id], method: 'DELETE' })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => {
                    store.dispatch(setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'modalDeleteMessage' }))
                    return new Accessibility(res.data.accessibilities)
                })
                .catch(err => {
                    throw this.handleError(err, false)
                }),
            request.cancelToken,
        )
    }

    /**
     * Upsert
     * @param {number} companyId companyId
     * @param {Accessibility} obj Request
     * @param {number=} id id
     * @returns {import('requests/apiHandler').RequestApi<Accessibility>} Request
     */
    // eslint-disable-next-line new-cap
    upsertAccessibility(companyId, obj = new Accessibility(), id = undefined) {
        if (id)
            return this.updateAccessibilityById(companyId, obj, id)

        return this.createAccessibility(companyId, obj)
    }
}
