import React from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    List, Text,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import Order from 'requests/objects/order'
import Time from 'helpers/methods/time'
import Card from 'components/containers/card'
import parseJson from 'helpers/methods/parseJson'
import Loader from 'components/visuals/loader'
import styles from 'styles/components/pages/orders/[id]/client/order-card-list.module.scss'
import classNames from 'classnames'

/**
 * OrderList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Order[]} props.orders orders
 * @param {Status} props.status status
 * @returns {JSX.Element} Element
 */
export default function OrderCardList({
    lang, orders, status,
}) {
    const { tKey } = useTranslate({ lang })

    if (status === Status.PENDING)
        return <Loader />

    return (
        <div className={styles['order-card-list']}>
            <Card>
                <List
                    items={orders}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderCell={(item, j) => (
                        <>
                            <OrderCardListRow
                                key={j}
                                item={item}
                                lang={lang}
                            />
                            {j !== orders.length - 1 && <hr />}
                        </>
                    )}
                />
                {!orders.length && status !== Status.PENDING
                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}
            </Card>
        </div>
    )
}

/**
 * OrderCardListRow
 * @param {object} props props
 * @param {Order} props.item item
 * @param {object} props.lang Lang
 * @returns {JSX.Element} JSX.Element
 */
export function OrderCardListRow({ item, lang }) {
    const { tKey, tObj } = useTranslate({ lang })

    return (
        <Link
            className={classNames(
                styles['order-card-list-row'],
            )}
            to={`/orders/${item?.orderId}`}
        >
            <div className={styles['order-card-list-row-column']}>
                <Text>
                    {tKey('remittance')}
                    {' '}
                    {item.orderId}
                    {' ● '}
                    {tKey('delivery')}
                </Text>
                <Text>{item['pickupAddressData.address.label'] || ''}</Text>
                <Text>
                    <span>
                        {item['pickupAddressData.address.street'] || ''}
                        {' '}
                        {item['pickupAddressData.address.zipCode'] || ''}
                        {' '}
                        {item['pickupAddressData.address.city'] || ''}
                        {' '}
                        {item['pickupAddressData.address.country.code'] || ''}
                    </span>
                </Text>
            </div>
            <div className={styles['order-card-list-row-column']}>
                <Text>
                    {item.deliveryDatasLength}
                    {' '}
                    {tKey('destinations')}
                </Text>
                <Text
                    className={classNames(
                        { [styles['is-yellow']]: item['orderStatus.orderId'] === 3 },
                        { [styles['is-green']]: item['orderStatus.orderStatusId'] === 1 },
                    )}
                >
                    {tObj(parseJson(item['orderStatus.name']))}
                </Text>
                <Text>
                    {tKey('createdThe')}
                    {' '}
                    {Time(item.createdAt).getCleanDate({
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    })}
                </Text>
            </div>
        </Link>
    )
}
