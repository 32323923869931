import React, {
    useMemo, useState,
} from 'react'
import styles from 'styles/components/pages/transports/transport-list.module.scss'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, DirectionalHint, SelectionMode, ShimmeredDetailsList, Text, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import Time from 'helpers/methods/time'
import Card from 'components/containers/card'
import Transport from 'requests/objects/transport'
import parseJson from 'helpers/methods/parseJson'
import { wfTObj } from 'helpers/methods/getStepTypes'
import ConfirmedDateLogo from 'assets/img/confirmed-date.png'
import ScheduledDateLogo from 'assets/img/scheduled-date.png'
import getOrderTypeLogo from 'helpers/methods/getOrderTypeLogo'
import { ParamElement } from 'requests/objects/param'

/**
 * TransportList
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {Transport[]} props.transports transports
 * @param {Status} props.status status
 * @param {(orderBy: string) => void} props.onOrderByChange onOrderByChange
 * @param {ParamElement[]} props.workflowTransportSteps workflowTransportSteps
 * @returns {JSX.Element} Element
 */
export default function TransportList({
    lang, transports, status, onOrderByChange, workflowTransportSteps = [],
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const [columnSort, setColumnSort] = useState(['', 'desc'])
    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'transportTmsId',
            name: tKey('transport'),
            fieldName: 'transportTmsId',
            minWidth: 90,
            maxWidth: 180,
            isResizable: true,
            isSorted: columnSort[0] === 'transportTmsId',
            isSortedDescending: columnSort[1] === 'desc',
            className: styles['transport-list-row-item'],
            onColumnClick: (ev, column) => {
                const sort = columnSort[0] !== 'transportTmsId' || column.isSortedDescending ? 'asc' : 'desc'
                const newColumnSort = [column.key, sort]
                setColumnSort(newColumnSort)
                onOrderByChange(newColumnSort.join('-'))
            },
            /**
             * @param {Transport} transport transport
             */
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => (
                    <TooltipHost
                        content={tObj(parseJson(transport['orderType.name']))}
                        directionalHint={DirectionalHint.leftBottomEdge}
                        delay={TooltipDelay.zero}
                    >
                        <div className={styles['transport-list-row-item-logo']}>
                            <img
                                src={getOrderTypeLogo(transport.orderTypeId)}
                                alt="Logo type de livraison"
                            />
                            <span>{transport.transportTmsId}</span>
                        </div>
                    </TooltipHost>
                ),
        },
        {
            key: 'contractor',
            name: tKey('refDo'),
            fieldName: 'contractor',
            minWidth: 50,
            maxWidth: 90,
            isResizable: true,
            isSorted: columnSort[0] === 'contractor',
            isSortedDescending: columnSort[1] === 'desc',
            className: styles['transport-list-row-item'],
            onColumnClick: (ev, column) => {
                const sort = columnSort[0] !== 'contractor' || column.isSortedDescending ? 'asc' : 'desc'
                const newColumnSort = [column.key, sort]
                setColumnSort(newColumnSort)
                onOrderByChange(newColumnSort.join('-'))
            },
        },
        {
            key: 'createdAt',
            name: tKey('createdThe'),
            minWidth: 50,
            maxWidth: 75,
            isResizable: true,
            className: styles['transport-list-row-item'],
            isSorted: columnSort[0] === 'createdAt',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: (ev, column) => {
                const sort = columnSort[0] !== 'createdAt' || column.isSortedDescending ? 'asc' : 'desc'
                const newColumnSort = [column.key, sort]
                setColumnSort(newColumnSort)
                onOrderByChange(newColumnSort.join('-'))
            },
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => (
                    transport.createdAt
                        ? Time(transport.createdAt).getCleanDate({
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                        })
                        : ''
                ),

        },
        {
            key: 'pickupDate',
            name: tKey('pickupDate'),
            minWidth: 80,
            maxWidth: 150,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => {
                    const date = transport.pickupDate || transport.pickupScheduledDate
                    if (!date)
                        return ''
                    return (
                        <TooltipHost
                            content={tKey(transport.pickupDate ? 'picked' : 'estimated')}
                            directionalHint={DirectionalHint.leftBottomEdge}
                            delay={TooltipDelay.zero}
                        >
                            <div className={styles['transport-list-row-item-date']}>
                                {transport.pickupDate ? (
                                    <img
                                        src={ConfirmedDateLogo}
                                        alt="Date de prise en charge"
                                    />

                                ) : (
                                    <img
                                        src={ScheduledDateLogo}
                                        alt="Date de prise en charge prévisionnelle"
                                    />
                                )}
                                <span>
                                    {Time(date).getCleanDate({
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                    })}
                                </span>
                            </div>
                        </TooltipHost>
                    )
                },
            isSorted: columnSort[0] === 'pickupDate',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: (ev, column) => {
                const sort = columnSort[0] !== 'pickupDate' || column.isSortedDescending ? 'asc' : 'desc'
                const newColumnSort = [column.key, sort]
                setColumnSort(newColumnSort)
                onOrderByChange(newColumnSort.join('-'))
            },

        },
        {
            key: 'pickup',
            name: tKey('pickup'),
            minWidth: 100,
            maxWidth: 220,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => (
                    <>
                        <b>
                            {transport['pickupAddress.label'] || ''}
                        </b>
                        <span>
                            {transport['pickupAddress.street'] || ''}
                        </span>
                        <span>
                            {transport['pickupAddress.zipCode'] || ''}
                            {' '}
                            {transport['pickupAddress.city'] || ''}
                            {' '}
                            {transport['pickupAddress.country.code'] || ''}
                        </span>
                    </>
                ),
        },
        {
            key: 'deliveryDate',
            name: tKey('deliveryDate'),
            minWidth: 80,
            maxWidth: 150,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => {
                    const date = transport.deliveryDate || transport.deliveryScheduledDate
                    if (!date)
                        return ''
                    return (
                        <TooltipHost
                            content={tKey(transport.deliveryDate ? 'delivered' : 'planned')}
                            directionalHint={DirectionalHint.leftBottomEdge}
                            delay={TooltipDelay.zero}
                        >
                            <div className={styles['transport-list-row-item-date']}>
                                {transport.deliveryDate ? (
                                    <img
                                        src={ConfirmedDateLogo}
                                        alt="Date de livraison"
                                    />

                                ) : (
                                    <img
                                        src={ScheduledDateLogo}
                                        alt="Date de livraison prévisionnelle"
                                    />
                                )}
                                <span>
                                    {Time(date).getCleanDate({
                                        month: '2-digit',
                                        day: '2-digit',
                                        year: 'numeric',
                                    })}
                                </span>
                            </div>
                        </TooltipHost>
                    )
                },
            isSorted: columnSort[0] === 'deliveryDate',
            isSortedDescending: columnSort[1] === 'desc',
            onColumnClick: (ev, column) => {
                const sort = columnSort[0] !== 'deliveryDate' || column.isSortedDescending ? 'asc' : 'desc'
                const newColumnSort = [column.key, sort]
                setColumnSort(newColumnSort)
                onOrderByChange(newColumnSort.join('-'))
            },
        },
        {
            key: 'delivery',
            name: tKey('delivery'),
            minWidth: 100,
            maxWidth: 220,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => (
                    <>
                        <b>
                            {transport['deliveryAddress.label'] || ''}
                        </b>
                        <span>
                            {transport['deliveryAddress.street'] || ''}
                        </span>
                        <span>
                            {transport['deliveryAddress.zipCode'] || ''}
                            {' '}
                            {transport['deliveryAddress.city'] || ''}
                            {' '}
                            {transport['deliveryAddress.country.code'] || ''}
                        </span>
                    </>
                ),
        },
        {
            key: 'status',
            name: tKey('status'),
            fieldName: 'status.name',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender: transport => tObj(parseJson(workflowTransportSteps.find(x => x.key === transport.currentWorkflowStepId)?.text)),
        },
        {
            key: 'comment',
            name: tKey('comments'),
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            className: styles['transport-list-row-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                transport => (
                    <>
                        <b>
                            {wfTObj(lang, parseJson(transport['transportStatus.0.tmsTransportStatus.label'])) || ''}
                        </b>
                        <span>
                            {
                                wfTObj(
                                    lang,
                                    parseJson(transport['transportStatus.0.tmsTransportStatus.subLabel']),
                                    parseJson(transport['transportStatus.0.args']),
                                ) || ''
                            }
                        </span>
                    </>
                ),
        },
    ]), [tKey, columnSort, onOrderByChange, tObj, workflowTransportSteps, lang])

    return (
        <div className={styles['transport-list']}>
            <Card>
                <ShimmeredDetailsList
                    items={transports}
                    columns={columns}
                    detailsListStyles={{
                        root: {
                            selectors: {
                                '& [role=grid]': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    height: transports.length ? '52vh' : 'auto',
                                },
                            },
                        },
                        headerWrapper: {
                            flex: '0 0 auto',
                        },
                        contentWrapper: {
                            flex: '1 1 auto',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        },
                    }}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    enableShimmer={status === Status.PENDING}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderRow={(props, defaultRender) => (
                        <Link to={`/transports/${props.item?.transportId}`}>
                            <div className={styles['transport-list-row']}>
                                {defaultRender(props)}
                            </div>
                        </Link>
                    )}
                />
                {!transports.length && status !== Status.PENDING
                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}
            </Card>
        </div>
    )
}
