import React, { PureComponent } from 'react'
import history from 'helpers/history'
import {
    getUpdatedList, handleRenderColumn, isValidDate, onColumnClick,
} from 'helpers/methods/common'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import time from 'helpers/methods/time'
import styles from 'styles/pages/transports/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import {
    Text, Icon, Label, DefaultButton, DirectionalHint, TooltipDelay, TooltipHost, TextField,
} from '@fluentui/react'
import { Columns } from 'react-bulma-components'
import parseJson from 'helpers/methods/parseJson'
import Card from 'components/containers/card'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import TransportList from 'components/pages/transports/transportList'
import Transport from 'requests/objects/transport'
import ContentResponsive from 'components/containers/contentResponsive'
import TransportListCard from 'components/pages/transports/transportListCard'
import Pagination from 'components/pages/Pagination'
import HandleBlob from 'helpers/methods/blob'

/** @debug {import('app').AppProps} */
/**
 * @typedef {object} SearchParamsType
 * @property {string} references references
 * @property {string} articleReferences articleReferences
 * @property {Date} pickupDate pickupDate
 * @property {string} pickupAddress pickupAddress
 * @property {number[]} workflowTransportStepIds workflowTransportStepIds
 * @property {Date} creationDate creationDate
 * @property {Date} deliveryDate deliveryDate
 * @property {string} deliveryAddress pickupAddress
 */
/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexTransport extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Transport[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                references: '',
                articleReferences: '',
                pickupDate: null,
                pickupAddress: '',
                workflowTransportStepIds: [],
                creationDate: null,
                deliveryDate: null,
                deliveryAddress: '',
            },
            /** @type {import('components/pages/Pagination').PagingationType} pagination data */
            pagination: {
                currentPage: 1,
                totalPages: 1,
                totalRecords: 0,
            },
            /** @type {boolean} Is filter card reduced ? */
            isFilterCardReduced: true,
        }

        this.submitInput = React.createRef()
        this.toolTipRef = React.createRef()

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null

        this.export = this.export.bind(this)
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()
        this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang, me, match } = this.props
        const { searchParams, pagination: { currentPage } } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
        if (lang !== prevProps.lang)
            this.init()

        if (me.companyId !== prevProps.me.companyId)
            this.init()

        if (match.params?.key !== prevProps.match.params?.key)
            this.setState(truePevState => ({
                searchParams: {
                    ...truePevState.searchParams,
                    workflowTransportStepIds: [],
                },
            }), () => {
                this.init()
                this.search()
            })

        if (prevState?.pagination?.currentPage && prevState.pagination.currentPage !== currentPage)
            this.search()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.transportsHandlerGetAll?.cancel()
        this.transportsHandlerExportAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('orderEntry'), key: 'order' },
            { text: this.tKey('allTransports'), key: 'all-transport', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand([])
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            const pickupDateTimestamp = Date.parse(queryParams.get('pickupDate'))
            const creationDateTimestamp = Date.parse(queryParams.get('creationDate'))
            const deliveryDateTimestamp = Date.parse(queryParams.get('deliveryDate'))

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    references: queryParams.get('references') ? queryParams.get('references') : '',
                    articleReferences: queryParams.get('articleReferences') ? queryParams.get('articleReferences') : '',
                    pickupAddress: queryParams.get('pickupAddress') ? queryParams.get('pickupAddress') : '',
                    deliveryAddress: queryParams.get('deliveryAddress') ? queryParams.get('references') : '',
                    pickupDate: !Number.isNaN(pickupDateTimestamp) ? new Date(pickupDateTimestamp) : null,
                    workflowTransportStepIds: queryParams.getAll('workflowTransportStepIds') ? queryParams.getAll('workflowTransportStepIds').map(x => +x) : [],
                    creationDate: !Number.isNaN(creationDateTimestamp) ? new Date(creationDateTimestamp) : null,
                    deliveryDate: !Number.isNaN(deliveryDateTimestamp) ? new Date(deliveryDateTimestamp) : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * Search elements
     * @param {string} orderBy orderBy
     */
    search(orderBy = undefined) {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { transportsHandler, match } = this.props
            const { searchParams, pagination: { currentPage } } = this.state
            try {
                this.transportsHandlerGetAll?.cancel()
                this.transportsHandlerGetAll = transportsHandler.getAllFromPage({
                    ...searchParams,
                    page: match.params?.key,
                    currentPage,
                    orderBy,
                })
                const res = await this.transportsHandlerGetAll.fetch()
                this.setState({
                    items: res.items.map(x => this.flattenObj(x)),
                    pagination: {
                        currentPage: res.items.length === 0 ? 1 : currentPage,
                        totalPages: res.totalPages,
                        totalRecords: res.totalRecords,
                    },
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Export elements
     */
    export() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { transportsHandler, match } = this.props
            const { searchParams } = this.state
            try {
                this.transportsHandlerExportAll = transportsHandler.exportAll({
                    ...searchParams,
                    page: match.params?.key,
                })
                const { fileName, blob } = await this.transportsHandlerExportAll.fetch()
                HandleBlob.download(blob, fileName)
                this.setState({ status: Status.RESOLVED })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError:
                    case NotImplementedError:
                    default:
                        this.setState({ status: Status.REJECTED })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, isFilterCardReduced, items, searchParams, pagination,
        } = this.state
        const { lang, param, match } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                    headerComponent={(
                        <button
                            type="button"
                            onClick={() => this.setState({ isFilterCardReduced: !isFilterCardReduced })}
                            className={styles['reduce-button-container']}
                        >
                            <div className={styles['reduce-button']}>
                                <Text>
                                    {isFilterCardReduced ? this.tKey('reduce') : this.tKey('extend')}
                                </Text>
                                <Text>
                                    <Icon iconName={isFilterCardReduced ? 'ChevronUp' : 'ChevronDown'} />
                                </Text>
                            </div>

                        </button>
                    )}
                >
                    {isFilterCardReduced && (
                        <form
                            onSubmit={ev => {
                                ev.preventDefault()
                                this.search()
                            }}
                        >
                            <Columns>
                                <Columns.Column size="three-quarters">
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('searchReferences')}
                                                placeholder={this.tKey('search')}
                                                disabled={status === Status.PENDING}
                                                value={searchParams.references}
                                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, references: newVal } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('articleReferences')}
                                                placeholder={this.tKey('search')}
                                                disabled={status === Status.PENDING}
                                                value={searchParams.articleReferences}
                                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, articleReferences: newVal } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('pickupAddress')}
                                                placeholder={this.tKey('pickupAddress')}
                                                value={searchParams.pickupAddress}
                                                disabled={status === Status.PENDING}
                                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, pickupAddress: newVal } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('pickupDate')}
                                                placeholder={this.tKey('pickupDate')}
                                                value={searchParams.pickupDate ? this.time(searchParams.pickupDate).getLocaleDateString() : ''}
                                                onChange={(ev, newVal) => {
                                                    ev.preventDefault()
                                                    const date = new Date(newVal)
                                                    this.setState({
                                                        searchParams: {
                                                            ...searchParams,
                                                            pickupDate: this.isValidDate(date) ? date : null,
                                                        },
                                                    })
                                                }}
                                                type="date"
                                            />
                                        </Columns.Column>

                                    </Columns>
                                </Columns.Column>
                                <Columns.Column size="three-quarters">
                                    <Columns>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('creationDate')}
                                                placeholder={this.tKey('creationDate')}
                                                value={searchParams.creationDate ? this.time(searchParams.creationDate).getLocaleDateString() : ''}
                                                onChange={(ev, newVal) => {
                                                    ev.preventDefault()
                                                    const date = new Date(newVal)
                                                    this.setState({
                                                        searchParams: {
                                                            ...searchParams,
                                                            creationDate: this.isValidDate(date) ? date : null,
                                                        },
                                                    })
                                                }}
                                                type="date"
                                            />
                                        </Columns.Column>
                                        {['all', 'progress'].includes(match.params?.key) && (
                                            <Columns.Column size="one-quarter">
                                                <FilteredVirtualCombobox
                                                    label={this.tKey('status')}
                                                    options={param.workflowTransportSteps
                                                        .filter(x => match.params?.key === 'all' || ![5, 7].includes(+x.key))
                                                        .map(({ key, text }) => ({
                                                            key,
                                                            text: this.tObj(this.parseJson(text)),
                                                        }))}
                                                    selectedKey={searchParams.workflowTransportStepIds}
                                                    disabled={status === Status.PENDING}
                                                    onChange={(_ev, option) => this.setState({
                                                        searchParams: {
                                                            ...searchParams,
                                                            workflowTransportStepIds: this.getUpdatedList(searchParams.workflowTransportStepIds, option),
                                                        },
                                                    })}
                                                    multiSelect
                                                />
                                            </Columns.Column>
                                        )}
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('deliveryAddress')}
                                                placeholder={this.tKey('deliveryAddress')}
                                                value={searchParams.deliveryAddress}
                                                disabled={status === Status.PENDING}
                                                onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, deliveryAddress: newVal } })}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <TextField
                                                label={this.tKey('deliveryDate')}
                                                placeholder={this.tKey('deliveryDate')}
                                                value={searchParams.deliveryDate ? this.time(searchParams.deliveryDate).getLocaleDateString() : ''}
                                                onChange={(ev, newVal) => {
                                                    ev.preventDefault()
                                                    const date = new Date(newVal)
                                                    this.setState({
                                                        searchParams: {
                                                            ...searchParams,
                                                            deliveryDate: this.isValidDate(date) ? date : null,
                                                        },
                                                    })
                                                }}
                                                type="date"
                                            />
                                        </Columns.Column>
                                    </Columns>
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <div
                                        className={styles['action-filter-buttons']}
                                    >
                                        <div>
                                            <Label className="is-hidden-mobile">&nbsp;</Label>
                                            <DefaultButton
                                                text={this.tKey('research')}
                                                primary
                                                split
                                                disabled={status === Status.PENDING}
                                                onClick={() => this.submitInput.current.click()}
                                                iconProps={{ iconName: 'Search' }}
                                                menuProps={{
                                                    items: [
                                                        {
                                                            key: 'Search',
                                                            text: this.tKey('research'),
                                                            iconProps: { iconName: 'Search' },
                                                            onClick: () => this.submitInput.current.click(),
                                                        },
                                                        {
                                                            key: 'Export',
                                                            text: this.tKey('export'),
                                                            iconProps: { iconName: 'Export' },
                                                            onClick: () => this.export(),
                                                        },
                                                    ],
                                                }}
                                            />
                                        </div>
                                        <div className={styles.separator} />
                                        <div>
                                            <Label className="is-hidden-mobile">&nbsp;</Label>
                                            <TooltipHost
                                                content={this.tKey('resetFilters')}
                                                directionalHint={DirectionalHint.topAutoEdge}
                                                delay={TooltipDelay.medium}
                                                componentRef={this.toolTipRef}
                                            >
                                                <DefaultButton
                                                    disabled={status === Status.PENDING}
                                                    styles={{
                                                        root: {
                                                            minWidth: '40px',
                                                            maxWidth: '40px',
                                                        },
                                                    }}
                                                    iconProps={{ iconName: 'ClearFilter' }}
                                                    onClick={() => this.setState({
                                                        /** @type {SearchParamsType} */
                                                        searchParams: {
                                                            references: '',
                                                            articleReferences: '',
                                                            pickupAddress: '',
                                                            deliveryAddress: '',
                                                            pickupDate: null,
                                                            workflowTransportStepIds: [],
                                                            creationDate: null,
                                                            deliveryDate: null,
                                                        },
                                                    })}
                                                />
                                            </TooltipHost>
                                        </div>
                                    </div>
                                </Columns.Column>
                            </Columns>
                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <button
                                type="submit"
                                style={{ display: 'none' }}
                                ref={this.submitInput}
                                tabIndex={-1}
                            />
                        </form>
                    )}
                </Card>
                <br />
                <ContentResponsive min={768}>
                    <TransportList
                        lang={lang}
                        transports={items}
                        status={status}
                        onOrderByChange={orderBy => this.search(orderBy)}
                        workflowTransportSteps={param.workflowTransportSteps}
                    />
                </ContentResponsive>
                <ContentResponsive max={768}>
                    <TransportListCard
                        lang={lang}
                        transports={items}
                        status={status}
                    />
                </ContentResponsive>
                <Pagination
                    data={pagination}
                    lang={lang}
                    onPageChange={page => this.setState({ pagination: { ...pagination, currentPage: page } })}
                    itemName="transport"
                />
            </main>
        )
    }
}

IndexTransport.prototype.onColumnClick = onColumnClick
IndexTransport.prototype.flattenObj = flattenObj
IndexTransport.prototype.handleRenderColumn = handleRenderColumn
IndexTransport.prototype.time = time
IndexTransport.prototype.isValidDate = isValidDate
IndexTransport.prototype.parseJson = parseJson
IndexTransport.prototype.getUpdatedList = getUpdatedList
IndexTransport.prototype.tKey = tKey
IndexTransport.prototype.tObj = tObj
