import Base from 'requests/objects/_base'
import Store from 'requests/objects/order/store'

/**
 * ComfourData
 */
export default class ComfourData extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.deliveryInstructionsDataId deliveryInstructionsDataId
     * @param {number=} data.storeId storeId
     * @param {object=} data.store store
     * @param {string=} data.allegroNumber allegroNumber
     * @param {string=} data.comfourNumber comfourNumber
     * @param {string=} data.supplierNumber supplierNumber
     * @param {string=} data.clientNumber clientNumber
     */
    constructor({
        deliveryInstructionsDataId,
        storeId = 0,
        store,
        allegroNumber = '',
        comfourNumber = '',
        supplierNumber = '',
        clientNumber = '',
        ...data
    }) {
        super(data)
        this.deliveryInstructionsDataId = deliveryInstructionsDataId
        this.storeId = storeId
        this.store = store ? new Store(store) : undefined
        this.allegroNumber = allegroNumber
        this.comfourNumber = comfourNumber
        this.supplierNumber = supplierNumber
        this.clientNumber = clientNumber
    }
}
