import React, {
    PureComponent,
} from 'react'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Card from 'components/containers/card'
import styles from 'styles/pages/tools/index.module.scss'
import { tKey, tObj } from 'helpers/methods/translate'
import { Link } from 'react-router-dom'

/**
 * The tool properties.
 *
 * @typedef {object} Tool
 * @property {string} name - name
 * @property {string} description - description
 * @property {string} pathName - pathName
 */

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexTools extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Tool[]} Items */
            items: [
                // {
                //     name: this.tKey('estimateDeliveryDate'),
                //     description: this.tKey('estimateDeliveryDateDescription'),
                //     pathName: 'estimate-delivery-date',
                // },
                {
                    name: this.tKey('userGuide'),
                    description: this.tKey('userGuideDescription'),
                    pathName: 'user-guide',
                },
            ],
            /** @type {import('@fluentui/react').IColumn[]} */
            columns: [
                {
                    key: 'name',
                    name: this.tKey('name'),
                    fieldName: 'name',
                    minWidth: 100,
                    maxWidth: 300,
                    isResizable: true,
                },
                {
                    key: 'description',
                    name: this.tKey('description'),
                    fieldName: 'description',
                    minWidth: 150,
                    maxWidth: 500,
                    isResizable: true,
                },
            ],
        }

        // this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        // this.search()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { lang, me } = this.props

        if (lang !== prevProps.lang)
            this.init()
        if (me.companyId !== prevProps.me.companyId)
            this.init()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('tools'), key: 'tools', isCurrentItem: true },
        ])
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
    }

    /**
     * Download user Guide
     */
    // async downloadUserGuide() {
    //     try {
    //         const { filesHandler } = this.props
    //         this.filesHandlerGetFile = filesHandler.getUserGuide()
    //         const { blob, fileName } = await this.filesHandlerGetFile.fetch()
    //         HandleBlob.download(blob, fileName)
    //     } catch (error) {
    //         switch (error?.constructor) {
    //             case CancelRequestError:
    //             case UnauthorizedError:
    //             case InvalidEntityError: break
    //             case NotImplementedError:
    //             default:
    //                 // eslint-disable-next-line no-console
    //                 console.error(error)
    //                 break
    //         }
    //     }
    // }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const { status, items, columns } = this.state

        return (
            <main className={styles.index}>
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link to={`/tools/${props.item?.pathName}`}>
                                {defaultRender(props)}
                            </Link>
                        )}
                    // onRenderRow={(props, defaultRender) => (props.item.pathName === 'user-guide'
                    //     ? (
                    //         <button
                    //             onClick={() => this.downloadUserGuide()}
                    //             type="button"
                    //             style={{ all: 'unset', cursor: props.item.file ? 'pointer' : undefined }}
                    //         >
                    //             {defaultRender(props)}
                    //         </button>
                    //     )
                    //     : (
                    //         <Link to={`/tools/${props.item?.pathName}`}>
                    //             {defaultRender(props)}
                    //         </Link>
                    //     )
                    // )}
                    />
                </Card>
            </main>
        )
    }
}

IndexTools.prototype.onColumnClick = onColumnClick
IndexTools.prototype.handleRenderColumn = handleRenderColumn
IndexTools.prototype.tKey = tKey
IndexTools.prototype.tObj = tObj
