import React, { PureComponent } from 'react'
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    buildColumns,
    Text,
    IColumn,
    TextField,
    Label,
    DefaultButton,
    TooltipHost,
    DirectionalHint,
    TooltipDelay,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import User from 'requests/objects/user'
import { Link } from 'react-router-dom'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import { clientAccountColumns } from 'types/users/columns'
import Card from 'components/containers/card'
import styles from 'styles/pages/users/index.module.scss'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import { clientProfiles } from 'types/users/profiles'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import parseJson from 'helpers/methods/parseJson'

/** @debug {AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {string} firstname firstname
 * @property {string} lastname lastname
 * @property {string} email email
 * @property {number} clientId clientId
 */

/**
 * @augments {PureComponent<AppProps>} extends
 */
export default class IndexClientAccounts extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {IColumn[]} Columns displayed */
            columns: [],
            /** @type {User[]} Items found in API */
            items: [],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                firstname: '',
                lastname: '',
                email: '',
                clientId: null,
            },
        }

        /** @type {NodeJS.Timeout} Time out to handle apply of searchParams */
        this.timeOutParams = null
        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    firstname: queryParams.get('firstname'),
                    lastname: queryParams.get('lastname'),
                    email: queryParams.get('email'),
                    clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.usersHandlerGetAll?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('allClientAccounts'), key: 'all-user', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand } = this.props

        setCommand(
            [
                {
                    key: 'new',
                    text: this.tKey('new'),
                    iconProps: { iconName: 'Add' },
                    onClick: () => history.push(`/client-accounts/${NEW_PATH}`),
                    disabled: false,
                },
            ],
        )
    }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()

        this.setState({ columns: this.buildColumns() }, () => this.search())
    }

    /**
     * Init <DetailList>
     * @returns {IColumn[]} Columns
     */
    buildColumns() {
        const cols = clientAccountColumns?.reduce((obj, col) => ({ ...obj, [col.fieldName]: col.name }), {})

        const columns = buildColumns(
            [cols],
            true,
            this.onColumnClick.bind(this, {
                colName: 'columns', dataName: ['items'], source: 'state', isFlatten: true,
            }),
        )
        // eslint-disable-next-line no-restricted-syntax
        for (const column of columns) {
            const name = cols[column.key]
            column.name = this.tKey(name) || name
            column.maxWidth = (() => {
                switch (name) {
                    case 'registrationNumber':
                    case 'client':
                    case 'lastname':
                    case 'firstname':
                    case 'profile':
                        return 130
                    case 'phoneNumber':
                    case 'email':
                        return 200
                    default:
                        return (column.name?.length ?? 1) * 12
                }
            })()
            column.minWidth = (column.name?.length ?? 1) * 5
            column.onRender = clientAccountColumns.find(x => x.name === name)?.onRender?.bind(this)
                ?? this.handleRenderColumn(clientAccountColumns, name, column)
        }
        return columns
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { usersHandler } = this.props
            const { searchParams } = this.state
            try {
                this.usersHandlerGetAll = usersHandler.getAll({ ...searchParams, profileIds: clientProfiles })
                const users = await this.usersHandlerGetAll.fetch()
                this.setState({
                    items: users.map(x => this.flattenObj(x)),
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            columns, status, items, searchParams,
        } = this.state

        const { param } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                >
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('email')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.email}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, email: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('lastname')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.lastname}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, lastname: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('firstname')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.firstname}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, firstname: newVal } })}
                                />
                            </Columns.Column>

                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('client')}
                                    options={param.clients.map(({ key, text }) => ({
                                        key,
                                        text: this.tObj(this.parseJson(text)),
                                    }))}
                                    selectedKey={searchParams.clientId}
                                    disabled={status === Status.PENDING}
                                    onChange={(_ev, option) => this.setState({
                                        searchParams: { ...searchParams, clientId: option.key },
                                    })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter" />
                            <Columns.Column size="one-quarter" />
                            <Columns.Column size="one-quarter">
                                <div
                                    className={styles['action-filter-buttons']}
                                >
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <DefaultButton
                                            text={this.tKey('research')}
                                            primary
                                            disabled={status === Status.PENDING}
                                            onClick={() => this.submitInput.current.click()}
                                            iconProps={{ iconName: 'Search' }}
                                        />
                                    </div>
                                    <div className={styles.separator} />
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <TooltipHost
                                            content={this.tKey('resetFilters')}
                                            directionalHint={DirectionalHint.topAutoEdge}
                                            delay={TooltipDelay.medium}
                                        >
                                            <DefaultButton
                                                disabled={status === Status.PENDING}
                                                styles={{
                                                    root: {
                                                        minWidth: '40px',
                                                        maxWidth: '40px',
                                                    },
                                                }}
                                                iconProps={{ iconName: 'ClearFilter' }}
                                                onClick={() => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        firstname: '',
                                                        lastname: '',
                                                        email: '',
                                                        clientId: null,
                                                    },
                                                })}
                                            />
                                        </TooltipHost>
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>
                        <Columns />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </Card>
                <br />
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link
                                to={{
                                    pathname: `/client-accounts/${props.item?.userId}`,
                                }}
                            >
                                {defaultRender(props)}
                            </Link>
                        )}

                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>Aucun résultat trouvé</Text>}
                </Card>
            </main>
        )
    }
}

IndexClientAccounts.prototype.onColumnClick = onColumnClick
IndexClientAccounts.prototype.flattenObj = flattenObj
IndexClientAccounts.prototype.handleRenderColumn = handleRenderColumn
IndexClientAccounts.prototype.tKey = tKey
IndexClientAccounts.prototype.tObj = tObj
IndexClientAccounts.prototype.parseJson = parseJson
