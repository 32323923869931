/**
 * @enum {number}
 */
const EAddressType = {
    PickUp: 1,
    Delivery: 2,
    Redelivery: 3,
}

export default EAddressType
