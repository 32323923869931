import React from 'react'
import {
    ConstrainMode, DefaultButton, DetailsListLayoutMode, Dialog, DialogFooter, DialogType,
    MessageBar, MessageBarType, PrimaryButton, SelectionMode, ShimmeredDetailsList, TextField, Toggle,
} from '@fluentui/react'
import Card from 'components/containers/card'
import parseJson from 'helpers/methods/parseJson'
import DeliveryType from 'requests/objects/deliveryType'
import DeliveryTypesHandler from 'requests/handlers/deliveryTypesHandler'
import Status from 'types/status'
import usePivotdeliveryTypes from 'helpers/hooks/components/pages/usePivotDeliveryTypes'

/**
 * PivotDeliveryTypes
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {DeliveryTypesHandler} props.deliveryTypesHandler deliveryTypesHandler
 * @param {DeliveryType[]} props.deliveryTypes deliveryTypes
 * @param {number} props.companyId companyId
 * @param {(deliveryType: DeliveryType, isDeletion: boolean) => void} props.onChange onChange
 * @returns {JSX.Element} Element
 */
export default function PivotdeliveryTypes({
    lang, deliveryTypes, deliveryTypesHandler, companyId, onChange,
}) {
    const {
        tKey,
        tObj,
        errorMessage,
        setErrorMessage,
        addDeliveryType,
        columns,
        deleteDeliveryType,
        upsertDeliveryType,
        deliveryType,
        errorField,
        setDeliveryType,
        updateDeliveryTypeList,
        status,
        isDeletion,
        isVisible,
        dialogTitle,
    } = usePivotdeliveryTypes({
        lang,
        companyId,
        deliveryTypes,
        deliveryTypesHandler,
        onChange,
    })

    return (
        <>
            <Card
                title={tKey('deliveryTypes')}
                iconName="PageData"
                headerComponent={(
                    <DefaultButton
                        onClick={() => addDeliveryType()}
                        text={tKey('add')}
                    />
                )}
            >
                <ShimmeredDetailsList
                    items={deliveryTypes}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                />
            </Card>
            {isVisible && (
                <Dialog
                    hidden={!isVisible}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: dialogTitle,
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                    // maxWidth="100%"
                    // minWidth="1000px"
                    maxWidth="555px"
                    minWidth="400px"
                    styles={{
                        main: {
                            width: '100%',
                            maxHeight: '100%',
                            maxWidth: '100%',
                        },
                    }}
                >
                    {errorMessage
                        && (
                            <>
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                    truncated
                                    onDismiss={() => setErrorMessage(null)}
                                >
                                    {tObj(errorMessage)}
                                </MessageBar>
                                <br />
                            </>
                        )}
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            if (isDeletion)
                                deleteDeliveryType()
                            else
                                upsertDeliveryType()
                        }}
                    >
                        {isDeletion ? tKey('sureDeleteDeliveryType') : (
                            <>
                                <TextField
                                    label={tKey('nameFr')}
                                    placeholder={tKey('nameFr')}
                                    value={parseJson(deliveryType.name)?.fr || ''}
                                    errorMessage={tObj(parseJson(errorField.name))}
                                    onChange={(ev, newVal) => {
                                        setDeliveryType({
                                            ...deliveryType,
                                            name: JSON.stringify({ ...parseJson(deliveryType.name), fr: newVal }),
                                        })
                                    }}
                                    required
                                />
                                <TextField
                                    label={tKey('nameEn')}
                                    placeholder={tKey('nameEn')}
                                    value={parseJson(deliveryType.name)?.en || ''}
                                    errorMessage={tObj(parseJson(errorField.name))}
                                    onChange={(ev, newVal) => {
                                        setDeliveryType({
                                            ...deliveryType,
                                            name: JSON.stringify({ ...parseJson(deliveryType.name), en: newVal }),
                                        })
                                    }}
                                // required={!isReadOnly}
                                />
                                <TextField
                                    label={tKey('nameNl')}
                                    placeholder={tKey('nameNl')}
                                    value={parseJson(deliveryType.name)?.nl || ''}
                                    errorMessage={tObj(parseJson(errorField.name))}
                                    onChange={(ev, newVal) => {
                                        setDeliveryType({
                                            ...deliveryType,
                                            name: JSON.stringify({ ...parseJson(deliveryType.name), nl: newVal }),
                                        })
                                    }}
                                // required={!isReadOnly}
                                />
                                <br />
                                <TextField
                                    label={tKey('abbreviation')}
                                    placeholder={tKey('abbreviation')}
                                    value={deliveryType.shortName || ''}
                                    errorMessage={tObj(parseJson(errorField.shortName))}
                                    onChange={(ev, newVal) => {
                                        setDeliveryType({
                                            ...deliveryType,
                                            shortName: newVal,
                                        })
                                    }}
                                    required
                                />
                                <br />
                                <Toggle
                                    label={tKey('isComfourDataRequired')}
                                    onText={tKey('yes')}
                                    offText={tKey('no')}
                                    checked={deliveryType.hasComfourDataRequired}
                                    onChange={(ev, checked) => setDeliveryType({ ...deliveryType, hasComfourDataRequired: checked })}
                                />
                                <br />
                                <Toggle
                                    label={tKey('isOtNumberRequired')}
                                    onText={tKey('yes')}
                                    offText={tKey('no')}
                                    checked={deliveryType.hasOtNumberRequired}
                                    onChange={(ev, checked) => setDeliveryType({ ...deliveryType, hasOtNumberRequired: checked })}
                                />
                                <br />
                                <Toggle
                                    label={tKey('isRdvRequired')}
                                    onText={tKey('yes')}
                                    offText={tKey('no')}
                                    checked={deliveryType.hasRdvRequired}
                                    onChange={(ev, checked) => setDeliveryType({ ...deliveryType, hasRdvRequired: checked })}
                                />
                            </>
                        )}
                        <br />
                        <DialogFooter>
                            <DefaultButton
                                onClick={() => updateDeliveryTypeList(null)}
                                text={tKey('cancel')}
                                disabled={status === Status.PENDING}
                            />
                            <PrimaryButton
                                type="submit"
                                text={tKey(isDeletion ? 'delete' : 'save')}
                                disabled={
                                    status === Status.PENDING
                                }
                            />
                        </DialogFooter>
                    </form>
                </Dialog>
            )}
        </>
    )
}
