import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Status from 'types/status'

import useTranslate from 'helpers/hooks/useTranslate'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/label-modal.module.scss'
import OrdersHandler from 'requests/handlers/ordersHandler'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import HandleBlob from 'helpers/methods/blob'
import { Columns } from 'react-bulma-components'

/**
 * Tracking ling data for modal usage
 * @typedef {object} LabelData
 * @property {number} orderId order id
 */

/**
 * LabelModal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {LabelData} props.data LabelData
 * @param {string} props.lang lang
 * @param {OrdersHandler} props.ordersHandler ordersHandler
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function LabelModal({
    isVisible,
    setIsVisible,
    data,
    lang,
    ordersHandler,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const { tKey } = useTranslate({ lang })
    const [selectedId, setSelectedId] = useState(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const printLabelsHandler = useRef()

    const printLabels = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            printLabelsHandler.current = ordersHandler.printEtiquette(data.orderId, selectedId)
            const blob = await printLabelsHandler.current.fetch()
            HandleBlob.download(blob, `${tKey('labels').toLowerCase()}.pdf`)
            setSelectedId(null)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            setStatus(Status.REJECTED)
            // eslint-disable-next-line no-console
            console.log(error)
        }
    }, [ordersHandler, data.orderId, selectedId, tKey, setIsVisible])

    // On component did unmount
    useEffect(() => () => {
        printLabelsHandler.current?.cancel()
    }, [])

    if (!isVisible)
        return null

    return (
        <Dialog
            hidden={!isVisible}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: tKey('printLabels'),
                subText: tKey('printLabelsSubtitle'),
                styles: {
                    innerContent: { height: '100%' },
                },
            }}
            maxWidth="555px"
            minWidth="400px"
        >
            <form
                onSubmit={ev => {
                    ev.preventDefault()
                    printLabels()
                }}
            />
            <div className={styles['label-modal']}>
                <Columns>
                    <Columns.Column size="half">
                        <FilteredVirtualCombobox
                            label={tKey('nbOfLabel')}
                            options={[
                                { key: 1, text: '1' },
                                { key: 2, text: '2' },
                                { key: 4, text: '4' },
                                { key: 6, text: '6' },
                                { key: 8, text: '8' },
                                { key: 10, text: '10' },
                                { key: 12, text: '12' }]}
                            selectedKey={selectedId}
                            onChange={(ev, option) => {
                                ev.preventDefault()
                                setSelectedId(option.key)
                            }}
                            required
                        />
                    </Columns.Column>
                </Columns>
                <DialogFooter className={styles.footer}>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            printLabels()
                        }}
                        text={tKey('print')}
                        disabled={status === Status.PENDING || !selectedId}
                    />
                </DialogFooter>
            </div>
        </Dialog>
    )
}
