import React, { PureComponent } from 'react'
import { Text } from '@fluentui/react'
import Card from 'components/containers/card'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import { tKey, tObj } from 'helpers/methods/translate'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}}
 */
export default class NotFound extends PureComponent {
    componentDidMount() {
        const { setBreadcrumb, setCommand } = this.props
        setBreadcrumb([{ text: this.tKey('error'), key: 'error' }])
        setCommand([])
    }

    render() {
        return (
            <main>
                <Card
                    title="Erreur"
                    iconName="Error"
                >
                    <Text
                        as="p"
                        block
                    >
                        {this.tKey('noResultFound')}
                    </Text>
                </Card>
            </main>
        )
    }
}

NotFound.prototype.tKey = tKey
NotFound.prototype.tObj = tObj
