import React, { useEffect } from 'react'
import {
    Dialog, DialogFooter, DialogType, PrimaryButton,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import history from 'helpers/history'
import { NEW_PATH } from 'types/others'
import styles from 'styles/components/modals/choose-order-type.module.scss'
import OrderType from 'requests/objects/orderType'
import parseJson from 'helpers/methods/parseJson'
import getOrderTypeLogo from 'helpers/methods/getOrderTypeLogo'

/**
 * @typedef {object} ChooseOrderTypeProps
 * @property {boolean} isOrderModalOpen isOrderModalOpen
 * @property {string=} lang lang
 * @property {OrderType[]=} orderTypes orderTypes
 * @property {string[]=} [authorizedDdeliveryTypes] authorizedDdeliveryTypes
 * @property {Function=} cancel cancel
 */

/**
 * ChooseOrderType
 * @param {ChooseOrderTypeProps} props props
 * @returns {JSX.Element} Element
 */
export default function ChooseOrderType({
    isOrderModalOpen,
    orderTypes = [],
    lang = 'fr',
    cancel = () => { },
}) {
    const { tKey, tObj } = useTranslate({ lang })

    useEffect(() => {
        if (isOrderModalOpen && orderTypes.length === 1)
            history.push(`/orders/${NEW_PATH}/?type=${orderTypes[0].orderTypeId}`)
    }, [isOrderModalOpen, orderTypes])

    if (!isOrderModalOpen)
        return null

    return (
        <Dialog
            hidden={!isOrderModalOpen}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: tKey('chooseDeliveryType'),
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
            minWidth="400px"
        >
            <div className={styles['choose-order-type']}>
                {orderTypes.map(orderType => (
                    <button
                        key={orderType.orderTypeId}
                        className={styles['choose-order-type-button']}
                        type="button"
                        onClick={() => {
                            history.push(`/orders/${NEW_PATH}/?type=${orderType.orderTypeId}`)
                        }}
                    >
                        <img
                            src={getOrderTypeLogo(orderType.orderTypeId)}
                            alt={tObj(parseJson(orderType.name))}
                        />

                        <span>{tObj(parseJson(orderType.name))}</span>
                    </button>
                ))}
                {/* <button
                    className={styles['choose-order-type-button']}
                    type="button"
                    onClick={() => {
                        history.push(`/orders/${NEW_PATH}`)
                    }}
                >
                    <img
                        src={DeliveryLogo}
                        alt="Delivery"
                    />

                    <span>{tKey('delivery')}</span>
                </button>
                {authorizedDdeliveryTypes.includes('REP') && (
                    <button
                        className={styles['choose-order-type-button']}
                        type="button"
                        onClick={() => {
                            history.push(`/orders/${NEW_PATH}/?type=${EOrderType.Reprise}`)
                        }}
                    >
                        <img
                            src={RecoveryLogo}
                            alt="Recovery"
                        />

                        <span>{tKey('recovery')}</span>
                    </button>
                )}
                {authorizedDdeliveryTypes.includes('REL') && (
                    <button
                        className={styles['choose-order-type-button']}
                        type="button"
                        onClick={() => {
                            history.push(`/orders/${NEW_PATH}/?type=${EOrderType.Redelivery}`)
                        }}
                    >
                        <img
                            src={RedeliveryLogo}
                            alt="Redelivery"
                        />

                        <span>{tKey('redelivery')}</span>
                    </button>
                )} */}
            </div>
            <br />
            <DialogFooter>
                <PrimaryButton
                    onClick={() => cancel()}
                    text={tKey('cancel')}
                />
            </DialogFooter>
        </Dialog>
    )
}
