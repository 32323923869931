import { Icon, Text } from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import Time from 'helpers/methods/time'
import React from 'react'
import Address from 'requests/objects/address'
import styles from 'styles/components/pages/transport-data.module.scss'

/**
 * TransportData
 * @param {object} props props
 * @param {Address} props.address address
 * @param {Date} props.scheduledDate scheduledDate
 * @param {Date} props.date date
 * @param {string} props.lang lang
 * @param {boolean} [props.isDelivery] isDelivery
 * @param {boolean} [props.plannedThe] plannedThe
 * @returns {JSX.Element} Returns
 */
export default function TransportData({
    address,
    scheduledDate,
    date,
    lang,
    isDelivery = false,
    plannedThe = false,
}) {
    const { tKey } = useTranslate({ lang })

    return (
        <div className={styles['transport-data']}>
            <div className={styles['transport-data-address']}>
                <Text>
                    <b>{address.label}</b>
                    <br />
                    {address.street}
                    {', '}
                    {address.zipCode}
                    {', '}
                    {address.city}
                    {address.country ? `, ${address.country.code}` : ''}
                </Text>
            </div>
            <div className={styles['transport-data-dates']}>
                <Text
                    className={styles['transport-data-dates-title']}
                    variant="smallPlus"
                >
                    <Icon iconName="Calendar" />
                    {' '}
                    {tKey('date')}
                </Text>
                <Text
                    className={styles['transport-data-dates-content']}
                    variant="smallPlus"
                >
                    {scheduledDate ? tKey(plannedThe ? 'plannedThe' : 'plannedFor') : ''}
                    {' '}
                    {scheduledDate ? Time(scheduledDate).getCleanDate({
                        year: 'numeric', month: 'long', day: '2-digit',
                    }) : ''}
                    <br />
                    {!date ? (<br />) : tKey(isDelivery ? 'deliveredOn' : 'removedOn')}
                    {' '}
                    {date ? Time(date).getCleanDate({
                        year: 'numeric', month: 'long', day: '2-digit',
                    }) : ''}
                </Text>
            </div>
        </div>
    )
}
