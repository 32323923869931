import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import Accessibility, { ErrorAccessibility } from 'requests/objects/accessibility'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import usePrevious from 'helpers/hooks/usePrevious'
import parseJson from 'helpers/methods/parseJson'

/**
 * accessibility modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {Partial<Accessibility>} props.accessibilityData accessibilityData must send accessibilityId prop at creation
 * @param {(accessibility: Accessibility) => void} props.onChange Callback to complete the accessibility form
 * @param {string} props.lang lang
 * @returns {JSX.Element} accessibility modal form to create/update a accessibility
 */
export default function AccessibilityModal({
    isVisible,
    companiesHandler,
    accessibilityData,
    onChange,
    lang,
}) {
    const [accessibility, setAccessibility] = useState(new Accessibility(accessibilityData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorAccessibility())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<Accessibility>>} */
    const upsertAccessibilityHandler = useRef()

    const prevIsVisible = usePrevious(isVisible)

    // Set logistic constraint data for edition or creation. Ensure that data in modal are up to date
    useEffect(() => {
        if (isVisible && prevIsVisible !== isVisible) {
            setErrorMessage(null)
            setErrorField(new ErrorAccessibility())
            setAccessibility(new Accessibility(accessibilityData))
        }
    }, [accessibilityData, isVisible, prevIsVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertAccessibilityHandler.current = companiesHandler.upsertAccessibility(accessibility.companyId, accessibility, accessibility.accessibilityId)
            const accessibilityResult = await upsertAccessibilityHandler.current.fetch()
            onChange(accessibilityResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorAccessibility>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [companiesHandler, accessibility, onChange])

    // On component did unmount
    useEffect(() => () => {
        upsertAccessibilityHandler.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: accessibility?.accessibilityId ? tKey('modalEditAccessibilityTitle') : tKey('modalAccessibilityTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                    style={{ display: 'flex', gap: '30px' }}
                />
                <TextField
                    label={tKey('nameFr')}
                    placeholder={tKey('nameFr')}
                    value={parseJson(accessibility.name)?.fr || ''}
                    errorMessage={tObj(parseJson(errorField.name))}
                    onChange={(ev, newVal) => {
                        setAccessibility({
                            ...accessibility,
                            name: JSON.stringify({ ...parseJson(accessibility.name), fr: newVal }),
                        })
                    }}
                    required
                />
                <TextField
                    label={tKey('nameEn')}
                    placeholder={tKey('nameEn')}
                    value={parseJson(accessibility.name)?.en || ''}
                    errorMessage={tObj(parseJson(errorField.name))}
                    onChange={(ev, newVal) => {
                        setAccessibility({
                            ...accessibility,
                            name: JSON.stringify({ ...parseJson(accessibility.name), en: newVal }),
                        })
                    }}
                    required
                />
                <TextField
                    label={tKey('nameNl')}
                    placeholder={tKey('nameNl')}
                    value={parseJson(accessibility.name)?.nl || ''}
                    errorMessage={tObj(parseJson(errorField.name))}
                    onChange={(ev, newVal) => {
                        setAccessibility({
                            ...accessibility,
                            name: JSON.stringify({ ...parseJson(accessibility.name), nl: newVal }),
                        })
                    }}
                    required
                />
                <br />
                <TextField
                    label={tKey('tmsCode')}
                    placeholder={tKey('tmsCode')}
                    value={accessibility.accessibilityTmsId || ''}
                    errorMessage={tObj(parseJson(errorField.accessibilityTmsId))}
                    onChange={(ev, newVal) => {
                        setAccessibility({
                            ...accessibility,
                            accessibilityTmsId: newVal,
                        })
                    }}
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
