import { Icon, Text } from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import React, { useRef, useCallback } from 'react'
import { TransportFile } from 'requests/objects/transport'
import styles from 'styles/components/pages/transports/documents-data.module.scss'
import FilesHandler from 'requests/handlers/filesHandler'
import EFileFolder from 'types/files/enums/fileFolder'
import HandleBlob from 'helpers/methods/blob'

/**
 * CompanyData
 * @param {object} props props
 * @param {TransportFile[]} props.items items
 * @param {object} props.lang Lang
 * @param {FilesHandler} props.handler handler
 * @returns {JSX.Element} Returns
 */
export default function DocumentsData({
    items, lang, handler,
}) {
    const { tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetUrlFile = useRef(null)

    /**
     * Callback on download file
     */
    const onDownloadFile = useCallback(
        /**
         * @param {number} fileId Fileid
         * @param {string} name name
         */
        async (fileId, name) => {
            try {
                handlerGetFile.current = handler.getFile(EFileFolder.TransportFile, fileId)
                const blob = await handlerGetFile.current.fetch()
                HandleBlob.download(blob, name)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }, [handler],
    )

    const onUrlDownload = useCallback(
        /**
         * @param {number} transportFileId transportFileId
         * @param {string} fileName fileName
         */
        async (transportFileId, fileName) => {
            try {
                handlerGetUrlFile.current = handler.getTransportCMRFile(transportFileId)
                const blob = await handlerGetUrlFile.current.fetch()

                HandleBlob.download(blob, fileName)
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error)
            }
        }, [handler],
    )

    return (
        <div className={styles['documents-data']}>
            <ul className={styles['documents-data-list']}>
                {items?.map((file, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${file.transportFileId}_${index}`}>
                        {
                            // eslint-disable-next-line no-nested-ternary
                            file.url?.includes('download=true') ? (
                                <Text
                                    onClick={() => onUrlDownload(file.transportFileId, file.name)}
                                >
                                    {file.name || file.url}
                                </Text>
                            ) : file.url ? (
                                <Text>
                                    <a
                                        href={file.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {file.name ?? file.url}
                                    </a>
                                </Text>
                            ) : (
                                <Text
                                    onClick={() => (!file.url ? onDownloadFile(file.fileId, file.name) : null)}
                                >
                                    {file.name || file.url}
                                </Text>
                            )
                        }
                        <Text>
                            <Icon iconName="TextDocument" />
                            {' '}
                            {tObj(file.category)}
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    )
}
