import WorkflowTransportStep from 'requests/objects/workflowTransportStep'

/**
 * Workflow Historic Object
 */
export default class WorkflowTransportHistoric {
    /**
     * @param {object} data data
     * @param {number=} data.workflowTransportHistoricId workflowTransportHistoricId
     * @param {boolean=} data.isActive isActive
     * @param {boolean=} data.isCompleted isCompleted
     * @param {boolean=} data.isCanceled isCanceled
     * @param {object=} data.workflowTransportStep workflowTransportStep
     * @param {number=} data.workflowTransportStepId workflowTransportStepId
     * @param {string=} data.error error
     */
    constructor({
        workflowTransportHistoricId,
        isActive,
        isCompleted,
        isCanceled,
        workflowTransportStep,
        workflowTransportStepId,
        error,
    } = {}) {
        this.workflowTransportHistoricId = workflowTransportHistoricId
        this.isActive = isActive
        this.isCompleted = isCompleted
        this.isCanceled = isCanceled
        this.workflowTransportStep = workflowTransportStep ? new WorkflowTransportStep(workflowTransportStep) : undefined
        this.workflowTransportStepId = workflowTransportStepId
        this.error = error
    }
}
