/**
 * Parse a json string or return string if invalid
 * @param {string} str String
 * @returns {object | string} Result
 */
export default function parseJson(str) {
    try {
        const res = JSON.parse(str)
        if (res && typeof res === 'object')
            return res
        return str
    } catch (error) {
        return str
    }
}
