/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import File from 'requests/objects/file'
import Company from 'requests/objects/company'

/**
 * HomeArticle Object
 */
export default class HomeArticle extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.homeArticleId homeArticleId
     * @param {string=} data.title title
     * @param {string=} data.category category
     * @param {string=} data.startDate startDate
     * @param {string=} data.endDate endDate
     * @param {string=} data.url url
     * @param {number=} data.fileId fileId
     * @param {object=} data.file file
     * @param {object[]=} data.companies companies
     */
    constructor({
        homeArticleId = 0,
        title = '',
        category = '',
        startDate,
        endDate,
        url = '',
        fileId,
        file,
        companies = [],
        ...data
    } = {}) {
        super(data)
        this.homeArticleId = homeArticleId
        this.title = title
        this.category = category
        this.startDate = startDate ? new Date(startDate) : undefined
        this.endDate = endDate ? new Date(endDate) : undefined
        this.url = url
        this.fileId = fileId
        this.file = file ? new File(file) : undefined
        this.companies = companies?.map(x => new Company(x)) ?? []
        this.companyIds = this.companies.map(x => x.companyId)
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorHomeArticle extends ErrorBase {
    constructor() {
        super()
        this.homeArticleId = ''
        this.title = ''
        this.category = ''
        this.startDate = ''
        this.endDate = ''
        this.url = ''
        this.fileId = ''
        this.file = ''
        this.companyIds = ''
    }
}
