import { DayOfWeek } from '@fluentui/react' // eslint-disable-line
import OpenDays from 'requests/objects/openDays'

/**
 * Create Date range
 * @param {DayOfWeek[]=} weekdays weekdays
 * @param {Date=} startDate startDate
 * @param {Date=} endDate endDate
 * @returns {Date[]} dates
 */
export default function createDateRange(weekdays, startDate, endDate) {
    const dateRange = []
    const currentDate = new Date(startDate)

    while (currentDate <= endDate) {
        if (weekdays.includes(currentDate.getDay()))
            dateRange.push(new Date(currentDate))

        currentDate.setDate(currentDate.getDate() + 1)
    }

    return dateRange
}

/**
 * Create Date range
 * @param {OpenDays=} openDays openDays
 * @param {Date=} startDate startDate
 * @param {Date=} endDate endDate
 * @returns {Date[]} dates
 */
export function createOpenDaysDateRange(openDays, startDate, endDate) {
    const weekdays = []

    if (openDays.monday === false)
        weekdays.push(DayOfWeek.Monday)
    if (openDays.tuesday === false)
        weekdays.push(DayOfWeek.Tuesday)
    if (openDays.wednesday === false)
        weekdays.push(DayOfWeek.Wednesday)
    if (openDays.thursday === false)
        weekdays.push(DayOfWeek.Thursday)
    if (openDays.friday === false)
        weekdays.push(DayOfWeek.Friday)
    if (openDays.saturday === false)
        weekdays.push(DayOfWeek.Saturday)
    if (openDays.sunday === false)
        weekdays.push(DayOfWeek.Sunday)

    return createDateRange(weekdays, startDate, endDate)
}
