/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react'
import { Translations, TranslationsType, TranslationsObjectType } from "types/translations" // eslint-disable-line

// eslint-disable-next-line jsdoc/require-returns
/**
 * Use Translate
 * @param {object} props props
 * @param {string=} props.lang lang
 */
export default function useTranslate({ lang = 'en' }) {
    /**
     * Translate an element by Translations object
     * Tips, you can use "{{key}}", "{{keyOther}}", etc in your translations file, and replace them with the second arg of this function
     */
    const tKey = useCallback(
        /**
         * @param {TranslationsType} key Key to translate
         * @param {{[key: string]: string}=} args Args to replace
         * @returns {string} Result
         */
        (key, args = {}) => {
            let translation = Translations[key]?.[lang || 'en'] ?? key ?? ''

            for (const argKey in args)
                translation = translation.replace(`{{${argKey}}}`, args[argKey])

            return translation
        }, [lang],
    )

    /**
     * Translate an element by an object
     */
    const tObj = useCallback(
        /**
         * @param {TranslationsObjectType | string | [string]} obj Object to translate, default to undifined to use "Translations" object
         * @param {{[key: string]: string}=} args Args to replace
         * @returns {string} Result
         */
        (obj, args = {}) => {
            if (typeof obj === 'string')
                return obj
            let translation = Array.isArray(obj) && obj.length
                ? JSON.parse(obj[0])?.[lang || 'en'] ?? ''
                : obj?.[lang || 'en'] ?? ''

            for (const argKey in args)
                translation = translation.replace(`{{${argKey}}}`, args[argKey])

            return translation
        }, [lang],
    )

    return { tKey, tObj }
}
