import Base from 'requests/objects/_base'
import Address from 'requests/objects/address'
import AddressType from 'requests/objects/addressType'
/**
 * AddressType Object
 */
export default class AddressTypeDefault extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.addressId addressId
     * @param {object=} data.address address
     * @param {number=} data.addressTypeId addressTypeId
     * @param {object=} data.addressType addressType
     * @param {boolean=} data.isDefault isDefault
     */
    constructor({
        addressId,
        address,
        addressTypeId,
        addressType,
        isDefault,
        ...data
    } = {}) {
        super(data)
        this.addressTypeId = addressTypeId
        this.addressId = addressId
        this.address = address ? new Address(address) : undefined
        this.addressTypeId = addressTypeId
        this.addressType = addressType ? new AddressType(addressType) : undefined
        this.isDefault = isDefault
    }
}
