import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useState,
} from 'react'
// eslint-disable-next-line import/named
import useTranslate from 'helpers/hooks/useTranslate'
import ComfourData from 'requests/objects/order/comfourData'
import { ParamElement } from 'requests/objects/param'
import parseJson from 'helpers/methods/parseJson'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/**
 * Comfour data modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {object} props.comfourData comfourData
 * @param {(comfourData: ComfourData) => void} props.onChange Callback to complete the comfour delivery data form
 * @param {string} props.lang lang
 * @param {ParamElement[]} props.storeParams storeParams
 * @returns {JSX.Element} comfour delivery data modal
 */
export default function ComfourDataModal({
    isVisible,
    comfourData,
    onChange,
    lang,
    storeParams,
}) {
    const [comfour, setComfour] = useState(new ComfourData(comfourData || {}))
    const { tKey, tObj } = useTranslate({ lang })

    const onSubmit = useCallback(() => {
        onChange(comfour)
    }, [comfour, onChange])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.close,
                    title: !comfourData ? tKey('modalComfourDataTitle') : tKey('modalEditComfourDataTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                />
                <FilteredVirtualCombobox
                    label={tKey('store')}
                    placeholder={tKey('select')}
                    options={storeParams.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                    selectedKey={comfour.storeId}
                    onChange={(ev, option) => {
                        ev.preventDefault()
                        setComfour(prevState => ({ ...prevState, storeId: option.key }))
                    }}
                    required
                />
                <TextField
                    label={tKey('allegroNumber')}
                    value={comfour.allegroNumber}
                    onChange={(ev, newVal) => {
                        if (newVal.length <= 8)
                            setComfour(prevState => ({ ...prevState, allegroNumber: newVal }))
                    }}
                    required
                />
                <TextField
                    label={tKey('supplierCodeNo')}
                    value={comfour.supplierNumber}
                    onChange={(ev, newVal) => setComfour(prevState => ({ ...prevState, supplierNumber: newVal }))}
                    required
                />
                <TextField
                    label={tKey('clientCodeNo')}
                    value={comfour.clientNumber}
                    onChange={(ev, newVal) => setComfour(prevState => ({ ...prevState, clientNumber: newVal }))}
                    required
                />
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={e => {
                            e.preventDefault()
                            onChange(null)
                        }}
                        text={tKey('cancel')}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                        disabled={!comfour.storeId || !comfour.allegroNumber}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
