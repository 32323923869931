import React, {
    PureComponent,
} from 'react'
import {
    ColumnActionsMode,
    ConstrainMode, DefaultButton, DetailsListLayoutMode, DirectionalHint, IconButton, Label, SelectionMode, ShimmeredDetailsList, Text,
    TextField,
    TooltipDelay,
    TooltipHost,
} from '@fluentui/react'
import history from 'helpers/history'
import { handleRenderColumn, onColumnClick } from 'helpers/methods/common'
import Status from 'types/status'
import Invoice from 'requests/objects/invoice'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import flattenObj from 'helpers/methods/flattenObj'
import Card from 'components/containers/card'
import styles from 'styles/pages/invoices/index.module.scss'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import { Link } from 'react-router-dom'
import Time from 'helpers/methods/time'
import EFileFolder from 'types/files/enums/fileFolder'
import HandleBlob from 'helpers/methods/blob'
import { clientProfiles } from 'types/users/profiles'
import { Columns } from 'react-bulma-components'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import parseJson from 'helpers/methods/parseJson'
import File from 'requests/objects/file'

/** @debug {import('app').AppProps} */

/**
 * @typedef {object} SearchParamsType
 * @property {string} invoiceNumber invoiceNumber
 * @property {number} invoiceDateKey invoiceDateKey
 * @property {number} clientId clientId
 */

/**
 * @augments {PureComponent<import('app').AppProps>} extends
 */
export default class IndexInvoices extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {Invoice[]} Items found in API */
            items: [],
            /** @type {import('@fluentui/react').IColumn[]} */
            columns: [
                {
                    key: 'invoiceNumber',
                    name: this.tKey('invoiceNumber'),
                    fieldName: 'invoiceNumber',
                    minWidth: 75,
                    maxWidth: 150,
                    isResizable: true,
                },
                {
                    key: 'date',
                    name: this.tKey('invoiceDate'),
                    fieldName: 'date',
                    minWidth: 75,
                    maxWidth: 150,
                    isResizable: true,
                    onRender: data => (data.date ? Time(data.date).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
                },
                {
                    key: 'deadlineDate',
                    name: this.tKey('deadlineDate'),
                    fieldName: 'deadlineDate',
                    minWidth: 75,
                    maxWidth: 150,
                    isResizable: true,
                    onRender: data => (data.deadlineDate ? Time(data.deadlineDate).getCleanDate({ year: 'numeric', month: '2-digit', day: '2-digit' }) : ''),
                },
                {
                    key: 'amountExcludingTax',
                    name: this.tKey('amountExcludingVat'),
                    fieldName: 'amountExcludingTax',
                    minWidth: 100,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: data => (data.amountExcludingTax
                        ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.amountExcludingTax)
                        : ''),
                },
                {
                    key: 'amountIncludingTax',
                    name: this.tKey('amountIncludingVat'),
                    fieldName: 'amountIncludingTax',
                    minWidth: 100,
                    maxWidth: 200,
                    isResizable: true,
                    onRender: data => (data.amountIncludingTax
                        ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(data.amountIncludingTax)
                        : ''),
                },
                {
                    key: 'files',
                    name: this.tKey('files'),
                    minWidth: 50,
                    maxWidth: 112,
                    columnActionsMode: ColumnActionsMode.disabled,
                    isResizable: true,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRender: data => (
                        <>
                            {
                                data.url && (
                                    <IconButton
                                        iconProps={{ iconName: 'PDF' }}
                                        title={this.tKey('urlFile')}
                                        onClick={() => this.downloadFileUrl(data.url, data.invoiceNumber)}
                                    />
                                )
                            }
                            {
                                data.file && (
                                    <IconButton
                                        iconProps={{ iconName: 'ExcelDocument' }}
                                        title={this.tKey('excelFile')}
                                        onClick={() => this.downloadFile(data.file)}
                                    />
                                )
                            }
                        </>
                    ),
                },
            ],
            /** @type {SearchParamsType} Params to search */
            searchParams: {
                invoiceNumber: '',
                invoiceDateKey: 1,
                clientId: null,
            },
        }

        /** @type {Array<import('@fluentui/react').IComboBoxOption & {invoiceDate: string}>} Month params */
        this.monthParams = (() => {
            const today = new Date()
            const lastDate = new Date('01/01/2024')
            const monthList = []
            let key = 1

            while (today >= lastDate) {
                const monthYear = today.toLocaleString('fr-FR', { month: 'long', year: 'numeric' })
                monthList.push({
                    text: monthYear.charAt(0).toUpperCase() + monthYear.slice(1),
                    key,
                    invoiceDate: `${today.getMonth()}-${today.getFullYear()}`,
                })

                today.setMonth(today.getMonth() - 1)
                key += 1
            }
            return monthList
        })()

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { me } = this.props
        const { columns } = this.state

        if (!me.clientId)
            this.setState({
                columns: [
                    {
                        key: 'client',
                        name: this.tKey('client'),
                        fieldName: 'client.name',
                        minWidth: 75,
                        maxWidth: 150,
                        isResizable: true,
                        onRender: data => data.client.name,
                    },
                    ...columns,
                ],
            })

        this.init()

        if (!window.location.search) {
            this.syncSearchParamsInHistory()
        } else {
            const queryParams = new URLSearchParams(window.location.search)

            // Reset Filter
            this.setState({
                /** @type {SearchParamsType} */
                searchParams: {
                    invoiceNumber: queryParams.get('invoiceNumber'),
                    invoiceDateKey: queryParams.get('invoiceDateKey') ? +queryParams.get('invoiceDateKey') : 1,
                    clientId: queryParams.get('clientId') ? +queryParams.get('clientId') : null,
                },
            }, () => this.syncSearchParamsInHistory())
        }
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     * @param {object} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        const { lang, me } = this.props
        const { searchParams } = this.state

        if (JSON.stringify(prevState.searchParams) !== JSON.stringify(searchParams)) {
            // Apply changement with delay to prevent lag
            clearTimeout(this.timeOutParams)
            this.timeOutParams = setTimeout(() => {
                history.replace({ search: '' })
                this.syncSearchParamsInHistory()
            }, 250)
        }

        if (lang !== prevProps.lang || (me.companyId !== prevProps.me.companyId))
            this.init()
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.invoicesHandlerGetAll?.cancel()
        this.filesHandlerGetFile?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb } = this.props

        setBreadcrumb([
            { text: this.tKey('invoices'), key: 'invoice', isCurrentItem: true },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const { setCommand, me } = this.props

        const commandItems = []

        if (!clientProfiles.includes(me.profileId))
            commandItems.push({
                key: 'new',
                text: this.tKey('new'),
                iconProps: { iconName: 'Add' },
                onClick: () => history.push(`/invoices/${NEW_PATH}`),
            })

        setCommand(commandItems)
    }

    // /**
    //  * Download button a file
    //  * @param {Invoice} invoice invoice
    //  * @returns {JSX.Element} button
    //  */
    // downloadButton(invoice) {
    //     return invoice.url || invoice.fileId ? (
    //         <IconButton
    //             iconProps={{ iconName: 'DownloadDocument' }}
    //             title={this.tKey('download')}
    //             onClick={e => {
    //                 e.preventDefault()
    //                 this.downloadInvoiceFile(invoice)
    //             }}
    //         />
    //     ) : (<div />)
    // }

    /**
     * Sync SearchParams in navigation history
     */
    syncSearchParamsInHistory() {
        const { searchParams } = this.state

        const queryParams = new URLSearchParams(window.location.search)

        // eslint-disable-next-line no-restricted-syntax
        for (const key in searchParams)
            if (searchParams[key] !== null && searchParams[key] !== '' && searchParams[key]?.length !== 0)
                if (Array.isArray(searchParams[key]))
                    // eslint-disable-next-line no-restricted-syntax
                    for (const element of searchParams[key])
                        queryParams.append(key, element)
                else
                    queryParams.set(key, searchParams[key])
            else
                queryParams.delete(key)

        history.replace({
            search: queryParams.toString(),
        })
    }

    /**
     * Init Page
     */
    init() {
        const { setMessageBar, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setCommand([])

        this.setupBreadcrumb()
        this.setupCommandBar()
        this.search()
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { invoicesHandler } = this.props
            const { searchParams: { invoiceDateKey, ...params } } = this.state
            try {
                this.invoicesHandlerGetAll = invoicesHandler.getAll({
                    ...params,
                    invoiceDate: this.monthParams.find(x => x.key === invoiceDateKey)?.invoiceDate ?? '',
                })
                const invoices = await this.invoicesHandlerGetAll.fetch()
                this.setState({
                    items: invoices,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            items: [],
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    /**
     * Download a file
     * @param {string} url url
     * @param {string} invoiceNumber invoiceNumber
     */
    async downloadFileUrl(url, invoiceNumber) {
        try {
            const { filesHandler } = this.props
            this.filesHandlerGetFile = filesHandler.getPdfFileFromUrl(url)

            const blob = await this.filesHandlerGetFile.fetch()
            HandleBlob.download(blob, `${this.tKey('invoice')} - ${invoiceNumber}.pdf`)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Download a file
     * @param {File} file file
     */
    async downloadFile(file) {
        try {
            const { filesHandler } = this.props
            this.filesHandlerGetFile = filesHandler.getFile(EFileFolder.Invoice, file.fileId)

            const blob = await this.filesHandlerGetFile.fetch()
            HandleBlob.download(blob, file.name)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            status, items, columns, searchParams,
        } = this.state
        const { me, param } = this.props

        return (
            <main className={styles.index}>
                <Card
                    title={this.tKey('filters')}
                    iconName="FilterSettings"
                >
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.search()
                        }}
                    >
                        <Columns>
                            {!me.clientId && (
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label={this.tKey('client')}
                                        options={param.clients.map(({ key, text }) => ({
                                            key,
                                            text: this.tObj(this.parseJson(text)),
                                        }))}
                                        selectedKey={searchParams.clientId}
                                        disabled={status === Status.PENDING}
                                        onChange={(_ev, option) => this.setState({
                                            searchParams: { ...searchParams, clientId: option.key },
                                        })}
                                    />
                                </Columns.Column>
                            )}
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('invoiceNumber')}
                                    disabled={status === Status.PENDING}
                                    value={searchParams.invoiceNumber ?? ''}
                                    onChange={(ev, newVal) => this.setState({ searchParams: { ...searchParams, invoiceNumber: newVal } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('invoiceDate')}
                                    placeholder={this.tKey('select')}
                                    options={this.monthParams}
                                    selectedKey={searchParams.invoiceDateKey}
                                    onChange={(ev, option) => this.setState({ searchParams: { ...searchParams, invoiceDateKey: option.key } })}
                                />
                            </Columns.Column>
                            {me.clientId && (<Columns.Column size="one-quarter" />)}
                            <Columns.Column size="one-quarter">
                                <div
                                    className={styles['action-filter-buttons']}
                                >
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <DefaultButton
                                            text={this.tKey('research')}
                                            primary
                                            disabled={status === Status.PENDING}
                                            onClick={() => this.submitInput.current.click()}
                                            iconProps={{ iconName: 'Search' }}
                                        />
                                    </div>
                                    <div className={styles.separator} />
                                    <div>
                                        <Label className="is-hidden-mobile">&nbsp;</Label>
                                        <TooltipHost
                                            content={this.tKey('resetFilters')}
                                            directionalHint={DirectionalHint.topAutoEdge}
                                            delay={TooltipDelay.medium}
                                        >
                                            <DefaultButton
                                                disabled={status === Status.PENDING}
                                                styles={{
                                                    root: {
                                                        minWidth: '40px',
                                                        maxWidth: '40px',
                                                    },
                                                }}
                                                iconProps={{ iconName: 'ClearFilter' }}
                                                onClick={() => this.setState({
                                                    /** @type {SearchParamsType} */
                                                    searchParams: {
                                                        invoiceDateKey: 1,
                                                        invoiceNumber: '',
                                                        clientId: null,
                                                    },
                                                })}
                                            />
                                        </TooltipHost>
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>

                        <Columns />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </Card>
                <br />
                <Card>
                    <ShimmeredDetailsList
                        items={items}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (clientProfiles.includes(me.profileId)
                            ? defaultRender(props)
                            : (
                                <Link to={`/invoices/${props.item?.invoiceId}`}>
                                    {defaultRender(props)}
                                </Link>
                            )
                        )}
                    />
                    {!items?.length && [Status.RESOLVED, Status.REJECTED].includes(status)
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                </Card>
            </main>
        )
    }
}

IndexInvoices.prototype.onColumnClick = onColumnClick
IndexInvoices.prototype.parseJson = parseJson
IndexInvoices.prototype.flattenObj = flattenObj
IndexInvoices.prototype.handleRenderColumn = handleRenderColumn
IndexInvoices.prototype.tKey = tKey
IndexInvoices.prototype.tObj = tObj
