import React from 'react'
import styles from 'styles/components/pages/orders/order-list.module.scss'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import Order from 'requests/objects/order'
import Time from 'helpers/methods/time'
import Card from 'components/containers/card'
import parseJson from 'helpers/methods/parseJson'
import { tKey, tObj } from 'helpers/methods/translate'
import { onPageColumnClick } from 'helpers/methods/common'

/**
 * @typedef {object} OrderListProps
 * @property {object} lang Lang
 * @property {Order[]} orders orders
 * @property {(orderBy: string) => void} onOrderByChange onOrderByChange
 * @property {Status} status status
 * @property {boolean} isAdmin isAdmin
 * @augments {React.PureComponent<OrderListProps>}}
 */
export default class OrderList extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            columns: (() => {
                /** @type {import('@fluentui/react').IColumn[]} */
                const cols = [
                    {
                        key: 'orderId',
                        name: this.tKey('remittance'),
                        fieldName: 'orderId',
                        minWidth: 50,
                        maxWidth: 100,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onColumnClick: (ev, column) => this.onPageColumnClick({
                            action: props.onOrderByChange,
                            colName: 'columns',
                        }, ev, column),
                    },
                    {
                        key: 'createdAt',
                        name: this.tKey('createdThe'),
                        fieldName: 'createdAt',
                        minWidth: 80,
                        maxWidth: 150,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (
                                Time(order.createdAt).getCleanDate({
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                })
                            ),
                        onColumnClick: (ev, column) => this.onPageColumnClick({
                            action: props.onOrderByChange,
                            colName: 'columns',
                        }, ev, column),
                    },
                    {
                        key: 'pickupDate',
                        name: this.tKey('pickupDate'),
                        fieldName: 'pickupDateData.pickupDate',
                        minWidth: 80,
                        maxWidth: 150,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (order['pickupDateData.pickupDate'] ? Time(order['pickupDateData.pickupDate']).getCleanDate({
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            }) : ''),
                        onColumnClick: (ev, column) => this.onPageColumnClick({
                            action: props.onOrderByChange,
                            colName: 'columns',
                        }, ev, column),
                    },
                    {
                        key: 'pickupAddressData.address.label',
                        name: this.tKey('pickup'),
                        fieldName: 'pickupAddressData.address.label',
                        minWidth: 150,
                        maxWidth: 400,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (
                                <>
                                    <b>
                                        {order['pickupAddressData.address.label'] || ''}
                                    </b>
                                    <span>
                                        {order['pickupAddressData.address.street'] || ''}
                                        {' '}
                                        {order['pickupAddressData.address.zipCode'] || ''}
                                        {' '}
                                        {order['pickupAddressData.address.city'] || ''}
                                        {' '}
                                        {order['pickupAddressData.address.country.code'] || ''}
                                    </span>
                                </>
                            ),
                    },
                    {
                        key: 'content',
                        name: this.tKey('content2'),
                        fieldName: 'content',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRender: order => {
                            const content = order.deliveryDatasLength > 1
                                ? this.tKey('deliveries')
                                : this.tKey('delivery')

                            return (
                                <span>{`${order.deliveryDatasLength} ${content}`}</span>
                            )
                        },
                    },
                    {
                        key: 'status',
                        name: this.tKey('status'),
                        fieldName: 'orderStatus.name',
                        minWidth: 100,
                        maxWidth: 200,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (
                                <span>
                                    {this.tObj(parseJson(order['orderStatus.name']))}
                                </span>
                            ),

                    },
                ]
                if (!props.isAdmin)
                    return cols
                return /** @type {import('@fluentui/react').IColumn[]} */([
                    {
                        key: 'client',
                        name: this.tKey('client'),
                        fieldName: 'client.name',
                        minWidth: 50,
                        maxWidth: 100,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onColumnClick: (ev, column) => this.onPageColumnClick({
                            action: props.onOrderByChange,
                            colName: 'columns',
                        }, ev, column),
                    },
                    {
                        key: 'operatingCenter',
                        name: this.tKey('operatingCenter'),
                        // fieldName: 'operatingCenter.shortName || operatingCenter.name',
                        minWidth: 50,
                        maxWidth: 100,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (
                                <span>
                                    {order['operatingCenter.shortName'] || order['operatingCenter.name'] || ''}
                                </span>
                            ),
                    },
                    ...cols,
                    {
                        key: 'step',
                        name: this.tKey('step'),
                        fieldName: 'currentWorkflowStep',
                        minWidth: 100,
                        maxWidth: 150,
                        isResizable: true,
                        className: styles['order-list-row-item'],
                        onRender:
                            // eslint-disable-next-line react/no-unstable-nested-components
                            order => (
                                <span>
                                    {this.tObj(parseJson(order.currentWorkflowStepName))}
                                </span>
                            ),
                    },
                ])
            })(),
        }
    }

    render() {
        const { columns } = this.state
        const { orders, status } = this.props

        return (
            <div className={styles['order-list']}>
                <Card>
                    <ShimmeredDetailsList
                        items={orders}
                        detailsListStyles={{
                            root: {
                                selectors: {
                                    '& [role=grid]': {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        height: orders.length ? '52vh' : 'auto',
                                    },
                                },
                            },
                            headerWrapper: {
                                flex: '0 0 auto',
                            },
                            contentWrapper: {
                                flex: '1 1 auto',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            },
                        }}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        onShouldVirtualize={() => true}
                        enableShimmer={status === Status.PENDING}
                        layoutMode={DetailsListLayoutMode.justified}
                        constrainMode={ConstrainMode.unconstrained}
                        onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        onRenderRow={(props, defaultRender) => (
                            <Link to={`/orders/${props.item?.orderId}`}>
                                <div className={styles['order-list-row']}>
                                    {defaultRender(props)}
                                </div>
                            </Link>
                        )}
                    />
                    {!orders.length && status !== Status.PENDING
                        && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{this.tKey('noResultFound')}</Text>}
                </Card>
            </div>
        )
    }
}

OrderList.prototype.tKey = tKey
OrderList.prototype.tObj = tObj
OrderList.prototype.onPageColumnClick = onPageColumnClick
