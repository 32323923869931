import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField,
    Toggle,
} from '@fluentui/react'
import history from 'helpers/history'
import Document, { ErrorDocument } from 'requests/objects/document'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import { tKey, tObj } from 'helpers/methods/translate'
import PreviewFileInput from 'components/pages/home-articles/[id]/previewFileInput'
import FileInput from 'components/inputs/fileInput'
import EFileFolder from 'types/files/enums/fileFolder'
import parseJson from 'helpers/methods/parseJson'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import Time from 'helpers/methods/time'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'

/** @debug {import('app').AppProps} */

/**
 * @augments {PureComponent<import('app').AppProps>}
 */
export default class IdDocuments extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.documentId,
            /** @type {Document} Element find from API */
            item: new Document(),
            /** @type {Document} Initial element fond from API. Used to refresh */
            iniItem: new Document(),
            /** @type {ErrorDocument} Errors */
            errorField: new ErrorDocument(),
            /** @type {globalThis.File} tempFile */
            tempFile: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        const { setMessageBar } = this.props
        setMessageBar({ isDisplayed: false })

        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const {
            match, setCommand, lang, command,
        } = this.props

        if (match?.params?.documentId !== prevProps?.match?.params?.documentId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
            }

        if (lang !== prevProps.lang) {
            setCommand([])
            this.setupBreadcrumb()
            this.setupCommandBar()
            setCommand(command.find(x => x.key === 'read') ? this.commandEdit : this.commandRead)
        }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.documentsHandlerRemoveById?.cancel()
        this.documentsHandlerGetById?.cancel()
        this.documentsHandlerUpsert?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match } = this.props

        setBreadcrumb([
            { text: this.tKey('documents'), key: 'document', href: '/documents' },
            {
                text: match?.params?.documentId ? `${this.tKey('document')} n°${match?.params?.documentId}` : this.tKey('newDocument'),
                key: 'one-document',
                isCurrentItem: true,
            },
        ])
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, setModal, documentsHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = [
            {
                key: 'edit',
                text: this.tKey('edit'),
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: item.documentTypeId !== 1,
            },
        ]

        /**
         * @type {import('@fluentui/react').ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: this.tKey('cancel'),
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    const { iniItem } = this.state
                    if (!match?.params?.documentId)
                        history.push('/documents')
                    else
                        this.setState(
                            {
                                isReadOnly: true,
                                item: iniItem,
                                status: Status.PENDING,
                                errorField: new ErrorDocument(),
                            },
                            () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                                () => setCommand(this.commandRead)),
                        )
                },
            },
            {
                key: 'save',
                text: this.tKey('save'),
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
            {
                key: 'delete',
                text: this.tKey('delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: this.tKey('deleteDocument'),
                        subTitle: this.tKey('deleteDocumentSub'),
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    this.documentsHandlerRemoveById = documentsHandler.removeById(match?.params?.documentId)
                                    await this.documentsHandlerRemoveById.fetch()
                                    history.push('/documents')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: this.tKey('modalDeleteMessage'),
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: !match?.params?.documentId,
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, documentsHandler, setMessageBar,
        } = this.props
        const { item } = this.state

        setCommand([])
        setMessageBar({ isDisplayed: false })
        this.setupBreadcrumb()
        this.setupCommandBar()

        // If there is an id in URL, get element by id
        if (match?.params?.documentId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.document) // If object came from history push with a create
                    this.setState({
                        item: location.state?.document,
                        iniItem: location.state?.document,
                        status: Status.RESOLVED,
                    }, () => {
                        setCommand([])
                        if (location.state?.document?.documentTypeId === 1)
                            setCommand(this.commandRead)
                    })
                else
                    try {
                        this.documentsHandlerGetById = documentsHandler.getById(match?.params?.documentId)
                        const document = await this.documentsHandlerGetById.fetch()
                        this.setState({
                            item: document,
                            iniItem: document,
                            status: Status.RESOLVED,
                        }, () => {
                            setCommand([])
                            if (document?.documentTypeId === 1)
                                setCommand(this.commandRead)
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                if (item?.documentTypeId === 1)
                                    setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new Document(),
                    iniItem: new Document(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, documentsHandler, filesHandler, match, setMessageBar,
        } = this.props
        const { item, tempFile } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.documentsHandlerUpsert = documentsHandler.upsert(item, match?.params?.documentId)
                const document = await this.documentsHandlerUpsert.fetch()

                if (tempFile) {
                    setMessageBar({ isDisplayed: false })
                    this.handlerUploadFile = filesHandler.uploadFile(
                        document?.documentId,
                        EFileFolder.Document,
                        tempFile,
                    )
                    document.file = await this.handlerUploadFile.fetch()
                    setMessageBar({ isDisplayed: true, type: MessageBarType.success, message: 'theElementHasBeenAdded' })
                }

                this.setState({
                    item: document,
                    iniItem: document,
                    status: Status.RESOLVED,
                    errorField: new ErrorDocument(),
                })

                this.setupCommandBar()
                if (document.documentTypeId === 1)
                    setCommand(this.commandRead)

                if (!match?.params?.documentId)
                    history.replace(`/documents/${document.documentId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorDocument>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, tempFile,
        } = this.state
        const { filesHandler, param } = this.props

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main>
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        this.upsert()
                    }}
                >
                    <Card
                        title={this.tKey('document')}
                        iconName="PageData"
                    >
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <Toggle
                                    onText={this.tKey('yes')}
                                    offText={this.tKey('no')}
                                    disabled={isReadOnly}
                                    label={this.tKey('allTheClients')}
                                    checked={item.allTheClients}
                                    onChange={(_ev, checked) => this.setState({ item: { ...item, allTheClients: checked } })}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <FilteredVirtualCombobox
                                    label={this.tKey('clients')}
                                    readOnly={isReadOnly}
                                    options={param.clients.map(x => ({ ...x, text: this.tObj(parseJson(x.text)) }))}
                                    onChange={(ev, option) => this.setState({
                                        item: {
                                            ...item,
                                            clientIds: this.getUpdatedList(item.clientIds, option),
                                        },
                                    })}
                                    selectedKey={item.clientIds}
                                    multiSelect
                                    disabled={item.allTheClients}
                                    errorMessage={this.tObj(this.parseJson(errorField.clientIds))}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('wording')}
                                    placeholder={this.tKey('wording')}
                                    value={item.name ? item.name : ''}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, name: newVal } })}
                                    errorMessage={this.tObj(this.parseJson(errorField.name))}
                                    required={!isReadOnly}
                                />
                            </Columns.Column>
                            {item.documentTypeId === 1 && (
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={this.tKey('documentType')}
                                        placeholder={this.tKey('documentType')}
                                        value={item.typeOfDocument ? item.typeOfDocument : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, typeOfDocument: newVal } })}
                                        errorMessage={this.tObj(this.parseJson(errorField.typeOfDocument))}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                            )}
                        </Columns>
                        <Columns>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('url')}
                                    placeholder={this.tKey('url')}
                                    value={item.url ? item.url : ''}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({ item: { ...item, url: newVal } })}
                                    errorMessage={this.tObj(this.parseJson(errorField.url))}
                                    required={!item.file?.fileId && !isReadOnly}
                                    disabled={!!item.file?.fileId || !!tempFile}
                                    type="url"
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                {item.documentId
                                    ? (
                                        <PreviewFileInput
                                            label={this.tKey('file')}
                                            handler={filesHandler}
                                            fileFolderId={EFileFolder.Document}
                                            entityId={item.documentId}
                                            isReadOnly={isReadOnly}
                                            file={item.file}
                                            updateItem={newFile => this.setState({ item: { ...item, file: newFile } })}
                                            isDisabled={!!item.url}
                                            isRequired={!isReadOnly && !item.url}
                                        />
                                    )
                                    : (
                                        <FileInput
                                            label={this.tKey('file')}
                                            isReadOnly={false}
                                            isDisabled={status === Status.PENDING || !!item.url}
                                            fileName={tempFile?.name}
                                            tooltipContent=""
                                            onDownload={() => null}
                                            onUpload={file => Promise.resolve(this.setState({ tempFile: file }))}
                                            onDelete={() => Promise.resolve(this.setState({ tempFile: null }))}
                                            isRequired={!isReadOnly && !item.url}
                                        />
                                    )}
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('startDate')}
                                    placeholder={this.tKey('startDate')}
                                    value={item.startDate ? Time(item.startDate).getLocaleDateString() : ''}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({
                                        item: {
                                            ...item, startDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '',
                                        },
                                    })}
                                    errorMessage={this.tObj(parseJson(errorField.startDate))}
                                    required={!isReadOnly}
                                    type="date"
                                    max={item.endDate ? Time(item.endDate).getLocaleDateString() : ''}
                                />
                            </Columns.Column>
                            <Columns.Column size="one-quarter">
                                <TextField
                                    label={this.tKey('endDate')}
                                    placeholder={!isReadOnly ? this.tKey('endDate') : ''}
                                    value={item.endDate ? Time(item.endDate).getLocaleDateString() : ''}
                                    readOnly={isReadOnly}
                                    borderless={isReadOnly}
                                    onChange={(ev, newVal) => this.setState({
                                        item: {
                                            ...item, endDate: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '',
                                        },
                                    })}
                                    errorMessage={this.tObj(parseJson(errorField.endDate))}
                                    type="date"
                                    min={item.startDate ? Time(item.startDate).getLocaleDateString() : ''}
                                />
                            </Columns.Column>
                        </Columns>
                        {item.documentTypeId === 2 && (
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={this.tKey('priceList')}
                                        placeholder={this.tKey('priceList')}
                                        value={item.priceListName ? item.priceListName : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, priceListName: newVal } })}
                                        errorMessage={this.tObj(this.parseJson(errorField.priceListName))}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={this.tKey('tariffCode')}
                                        placeholder={this.tKey('tariffCode')}
                                        value={item.priceCode ? item.priceCode : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, priceCode: newVal } })}
                                        errorMessage={this.tObj(this.parseJson(errorField.priceCode))}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={this.tKey('tariffType')}
                                        placeholder={this.tKey('tariffType')}
                                        value={item.priceCode ? item.priceCode : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, priceCode: newVal } })}
                                        errorMessage={this.tObj(this.parseJson(errorField.priceCode))}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label={this.tKey('excelFile')}
                                        placeholder={this.tKey('excelFile')}
                                        value={item.excelFileUrl ? item.excelFileUrl : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, excelFileUrl: newVal } })}
                                        errorMessage={this.tObj(this.parseJson(errorField.excelFileUrl))}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                            </Columns>
                        )}
                    </Card>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={this.submitInput}
                        tabIndex={-1}
                    />
                </form>
            </main>
        )
    }
}

IdDocuments.prototype.getUpdatedList = getUpdatedList
IdDocuments.prototype.tKey = tKey
IdDocuments.prototype.tObj = tObj
IdDocuments.prototype.parseJson = parseJson
