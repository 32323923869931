import React, { PureComponent } from 'react'
import { Columns } from 'react-bulma-components'
import Card from 'components/containers/card'
import { tKey, tObj } from 'helpers/methods/translate'
import Home from 'requests/objects/home'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import Loader from 'components/visuals/loader'
import styles from 'styles/pages/index.module.scss'
import Actuality from 'components/pages/index/actuality'
import Article from 'components/pages/index/article'
import {
    ConstrainMode, DetailsListLayoutMode, PrimaryButton, SelectionMode, ShimmeredDetailsList, Text,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Time from 'helpers/methods/time'
import EProfile from 'types/users/enums/profile'
import flattenObj from 'helpers/methods/flattenObj'
import parseJson from 'helpers/methods/parseJson'
import MaintenancePortalInfo from 'components/pages/maintenancePortalInfo'
import ECompany from 'types/companies/enums/company'
import ChooseOrderType from 'components/modals/choose-order-type'

/** @debug {import('./users').AppProps} */

/**
 * @augments {PureComponent<import('./users').AppProps>}}
 */
export default class Index extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** Item */
            item: new Home(),
            /** @type {import('@fluentui/react').IColumn[]} Columns displayed */
            transportColumns: this.getTransportsColumns(),
            /** Is order modal open */
            /** @type {boolean} Is order modal open */
            isOrderModalOpen: false,
        }
    }

    componentDidMount() {
        const { setMessageBar, setBreadcrumb, setCommand } = this.props

        setMessageBar({ isDisplayed: false })
        setBreadcrumb([
            { text: this.tKey('home'), key: 'accueil', isCurrentItem: true },
        ])
        setCommand([])

        this.search()
    }

    /**
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const {
            setMessageBar, setBreadcrumb, setCommand, lang, me: { companyId },
        } = this.props

        if (lang !== prevProps.lang || companyId !== prevProps.me.companyId) {
            setMessageBar({ isDisplayed: false })
            setBreadcrumb([
                { text: this.tKey('home'), key: 'accueil', isCurrentItem: true },
            ])
            setCommand([])
            this.setState({ transportColumns: this.getTransportsColumns() })
        }
    }

    /**
     * @returns {import('@fluentui/react').IColumn[]} Columns
     */
    getTransportsColumns() {
        const { me, param } = this.props

        if (me.companyId === ECompany.JetFreeze)
            return [{
                key: 'order.orderId',
                name: this.tKey('remittance'),
                fieldName: 'order.orderId',
                minWidth: 40,
                maxWidth: 80,
                isRowHeader: true,
                isResizable: true,
                onRender: item => item.orderId,
            },
            {
                key: 'order.createdAt',
                name: this.tKey('createdAt'),
                fieldName: 'order.createdAt',
                minWidth: 50,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                onRender: item => (item.order?.createdAt
                    ? Time(item.order.createdAt).getCleanDate({ year: '2-digit', month: '2-digit', day: '2-digit' })
                    : ''),
            },
            {
                key: 'order.loading',
                name: this.tKey('loading'),
                fieldName: 'order.loading',
                minWidth: 100,
                maxWidth: 225,
                isRowHeader: true,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => (
                    <span>
                        <b style={{ fontWeight: 'bold' }}>
                            {item.order?.pickupAddressData.address.label || ''}
                        </b>
                        <br />
                        <span>
                            {item.order?.pickupAddressData.address.street || ''}
                            {' '}
                            {item.order?.pickupAddressData.address.zipCode || ''}
                            {' '}
                            {item.order?.pickupAddressData.address.city || ''}
                            {' '}
                            {item.order?.pickupAddressData.address.country?.code || ''}
                        </span>
                    </span>
                ),
            },
            {
                key: 'order.content',
                name: this.tKey('content'),
                fieldName: 'order.content',
                minWidth: 50,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => {
                    if (!item.order?.deliveryDatas?.length)
                        return null
                    const content = item.order?.deliveryDatas?.length > 1
                        ? this.tKey('deliveries')?.toLocaleLowerCase()
                        : this.tKey('delivery')?.toLocaleLowerCase()

                    return (
                        <span>{`${item.order?.deliveryDatas?.length} ${content}`}</span>
                    )
                },
            },
            {
                key: 'order.status',
                name: this.tKey('status'),
                fieldName: 'order.status',
                minWidth: 50,
                maxWidth: 75,
                isRowHeader: true,
                isResizable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                onRender: item => this.tObj(parseJson(item.order?.orderStatus?.name)),
            }]
        return [
            {
                key: 'transportTmsId',
                name: this.tKey('transport'),
                fieldName: 'transportTmsId',
                minWidth: 50,
                maxWidth: 120,
                isResizable: true,
                className: styles['transport-list-row-item'],
            },
            {
                key: 'pickupDate',
                name: this.tKey('pickupDate'),
                minWidth: 80,
                maxWidth: 140,
                isResizable: true,
                className: styles['transport-list-row-item'],
                onRender:
                    // eslint-disable-next-line react/no-unstable-nested-components
                    transport => {
                        const date = transport.pickupDate || transport.pickupScheduledDate
                        if (date)
                            return Time(date).getCleanDate({
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })

                        return ''
                    },
            },
            {
                key: 'pickup',
                name: this.tKey('pickup'),
                minWidth: 150,
                maxWidth: 220,
                isResizable: true,
                className: styles['transport-list-row-item'],
                onRender:
                    // eslint-disable-next-line react/no-unstable-nested-components
                    transport => (
                        <>
                            <b>
                                {transport.pickupAddress?.label || ''}
                            </b>
                            <span>
                                {transport.pickupAddress?.street || ''}
                                {' '}
                                {transport.pickupAddress?.zipCode || ''}
                                {' '}
                                {transport.pickupAddress?.city || ''}
                                {' '}
                                {transport.pickupAddress?.country?.code || ''}
                            </span>
                        </>
                    ),
            },
            {
                key: 'deliveryDate',
                name: this.tKey('deliveryDate'),
                minWidth: 80,
                maxWidth: 140,
                isResizable: true,
                className: styles['transport-list-row-item'],
                onRender:
                    // eslint-disable-next-line react/no-unstable-nested-components
                    transport => {
                        const date = transport.deliveryDate || transport.deliveryScheduledDate
                        if (date)
                            return Time(date).getCleanDate({
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })

                        return ''
                    },
            },
            {
                key: 'delivery',
                name: this.tKey('delivery'),
                minWidth: 150,
                maxWidth: 220,
                isResizable: true,
                className: styles['transport-list-row-item'],
                onRender:
                    // eslint-disable-next-line react/no-unstable-nested-components
                    transport => (
                        <>
                            <b>
                                {transport.deliveryAddress?.label || ''}
                            </b>
                            <span>
                                {transport.deliveryAddress?.street || ''}
                                {' '}
                                {transport.deliveryAddress?.zipCode || ''}
                                {' '}
                                {transport.deliveryAddress?.city || ''}
                                {' '}
                                {transport.deliveryAddress?.country?.code || ''}
                            </span>
                        </>
                    ),
            },
            {
                key: 'status',
                name: this.tKey('status'),
                fieldName: 'status.name',
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                className: styles['transport-list-row-item'],
                onRender: transport => tObj(parseJson(param.workflowTransportSteps.find(x => x.key === transport.currentWorkflowStepId)?.text)),
            },
        ]
    }

    /**
     * Search elements
     */
    search() {
        this.setState({
            status: Status.PENDING,
        }, async () => {
            const { homesHandler } = this.props
            try {
                this.homesHandlerGetAll = homesHandler.getHome()
                const home = await this.homesHandlerGetAll.fetch()
                this.setState({
                    item: home,
                    status: Status.RESOLVED,
                })
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        this.setState({
                            item: new Home(),
                            status: Status.REJECTED,
                        })
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })
    }

    render() {
        const {
            lang, filesHandler, setMessageBar, me,
        } = this.props
        const {
            status, item, transportColumns, isOrderModalOpen,
        } = this.state
        const isJetfreeze = me.companyId === ECompany.JetFreeze

        if (status === Status.PENDING)
            return <Loader />

        return (
            <main className={styles.index}>
                <Columns>
                    <Columns.Column size="two-fifths">
                        <Card
                            title={this.tKey('news')}
                            iconName="Articles"
                        >
                            <div
                                className={styles['card-top-container']}
                                style={{ overflowY: 'auto' }}
                            >
                                {item.homeActualities.map(homeActuality => (
                                    <Actuality
                                        key={homeActuality.homeActualityId}
                                        homeActuality={homeActuality}
                                    />
                                ))}
                            </div>
                        </Card>
                    </Columns.Column>
                    <Columns.Column size="three-fifths">
                        {me.company.isOperational ? (
                            <Card
                                title={this.tKey('lastTransportInGoing')}
                                iconName="Ticket"
                                headerComponent={[EProfile.CustomOrderEntry, EProfile.CustomerAdmin].includes(me.profileId) && me.client?.canCreateOrder
                                    ? (
                                        <PrimaryButton
                                            onClick={() => {
                                                // if (param.deliveryTypes.some(x => x.shortName === 'REP'))
                                                this.setState({ isOrderModalOpen: true })
                                                // else
                                                // history.push(`/orders/${NEW_PATH}`)
                                            }}
                                            styles={{ root: { height: '27px' } }}
                                        >
                                            {this.tKey('newEntry')}
                                        </PrimaryButton>
                                    )
                                    : null}
                                hasDividerMargin={false}
                            >
                                <div className={styles['card-top-container']}>
                                    {((isJetfreeze && item.orderVisitHistorics?.length) || (!isJetfreeze && item.transports?.length))
                                        ? (
                                            <ShimmeredDetailsList
                                                items={isJetfreeze ? item.orderVisitHistorics : item.transports}
                                                columns={transportColumns}
                                                selectionMode={SelectionMode.none}
                                                onShouldVirtualize={() => true}
                                                layoutMode={DetailsListLayoutMode.justified}
                                                constrainMode={ConstrainMode.unconstrained}
                                                // eslint-disable-next-line react/no-unstable-nested-components
                                                onRenderRow={(props, defaultRender) => (
                                                    <Link
                                                        to={isJetfreeze ? `/orders/${props.item?.orderId}` : `transports/${props.item?.transportId}`}
                                                    >
                                                        <div className={styles['transport-list-row']}>
                                                            {defaultRender({
                                                                ...props,
                                                                styles: {
                                                                    isRowHeader: {
                                                                        fontSize: '12px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        flexDirection: 'column',
                                                                    },
                                                                },
                                                            })}
                                                        </div>
                                                    </Link>
                                                )}
                                                detailsListStyles={{
                                                    root: {
                                                        selectors: {
                                                            '& [role=grid]': {
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'start',
                                                                height: '29vh',
                                                            },
                                                        },
                                                    },
                                                    headerWrapper: {
                                                        flex: '0 0 auto',
                                                    },
                                                    contentWrapper: {
                                                        flex: '1 1 auto',
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden',
                                                    },
                                                }}
                                            />
                                        ) : (<Text styles={{ root: { fontStyle: 'italic' } }}>{this.tKey('noResultFound')}</Text>)}
                                </div>
                            </Card>
                        ) : <MaintenancePortalInfo lang={lang} />}
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Card
                            title={this.tKey('homeArticles')}
                            iconName="News"
                        >
                            <div className={styles['card-bottom-container']}>
                                {item.homeArticles.map(homeArticle => (
                                    <Article
                                        key={homeArticle.homeArticleId}
                                        homeArticle={homeArticle}
                                        lang={lang}
                                        handler={filesHandler}
                                        setMessageBar={setMessageBar}
                                    />
                                ))}
                            </div>
                        </Card>
                    </Columns.Column>
                </Columns>
                {!!me.client && (
                    <ChooseOrderType
                        isOrderModalOpen={isOrderModalOpen}
                        orderTypes={me.client.orderTypes}
                        lang={lang}
                        cancel={() => this.setState({ isOrderModalOpen: false })}
                    />

                )}
            </main>
        )
    }
}

Index.prototype.tKey = tKey
Index.prototype.tObj = tObj
Index.prototype.flattenObj = flattenObj
