import React, {
    useEffect, useRef, useState, MutableRefObject, Dispatch, SetStateAction, ReactElement,
} from 'react'
import {
    IComboBoxProps, ITextFieldProps, IComboBoxOption, VirtualizedComboBox,
} from '@fluentui/react'
// eslint-disable-next-line import/named
import FilteredVirtualCombobox, { FilteredVirtualComboboxProps } from 'components/inputs/filteredVirtualCombobox'
// eslint-disable-next-line import/named
import ApiHandler, { RequestApi } from 'requests/apiHandler'

/** @debug {RequestApi|MutableRefObject} */

/**
 * @typedef {object} FilteredVirtualComboboxAjaxProps
 * @property {ApiHandler} handler Handler
 * @property {string} functionName Function name of the Handler, must returns: "string[]" OR "ParamElement[]"
 * @property {number=} minChar Min char to search and clear
 * @property {object=} extraFilters Extra filters to add
 */

/**
 * FilteredVirtualComboboxAjax
 * @example
    <FilteredVirtualComboboxAjax
        label="Mon entitée"
        minChar={1}
        defaultValue={item.entity.name} // Set a default value to display as default on input when readonly
        readOnly={isReadOnly}
        disabled={status === Status.PENDING}
        selectedKey={item.entityId} // Selected key of the item, also used to set defaultValue
        onChange={(_ev, option) => this.setState({ item: { ...item, entityId: option.key } })}
        functionName="searchMyEntity"
        handler={entitiesHandler}
        options={undefined} // Must be set to 'undefined'
        errorMessage={errorField.entityId}
    />
 * @param {FilteredVirtualComboboxAjaxProps & Omit<FilteredVirtualComboboxProps & IComboBoxProps & ITextFieldProps, 'options'>} props Props
 * @returns {ReactElement} Result
 */
export default function FilteredVirtualComboboxAjax({
    handler,
    functionName,
    minChar = 3,
    extraFilters = {},
    ...props
}) {
    /** @type {[{ key: any, text: string }[], Dispatch<SetStateAction<{ key: any, text: string }[]>>]} */
    const [options, setOptions] = useState([])
    /** @type {[IComboBoxOption, Dispatch<SetStateAction<IComboBoxOption>>]} */
    const [selectedOption, setSelectedOption] = useState(null)

    /** @type {MutableRefObject<RequestApi<(string | { key: any, text: string })[]>>} */
    const handlerRequest = useRef(null)

    /** @type {MutableRefObject<NodeJS.Timeout>} */
    const timeout = useRef(null)

    /** @type {MutableRefObject<FilteredVirtualCombobox>} */
    const inputRef = useRef(null)

    useEffect(() => () => {
        handlerRequest.current?.cancel()
    }, [])

    /** Previous option value */
    const prevSelectedOption = useRef(null)

    // Triggger on change if value has changed
    useEffect(() => {
        if (prevSelectedOption.current?.key !== selectedOption?.key)
            props.onChange(null, selectedOption)
    }, [selectedOption, props])

    // Update previous value
    useEffect(() => {
        prevSelectedOption.current = selectedOption
    }, [selectedOption])

    // Apply default value when readonly
    useEffect(() => {
        if (props.defaultValue && props.readOnly)
            setOptions([{ key: props.selectedKey, text: props.defaultValue }])
    }, [props.defaultValue, props.selectedKey, props.readOnly])

    const fetchOption = async (/** @type {{ target: { value: string; }; }} */ event) => {
        if (props.readOnly)
            return
        handlerRequest.current?.cancel()
        const value = /** @type {string} */(event?.target?.value)

        // if (!selectedOption?.text?.includes(value))
        //     props.onChange(event, { key: null, text: '' })

        if (!minChar || value?.length >= minChar) {
            clearInterval(timeout.current)
            handlerRequest.current = handler[functionName]?.(value, extraFilters)
            try {
                const results = await handlerRequest.current?.fetch()
                setOptions(
                    results.every(x => (typeof x === 'string'))
                        ? /** @type {string[]} */(results).map(result => ({ key: result, text: result }))
                        : /** @type {{ key: any, text: string }[]} */(results),
                )
                // @ts-ignore
                // eslint-disable-next-line no-underscore-dangle
                const comboBox = /** @type {VirtualizedComboBox} */(inputRef.current.input._comboBox.current)
                // if (!comboBox.state.isOpen) {
                comboBox.dismissMenu()
                comboBox.focus(true)
                // }
                // eslint-disable-next-line no-empty
            } catch (error) { }
        } else {
            timeout.current = setTimeout(() => {
                setOptions([])
            }, 25)
        }
    }

    return (
        <FilteredVirtualCombobox
            // eslint-disable-next-line react/jsx-props-no-spreading
            {.../** @type {any} */(props)}
            options={options}
            onChange={(/** @type {any} */ _ev, /** @type {React.SetStateAction<IComboBoxOption>} */ option) => setSelectedOption(option)}
            ref={inputRef}
            onKeyUp={ev => (!props.readOnly ? fetchOption(ev) : null)}
            isFilterAllowed={false}
        />
    )
}
