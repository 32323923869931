import { Icon } from '@fluentui/react'
import React from 'react'

/**
 * Icon valid or not
 * @param {object} props props
 * @param {boolean} props.isValid isValid
 * @param {React.CSSProperties} [props.style] style
 * @returns {JSX.Element} Element
 */
function IsValidIcon({ isValid, style = {} }) {
    return (
        <Icon
            style={style}
            iconName={isValid ? 'CheckMark' : 'Clear'}
            styles={{ root: { color: isValid ? '#0b6a0b' : '#a4262c', fontWeight: 'bold', marginLeft: '.5em' } }}
        />
    )
}

export default IsValidIcon
