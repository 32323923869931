import HomeActuality, { ErrorHomeActuality } from 'requests/objects/homeActuality'
import ApiHandler from 'requests/apiHandler'

/**
 * HomeActualitiesHandler
 * @augments {ApiHandler<HomeActuality, ErrorHomeActuality>}
 */
export default class HomeActualitiesHandler extends ApiHandler {
    constructor() {
        super({ type: HomeActuality, errorType: ErrorHomeActuality, key: 'home-actualities' })
    }
}
