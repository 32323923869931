import React, {
    useCallback, useMemo,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, Text, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Status from 'types/status'
import Address from 'requests/objects/address'
import Contact from 'requests/objects/contact'
import styles from 'styles/components/pages/clients/address-list.module.scss'
import EAddressType from 'types/addresses/enums/addressType'
import IsValidIcon from 'components/visuals/is-valid-icon'

/**
 * @callback EditAddressCallback
 * @param {Address} address address
 */

/**
 * @callback DeleteAddressCallback
 * @param {Address} address address
 */

/**
 * @callback AddContactCallback
 * @param {Address} address address
 */

/**
 * @callback EditContactCallback
 * @param {Contact} contact contact
 */

/**
 * @callback SetAddressToDefaultCallback
 * @param {number} addressId addressId
 * @param {number} addressTypeId addressTypeId
 */

/**
 * AddressList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Address[]} props.items items
 * @param {Status} props.status status
 * @param {EditAddressCallback} props.editAddress editAddress
 * @param {DeleteAddressCallback} props.deleteAddress deleteAddress
 * @param {AddContactCallback} props.addContact addContact
 * @param {EditContactCallback} props.editContact editContact
 * @param {SetAddressToDefaultCallback} props.setAddressToDefault setAddressToDefault
 * @param {boolean} [props.isCompany] isCompany
 * @param {boolean} [props.isCustomer] isCompany
 * @returns {JSX.Element} Element
 */
export default function AddressList({
    lang,
    items,
    status,
    editAddress,
    deleteAddress,
    addContact,
    editContact,
    setAddressToDefault,
    isCompany = false,
    isCustomer = false,
}) {
    const { tKey } = useTranslate({ lang })

    const addresses = useMemo(() => items, [items])

    const renderAddressPin = useCallback(
        (addrType, shouldRenderPin = true) => {
            if (isCompany || !addrType)
                return (
                    <div
                        className={styles['address-display-item']}
                    >
                        <IsValidIcon
                            isValid={!!addrType}
                        />
                    </div>
                )
            return (
                <div className={styles['address-display-item-pins']}>
                    <IsValidIcon
                        isValid
                    />
                    {shouldRenderPin && (
                        <IconButton
                            iconProps={{ iconName: addrType.isDefault ? 'Unpin' : 'Pinned' }}
                            title={addrType.isDefault ? tKey('removeDefaultAddress') : tKey('addDefaultAddress')}
                            onClick={() => setAddressToDefault(addrType.addressId, addrType.addressTypeId)}
                        />
                    )}
                </div>
            )
        }, [isCompany, setAddressToDefault, tKey],
    )

    const DeleteButton = useCallback(
        address => !isCustomer && (
            <div className={styles['address-display-item']}>
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={tKey('delete')}
                    onClick={() => deleteAddress(address)}
                />
            </div>
        ), [deleteAddress, isCustomer, tKey],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'address',
            name: tKey('location'),
            fieldName: 'address.label',
            minWidth: 150,
            maxWidth: 450,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: address => (
                <div
                    style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '2em',
                    }}
                >
                    <div className={styles['address-display-item']}>
                        <b>
                            {address.label}
                        </b>
                        <span>
                            {address.street}
                            {' '}
                            {address.zipCode}
                            {' '}
                            {address.city}
                            {' '}
                            {address.country?.code || ''}
                        </span>
                    </div>
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        style={{ alignSelf: 'center' }}
                        title={tKey('edit')}
                        onClick={e => {
                            e.preventDefault()
                            editAddress(address)
                        }}
                    />
                </div>
            ),
        },
        {
            key: 'isVisible',
            name: tKey('isVisible'),
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: address => (
                <div
                    className={styles['address-display-item']}
                >
                    <IsValidIcon
                        isValid={address.isVisible}
                    />
                </div>
            ),
        },
        {
            key: 'contact',
            name: tKey('contact'),
            fieldName: 'address.contact.firstname',
            minWidth: 150,
            maxWidth: 300,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: address => (address.contact ? (
                <div
                    style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '2em',
                    }}
                >
                    <div className={styles['address-display-item']}>
                        <b>
                            {address.contact.firstname || ''}
                            {' '}
                            {address.contact.lastname || ''}
                        </b>
                        <span>
                            {address.contact.email}
                            {', '}
                            +
                            {address.contact.phoneNumber}
                        </span>
                    </div>
                    <IconButton
                        style={{ alignSelf: 'center' }}
                        iconProps={{ iconName: 'EditContact' }}
                        title={tKey('editContact')}
                        onClick={() => editContact(address.contact)}
                    />
                </div>
            ) : (
                <div
                    className={styles['address-display-item']}
                >
                    <IconButton
                        style={{ alignSelf: 'left' }}
                        iconProps={{ iconName: 'AddFriend' }}
                        title={tKey('addContact')}
                        onClick={() => addContact(address)}
                    />
                </div>

            )),
        },
        {
            key: 'isPickup',
            name: tKey('isPickupAddress'),
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            onRender: address => renderAddressPin(address.addressTypeDefaults.find(x => x.addressTypeId === EAddressType.PickUp)),
        },
        {
            key: 'isDelivery',
            name: tKey('isDeliveryAddress'),
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            className: styles['company-list-row'],
            onRender: address => renderAddressPin(address.addressTypeDefaults.find(x => x.addressTypeId === EAddressType.Delivery), false),
        },
        {
            key: 'delete',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: DeleteButton,
        },
    ]), [tKey, DeleteButton, editAddress, editContact, addContact, renderAddressPin])

    return (
        <div className={styles['address-list']}>
            <ShimmeredDetailsList
                items={addresses}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
            />
            {!addresses.length && status !== Status.PENDING
                && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}
        </div>
    )
}
