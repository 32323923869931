import { Icon, Text } from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import HandleBlob from 'helpers/methods/blob'
import React, {
    useCallback, useRef,
} from 'react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import FilesHandler from 'requests/handlers/filesHandler'
import File from 'requests/objects/file'
import styles from 'styles/components/pages/transports/invoice-data.module.scss'
import EFileFolder from 'types/files/enums/fileFolder'

/**
 * CompanyData
 * @param {object} props props
 * @param {File} props.file file
 * @param {string} props.price price
 * @param {object} props.lang Lang
 * @param {FilesHandler} props.handler handler
 * @returns {JSX.Element} Returns
 */
export default function InvoiceData({
    file, price, lang, handler,
}) {
    const { tKey } = useTranslate({ lang })

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const handlerGetFile = useRef(null)

    /**
     * Callback on download file
     */
    const onDownloadFile = useCallback(
        async () => {
            try {
                handlerGetFile.current = handler.getFile(EFileFolder.Invoice, file?.fileId)
                const blob = await handlerGetFile.current.fetch()
                HandleBlob.download(blob, file?.name)
            } catch (error) {
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        }, [file, handler],
    )

    return (
        <div className={styles['invoice-data']}>
            <ul className={styles['invoice-data-numbers']}>
                <li>
                    <Text>{tKey('price')}</Text>
                    <Text>
                        {price}
                        €
                    </Text>
                </li>
                <li>
                    <Text>{tKey('billNumber')}</Text>
                    <Text>{file?.fileId}</Text>
                </li>
            </ul>
            <hr />
            <ul className={styles['invoice-data-files']}>
                <li>
                    <Text onClick={onDownloadFile}>{file?.name}</Text>
                    <Text>
                        <Icon iconName="TextDocument" />
                        {' '}
                        {tKey('billing')}
                    </Text>
                </li>
            </ul>
        </div>
    )
}
