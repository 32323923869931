/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Translations, TranslationsType, TranslationsObjectType } from "types/translations" // eslint-disable-line

/**
 * Translate an element by Translations object
 * Tips, you can use "{{0}}", "{{1}}", etc in your translations file, and replace them with the second arg of this function
 * @param {TranslationsType} key Key to translate
 * @param {{[key: string]: string}=} args Args to replace
 * @returns {string} Result
 */
export function tKey(key, args = {}) {
    let translation = Translations[key]?.[this?.props?.lang || 'en'] ?? key ?? ''

    for (const argKey in args)
        translation = translation.replace(`{{${argKey}}}`, args[argKey])

    return translation
}

/**
 * Translate an element by an object
 * @param {TranslationsObjectType | string} obj Object to translate, default to undifined to use "Translations" object
 * @param {{[key: string]: string}=} args Args to replace
 * @returns {string} Result
 */
export function tObj(obj, args = {}) {
    if (typeof obj === 'string')
        return obj

    let translation = obj?.[this?.props?.lang || 'en'] ?? ''

    for (const argKey in args)
        translation = translation.replace(`{{${argKey}}}`, args[argKey])

    return translation
}
