/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'
import Address from 'requests/objects/address'
import Client from 'requests/objects/client'
import OrderHandling from 'requests/objects/order/orderHandling'
import WorkflowTransportHistoric from 'requests/objects/workflowTransportHistoric'
import OperatingCenter from 'requests/objects/operatingCenter'
import File from 'requests/objects/file'
import WorkflowTransportStep from 'requests/objects/workflowTransportStep'
import OrderType from 'requests/objects/orderType'

/**
 *  Invoice Object
 */
export class Invoice {
    /**
     * @param {object} data data
     * @param {number=} data.transportId transportId
     * @param {string=} data.transportTmsId transportTmsId
     * @param {string=} data.amountExcludingTax amountExcludingTax
     * @param {string=} data.amountIncludingTax amountIncludingTax
     * @param {string=} data.startDate startDate
     * @param {string=} data.deadlineDate deadlineDate
     * @param {number=} data.fileId fileId
     * @param {object=} data.file file
     */
    constructor({
        transportId,
        transportTmsId,
        amountExcludingTax,
        amountIncludingTax,
        startDate,
        deadlineDate,
        fileId,
        file,
    }) {
        this.transportId = transportId
        this.transportTmsId = transportTmsId
        this.amountExcludingTax = amountExcludingTax
        this.amountIncludingTax = amountIncludingTax
        this.startDate = startDate ? new Date(startDate) : undefined
        this.deadlineDate = deadlineDate ? new Date(deadlineDate) : undefined
        this.fileId = fileId
        this.file = file
    }
}

/**
 *  TmsTransportStatus Object
 */
export class TmsTransportStatus {
    /**
     * @param {object} data data
     * @param {number=} data.tmsTransportStatusId transportStatusId
     * @param {string=} data.reasonType reasonType
     * @param {string=} data.eventType eventType
     * @param {string=} data.label label
     * @param {string=} data.subLabel subLabel
     * @param {'normal' | 'error' | 'warning' | 'canceled'=} data.type Status
     */
    constructor({
        tmsTransportStatusId,
        reasonType,
        eventType,
        label,
        subLabel,
        type,
    }) {
        this.tmsTransportStatusId = tmsTransportStatusId
        this.reasonType = reasonType
        this.eventType = eventType
        this.label = label
        this.subLabel = subLabel
        this.type = type
    }
}

/**
 *  TransportStatus Object
 */
export class TransportStatus {
    /**
     * @param {object} data data
     * @param {number=} data.transportStatusId transportStatusId
     * @param {string=} data.transportId transportId
     * @param {number=} data.tmsTransportStatusId tmsTransportStatusId
     * @param {object=} data.tmsTransportStatus tmsTransportStatus
     * @param {string=} data.args args
     * @param {string=} data.date date
     */
    constructor({
        transportStatusId,
        transportId,
        tmsTransportStatusId,
        tmsTransportStatus,
        args,
        date,
    }) {
        this.transportStatusId = transportStatusId
        this.transportId = transportId
        this.tmsTransportStatusId = tmsTransportStatusId
        this.tmsTransportStatus = tmsTransportStatus ? new TmsTransportStatus(tmsTransportStatus) : undefined
        this.args = args
        this.date = date ? new Date(date) : undefined
    }
}

/**
 *  TransportAnomaly Object
 */
export class TransportAnomaly {
    /**
     * @param {object} data data
     * @param {number=} data.transportAnomalyId transportAnomalyId
     * @param {string=} data.transportId transportId
     * @param {string=} data.name name
     * @param {string=} data.reason reason
     */
    constructor({
        transportAnomalyId,
        transportId,
        name,
        reason,
    }) {
        this.transportAnomalyId = transportAnomalyId
        this.transportId = transportId
        this.name = name
        this.reason = reason
    }
}

/**
 *  TransportFile Object
 */
export class TransportFile {
    /**
     * @param {object} data data
     * @param {number=} data.transportFileId transportFileId
     * @param {string=} data.transportId transportId
     * @param {string=} data.name name
     * @param {string=} data.url url
     * @param {string=} data.category category
     * @param {number=} data.fileId fileId
     * @param {object=} data.file file
     */
    constructor({
        transportFileId,
        transportId,
        name,
        url,
        category,
        fileId,
        file,
    }) {
        this.transportFileId = transportFileId
        this.transportId = transportId
        this.name = name
        this.url = url
        this.category = category
        this.fileId = fileId
        this.file = file ? new File(file) : undefined
    }
}

/**
 * Transport Object
 */
export default class Transport extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.transportId transportId
     * @param {string=} data.transportTmsId transportTmsId
     * @param {string=} data.trackingToken trackingToken
     * @param {number=} data.pickupAddressId pickupAddressId
     * @param {object=} data.pickupAddress pickupAddress
     * @param {number=} data.operatingCenterId operatingCenterId
     * @param {object=} data.operatingCenter operatingCenter
     * @param {number=} data.currentWorkflowStepId currentWorkflowStepId
     * @param {object=} data.currentWorkflowStep currentWorkflowStep
     * @param {string=} data.pickupDate pickupDate
     * @param {string=} data.pickupScheduledDate pickupScheduledDate
     * @param {number=} data.deliveryAddressId deliveryAddressId
     * @param {object=} data.deliveryAddress deliveryAddress
     * @param {string=} data.deliveryDate deliveryDate
     * @param {string=} data.deliveryScheduledDate deliveryScheduledDate
     * @param {string=} data.agency agency
     * @param {string=} data.contractor contractor
     * @param {string=} data.transport transport
     * @param {string=} data.remittanceEntry remittanceEntry
     * @param {string=} data.gedmouvRef gedmouvRef
     * @param {object[]=} data.transportFiles transportFiles
     * @param {object[]=} data.transportAnomalies transportAnomalies
     * @param {object[]=} data.transportStatus transportStatus
     * @param {object[]=} data.invoices invoices
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     * @param {number=} data.orderTypeId orderTypeId
     * @param {object=} data.orderType orderType
     * @param {object[]=} data.orderHandlings orderHandlings
     * @param {string=} data.comment comment
     * @param {object[]=} data.workflowTransportHistorics workflowTransportHistoric
     */
    constructor({
        transportId,
        transportTmsId = '',
        trackingToken = '',
        pickupAddressId,
        pickupAddress = {},
        operatingCenterId,
        operatingCenter = {},
        currentWorkflowStepId,
        currentWorkflowStep,
        pickupDate = '',
        pickupScheduledDate = '',
        deliveryAddressId,
        deliveryAddress = {},
        deliveryDate = '',
        deliveryScheduledDate = '',
        agency = '',
        contractor = '',
        transport = '',
        remittanceEntry = '',
        gedmouvRef = '',
        transportFiles = [],
        transportAnomalies = [],
        transportStatus = [],
        invoices = [],
        clientId,
        client = {},
        orderTypeId,
        orderType = {},
        orderHandlings = [],
        comment = '',
        workflowTransportHistorics = [],
        ...data
    }) {
        super(data)
        this.transportId = transportId // : number
        this.transportTmsId = transportTmsId // : string | null
        this.trackingToken = trackingToken // : string | null
        this.pickupAddressId = pickupAddressId // : number | null
        this.pickupAddress = pickupAddress ? new Address(pickupAddress) : undefined
        this.pickupDate = pickupDate ? new Date(pickupDate) : undefined
        this.pickupScheduledDate = pickupScheduledDate ? new Date(pickupScheduledDate) : undefined
        this.deliveryAddressId = deliveryAddressId // : number | null
        this.deliveryAddress = deliveryAddress ? new Address(deliveryAddress) : undefined
        this.operatingCenterId = operatingCenterId // : number | null
        this.operatingCenter = operatingCenter ? new OperatingCenter(operatingCenter) : undefined
        this.currentWorkflowStepId = currentWorkflowStepId // : number | null
        this.currentWorkflowStep = currentWorkflowStep ? new WorkflowTransportStep(currentWorkflowStep) : undefined
        this.deliveryDate = deliveryDate ? new Date(deliveryDate) : undefined
        this.deliveryScheduledDate = deliveryScheduledDate ? new Date(deliveryScheduledDate) : undefined
        this.agency = agency // : string | null
        this.contractor = contractor // : string | null
        this.transport = transport // : string | null
        this.remittanceEntry = remittanceEntry // : string | null
        this.gedmouvRef = gedmouvRef // : string | null
        this.transportFiles = transportFiles?.map(x => new TransportFile(x)) ?? []
        this.transportAnomalies = transportAnomalies?.map(x => new TransportAnomaly(x)) ?? []
        this.transportStatus = transportStatus?.map(x => new TransportStatus(x)) ?? []
        this.invoices = invoices?.map(x => new Invoice(x)) ?? []
        this.clientId = clientId
        this.client = client ? new Client(client) : undefined
        this.orderTypeId = orderTypeId
        this.orderType = orderType ? new OrderType(orderType) : undefined
        this.orderHandlings = orderHandlings?.map(x => new OrderHandling(x)) ?? []
        this.comment = comment
        this.workflowTransportHistorics = workflowTransportHistorics?.map(x => new WorkflowTransportHistoric(x)) ?? []
    }
}
