/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * FileCategory Object
 */
export default class FileCategory extends Base {
    /**
     * @param {object} props props
     * @param {number=} props.fileCategoryId fileCategoryId
     * @param {string=} props.name name
     * @param {object[]=} props.countryToFileCategories countryToFileCategories
     * @param {number=} props.fileCategoryXmlCodeId fileCategoryXmlCodeId
     */
    constructor({
        fileCategoryId = 0,
        name = '',
        countryToFileCategories = [],
        fileCategoryXmlCodeId,
        ...props
    } = {}) {
        super(props)
        this.fileCategoryId = fileCategoryId
        this.name = name
        this.countryToFileCategories = countryToFileCategories
        this.optionalCountriesId = this.countryToFileCategories.filter(x => !x.isMandatory)?.map(x => x.countryId) ?? []
        this.requiredCountriesId = this.countryToFileCategories.filter(x => x.isMandatory)?.map(x => x.countryId) ?? []
        this.fileCategoryXmlCodeId = fileCategoryXmlCodeId
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorFileCategory extends ErrorBase {
    constructor() {
        super()
        this.fileCategoryId = ''
        this.name = ''
        this.optionalCountries = ''
        this.requiredCountriesId = ''
        this.fileCategoryXmlCodeId = ''
    }
}
