import Stock, { ErrorStock } from 'requests/objects/stock'
import ApiHandler from 'requests/apiHandler'

/**
 * StocksHandler
 * @augments {ApiHandler<Stock, ErrorStock>}
 */
export default class StocksHandler extends ApiHandler {
    constructor() {
        super({ type: Stock, errorType: ErrorStock, key: 'stocks' })
    }

    /**
     * Export
     * @returns {import('requests/apiHandler').RequestApi<{blob: Blob, fileName: string}>} Request
     */
    export() {
        const request = this.initFetchRequest({
            url: ['export'],
            method: 'GET',
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => ({
                    fileName: decodeURI(res.headers['content-disposition'])?.split('attachment;filename=')?.[1] ?? 'EXPORT-Stocks.xlsx',
                    blob: new Blob([res.data]),
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
