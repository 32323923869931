import React, { useRef, useState } from 'react'
import {
    Label, Text,
} from '@fluentui/react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// @ts-ignore
import fr from 'react-phone-input-2/lang/fr.json'
import styles from 'styles/components/inputs/phone-field.module.scss'
import classNames from 'classnames'

/**
 * @typedef {object} PhoneFieldProps
 * @property {import('react-phone-input-2').PhoneInputProps['country']=} defaultCountry defaultCountry
 * @property {string=} lang lang
 */

/**
 * Phone Field
 * @param {PhoneFieldProps & import('@fluentui/react').ITextFieldProps} props props
 * @returns {JSX.Element} Element
 */
export default function PhoneField({
    defaultCountry = 'fr',
    lang = 'fr',
    ...props
}) {
    const {
        required, disabled, value, label, onChange, borderless, readOnly, errorMessage, placeholder,
    } = props

    const [isFocus, setIsFocus] = useState(false)

    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const ref = useRef()

    return (
        <div
            className={classNames(
                styles['ms-TextField'],
                { [styles['ms-TextField--borderless']]: borderless },
                { [styles['is-required']]: required },
                { [styles['is-disabled']]: disabled },
                { [styles['is-error']]: errorMessage && !borderless },
            )}
        >
            <div className={styles['ms-TextField-wrapper']}>
                {label && (
                    <Label required={required && !readOnly}>
                        {label}
                    </Label>
                )}
                <div
                    className={classNames(
                        styles['ms-TextField-fieldGroup'],
                        { [styles['is-focused']]: isFocus },
                        { [styles['is-disabled']]: disabled },
                    )}
                    ref={ref}
                >
                    <PhoneInput
                        country={defaultCountry}
                        localization={lang === 'fr' ? fr : undefined}
                        value={value}
                        placeholder={placeholder}
                        onChange={(newVal, _, ev) => onChange(ev, newVal)}
                        disabled={disabled}
                        buttonStyle={{
                            border: 'none',
                            pointerEvents: readOnly ? 'none' : undefined,
                        }}
                        inputStyle={{
                            maxHeight: '100%',
                        }}
                        inputProps={{
                            readOnly,
                        }}
                        dropdownStyle={{
                            width: `${ref.current?.clientWidth || 225}px`,
                        }}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                    />
                </div>
            </div>
            {
                errorMessage && !disabled && !readOnly
                && (
                    <div className={styles['error-message']}>
                        <Text>
                            {errorMessage}
                            {' '}
                        </Text>
                    </div>
                )
            }
        </div>
    )
}
