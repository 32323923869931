import React from 'react'
import styles from 'styles/components/pages/transports/transport-card-list.module.scss'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    List, Text,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import Time from 'helpers/methods/time'
import Card from 'components/containers/card'
import Transport from 'requests/objects/transport'
import Loader from 'components/visuals/loader'
import classNames from 'classnames'

/**
 * TransportList
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {Transport[]} props.transports transports
 * @param {Status} props.status status
 * @returns {JSX.Element} Element
 */
export default function TransportListCard({
    lang, transports, status,
}) {
    const { tKey } = useTranslate({ lang })

    if (status === Status.PENDING)
        return <Loader />

    return (
        <div className={styles['transport-card-list']}>
            <Card>
                <List
                    items={transports}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderCell={(item, j) => (
                        <>
                            <TransportCardListRow
                                key={j}
                                item={item}
                                lang={lang}
                            />
                            {j !== transports.length - 1 && <hr />}
                        </>
                    )}
                />
                {!transports.length && status !== Status.PENDING
                    && <Text styles={{ root: { fontStyle: 'italic', marginLeft: '1em' } }}>{tKey('noResultFound')}</Text>}
            </Card>
        </div>
    )
}

/**
 * TransportCardListRow
 * @param {object} props props
 * @param {Transport} props.item item
 * @param {object} props.lang Lang
 * @returns {JSX.Element} JSX.Element
 */
export function TransportCardListRow({ item, lang }) {
    const { tKey } = useTranslate({ lang })

    return (
        <Link
            className={classNames(
                styles['transport-card-list-row'],
            )}
            to={`/transports/${item?.transportId}`}
        >
            <div>
                <div className={styles['transport-card-list-row-column']}>
                    <Text>
                        {tKey('transport')}
                        {' '}
                        {item.transportId}
                        {' ● '}
                        {tKey('createdThe')}
                        {' '}
                        {Time(item.createdAt).getCleanDate({
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        })}
                    </Text>
                </div>
                <div className={styles['transport-card-list-row-column']}>
                    <Text>...</Text>
                </div>
            </div>
            <div>
                <div className={styles['transport-card-list-row-column']}>
                    <Text>{item['pickupAddress.label'] || ''}</Text>
                    <Text>
                        <span>
                            {item['pickupAddress.street'] || ''}
                        </span>
                    </Text>
                    <Text>
                        <span>
                            {' '}
                            {item['pickupAddress.zipCode'] || ''}
                            {' '}
                            {item['pickupAddress.city'] || ''}
                            {' '}
                            {item['pickupAddress.country.code'] || ''}
                        </span>
                    </Text>
                </div>
                <div className={styles['transport-card-list-row-column']}>
                    <Text>{item['deliveryAddress.label'] || ''}</Text>
                    <Text>
                        <span>
                            {item['deliveryAddress.street'] || ''}
                        </span>
                    </Text>
                    <Text>
                        <span>
                            {item['deliveryAddress.zipCode'] || ''}
                            {' '}
                            {item['deliveryAddress.city'] || ''}
                            {' '}
                            {item['deliveryAddress.country.code'] || ''}
                        </span>
                    </Text>
                </div>
            </div>
        </Link>
    )
}
