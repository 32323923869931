import React, { PureComponent } from 'react'
import { Columns } from 'react-bulma-components'
import { Text, MessageBar } from '@fluentui/react'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import styles from 'styles/pages/_publicLayout.module.scss'
import Card from 'components/containers/card'
import { tKey, tObj } from 'helpers/methods/translate'

/** @debug {AppProps} */

/**
 * @typedef {object} PublicLayoutProps
 * @property {boolean} isDisplay Is layout is displayed
 * @augments {PureComponent<AppProps & PublicLayoutProps>}}
 */
export default class PublicLayout extends PureComponent {
    render() {
        const {
            isDisplay, children, messageBar, setMessageBar,
        } = this.props

        if (!isDisplay)
            return children

        return (
            <section
                className={styles.layout}
                // eslint-disable-next-line global-require
                style={{ background: `url(${require('assets/img/bg.png').toString()}), white` }}
            >
                <Columns>
                    <Columns.Column className="is-hidden-touch" />

                    <Columns.Column>
                        <div className="flex-col">
                            <Card
                                className="has-text-centered"
                            >
                                <img
                                    // eslint-disable-next-line global-require
                                    src={require('assets/img/logo.png').toString()}
                                    alt={process.env.REACT_APP_SHORT_NAME}
                                    style={{ filter: 'drop-shadow(rgb(0 0 0 / 24%) 0px 3px 8px)' }}
                                />
                                <br />
                                <Text
                                    variant="xxLarge"
                                    block
                                >
                                    {process.env.REACT_APP_NAME}
                                </Text>
                                <br />

                                {
                                    messageBar.isDisplayed
                                    && (
                                        <>
                                            <MessageBar
                                                messageBarType={messageBar.type}
                                                isMultiline={false}
                                                truncated
                                                onDismiss={() => setMessageBar({ isDisplayed: false })}
                                            >
                                                {(typeof messageBar.message === 'string'
                                                    ? this.tKey(/** @type {any} */(messageBar.message))
                                                    : this.tObj(/** @type {any} */(messageBar.message)))
                                                    || messageBar.message?.toString()}
                                            </MessageBar>
                                            <br />
                                        </>
                                    )
                                }
                                {children}
                            </Card>
                        </div>
                    </Columns.Column>
                    <Columns.Column className="is-hidden-touch" />
                </Columns>
            </section>
        )
    }
}

PublicLayout.prototype.tKey = tKey
PublicLayout.prototype.tObj = tObj
