import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step.module.scss'
import Card from 'components/containers/card'
import useTranslate from 'helpers/hooks/useTranslate'
import Order from 'requests/objects/order'
import { ErrorDeliveryData } from 'requests/objects/order/deliveryData'
import {
    DefaultButton, Label, PrimaryButton, Text, TextField, Toggle,
} from '@fluentui/react'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import DetailInfo from 'components/pages/orders/[id]/client/common/detailInfo'
import { Columns } from 'react-bulma-components'
import DeliveryStepContent from 'components/pages/orders/[id]/client/deliveryStep/content'
import AdvancedFileInput from 'components/pages/orders/[id]/client/deliveryStep/advancedFileInput'
import DeliveryStepFileList from 'components/pages/orders/[id]/client/deliveryStep/fileList'
import Param from 'requests/objects/param'
import AddressesHandler from 'requests/handlers/addressesHandler'
import AddressModal from 'components/pages/addressModal'
import { getUpdatedList, isValidDate } from 'helpers/methods/common'
import Time from 'helpers/methods/time'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import OrderHandling from 'requests/objects/order/orderHandling'
import EAddressType from 'types/addresses/enums/addressType'
import ContactModal from 'components/pages/contactModal'
import Address from 'requests/objects/address'
import InstructionModal from 'components/pages/orders/[id]/client/common/instructionsModal'
import Contact from 'requests/objects/contact'
import FilesHandler from 'requests/handlers/filesHandler'
import ComfourDataModal from 'components/pages/orders/[id]/client/deliveryStep/comfourDataModal'
import parseJson from 'helpers/methods/parseJson'
import EProfile from 'types/users/enums/profile'
import TextFieldWithTooltip from 'components/inputs/textFieldWithTooltip'
import CompanyRule from 'requests/objects/companyRule'
import OpenWeekDays from 'components/pages/orders/[id]/client/common/openWeekDays'
import DeliveryTime from 'requests/objects/deliveryTime'
import OpenDays from 'requests/objects/openDays'
import DatePicker from 'components/inputs/datePicker'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import useCompany from 'helpers/hooks/useCompany'
import OrderSubHandling from 'requests/objects/order/orderSubHandling'
import Department from 'requests/objects/department'
import usePrevious from 'helpers/hooks/usePrevious'
import EdeliveryDateType from 'types/deliveryDateType/enums/deliveryDateType'
import EOrderType from 'types/orders/enums/orderType'

/**
 * get week number
 * @param {Date} date date
 * @returns {number} week number
 */
// eslint-disable-next-line no-unused-vars
// function getWeekNumber(date) {
//     const dt = new Date(date)
//     const firstDayOfYear = new Date(dt.getFullYear(), 0, 1)
//     const days = Math.floor((dt.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000)) + 1
//     const week = Math.ceil(days / 7)

//     if (week > 52)
//         return week % 52
//     return week
// }
/**
 * PickupStep
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Order} props.item Item
 * @param {CompanyRule} props.companyRules companyRules
 * @param {boolean} props.isReadOnly isReadOnly
 * @param {ErrorDeliveryData} props.errorField errorField
 * @param {(order: Partial<Order>) => void} props.onChange onChange
 * @param {Param} props.param param
 * @param {AddressesHandler} props.addressesHandler AddressesHandler
 * @param {number} props.deliveryIndex deliveryIndex
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.editParam editParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.addParam addParam
 * @param {(arg: import('redux/slices/user').PayloadParam) => void} props.removeParam removeParam
 * @param {FilesHandler} props.filesHandler AddressesHandler
 * @param {number} props.profileId profileId
 * @returns {JSX.Element} Element
 */
export default function DeliveryStep({
    lang, item, companyRules, isReadOnly, errorField, onChange, param, addressesHandler, deliveryIndex, editParam, addParam, removeParam,
    filesHandler, profileId,
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
    const [isContactModalVisible, setIsContactModalVisible] = useState(false)
    const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false)
    const [isComfourModalDataVisible, setIsComfourModalDataVisible] = useState(false)
    const [addressModalData, setAddressModalData] = useState(new Address({ addressTypeIds: [EAddressType.Delivery] }))
    // const [status, setStatus] = useState(Status.IDLE)
    const { isJetFreeze, isPineau, isRaud } = useCompany({ companyId: companyRules.companyId })

    const [department, setDepartment] = useState(null)
    const {
        deliveryAddressData, deliveryComplementData, deliveryDateData, deliveryInstructionsData, deliveryContentData, deliveryFileData,
    } = useMemo(() => (item.deliveryDatas[deliveryIndex]), [item.deliveryDatas, deliveryIndex])

    const previousAddressId = usePrevious(deliveryAddressData.addressId)

    const [deliveryWeeks, minDate] = useMemo(() => {
        if (!item.pickupDateData.pickupDate)
            return []

        const weeks = Array(4).fill().map((_, i) => {
            const dt = new Date(item.pickupDateData.pickupDate)
            const index = i
            const firstDayOfYear = new Date(dt.getFullYear(), 0, 1)
            dt.setDate(dt.getDate() + (index * 7))
            const days = Math.floor((dt.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000)) + 1
            let week = Math.ceil(days / 7)
            if (week > 52)
                week %= 52

            const first = dt.getDate() - dt.getDay() + 1
            const last = first + 6

            const minimumDate = new Date(dt.setDate(first))
            const maxDate = new Date(dt.setDate(last))

            // there is a bug where maxDate month is not updated
            if (maxDate < minimumDate)
                maxDate.setMonth(maxDate.getMonth() + 1)

            return {
                key: week,
                text: { fr: `Semaine ${week}`, en: `Week ${week}`, nl: `Week ${week}` },
                minDate: minimumDate,
                maxDate,
            }
        })
        const returnWeeks = item.client.deliveryDateTypeId === EdeliveryDateType.WeekPlusOne ? weeks.slice(1, 4) : weeks.slice(0, 3)

        return [returnWeeks, Time(item.pickupDateData.pickupDate).add(item.client.minDays, 'day')]
    }, [item.client.deliveryDateTypeId, item.client.minDays, item.pickupDateData.pickupDate])

    const isAddressReadOnly = useMemo(() => {
        if (item.isAddressValidation)
            return ![EProfile.Admin, EProfile.SuperAdmin, EProfile.Commercial].includes(profileId)
        if (item.isOperatorsValidation)
            return true
        return isReadOnly
    }, [isReadOnly, item.isAddressValidation, item.isOperatorsValidation, profileId])

    const onUpdate = useCallback(
        /**
         * @param {Partial<
         * Order['deliveryDatas'][0]['deliveryAddressData']
         * | Order['deliveryDatas'][0]['deliveryComplementData']
         * | Order['deliveryDatas'][0]['deliveryDateData']
         * | Order['deliveryDatas'][0]['deliveryInstructionsData']
         * | Order['deliveryDatas'][0]['deliveryContentData']
         * | Order['deliveryDatas'][0]['deliveryFileData']
         * >} data Data
         * @param {'deliveryAddressData'
         * | 'deliveryComplementData'
         * | 'deliveryDateData'
         * | 'deliveryInstructionsData'
         * | 'deliveryContentData'
         * | 'deliveryFileData'} key Key
         */
        (data, key) => {
            switch (key) {
                case 'deliveryAddressData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryAddressData: { ...deliveryAddressData, ...data } })
                            : delData)),
                    })
                    break
                case 'deliveryComplementData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryComplementData: { ...deliveryComplementData, ...data } })
                            : delData)),
                    })
                    break
                case 'deliveryDateData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryDateData: { ...deliveryDateData, ...data } })
                            : delData)),
                    })
                    break
                case 'deliveryInstructionsData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryInstructionsData: { ...deliveryInstructionsData, ...data } })
                            : delData)),
                    })
                    break
                case 'deliveryContentData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryContentData: { ...deliveryContentData, ...data } })
                            : delData)),
                    })
                    break
                case 'deliveryFileData':
                    onChange({
                        deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                            ? ({ ...delData, deliveryFileData: { ...deliveryFileData, ...data } })
                            : delData)),
                    })
                    break
                default:
                    break
            }
        },
        [
            onChange,
            item.deliveryDatas,
            deliveryIndex,
            deliveryAddressData,
            deliveryComplementData,
            deliveryDateData,
            deliveryInstructionsData,
            deliveryContentData,
            deliveryFileData,
        ],
    )

    const previousDeliveryTypeId = usePrevious(deliveryInstructionsData?.deliveryTypeId)

    const [isComfourTypeSelected, isRdvTypeSelected, isOtTypeSelected] = useMemo(
        () => {
            const deliveryType = param.deliveryTypes.find(x => x.key === deliveryInstructionsData.deliveryTypeId)
            return [
                deliveryType?.hasComfourDataRequired,
                deliveryType?.hasRdvRequired,
                deliveryType?.hasOtNumberRequired,
            ]
        },
        [deliveryInstructionsData.deliveryTypeId, param.deliveryTypes],
    )

    const storeData = useMemo(() => {
        if (!deliveryInstructionsData.comfourData?.storeId)
            return null
        const stores = param.stores.filter(store => +store.key === deliveryInstructionsData.comfourData.storeId)
        if (stores.length)
            return {
                name: stores[0].text,
                storeNumber: stores[0].storeNumber,
            }

        return null
    }, [param.stores, deliveryInstructionsData.comfourData?.storeId])
    useEffect(() => {
        if (
            !deliveryInstructionsData.comfourData
            && deliveryInstructionsData.deliveryTypeId
            && previousDeliveryTypeId !== deliveryInstructionsData.deliveryTypeId
            && isComfourTypeSelected
        )
            setIsComfourModalDataVisible(true)
    }, [deliveryInstructionsData.comfourData, deliveryInstructionsData.deliveryTypeId, isComfourTypeSelected, previousDeliveryTypeId])

    const addOrderHandling = useCallback(() => {
        const vraSupport = param.supports.find(x => x.subText === 'VRA')
        const supportId = vraSupport ? +vraSupport.key : null
        onUpdate({
            orderHandlings: [...deliveryContentData.orderHandlings, new OrderHandling({
                supportId: isRaud ? supportId : null,
                orderSubHandlings: isRaud ? [new OrderSubHandling({ quantity: 1 })] : [],
            })],
        }, 'deliveryContentData')
    }, [deliveryContentData.orderHandlings, isRaud, onUpdate, param.supports])

    /** @type {React.MutableRefObject<RequestApi<Address>>} */
    const getAddressHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<Department>>} */
    const getDepartmentHandler = useRef()

    /** @type {React.MutableRefObject<RequestApi<DeliveryTime>>} */
    const getDeliveryTimeHandler = useRef()

    /** Files missing */
    const missingFiles = useMemo(() => {
        const files = param.fileCategories
            .filter(fileCategory => deliveryFileData.files.findIndex(file => file.fileCategoryId === fileCategory.key) === -1
                && (fileCategory.requiredCountriesId.includes(deliveryAddressData.address?.countryId)
                    || (isJetFreeze && +fileCategory.storeNumber === 7 && deliveryInstructionsData.isAgainstReimbursement))) // ad valorem

        if (!files.length)
            return null
        return (
            <Text variant="smallPlus">
                {tKey('someFilesAreMissing')}
                {': '}
                {files.map(file => tObj(parseJson(file.text))).join(', ')}
            </Text>
        )
    }, [param.fileCategories, tKey, deliveryFileData.files, deliveryAddressData.address?.countryId,
        isJetFreeze, deliveryInstructionsData.isAgainstReimbursement, tObj])

    // const fileCategories = useMemo(() => param.fileCategories
    //     .filter(fileCategory => fileCategory.requiredCountriesId.includes(deliveryAddressData.address?.countryId)
    //         || fileCategory.optionalCountriesId.includes(deliveryAddressData.address?.countryId)
    //         || (isJetFreeze && +fileCategory.storeNumber === 2 && deliveryInstructionsData.isAgainstReimbursement))
    //     .map(fileCategory => ({
    //         id: /** @type {number} */(fileCategory.key),
    //         text: tObj(parseJson(fileCategory.text)),
    //         disabled: deliveryFileData.files.findIndex(file => file.fileCategoryId === fileCategory.key) > -1,
    //     })), [
    //     deliveryAddressData.address?.countryId,
    //     deliveryFileData.files,
    //     deliveryInstructionsData.isAgainstReimbursement,
    //     isJetFreeze,
    //     param.fileCategories,
    //     tObj,
    // ])

    const fileCategories = useMemo(() => param.fileCategories
        .map(fileCategory => ({
            id: /** @type {number} */(fileCategory.key),
            text: tObj(parseJson(fileCategory.text)),
            disabled: false,
        })), [param.fileCategories, tObj])

    const openDays = useMemo(() => new OpenDays({
        monday: department?.mondayDelivery,
        tuesday: department?.tuesdayDelivery,
        wednesday: department?.wednesdayDelivery,
        thursday: department?.thursdayDelivery,
        friday: department?.fridayDelivery,
        saturday: department?.saturdayDelivery,
        sunday: department?.sundayDelivery,
    }), [department])

    const deliveryTypesOption = useMemo(() => {
        let options = param.deliveryTypes

        switch (item.orderTypeId) {
            case EOrderType.Reprise:
                options = options.filter(x => x.shortName === 'REP')
                break
            case EOrderType.Redelivery:
                options = options.filter(x => x.shortName === 'REL')
                break
            case EOrderType.Delivery:
            case EOrderType.DocksideShed:
                options = options.filter(x => x.shortName !== 'REP' && x.shortName !== 'REL')
                break
            default:
                break
        }

        return options.map(x => ({ ...x, text: `${x.shortName} - ${tObj(parseJson(x.text))}` }))
    }, [item.orderTypeId, param.deliveryTypes, tObj])

    // update transport plan data from address id
    useEffect(() => {
        if (isJetFreeze && previousAddressId !== deliveryAddressData.addressId)
            if (deliveryAddressData.addressId) {
                getDepartmentHandler.current = addressesHandler.getDepartement(deliveryAddressData.addressId)
                getDeliveryTimeHandler.current = addressesHandler.getDeliveryTime(item.pickupAddressData.addressId, deliveryAddressData.addressId)

                Promise.all([
                    getDepartmentHandler.current.fetch(),
                    getDeliveryTimeHandler.current.fetch(),
                ])
                    .then(([dep, deliveryTime]) => {
                        setDepartment(dep)
                        onUpdate({ deliveryTime: deliveryTime.time }, 'deliveryDateData')
                    })
                    .catch(() => {
                        setDepartment(null)
                    })
            } else {
                setDepartment(null)
                onUpdate({ deliveryTime: null }, 'deliveryDateData')
            }
    }, [addressesHandler, previousAddressId, deliveryAddressData, deliveryAddressData.addressId, isJetFreeze, item.pickupAddressData.addressId, onUpdate])

    const setDeliveryAddressDataByAddressId = useCallback(async addressId => {
        try {
            // setStatus(Status.PENDING)
            if (!addressId) {
                onChange({
                    deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                        ? ({
                            ...delData,
                            deliveryAddressData: {
                                ...deliveryAddressData,
                                address: undefined,
                                addressId: null,
                            },
                        })
                        : delData)),
                })
                return
            }
            getAddressHandler.current = addressesHandler.getById(addressId)
            const address = await getAddressHandler.current.fetch()
            onChange({
                deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                    ? ({
                        ...delData,
                        deliveryAddressData: {
                            ...deliveryAddressData,
                            address,
                            addressId: address?.addressId,
                        },
                        deliveryInstructionsData: {
                            ...deliveryInstructionsData,
                            accessibilityId: address?.accessibilityId ? address.accessibilityId : delData.deliveryInstructionsData.accessibilityId,
                            accessibilityIds: address?.accessibilityIds ? address.accessibilityIds : [],
                            vehicleEquipmentId: address?.vehicleEquipmentId ? address.vehicleEquipmentId : delData.deliveryInstructionsData.vehicleEquipmentId,
                        },
                    })
                    : delData)),
            })

            // setStatus(Status.RESOLVED)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
            // setStatus(Status.REJECTED)
        }
    }, [addressesHandler, deliveryAddressData, deliveryIndex, deliveryInstructionsData, item.deliveryDatas, onChange])

    // useEffect(() => {
    //     if (status !== Status.PENDING && param.defaultDeliveryAddressId && !deliveryAddressData.addressId)
    //         setDeliveryAddressDataByAddressId(param.defaultDeliveryAddressId)
    // }, [deliveryAddressData.addressId, param.defaultDeliveryAddressId, setDeliveryAddressDataByAddressId, status])

    useEffect(() => () => {
        getAddressHandler?.current?.cancel()
        getDepartmentHandler?.current?.cancel()
        getDeliveryTimeHandler?.current?.cancel()
    }, [])

    // set default delivery week if delivery date type is week + 1
    useEffect(() => {
        if (deliveryWeeks.length && item.client.deliveryDateTypeId === EdeliveryDateType.WeekPlusOne && !deliveryDateData.desiredWeekNumber)
            onUpdate({
                desiredWeekNumber: deliveryWeeks[0].key,
            }, 'deliveryDateData')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryWeeks, item.client.deliveryDateTypeId])

    const { address } = deliveryAddressData

    const contact = address?.contact ? address.contact : null

    const optionAddresses = useMemo(() => {
        switch (item.orderTypeId) {
            case EOrderType.Reprise:
                // only pickup addresses and no platforms
                return param.addresses.filter(addr => !addr.isPlatform && addr.types.includes(EAddressType.PickUp))
            case EOrderType.Redelivery:
            case EOrderType.Delivery:
            case EOrderType.DocksideShed:
            default:
                // only delivery addresses and no platforms
                return param.addresses.filter(addr => !addr.isPlatform && addr.types.includes(EAddressType.Delivery))
        }
    }, [item.orderTypeId, param.addresses])

    // const isLadDeliveryType = useMemo(() => {
    //     const deliveryType = param.deliveryTypes.find(x => x.key === deliveryInstructionsData.deliveryTypeId)
    //     return deliveryType && ['LAD', 'LDD'].includes(deliveryType.shortName)
    // }, [deliveryInstructionsData.deliveryTypeId, param.deliveryTypes])

    const deliveryType = useMemo(() => param.deliveryTypes.find(x => x.key === deliveryInstructionsData.deliveryTypeId),
        [deliveryInstructionsData.deliveryTypeId, param.deliveryTypes])

    return (
        <>
            <div className={styles['delivery-step']}>
                <div className={styles['delivery-step-left']}>
                    <div className={styles['delivery-step-left-address']}>
                        <Card
                            title={tKey('address')}
                            iconName="CityNext"
                            hasLitleDividerMarging
                        >
                            <Columns>
                                <Columns.Column size="half">
                                    <FilteredVirtualCombobox
                                        label={tKey('deliveryAddress')}
                                        placeholder={item.deliveryDatas[deliveryIndex]?.deliveryAddressData?.address?.label ?? tKey('research')}
                                        options={optionAddresses}
                                        selectedKey={deliveryAddressData.addressId}
                                        readOnly={isReadOnly || !item.isCreation}
                                        borderless={isReadOnly || !item.isCreation}
                                        onChange={(ev, option) => {
                                            if (!option.key || (option.key && option.index >= 0))// if key exist and index is undefined dont do anything
                                                setDeliveryAddressDataByAddressId(option.key)
                                        }}
                                        required={!isReadOnly && item.isCreation}
                                    />
                                </Columns.Column>
                                <Columns.Column
                                    size="half"
                                    style={{ textAlign: 'right' }}
                                >
                                    <Label>&#8203;</Label>
                                    {!isAddressReadOnly && item.isCreation && !(isRaud && item.orderTypeId === EOrderType.Reprise) && (
                                        <DefaultButton
                                            text={tKey('addAddress')}
                                            disabled={isReadOnly}
                                            onClick={() => {
                                                const [startTime, endTime] = isPineau ? ['08:00', '18:00'] : ['00:00', '23:59']

                                                setAddressModalData(new Address({ addressTypeIds: [EAddressType.Delivery], startTime, endTime }))
                                                setIsAddressModalVisible(true)
                                            }}
                                            iconProps={{ iconName: 'Add' }}
                                        />
                                    )}
                                    {!isAddressReadOnly && item.isAddressValidation && (
                                        <DefaultButton
                                            text={tKey(deliveryAddressData.address.isValidated ? 'validatedAddress' : 'validateAddress')}
                                            disabled={deliveryAddressData.address.isValidated}
                                            onClick={() => {
                                                setAddressModalData(new Address({ addressId: address.addressId }))
                                                setIsAddressModalVisible(true)
                                            }}
                                            iconProps={{ iconName: 'CheckMark' }}
                                        />
                                    )}
                                </Columns.Column>
                            </Columns>
                            {address?.addressId && (
                                <>
                                    <DetailInfo
                                        onEdit={() => {
                                            setAddressModalData(new Address({ addressId: address.addressId }))
                                            setIsAddressModalVisible(true)
                                        }}
                                        isReadOnly={isAddressReadOnly || deliveryAddressData.address.isPlatform}
                                        style={{ marginTop: '-1rem' }}
                                        lang={lang}
                                    >
                                        <b>{address.label}</b>
                                        <br />
                                        {[
                                            address.street,
                                            address.zipCode,
                                            address.city,
                                            address.country?.code,
                                        ].filter(x => x).join(', ')}
                                    </DetailInfo>
                                    <DetailInfo
                                        onEdit={() => setIsInstructionsModalVisible(true)}
                                        label={tKey('deliveryInstructions')}
                                        isReadOnly={isAddressReadOnly || deliveryAddressData.address.isPlatform}
                                        lang={lang}
                                        infoBubbleText={tKey('helperBubbleInstruction')}
                                    >
                                        {address.instructions || (isReadOnly && tKey('noInstruction')) || ''}
                                    </DetailInfo>
                                    <Columns>
                                        <Columns.Column size="half">
                                            <TextFieldWithTooltip
                                                label={tKey('deliveryRequirement')}
                                                placeholder={isReadOnly ? tKey('noRequirement') : tKey('writeAnImperative')}
                                                value={deliveryAddressData.requirement}
                                                onChange={(_ev, newVal) => onUpdate({
                                                    requirement: newVal?.length > 255 ? newVal.substring(0, 255) : newVal,
                                                }, 'deliveryAddressData')}
                                                errorMessage={tObj(errorField['deliveryAddressData.requirement'])}
                                                multiline
                                                readOnly={isAddressReadOnly}
                                                borderless={isAddressReadOnly}
                                                autoAdjustHeight
                                                resizable={false}
                                                tooltipMessage={tKey('helperBubbleDeliveryRequirement')}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="half">
                                            {
                                                // eslint-disable-next-line no-nested-ternary
                                                contact?.contactId ? (
                                                    <DetailInfo
                                                        onEdit={() => setIsContactModalVisible(true)}
                                                        label={tKey('contact')}
                                                        buttonPosition="bottom"
                                                        isReadOnly={isAddressReadOnly || deliveryAddressData.address.isPlatform}
                                                        lang={lang}
                                                    >
                                                        <span style={{ fontWeight: 'bold' }}>
                                                            {`${contact.firstname} ${contact.lastname.toUpperCase()}`}
                                                        </span>
                                                        <br />
                                                        {contact.email}
                                                        <br />
                                                        {!!contact.phoneNumber && `+${contact.phoneNumber}`}
                                                        <br />
                                                        {!!contact.phoneNumber2 && `+${contact.phoneNumber2}`}
                                                    </DetailInfo>
                                                ) : !isAddressReadOnly ? (
                                                    <>
                                                        <Label
                                                            required
                                                            styles={{ root: { paddingBottom: 0, marginBottom: 0 } }}
                                                        >
                                                            {tKey('contact')}
                                                        </Label>
                                                        <TextField
                                                            styles={{
                                                                fieldGroup: {
                                                                    border: 'none', padding: 0, margin: 0, height: 0,
                                                                },
                                                            }}
                                                            errorMessage={tObj(errorField['deliveryAddressData.addressId'])}
                                                        />
                                                        <PrimaryButton
                                                            style={{ marginTop: '10px' }}
                                                            text={tKey('addContact')}
                                                            onClick={() => {
                                                                onUpdate({
                                                                    address: {
                                                                        ...deliveryAddressData.address,
                                                                        contact: new Contact({ addressId: deliveryAddressData.addressId }),
                                                                    },
                                                                }, 'deliveryAddressData')
                                                                setIsContactModalVisible(true)
                                                            }}
                                                            iconProps={{ iconName: 'Add' }}
                                                        />
                                                    </>

                                                ) : null
                                            }
                                        </Columns.Column>
                                    </Columns>
                                </>
                            )}
                        </Card>
                    </div>
                    <div className={styles['delivery-step-left-complement']}>
                        <Card
                            title={tKey('complement')}
                            iconName="TextBox"
                            hasLitleDividerMarging

                        >
                            <TextFieldWithTooltip
                                label={tKey('orderingPartyReference')}
                                required={!isReadOnly}
                                placeholder={isReadOnly ? '' : tKey('referenceNumber')}
                                value={deliveryComplementData.orderingPartyReference}
                                onChange={(_ev, newVal) => onUpdate({
                                    orderingPartyReference: newVal?.length > 100 ? newVal.substring(0, 100) : newVal,
                                }, 'deliveryComplementData')}
                                errorMessage={`${tObj(errorField['deliveryComplementData.orderingPartyReference'])}`}
                                multiline
                                autoAdjustHeight={!isReadOnly}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                resizable={false}
                                tooltipMessage={tKey('orderingPartyReferenceTooltip')}
                            />
                            <TextFieldWithTooltip
                                label={tKey('merchandiseComment')}
                                placeholder={isReadOnly ? tKey('noComment') : tKey('writeAComment')}
                                value={deliveryComplementData.merchandiseComment}
                                onChange={(_ev, newVal) => onUpdate({ merchandiseComment: newVal?.length > 255 ? newVal.substring(0, 255) : newVal },
                                    'deliveryComplementData')}
                                errorMessage={tObj(errorField['deliveryComplementData.merchandiseComment'])}
                                multiline
                                autoAdjustHeight={!isReadOnly}
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                resizable={false}
                                tooltipMessage={tKey('handlingCommentTooltip')}
                            />
                            <TextFieldWithTooltip
                                label={tKey('invoiceComment')}
                                placeholder={isReadOnly ? tKey('noComment') : tKey('writeAComment')}
                                value={deliveryComplementData.invoiceComment}
                                onChange={(_ev, newVal) => onUpdate({
                                    invoiceComment: newVal?.length > 255 ? newVal.substring(0, 255) : newVal,
                                }, 'deliveryComplementData')}
                                errorMessage={tObj(errorField['deliveryComplementData.invoiceComment'])}
                                multiline
                                autoAdjustHeight
                                readOnly={isReadOnly}
                                borderless={isReadOnly}
                                resizable={false}
                                tooltipMessage={tKey('invoiceCommentTooltip')}
                            />
                        </Card>
                    </div>
                    <div className={styles['delivery-step-left-instructions']}>
                        <Card
                            title={tKey('instructions')}
                            iconName="NumberedListText"
                            hasLitleDividerMarging

                        >
                            {!isJetFreeze ? (
                                // RAUD / PINEAU INSTRUCTIONS
                                <Columns>
                                    <Columns.Column size="half">
                                        <FilteredVirtualCombobox
                                            label={tKey('typeOfDelivery')}
                                            placeholder={tKey('select')}
                                            options={deliveryTypesOption}
                                            readOnly={isReadOnly || [EOrderType.Reprise, EOrderType.Redelivery].includes(item.orderTypeId)}
                                            selectedKey={deliveryInstructionsData?.deliveryTypeId}
                                            onChange={(ev, option) => onUpdate({
                                                deliveryTypeId: option.key,
                                            }, 'deliveryInstructionsData')}
                                            // eslint-disable-next-line max-len
                                            errorMessage={`${tObj(errorField['deliveryInstructionsData.deliveryTypeId'])
                                                }${tObj(errorField['deliveryInstructionsData.comfourData'])
                                                }${tObj(errorField['deliveryInstructionsData.comfourData.allegroNumber'])
                                                }${tObj(errorField['deliveryInstructionsData.comfourData.storeId'])
                                                }`}
                                            required={!isReadOnly}
                                        />
                                        {isComfourTypeSelected && !!storeData && (
                                            <DetailInfo
                                                onEdit={() => setIsComfourModalDataVisible(true)}
                                                buttonPosition="right"
                                                isReadOnly={isReadOnly}
                                                lang={lang}
                                                style={{ marginBottom: '-9px' }}
                                            >
                                                <b>{`${tKey('allegroNumberShort')}: ${deliveryInstructionsData.comfourData.allegroNumber}`}</b>
                                                <br />
                                                {
                                                    // eslint-disable-next-line max-len
                                                    `${tKey('supplier')}: ${deliveryInstructionsData.comfourData.comfourNumber ? deliveryInstructionsData.comfourData.comfourNumber
                                                        : item.client.comfourNumber
                                                    }`
                                                }
                                                <br />
                                                {storeData.name}
                                            </DetailInfo>
                                        )}
                                        {isPineau && (
                                            <FilteredVirtualCombobox
                                                label={tKey('logisticConstraints')}
                                                placeholder={!isReadOnly ? tKey('select') : tKey('noLogisticConstraint')}
                                                styles={{ container: { marginTop: '9px' } }}
                                                options={param.logisticConstraints.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                readOnly={isReadOnly}
                                                selectedKey={deliveryInstructionsData.deliveryLogisticConstraintIds[0]}
                                                onChange={(ev, option) => onUpdate({ deliveryLogisticConstraintIds: [option.key] }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.deliveryLogisticConstraintIds'])}
                                                required={!isReadOnly}
                                            />
                                        )}
                                        {isRaud && ![EOrderType.Reprise, EOrderType.Redelivery].includes(item.orderTypeId) && (
                                            <FilteredVirtualCombobox
                                                label={tKey('vehicleEquipment')}
                                                placeholder={tKey('select')}
                                                styles={{ container: { marginTop: '9px' } }}
                                                options={param.vehicleEquipments.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                readOnly={isReadOnly}
                                                selectedKey={deliveryInstructionsData.vehicleEquipmentId}
                                                onChange={(ev, option) => onUpdate({ vehicleEquipmentId: option.key }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.vehicleEquipmentId'])}
                                                required={!isReadOnly}
                                            />
                                        )}
                                        {
                                            isRaud
                                            && deliveryInstructionsData.vehicleEquipmentId !== 2
                                            && [EOrderType.DocksideShed, EOrderType.Delivery].includes(item.orderTypeId) && (
                                                <FilteredVirtualCombobox
                                                    label={tKey('accessibility')}
                                                    placeholder={tKey('select')}
                                                    options={param.accessibilities.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                    // eslint-disable-next-line max-len
                                                    // readOnly={isReadOnly || (!!deliveryAddressData.address?.accessibilityId && !!deliveryAddressData.addressId)}
                                                    readOnly={isReadOnly}
                                                    selectedKey={deliveryInstructionsData.accessibilityId}
                                                    onChange={(ev, option) => onUpdate({ accessibilityId: option.key }, 'deliveryInstructionsData')}
                                                    errorMessage={tObj(errorField['deliveryInstructionsData.accessibilityId'])}
                                                    required={!isReadOnly}
                                                />
                                            )
                                        }
                                    </Columns.Column>
                                    <Columns.Column size="half">
                                        {isOtTypeSelected && (
                                            <TextField
                                                label={tKey('otNumber')}
                                                placeholder={tKey('otNumber')}
                                                value={deliveryInstructionsData.otNumber}
                                                onChange={(_ev, newVal) => onUpdate({ otNumber: newVal }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.otNumber'])}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                required={!isReadOnly && item.orderTypeId !== EOrderType.Redelivery}
                                            />
                                        )}
                                        {isRdvTypeSelected && (
                                            <Toggle
                                                onText={tKey('yes')}
                                                offText={tKey('no')}
                                                label={tKey('telephoneAppointment')}
                                                styles={{
                                                    root: { display: 'flex', justifyContent: 'space-between' },
                                                    container: { alignItems: 'center' },
                                                }}
                                                checked={deliveryInstructionsData.isTelephoneAppointment}
                                                onChange={(ev, checked) => onUpdate({
                                                    isTelephoneAppointment: checked,
                                                }, 'deliveryInstructionsData')}
                                                disabled={isReadOnly}
                                            />
                                        )}
                                        {
                                            ((isRaud && isRdvTypeSelected && deliveryInstructionsData.isTelephoneAppointment)
                                                || (isPineau && deliveryInstructionsData.isTelephoneAppointment)) && (
                                                <TextField
                                                    styles={{ root: { marginTop: '9px' } }}
                                                    label={tKey('commentAppointment')}
                                                    placeholder={isReadOnly ? '' : tKey('commentAppointment')}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    value={deliveryInstructionsData.commentAppointment}
                                                    onChange={(_ev, newVal) => onUpdate({ commentAppointment: newVal }, 'deliveryInstructionsData')}
                                                    errorMessage={tObj(errorField['deliveryInstructionsData.commentAppointment'])}
                                                />
                                            )
                                        }
                                        <Toggle
                                            onText={tKey('yes')}
                                            offText={tKey('no')}
                                            label={tKey('againstReimbursement')}
                                            styles={{
                                                root: { display: 'flex', justifyContent: 'space-between', marginTop: '9px' },
                                                container: { alignItems: 'center' },
                                            }}
                                            checked={deliveryInstructionsData.isAgainstReimbursement}
                                            onChange={(ev, checked) => onUpdate({
                                                isAgainstReimbursement: checked,
                                            }, 'deliveryInstructionsData')}
                                            disabled={isReadOnly}
                                        />
                                        {deliveryInstructionsData.isAgainstReimbursement && (
                                            <TextField
                                                placeholder={tKey('amount')}
                                                value={deliveryInstructionsData.amount}
                                                onChange={(_ev, newVal) => onUpdate({ amount: newVal }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.amount'])}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                required={!isReadOnly}
                                                suffix="€"
                                                type="number"
                                            />
                                        )}
                                        {deliveryType && deliveryType.shortName === 'LAD' && (
                                            <TextFieldWithTooltip
                                                tooltipIconName="Info"
                                                tooltipMessage={tKey('sellerTooltipMessage')}
                                                label={tKey('seller')}
                                                placeholder={tKey('seller')}
                                                value={deliveryInstructionsData.seller}
                                                onChange={(_ev, newVal) => onUpdate({ seller: newVal }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.seller'])}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                            />
                                        )}
                                    </Columns.Column>
                                </Columns>
                            ) : (
                                // JETFREEZE INSTRUCTIONS
                                <>
                                    <Columns>
                                        <Columns.Column size="half">
                                            <FilteredVirtualCombobox
                                                label={tKey('logisticConstraints')}
                                                placeholder={tKey('select')}
                                                options={param.logisticConstraints.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                readOnly={isReadOnly}
                                                selectedKey={deliveryInstructionsData.deliveryLogisticConstraintIds}
                                                onChange={(ev, option) => onUpdate({
                                                    // eslint-disable-next-line max-len
                                                    deliveryLogisticConstraintIds: getUpdatedList(deliveryInstructionsData.deliveryLogisticConstraintIds, option),
                                                }, 'deliveryInstructionsData')}
                                                // eslint-disable-next-line max-len
                                                errorMessage={tObj(errorField['deliveryInstructionsData.deliveryLogisticConstraintIds'])}
                                                multiSelect
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="half">
                                            <FilteredVirtualCombobox
                                                label={tKey('accessibilities')}
                                                placeholder={tKey('select')}
                                                options={param.accessibilities.map(x => ({ ...x, text: tObj(parseJson(x.text)) }))}
                                                readOnly={isReadOnly}
                                                selectedKey={deliveryInstructionsData.accessibilityIds}
                                                onChange={(ev, option) => onUpdate({
                                                    accessibilityIds: getUpdatedList(deliveryInstructionsData.accessibilityIds, option),
                                                }, 'deliveryInstructionsData')}
                                                errorMessage={tObj(errorField['deliveryInstructionsData.accessibilityIds'])}
                                                multiSelect
                                            />
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column size="half">
                                            <Toggle
                                                onText={tKey('yes')}
                                                offText={tKey('no')}
                                                label={tKey('isTheMeetingMandatoryInOrderToDeliverTheOrder')}
                                                styles={{
                                                    root: { display: 'flex', justifyContent: 'space-between' },
                                                    container: { alignItems: 'center' },
                                                }}
                                                checked={deliveryInstructionsData.isTelephoneAppointment}
                                                onChange={(ev, checked) => onUpdate({
                                                    isTelephoneAppointment: checked,
                                                }, 'deliveryInstructionsData')}
                                                disabled={isReadOnly}
                                            />
                                            {deliveryInstructionsData.isTelephoneAppointment && (
                                                <TextField
                                                    label={tKey('appointmentReference')}
                                                    placeholder={tKey('appointmentReference')}
                                                    value={deliveryInstructionsData.appointmentReference}
                                                    onChange={(_ev, newVal) => onUpdate({ appointmentReference: newVal }, 'deliveryInstructionsData')}
                                                    // eslint-disable-next-line max-len
                                                    errorMessage={tObj(errorField['deliveryInstructionsData.appointmentReference'])}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    required={!isReadOnly}
                                                />
                                            )}
                                            {deliveryInstructionsData.isTelephoneAppointment && (
                                                <TextField
                                                    styles={{ root: { marginTop: '9px' } }}
                                                    label={tKey('commentAppointment')}
                                                    placeholder={isReadOnly ? undefined : tKey('commentAppointment')}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    value={deliveryInstructionsData.commentAppointment}
                                                    onChange={(_ev, newVal) => onUpdate({ commentAppointment: newVal }, 'deliveryInstructionsData')}
                                                    errorMessage={tObj(errorField['deliveryInstructionsData.commentAppointment'])}
                                                />
                                            )}
                                        </Columns.Column>
                                        <Columns.Column size="half">
                                            <Toggle
                                                onText={tKey('yes')}
                                                offText={tKey('no')}
                                                label={tKey('doYouNeedAnInsurance')}
                                                styles={{
                                                    root: { display: 'flex', justifyContent: 'space-between' },
                                                    container: { alignItems: 'center' },
                                                }}
                                                checked={deliveryInstructionsData.isAgainstReimbursement}
                                                onChange={(ev, checked) => onUpdate({
                                                    isAgainstReimbursement: checked,
                                                }, 'deliveryInstructionsData')}
                                                disabled={isReadOnly}
                                            />
                                            {deliveryInstructionsData.isAgainstReimbursement && (
                                                <TextField
                                                    label={tKey('amount')}
                                                    placeholder={tKey('amount')}
                                                    value={deliveryInstructionsData.amount}
                                                    onChange={(_ev, newVal) => onUpdate({ amount: newVal }, 'deliveryInstructionsData')}
                                                    errorMessage={tObj(errorField['deliveryInstructionsData.amount'])}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    required={!isReadOnly}
                                                    suffix="€"
                                                />
                                            )}
                                        </Columns.Column>
                                    </Columns>
                                </>
                            )}
                        </Card>
                    </div>
                    <div className={styles['delivery-step-left-date']}>
                        <Card
                            title={tKey('date')}
                            iconName="Calendar"
                            hasLitleDividerMarging
                        >
                            {!isJetFreeze ? (
                                // RAUD / PINEAU DATE
                                <Columns>
                                    {deliveryType && ['LAD', 'LDD'].includes(deliveryType.shortName) && (
                                        <Columns.Column>
                                            <Text>{tKey('ladDeliveryTypeDateMessage')}</Text>
                                        </Columns.Column>
                                    )}
                                    {(isPineau || item.client.deliveryDateTypeId !== EdeliveryDateType.MandatoryDate)
                                        && !(deliveryType && ['LAD', 'LDD'].includes(deliveryType.shortName))
                                        && (
                                            <Columns.Column size="half">
                                                <FilteredVirtualCombobox
                                                    label={tKey('desiredWeek')}
                                                    placeholder={tKey('chooseWeek')}
                                                    options={deliveryWeeks.map(x => ({ ...x, text: tObj(x.text) }))}
                                                    selectedKey={deliveryDateData.desiredWeekNumber}
                                                    onChange={(ev, option) => onUpdate({
                                                        desiredWeekNumber: option.key,
                                                    }, 'deliveryDateData')}
                                                    errorMessage={tObj(errorField['deliveryDateData.desiredWeekNumber'])}
                                                    readOnly={isReadOnly}
                                                    required={!isReadOnly}
                                                />
                                            </Columns.Column>
                                        )}
                                    {isPineau && (
                                        <Columns.Column size="half">
                                            <TextFieldWithTooltip
                                                label={tKey('wishedDateOfDelivery')}
                                                placeholder={isReadOnly ? tKey('noDesiredDate') : tKey('wishedDateOfDelivery')}
                                                // eslint-disable-next-line max-len
                                                value={deliveryDateData.wishedDateOfDelivery ? Time(new Date(deliveryDateData.wishedDateOfDelivery)).getLocaleDateString() : ''}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChange={(ev, newVal) => {
                                                    const newDate = new Date(newVal)
                                                    if (isValidDate(newDate)) {
                                                        const firstDayOfYear = new Date(newDate.getFullYear(), 0, 1)
                                                        const days = Math.floor((newDate.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000))
                                                        const week = Math.ceil(days / 7)
                                                        onUpdate({
                                                            wishedDateOfDelivery: newDate,
                                                            desiredWeekNumber: week,
                                                        }, 'deliveryDateData')
                                                    } else {
                                                        onUpdate({
                                                            wishedDateOfDelivery: null,
                                                        }, 'deliveryDateData')
                                                    }
                                                }}
                                                type={isReadOnly && !deliveryDateData.wishedDateOfDelivery ? 'text' : 'date'}
                                                min={
                                                    Time(new Date(Math.max(
                                                        deliveryWeeks.find(x => x.key === deliveryDateData.desiredWeekNumber)?.minDate?.getTime() ?? 0,
                                                        item.pickupDateData.pickupDate?.getTime() ?? 0,
                                                        0,
                                                    ))).getLocaleDateString() ?? ''
                                                }
                                                max={
                                                    Time(
                                                        deliveryWeeks.find(x => x.key === deliveryDateData.desiredWeekNumber)?.maxDate
                                                        ?? new Date(9999, 0, 1),
                                                    ).getLocaleDateString() ?? ''
                                                }
                                                errorMessage={tObj(errorField['deliveryDateData.wishedDateOfDelivery'])}
                                                tooltipMessage={tKey('wishedDateOfDeliveryTooltip')}
                                            />
                                        </Columns.Column>
                                    )}
                                    {(!isPineau && item.client.deliveryDateTypeId === EdeliveryDateType.MandatoryDate)
                                        && !(deliveryType && ['LAD', 'LDD'].includes(deliveryType.shortName))
                                        && (
                                            <Columns.Column size="half">
                                                <TextFieldWithTooltip
                                                    label={tKey('wishedDateOfDelivery')}
                                                    placeholder={isReadOnly ? tKey('noDesiredDate') : tKey('wishedDateOfDelivery')}
                                                    // eslint-disable-next-line max-len
                                                    value={deliveryDateData.wishedDateOfDelivery ? Time(new Date(deliveryDateData.wishedDateOfDelivery)).getLocaleDateString() : ''}
                                                    readOnly={isReadOnly}
                                                    borderless={isReadOnly}
                                                    onChange={(ev, newVal) => {
                                                        const newDate = new Date(newVal)
                                                        if (isValidDate(newDate)) {
                                                            const firstDayOfYear = new Date(newDate.getFullYear(), 0, 1)
                                                            const days = Math.floor((newDate.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000))
                                                            const week = Math.ceil(days / 7)
                                                            onUpdate({
                                                                wishedDateOfDelivery: newDate,
                                                                desiredWeekNumber: week,
                                                            }, 'deliveryDateData')
                                                        } else {
                                                            onUpdate({
                                                                wishedDateOfDelivery: null,
                                                            }, 'deliveryDateData')
                                                        }
                                                    }}
                                                    type={isReadOnly && !deliveryDateData.wishedDateOfDelivery ? 'text' : 'date'}
                                                    min={Time(minDate).getLocaleDateString() ?? ''}
                                                    max={
                                                        Time(
                                                            deliveryWeeks[2]?.maxDate
                                                            ?? new Date(9999, 0, 1),
                                                        ).getLocaleDateString() ?? ''
                                                    }
                                                    errorMessage={tObj(errorField['deliveryDateData.wishedDateOfDelivery'])}
                                                    tooltipMessage={tKey('wishedDateOfDeliveryTooltip')}
                                                />
                                            </Columns.Column>
                                        )}
                                </Columns>
                            ) : (
                                <>
                                    <Columns>
                                        <Columns.Column size="half">
                                            <DatePicker
                                                date={deliveryDateData.wishedDateOfDelivery}
                                                label={tKey('deliveryDate')}
                                                readOnly={isReadOnly}
                                                borderless={isReadOnly}
                                                onChangeDate={newDate => onUpdate({
                                                    wishedDateOfDelivery: isValidDate(new Date(newDate)) ? /** @type {any} */(new Date(newDate)) : '',
                                                }, 'deliveryDateData')}
                                                required={!isReadOnly}
                                                lang={lang}
                                                openDays={openDays}
                                                minDate={Time(new Date(item.pickupDateData.pickupDate)).add(deliveryDateData.deliveryTime, 'day')}
                                                errorMessage={tObj(errorField['deliveryDateData.wishedDateOfDelivery'])}
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="half">
                                            <OpenWeekDays
                                                lang={lang}
                                                openDays={openDays}
                                                label={tKey('deliveryDay')}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                    <Columns>
                                        <Columns.Column size="one-third">
                                            <TextField
                                                label={tKey('deliveryStartWindow')}
                                                readOnly
                                                borderless
                                                value={deliveryAddressData.address?.startTime || ''}
                                                type="time"
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-third">
                                            <TextField
                                                label={tKey('deliveryEndWindow')}
                                                readOnly
                                                borderless
                                                value={deliveryAddressData.address?.endTime || ''}
                                                type="time"
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-third">
                                            <TextField
                                                label={tKey('deliveryTime')}
                                                readOnly
                                                borderless
                                                value={deliveryDateData.deliveryTime
                                                    ? tKey('deliveryTimeUnit', { deliveryTime: deliveryDateData.deliveryTime.toString() }) : ''}
                                            />
                                        </Columns.Column>
                                    </Columns>
                                </>

                            )}
                        </Card>
                    </div>
                </div>
                <div className={styles['delivery-step-right']}>
                    <div className={styles['delivery-step-right-content']}>
                        <Card
                            title={tKey('content')}
                            iconName="ShoppingCart"
                            hasLitleDividerMarging

                        >
                            <div className={styles['delivery-step-right-content-content']}>
                                <DeliveryStepContent
                                    items={deliveryContentData?.orderHandlings}
                                    lang={lang}
                                    onUpdate={newVal => onUpdate({
                                        orderHandlings: newVal,
                                    }, 'deliveryContentData')}
                                    param={param}
                                    isReadOnly={isReadOnly}
                                    errorField={errorField}
                                    companyId={companyRules.companyId}
                                    client={item.client}
                                />
                                {!isReadOnly && (
                                    <DefaultButton
                                        text={tKey('addHandlingUnit')}
                                        className={styles['btns-blue-btn']}
                                        iconProps={{ iconName: 'Add' }}
                                        onClick={() => addOrderHandling()}
                                    />
                                )}
                            </div>
                        </Card>
                    </div>
                    <div className={styles['delivery-step-right-documents']}>
                        <Card
                            title={tKey('documents')}
                            iconName="DocumentSet"
                            hasLitleDividerMarging
                        >
                            <Columns>
                                <Columns.Column size="half">
                                    {param.fileCategories.length === 0 && (
                                        <Text variant="smallPlus">
                                            {tKey('fileNotRequired')}
                                        </Text>
                                    )}
                                    {missingFiles}
                                    <DeliveryStepFileList
                                        item={deliveryFileData}
                                        deliveryFileData={deliveryFileData}
                                        isReadOnly={isReadOnly || param.fileCategories.length === 0}
                                        lang={lang}
                                        removeFile={fileId => onUpdate({
                                            files: deliveryFileData.files.filter(x => x.fileId !== fileId),
                                        }, 'deliveryFileData')}
                                        handler={filesHandler}
                                    />
                                    {isReadOnly && deliveryFileData.files.length === 0 && (
                                        <TextField
                                            borderless
                                            readOnly
                                            placeholder={tKey('noDocument')}
                                        />
                                    )}
                                </Columns.Column>
                                <Columns.Column size="half">
                                    {!isReadOnly && (
                                        <AdvancedFileInput
                                            item={deliveryFileData}
                                            lang={lang}
                                            handler={filesHandler}
                                            addFile={newFile => onUpdate({
                                                files: [...deliveryFileData.files, newFile],
                                            }, 'deliveryFileData')}
                                            options={fileCategories}
                                            isReadOnly={isReadOnly || param.fileCategories.length === 0}
                                        />
                                    )}
                                </Columns.Column>
                            </Columns>
                        </Card>
                    </div>
                </div>
            </div>
            <AddressModal
                addressData={addressModalData}
                handler={addressesHandler}
                isVisible={isAddressModalVisible}
                lang={lang}
                addParam={addParam}
                editParam={editParam}
                removeParam={removeParam}
                param={param}
                onChange={newData => {
                    if (newData)
                        onChange({
                            deliveryDatas: item.deliveryDatas.map((delData, index) => (index === deliveryIndex
                                ? ({
                                    ...delData,
                                    deliveryAddressData: {
                                        ...deliveryAddressData,
                                        address: newData,
                                        addressId: newData.addressId,
                                    },
                                    deliveryInstructionsData: {
                                        ...deliveryInstructionsData,
                                        accessibilityId: newData.accessibilityId ? newData.accessibilityId : 1,
                                    },
                                })
                                : delData)),
                        })

                    setIsAddressModalVisible(false)
                }}
                addressTypeDisableIds={[EAddressType.Delivery]}
                companyRules={companyRules}
                isValidation={item.currentWorkflowStepId === EWorkflowStep.AddressValidation}
            />
            <ContactModal
                isVisible={isContactModalVisible}
                handler={addressesHandler}
                contactData={deliveryAddressData.address?.contact}
                lang={lang}
                onChange={newData => {
                    if (newData)
                        onUpdate({
                            address: { ...deliveryAddressData.address, contact: newData },
                        }, 'deliveryAddressData')
                    setIsContactModalVisible(false)
                }}
            />
            <InstructionModal
                addressType={EAddressType.Delivery}
                isVisible={isInstructionsModalVisible}
                setIsVisible={newVal => setIsInstructionsModalVisible(newVal)}
                instructionsData={deliveryAddressData.address?.instructions}
                addressId={deliveryAddressData.address?.addressId}
                handler={addressesHandler}
                onChange={newVal => onUpdate({
                    address: {
                        ...deliveryAddressData.address,
                        instructions: newVal?.length > 255 ? newVal.substring(0, 100) : newVal,
                    },
                }, 'deliveryAddressData')}
                lang={lang}
            />
            <ComfourDataModal
                isVisible={isComfourModalDataVisible}
                comfourData={deliveryInstructionsData.comfourData}
                onChange={newData => {
                    onUpdate(!newData && !deliveryInstructionsData.comfourData ? {
                        deliveryTypeId: 0,
                    } : {
                        comfourData: newData,
                    }, 'deliveryInstructionsData')
                    setIsComfourModalDataVisible(false)
                }}
                lang={lang}
                storeParams={param.stores}
            />
        </>
    )
}
