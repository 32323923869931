import React, {
    useState, useCallback, useRef,
} from 'react'
import {
    DefaultButton, DirectionalHint, TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import DeliveryTime from 'requests/objects/deliveryTime'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import Card from 'components/containers/card'
import styles from 'styles/components/pages/companies/delivery-times/pivot-delivery-times.module.scss'
import { Columns } from 'react-bulma-components'
import DeliveryTimeList from 'components/pages/company/delivery-time/delivery-time-list'
import DeliveryTimeModal from 'components/pages/company/delivery-time/delivery-time-modal'
import ImportDataModal from 'components/modals/import-data-modal'
import HandleBlob from 'helpers/methods/blob'
import store from 'redux/store'
import { setMessageBar } from 'redux/slices/common'

/**
 * @typedef {object} DeliveryTimeIndexes
 * @property {number?} clientId - client id for client pivot
 * @property {number?} companyId - company id for company pivot
 */

/**
 * @typedef {object} SearchParamsType
 * @property {number} pickupZoneId pickupZoneId
 * @property {number} deliveryZoneId deliveryZoneId
 * @property {number} time time
 */

/**
 * @type {SearchParamsType}
 */
const defaultSearchParams = {
    pickupZoneId: undefined,
    deliveryZoneId: undefined,
    time: undefined,
}

/**
 * Pivot deliveryTimes
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {number=} props.companyId companyId
 * @param {function(import('redux/slices/common').PayloadModal):void} props.setModal Set Modal
 * @returns {JSX.Element} Element
 */
export default function PivotDeliveryTimes({
    lang,
    companiesHandler,
    companyId,
    setModal,
}) {
    const { tKey } = useTranslate({ lang })
    const [status, setStatus] = useState(Status.IDLE)
    const [deliveryTimes, setDeliveryTimes] = useState([])
    const [isDeliveryTimeModalVisible, setIsDeliveryTimeModalVisible] = useState(false)
    const [deliveryTime, setDeliveryTime] = useState(new DeliveryTime())
    const [searchParams, setSearchParams] = useState(defaultSearchParams)
    const [isImportDataVisible, setIsImportDataVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryTime>>} */
    const deleteDeliveryTimeHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryTime[]>>} */
    const searchdeliveryTimesHandler = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<DeliveryTime[]>>} */
    const handlerDeliveryTimeImport = useRef(null)

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Blob>>} */
    const downloadTemplateHandler = useRef()

    const submitInput = useRef(null)
    const toolTipRef = useRef(null)

    const editDeliveryTime = useCallback(
        /**
         * Edit deliveryTime
         * @param {DeliveryTime} deliveryTimeToEdit deliveryTimeToEdit
         */
        deliveryTimeToEdit => {
            setDeliveryTime(deliveryTimeToEdit)
            setIsDeliveryTimeModalVisible(true)
        }, [],
    )

    const deleteDeliveryTime = useCallback(
        /**
         * Delete deliveryTime
         * @param {DeliveryTime} deliveryTimeToDelete deliveryTimeToDelete
         */
        deliveryTimeToDelete => {
            setModal({
                show: true,
                title: tKey('removeDeliveryTime'),
                subTitle: tKey('sureRemoveDeliveryTime'),
                callback: async () => {
                    try {
                        deleteDeliveryTimeHandler.current = companiesHandler.removeDeliveryTimeById(companyId, deliveryTimeToDelete.deliveryTimeId)
                        const dep = await deleteDeliveryTimeHandler.current.fetch()
                        setDeliveryTimes(olds => olds.filter(x => x.deliveryTimeId !== dep.deliveryTimeId))
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }
                },
            })
        }, [companiesHandler, companyId, setModal, tKey],
    )

    const addDeliveryTime = useCallback(() => {
        setDeliveryTime(new DeliveryTime({ companyId }))
        setIsDeliveryTimeModalVisible(true)
    }, [companyId])

    const search = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            searchdeliveryTimesHandler.current?.cancel()
            searchdeliveryTimesHandler.current = companiesHandler.getAllDeliveryTimes(companyId, searchParams)
            const res = await searchdeliveryTimesHandler.current.fetch()
            setDeliveryTimes(res)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
                default:
                    setDeliveryTimes([])
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [companiesHandler, companyId, searchParams])

    const onImport = useCallback(async file => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            store.dispatch(setMessageBar({ isDisplayed: false }))
            handlerDeliveryTimeImport.current = companiesHandler.importDeliveryTimes(file, companyId)
            const res = await handlerDeliveryTimeImport.current.fetch()
            setDeliveryTimes(res)
            setIsImportDataVisible(false)
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            setErrorMessage(error)
        }
    }, [companiesHandler, companyId])

    /**
     * Download template
     */
    const onDownloadTemplate = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            setErrorMessage(null)
            downloadTemplateHandler.current = companiesHandler.downloadDeliveryTimeTemplate(companyId)
            const blob = await downloadTemplateHandler.current.fetch()
            HandleBlob.download(blob, 'delivery-times-import-template.xlsx')
            setStatus(Status.RESOLVED)
        } catch (error) {
            setStatus(Status.REJECTED)
            setErrorMessage(error)
        }
    }, [companiesHandler, companyId])

    return (
        <main className={styles['pivot-delivery-times']}>
            <Card
                title={tKey('filters')}
                iconName="FilterSettings"
                headerComponent={(
                    <div
                        className={styles['action-filter-buttons']}
                    >
                        <div>
                            <DefaultButton
                                text={tKey('research')}
                                primary
                                disabled={status === Status.PENDING}
                                onClick={() => submitInput.current.click()}
                                iconProps={{ iconName: 'Search' }}
                            />
                        </div>
                        <div className={styles.separator} />
                        <div>
                            <TooltipHost
                                content={tKey('resetFilters')}
                                directionalHint={DirectionalHint.topAutoEdge}
                                delay={TooltipDelay.medium}
                                componentRef={toolTipRef}
                            >
                                <DefaultButton
                                    disabled={status === Status.PENDING}
                                    styles={{
                                        root: {
                                            minWidth: '40px',
                                            maxWidth: '40px',
                                        },
                                    }}
                                    iconProps={{ iconName: 'ClearFilter' }}
                                    onClick={() => setSearchParams(defaultSearchParams)}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                )}
            >
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        search()
                    }}
                >
                    <Columns>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('pickupZone')}
                                value={searchParams.pickupZoneId?.toString() || ''}
                                type="number"
                                onChange={(_ev, newVal) => setSearchParams(prev => ({ ...prev, pickupZoneId: newVal?.length > 0 ? +newVal : undefined }))}
                                disabled={status === Status.PENDING}
                                min={0}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('deliveryZone')}
                                value={searchParams.deliveryZoneId?.toString() || ''}
                                type="number"
                                onChange={(_ev, newVal) => setSearchParams(prev => ({ ...prev, deliveryZoneId: newVal?.length > 0 ? +newVal : undefined }))}
                                disabled={status === Status.PENDING}
                                min={0}
                            />
                        </Columns.Column>
                        <Columns.Column size="one-fifth">
                            <TextField
                                label={tKey('time')}
                                value={searchParams.time?.toString() || ''}
                                type="number"
                                onChange={(_ev, newVal) => setSearchParams(prev => ({ ...prev, time: newVal?.length > 0 ? +newVal : undefined }))}
                                disabled={status === Status.PENDING}
                                min={0}
                            />
                        </Columns.Column>
                    </Columns>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={submitInput}
                        tabIndex={-1}
                    />
                </form>
            </Card>
            <br />
            <Card
                iconName="MapPin"
                title={tKey('deliveryTimes')}
                headerComponent={(
                    <>
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                setIsImportDataVisible(true)
                            }}
                            text={tKey('integrateDeliveryTimes')}
                        />
                        <DefaultButton
                            onClick={e => {
                                e.preventDefault()
                                addDeliveryTime()
                            }}
                            text={tKey('add')}
                        />
                    </>

                )}
                hasDividerMargin={false}
            >
                <DeliveryTimeList
                    editDeliveryTime={editDeliveryTime}
                    deleteDeliveryTime={deleteDeliveryTime}
                    items={deliveryTimes}
                    lang={lang}
                    status={status}
                />
            </Card>
            <DeliveryTimeModal
                deliveryTimeData={deliveryTime}
                isVisible={isDeliveryTimeModalVisible}
                companiesHandler={companiesHandler}
                onChange={deliveryTimeReturned => {
                    if (deliveryTimeReturned)
                        if (!deliveryTime.deliveryTimeId)
                            setDeliveryTimes([...deliveryTimes, deliveryTimeReturned])
                        else
                            setDeliveryTimes(prev => prev.map(x => (x.deliveryTimeId === deliveryTimeReturned.deliveryTimeId ? deliveryTimeReturned : x)))

                    setIsDeliveryTimeModalVisible(false)
                }}
                lang={lang}
            />
            <ImportDataModal
                lang={lang}
                isVisible={isImportDataVisible}
                setIsVisible={setIsImportDataVisible}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                onDownloadTemplate={onDownloadTemplate}
                onImport={onImport}
                status={status}
                title={tKey('integrateDeliveryTimes')}
            />
        </main>
    )
}
