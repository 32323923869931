/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'

/**
 * Store Object
 */
export default class Store extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.storeId storeId
     * @param {string=} data.name name
     * @param {string=} data.storeNumber storeNumber
     */
    constructor({
        storeId,
        name,
        storeNumber,
        ...data
    } = {}) {
        super(data)
        this.storeId = storeId
        this.name = name
        this.storeNumber = storeNumber
    }
}
