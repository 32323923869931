import React, { PureComponent } from 'react'
import {
    TextField, PrimaryButton, SpinnerSize, Spinner, MessageBarType, Checkbox,
} from '@fluentui/react'
import history from 'helpers/history'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { tKey, tObj } from 'helpers/methods/translate'
import parseJson from 'helpers/methods/parseJson'

/**
 * @augments {React.PureComponent<AppProps>}}
 */
export default class Signup extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            /** @type {string} Email */
            email: '',
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {ErrorUser} */
            errorField: new ErrorUser(),
        }

        this.tKey = tKey
        this.tObj = tObj
    }

    componentDidMount() {
        const {
            setBreadcrumb, setCommand, isAuthenticated, messageBar, setMessageBar,
        } = this.props

        setBreadcrumb([])
        setCommand([])

        if (messageBar.type !== MessageBarType.error)
            setMessageBar({ isDisplayed: false })

        if (isAuthenticated)
            history.push('/')
    }

    componentWillUnmount() {
        this.userHandlerSignup?.cancel()
    }

    signup() {
        const { setMessageBar, usersHandler } = this.props
        const { email } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.userHandlerSignup = usersHandler.reset(email)
                await this.userHandlerSignup.fetch()
                history.push('/login')
                setMessageBar({
                    isDisplayed: true,
                    message: this.tKey('yourPasswordHasBeenReset'),
                    type: MessageBarType.success,
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            break
                        default:
                            this.setState({ errorField: new ErrorUser() })
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const {
            email, status, errorField,
        } = this.state

        return (
            <form
                onSubmit={(ev => {
                    ev.preventDefault()
                    this.signup()
                })}
            >
                <TextField
                    label={this.tKey('email')}
                    placeholder={this.tKey('email')}
                    value={email}
                    onChange={ev => this.setState({ email: /** @type {HTMLInputElement} */(ev.target).value })}
                    // iconProps={{ iconName: 'Mail' }}
                    readOnly={status === Status.PENDING}
                    autoComplete="username"
                    errorMessage={this.tObj(parseJson(errorField.email))}
                    required
                />
                <br />
                <Checkbox
                    label={this.tKey('clickingValidate')}
                    required
                />
                <br />
                <PrimaryButton
                    iconProps={{ iconName: 'FollowUser' }}
                    text={this.tKey('validate')}
                    type="submit"
                    disabled={status === Status.PENDING}
                >
                    {status === Status.PENDING && (
                        <>
                            &nbsp;&nbsp;
                            <Spinner size={SpinnerSize.small} />
                        </>
                    )}
                </PrimaryButton>
            </form>
        )
    }
}

Signup.prototype.tKey = tKey
Signup.prototype.tObj = tObj
