/* eslint-disable max-classes-per-file */

import { Article } from 'requests/objects/support'
import Address from 'requests/objects/address'
import Base, { ErrorBase } from 'requests/objects/_base'

/**
 * Stock Object
 */
export default class Stock extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.stockId stockId
     * @param {string=} data.name name
     * @param {string=} data.containerCode containerCode
     * @param {number=} data.quantity quantity
     * @param {number=} data.articleId articleId
     * @param {object=} data.article article
     * @param {number=} data.addressId addressId
     * @param {object=} data.address address
     */
    constructor({
        stockId,
        name = '',
        containerCode = '',
        quantity,
        articleId,
        article,
        addressId,
        address,
        ...data
    } = {}) {
        super(data)
        this.stockId = stockId
        this.name = name
        this.containerCode = containerCode
        this.quantity = quantity
        this.articleId = articleId
        this.article = article ? new Article(article) : undefined
        this.addressId = addressId
        this.address = address ? new Address(address) : undefined
    }
}

/**
 * Stock Object used to bind error message
 */
export class ErrorStock extends ErrorBase {
    constructor() {
        super()
        this.stockId = ''
        this.name = ''
        this.quantity = ''
        this.containerCode = ''
        this.articleId = ''
        this.addressId = ''
    }
}
