import React, {
    useCallback,
    useMemo, useState,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DefaultButton, DetailsListLayoutMode, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Card from 'components/containers/card'
import parseJson from 'helpers/methods/parseJson'
import LogisticConstraint from 'requests/objects/logisticConstraint'
import LogisticConstraintsHandler from 'requests/handlers/logisticConstraintsHandler'
import LogisticConstraintModal from 'components/pages/logisticConstraintModal'
import styles from 'styles/pages/logistic-constraints/pivot-logistic-constraints.module.scss'

/**
 * PivotLogisticConstraints
 * @param {object} props Props
 * @param {string} props.lang Lang
 * @param {LogisticConstraintsHandler} props.logisticConstraintsHandler logisticConstraintsHandler
 * @param {LogisticConstraint[]} props.logisticConstraints logisticConstraints
 * @param {number} props.companyId companyId
 * @param {(logisticConstraint: LogisticConstraint, isDeletion: boolean) => void} props.onChange onChange
 * @returns {JSX.Element} Element
 */
export default function PivotLogisticConstraints({
    lang, logisticConstraints, logisticConstraintsHandler, companyId, onChange,
}) {
    const { tKey, tObj } = useTranslate({ lang })
    const [selectedLCId, setSelectedLCId] = useState(null)
    const [isVisible, setIsVisible] = useState(false)
    const [isDeletion, setIsDeletion] = useState(false)

    const updateLCList = useCallback(
        /**
         * @param {LogisticConstraint} logisticConstraint logisticConstraint
         */
        logisticConstraint => {
            if (logisticConstraint)
                onChange(logisticConstraint, isDeletion)
            if (isDeletion)
                setIsDeletion(false)
            setIsVisible(false)
        }, [onChange, isDeletion],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: tKey('name'),
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 400,
            isResizable: true,
            className: styles['list-item'],
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => (<span>{tObj(parseJson(data.name))}</span>),

        },
        {
            key: 'code',
            name: tKey('code'),
            fieldName: 'logisticConstraintTmsId',
            targetWidthProportion: 300,
            isResizable: true,
            className: styles['list-item'],
        },
        {
            key: 'actions',
            name: '',
            minWidth: 99,
            maxWidth: 112,
            className: styles['list-item'],
            onRender:
                // eslint-disable-next-line react/no-unstable-nested-components
                logisticConstraint => (
                    <div className={styles['actions-buttons']}>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title={tKey('edit')}
                            onClick={e => {
                                e.preventDefault()
                                setSelectedLCId(logisticConstraint.logisticConstraintId)
                                setIsVisible(true)
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={e => {
                                e.preventDefault()
                                setSelectedLCId(logisticConstraint.logisticConstraintId)
                                setIsDeletion(true)
                                setIsVisible(true)
                            }}
                        />
                    </div>
                ),
        },
    ]), [tKey, tObj])

    return (
        <>
            <Card
                title={tKey('logisticConstraints')}
                iconName="PageData"
                headerComponent={(
                    <DefaultButton
                        onClick={() => {
                            setSelectedLCId(null)
                            setIsVisible(true)
                        }}
                        text={tKey('add')}
                    />
                )}
            >
                <ShimmeredDetailsList
                    items={logisticConstraints}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    onShouldVirtualize={() => true}
                    layoutMode={DetailsListLayoutMode.justified}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                />
            </Card>
            <LogisticConstraintModal
                handler={logisticConstraintsHandler}
                isVisible={isVisible}
                lang={lang}
                onChange={updateLCList}
                isReadOnly={false}
                logisticConstraintdata={logisticConstraints.find(x => x.logisticConstraintId === selectedLCId)}
                companyId={companyId}
                isDeletion={isDeletion}
            />
        </>
    )
}
