import {
    DirectionalHint, Icon, Label, Text, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import classNames from 'classnames'
import useTranslate from 'helpers/hooks/useTranslate'
import React from 'react'
import styles from 'styles/components/pages/orders/[id]/client/detail-info.module.scss'

/**
 * DetailInfo
 * @param {object} props props
 * @param {string=} props.label label
 * @param {React.ReactNode} props.children children
 * @param {(() => void)=} props.onEdit onEdit
 * @param {React.CSSProperties=} props.style style
 * @param {'right' | 'bottom'=} props.buttonPosition buttonPosition
 * @param {object} props.lang Lang
 * @param {boolean=} props.isReadOnly isReadOnly
 * @param {string=} props.infoBubbleText infoBubbleText
 * @returns {JSX.Element} Element
 */
export default function DetailInfo({
    label, children, onEdit, style, buttonPosition = 'right', lang, isReadOnly = false, infoBubbleText = '',
}) {
    const { tKey } = useTranslate({ lang })

    return (
        <div
            className={styles['detail-info']}
            style={style}
        >
            <div className={styles['detail-info-top']}>
                {label && (
                    <Label>{label}</Label>
                )}
                {infoBubbleText.length > 0 && (
                    <div className={styles['detail-info-top-button']}>
                        <TooltipHost
                            content={infoBubbleText}
                            directionalHint={DirectionalHint.topCenter}
                            delay={TooltipDelay.zero}
                        >
                            <Icon
                                iconName="Info"
                            />
                        </TooltipHost>
                    </div>
                )}
            </div>
            <div
                className={classNames(
                    styles['detail-info-content'],
                    { [styles['is-column']]: buttonPosition === 'bottom' },
                )}
            >
                <Text className={styles['detail-info-content-children']}>{children}</Text>
                {!isReadOnly && onEdit && (
                    <button
                        type="button"
                        onClick={onEdit}
                        disabled={isReadOnly}
                        className={styles['detail-info-content-button']}
                    >
                        <Text variant="smallPlus">
                            {tKey('edit')}
                            {' '}
                            <Icon iconName="Edit" />
                        </Text>
                    </button>
                )}
            </div>
        </div>
    )
}
