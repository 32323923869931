/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import OrderType from 'requests/objects/orderType'

/**
 * deliveryType Object
 */
export default class DeliveryType extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.deliveryTypeId deliveryTypeId
     * @param {number=} data.companyId companyId
     * @param {number=} data.orderTypeId orderTypeId
     * @param {object=} data.orderType orderType
     * @param {string=} data.shortName shortName
     * @param {string=} data.name name
     * @param {boolean=} data.hasComfourDataRequired hasComfourDataRequired
     * @param {boolean=} data.hasRdvRequired hasRdvRequired
     * @param {boolean=} data.hasOtNumberRequired hasOtNumberRequired
     */
    constructor({
        deliveryTypeId = 0,
        companyId,
        orderTypeId,
        orderType,
        shortName = '',
        name = '',
        hasComfourDataRequired = false,
        hasOtNumberRequired = false,
        hasRdvRequired = false,
        ...data
    } = {}) {
        super(data)
        this.deliveryTypeId = deliveryTypeId
        this.companyId = companyId
        this.orderTypeId = orderTypeId
        this.orderType = orderType ? new OrderType(orderType) : null
        this.shortName = shortName
        this.name = name
        this.hasComfourDataRequired = hasComfourDataRequired
        this.hasRdvRequired = hasRdvRequired
        this.hasOtNumberRequired = hasOtNumberRequired
    }
}

/**
 * User Object used to bind error message
 */
export class ErrorDeliveryType extends ErrorBase {
    constructor() {
        super()
        this.deliveryTypeId = ''
        this.companyId = ''
        this.shortName = ''
        this.name = ''
        this.hasComfourDataRequired = ''
        this.hasRdvRequired = ''
        this.hasOtNumberRequired = ''
    }
}
