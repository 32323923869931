import React, { PureComponent } from 'react'

import { tKey, tObj } from 'helpers/methods/translate'
import { AppProps } from 'app' // eslint-disable-line
import store from 'redux/store'
import Status from 'types/status'
import { ErrorUser } from 'requests/objects/user'
import {
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import NotImplementedError from 'requests/errors/notImplementedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import PhoneField from 'components/inputs/phoneField'
import parseJson from 'helpers/methods/parseJson'
import { Columns } from 'react-bulma-components'

/**
 * @augments {PureComponent<AppProps & {isVisible: boolean}>}}
 */
export default class UserPhoneModal extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            status: Status.IDLE,
            errorField: new ErrorUser(),
            lastname: '',
            firstname: '',
            phoneNumber: '',
            errorMessage: null,
        }
    }

    componentDidUpdate(prevProps) {
        const { me } = this.props

        if (prevProps.me && me.firstname !== prevProps.me?.firstname)
            this.setState({ firstname: me.firstname ?? '', lastname: me.lastname ?? '' })
    }

    componentWillUnmount() {
        this.userHandlerUpsert?.cancel()
    }

    save() {
        const { phoneNumber, lastname, firstname } = this.state
        const {
            setMe, usersHandler, setMessageBar,
        } = this.props
        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.userHandlerUpsert = usersHandler.updateUserPhoneNumber({ phoneNumber, lastname, firstname })
                const user = await this.userHandlerUpsert.fetch()
                setMe({ me: user })
                this.setState({
                    status: Status.RESOLVED,
                    errorField: new ErrorUser(),
                    lastname: user.lastnauser,
                    firstname: user.firstname,
                    phoneNumber: '',
                })
                setMessageBar({
                    isDisplayed: true,
                    message: this.tKey('theElementhasBeenUpdated'),
                    type: MessageBarType.success,
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            break
                        default:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const { isVisible } = this.props
        const {
            phoneNumber, errorField, status, errorMessage, firstname, lastname,
        } = this.state

        if (!isVisible)
            return null

        return (
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: this.tKey('pleaseEnterYourPhoneNumber'),
                    subText: this.tKey('inOrderPleaseSpecifyInformationBelow'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="600px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => this.setState({ errorMessage: null })}
                        >
                            {this.tObj.call({ props: { lang: store.getState().common?.lang } }, errorMessage)}
                        </MessageBar>
                    )}
                <Columns>
                    <Columns.Column size="one-half">
                        <TextField
                            label={this.tKey('lastname')}
                            placeholder={this.tKey('lastname')}
                            value={lastname}
                            onChange={(ev, newVal) => this.setState({ lastname: newVal })}
                            errorMessage={this.tObj(parseJson(errorField.lastname))}
                            required
                        />
                    </Columns.Column>
                    <Columns.Column size="one-half">
                        <TextField
                            label={this.tKey('firstname')}
                            placeholder={this.tKey('firstname')}
                            value={firstname}
                            onChange={(ev, newVal) => this.setState({ firstname: newVal })}
                            errorMessage={this.tObj(parseJson(errorField.firstname))}
                            required
                        />
                    </Columns.Column>

                </Columns>
                <PhoneField
                    label={this.tKey('phoneNumber')}
                    placeholder={this.tKey('phoneNumber')}
                    value={phoneNumber}
                    errorMessage={this.tObj(parseJson(errorField.phoneNumber))}
                    onChange={(ev, newVal) => this.setState({ phoneNumber: newVal })}
                    required
                    type="tel"
                />
                <br />
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            this.save()
                        }}
                        text={this.tKey('save')}
                        disabled={status === Status.PENDING || !phoneNumber || !firstname || !lastname}
                    />
                </DialogFooter>
            </Dialog>
        )
    }
}

UserPhoneModal.prototype.tKey = tKey
UserPhoneModal.prototype.tObj = tObj
