import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import DeliveryTime, { ErrorDeliveryTime } from 'requests/objects/deliveryTime'
import Status from 'types/status'
// @ts-ignore
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import CompaniesHandler from 'requests/handlers/companiesHandler'
import usePrevious from 'helpers/hooks/usePrevious'

/**
 * deliveryTime modal
 * @param {object} props Props
 * @param {boolean} props.isVisible isVisible
 * @param {CompaniesHandler} props.companiesHandler companiesHandler
 * @param {Partial<DeliveryTime>} props.deliveryTimeData deliveryTimeData must send deliveryTimeId prop at creation
 * @param {(deliveryTime: DeliveryTime) => void} props.onChange Callback to complete the deliveryTime form
 * @param {string} props.lang lang
 * @returns {JSX.Element} deliveryTime modal form to create/update a deliveryTime
 */
export default function DeliveryTimeModal({
    isVisible,
    companiesHandler,
    deliveryTimeData,
    onChange,
    lang,
}) {
    const [deliveryTime, setDeliveryTime] = useState(new DeliveryTime(deliveryTimeData))
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorDeliveryTime())
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<RequestApi<DeliveryTime>>} */
    const upsertDeliveryTimeHandler = useRef()

    const prevIsVisible = usePrevious(isVisible)

    // Set logistic constraint data for edition or creation. Ensure that data in modal are up to date
    useEffect(() => {
        if (isVisible && prevIsVisible !== isVisible) {
            setErrorMessage(null)
            setErrorField(new ErrorDeliveryTime())
            setDeliveryTime(new DeliveryTime(deliveryTimeData))
        }
    }, [deliveryTimeData, isVisible, prevIsVisible])

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            upsertDeliveryTimeHandler.current = companiesHandler.upsertDeliveryTime(deliveryTime.companyId, deliveryTime, deliveryTime.deliveryTimeId)
            const deliveryTimeResult = await upsertDeliveryTimeHandler.current.fetch()
            onChange(deliveryTimeResult)
            setStatus(Status.RESOLVED)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorDeliveryTime>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    setErrorMessage(error)
                    break
            }
        }
    }, [companiesHandler, deliveryTime, onChange])

    // On component did unmount
    useEffect(() => () => {
        upsertDeliveryTimeHandler.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: deliveryTime?.deliveryTimeId ? tKey('modalEditDeliveryTimeTitle') : tKey('modalDeliveryTimeTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="555px"
                minWidth="400px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                    style={{ display: 'flex', gap: '30px' }}
                />
                <TextField
                    label={tKey('pickupZone')}
                    placeholder={tKey('pickupZone')}
                    value={deliveryTime.pickupZoneId?.toString() || ''}
                    type="number"
                    required
                    onChange={(ev, newVal) => setDeliveryTime(prevState => ({ ...prevState, pickupZoneId: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.pickupZoneId)}
                    min={0}
                />
                <TextField
                    label={tKey('deliveryZone')}
                    placeholder={tKey('deliveryZone')}
                    value={deliveryTime.deliveryZoneId?.toString() || ''}
                    type="number"
                    required
                    onChange={(ev, newVal) => setDeliveryTime(prevState => ({ ...prevState, deliveryZoneId: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.deliveryZoneId)}
                    min={0}
                />
                <TextField
                    label={tKey('time')}
                    placeholder={tKey('time')}
                    value={deliveryTime.time?.toString() || ''}
                    type="number"
                    required
                    onChange={(ev, newVal) => setDeliveryTime(prevState => ({ ...prevState, time: +newVal }))}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField.time)}
                    min={0}
                />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => onChange(null)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
