import React, { PureComponent } from 'react'
import {
    Icon, TextField, Dropdown as MsDropdown, Label, getTheme,
    IDropdownProps, ITextFieldProps, IIconProps,
} from '@fluentui/react'
import { SharedColors } from '@uifabric/fluent-theme/lib/fluent/FluentColors'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnimationStyles } from '@fluentui/theme'

/** @debug {IIconProps|IDropdownProps|ITextFieldProps} */

/**
 * @augments {PureComponent<IDropdownProps & ITextFieldProps>}}
 */
export default class Dropdown extends PureComponent {
    /**
     * Get color of Icon
     * @param {IIconProps['iconName']=} iconName iconName
     * @returns {string} Color of the icon
     */
    // eslint-disable-next-line class-methods-use-this
    getIconColor(iconName) {
        switch (iconName) {
            case 'Accept':
                return SharedColors.green10
            case 'Cancel':
                return SharedColors.red10
            default:
                return ''
        }
    }

    render() {
        const {
            readOnly, selectedKey, options, required, label,
        } = this.props

        if (readOnly) {
            const propsClean = { ...this.props }
            delete propsClean.borderless
            delete propsClean.readOnly
            delete propsClean.label

            /** @type {IIconProps['iconName']=} Icon to display */
            const icon = selectedKey
                ? options?.find(x => x.key === propsClean?.selectedKey)?.data?.icon
                : options?.filter(x => propsClean?.selectedKeys?.includes(/** @type {never} */(x.key)))?.[0]?.data?.icon

            return (
                <div className="flex-col flex-start">
                    {icon && (
                        <Label
                            required={required}
                        >
                            {label}
                        </Label>
                    )}
                    <div className="flex-row flex-start">
                        {icon && (
                            <Icon
                                className="flex-col"
                                style={{ marginRight: '1px', height: 'auto' }}
                                iconName={icon}
                                styles={{ root: { color: this.getIconColor(icon) } }}
                            />
                        )}
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...propsClean}
                            label={!icon ? label : undefined}
                            required={!icon ? required : undefined}
                            borderless
                            readOnly
                            value={propsClean.defaultValue ?? (
                                selectedKey
                                    ? options?.find(x => x.key === propsClean?.selectedKey)?.text
                                    : options?.filter(x => propsClean?.selectedKeys?.includes(/** @type {never} */(x.key))).map(x => x.text).join(', ')
                            )}
                            onChange={() => null}
                        />
                    </div>
                </div>
            )
        }
        const propsClean = { ...this.props }
        delete propsClean.onRenderOption
        delete propsClean.onRenderTitle

        return (
            <div
                style={{
                    position: 'relative',
                    // paddingBottom: !!this.props.errorMessage ? "15px" : undefined
                }}
            >
                <MsDropdown
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    onChange={(ev, option, index) => {
                        const opt = option.key !== propsClean.selectedKey ? option : { key: null, text: null }
                        if (propsClean.onChange)
                            propsClean.onChange(ev, opt, index)
                    }}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderOption={option => (
                        <>
                            {option.data?.icon
                                && (
                                    <Icon
                                        style={{ marginRight: '8px', verticalAlign: 'bottom' }}
                                        iconName={option.data?.icon}
                                        styles={{ root: { color: this.getIconColor(option.data?.icon) } }}
                                    />
                                )}
                            <span
                                style={{
                                    color: propsClean.selectedKey === option.key ? getTheme().palette.themeSecondary : null,
                                    fontWeight: propsClean.selectedKey === option.key ? 'bold' : null,
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {option.text}
                            </span>
                        </>
                    )}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    onRenderTitle={opts => {
                        const option = opts[0]

                        return (
                            <>
                                {option.data?.icon
                                    && (
                                        <Icon
                                            style={{ marginRight: '8px', verticalAlign: 'bottom' }}
                                            iconName={option.data?.icon}
                                            styles={{ root: { color: this.getIconColor(option.data?.icon) } }}
                                        />
                                    )}
                                <span>{option.text}</span>
                            </>
                        )
                    }}
                    styles={{
                        ...propsClean.styles,
                        errorMessage: {
                            // @ts-ignore
                            ...(propsClean.styles?.errorMessage ?? {}),
                            ...AnimationStyles.slideDownIn20,
                        },
                    }}
                />
                {required
                    && (
                        <TextField
                            value={selectedKey?.toString() || ''}
                            onChange={() => null}
                            styles={{
                                wrapper: {
                                    opacity: 0,
                                },
                                root: {
                                    position: 'absolute',
                                    bottom: 0,
                                    pointerEvents: 'none',
                                },
                            }}
                            tabIndex={-1}
                            required
                        />
                    )}
            </div>
        )
    }
}
