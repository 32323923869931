/* eslint-disable no-unused-vars */
import {
    DefaultButton, Icon, IconButton, Text,
} from '@fluentui/react'
import Divider from 'components/visuals/divider'
import React, { useMemo, useState } from 'react'
import SupportContact from 'requests/objects/supportContact'
import styles from 'styles/components/containers/support-contacts.module.scss'
import useTranslate from 'helpers/hooks/useTranslate'
import parseJson from 'helpers/methods/parseJson'
import SupportContactsHandler from 'requests/handlers/supportClientsHandler'
import SupportContactModal from 'components/pages/supportContactModal'
import { ParamElement } from 'requests/objects/param'
import ESupportContact from 'types/supportContact/enums/supportContact'

const defaultProfilePicture = require('assets/img/default-profile-picture.png').toString()

/**
 * Support contacts cards
 * @param {object} props props
 * @param {SupportContact[]} props.supportContacts supportContacts
 * @param {string} props.lang lang
 * @param {SupportContactsHandler} props.supportContactsHandler SupportContactsHandler
 * @param {boolean} [props.canEdit] canEdit
 * @param {Partial<SupportContact>} [props.defaultData] defaultData
 * @param {ParamElement[]} [props.supportContactTypes] supportContactTypes
 * @param {function():void} [props.onError] onError
 * @returns {JSX.Element} Element
 */
export default function SupportContacts({
    supportContacts,
    lang,
    supportContactsHandler,
    canEdit = false,
    defaultData = {},
    supportContactTypes = [],
    onError = () => { },
}) {
    const [contacts, setContacts] = useState(supportContacts)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedSupportContact, setSelectedSupportContact] = useState(new SupportContact(defaultData))
    const { tKey, tObj } = useTranslate({ lang })

    const availableContactTypes = useMemo(() => {
        let types = supportContactTypes.filter(type => !contacts.some(contact => contact.supportContactTypeId === +type.key))

        if (defaultData.clientId)
            types = types.filter(type => +type.key !== ESupportContact.Support)

        return types.map(type => ({
            ...type,
            text: tObj(parseJson(type.text)),
        }))
    }, [defaultData.clientId, supportContactTypes, contacts, tObj])

    return (
        <>
            <div className={styles.index}>
                <div className={styles['contact-container']}>
                    {contacts.map(contact => (
                        <div
                            className={styles['contact-card']}
                            key={contact.supportContactId}
                        >
                            <div className={styles['contact-header']}>
                                <div className={styles['contact-picture']}>
                                    <img
                                        src={defaultProfilePicture}
                                        alt=""
                                    />
                                </div>
                                <div className={styles['contact-title']}>
                                    <div className={styles['contact-name']}>
                                        {contact.lastname.toUpperCase()}
                                        {' '}
                                        {contact.firstname}
                                    </div>
                                    <div className={styles['contact-function']}>{tObj(parseJson(contact.supportContactType.name))}</div>
                                </div>
                                {canEdit && (
                                    <div className={styles['contact-edit-button']}>
                                        <IconButton
                                            iconProps={{ iconName: 'Edit' }}
                                            title={tKey('edit')}
                                            onClick={() => {
                                                setSelectedSupportContact(contact)
                                                setIsModalVisible(true)
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            <Divider />
                            <div className={styles['contact-body']}>
                                <div className={styles['contact-info']}>
                                    <Icon iconName="Phone" />
                                    {
                                        canEdit
                                            ? (<span>{contact.phone}</span>)
                                            : (<a href={`tel:+${contact.phone}`}>{contact.phone}</a>)
                                    }
                                </div>
                                <div className={styles['contact-info']}>
                                    <Icon iconName="Mail" />
                                    {
                                        canEdit
                                            ? (<span>{contact.email}</span>)
                                            : (<a href={`mailto:${contact.email}`}>{contact.email}</a>)
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    {canEdit && availableContactTypes.length > 0 && (
                        <div className={styles['contact-card']}>
                            <div
                                style={{
                                    display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%',
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <DefaultButton
                                        onClick={() => {
                                            setSelectedSupportContact(new SupportContact(defaultData))
                                            setIsModalVisible(true)
                                        }}
                                        text={tKey('addContact')}
                                    />
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
            {canEdit && (
                <SupportContactModal
                    isVisible={isModalVisible}
                    defaultData={selectedSupportContact}
                    lang={lang}
                    onChange={supportContact => {
                        if (supportContact)
                            setContacts(prevContacts => {
                                const newContacts = [...prevContacts]
                                const index = newContacts.findIndex(x => x.supportContactId === supportContact.supportContactId)

                                if (index === -1)
                                    return [...newContacts, supportContact]

                                newContacts[index] = supportContact

                                return newContacts
                            })
                        setIsModalVisible(false)
                    }}
                    onError={onError}
                    supportContactsHandler={supportContactsHandler}
                    availableContactTypes={availableContactTypes}
                />
            )}
        </>
    )
}
