import Base from 'requests/objects/_base'

/**
 * WorkflowTransportStep Object
 */
export default class WorkflowTransportStep extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.workflowTransportStepId workflowTransportStepId
     * @param {string=} data.name name
     * @param {string=} data.text text
     * @param {string=} data.message message
     */
    constructor({
        workflowTransportStepId,
        name,
        text,
        message,
        ...data
    } = {}) {
        super(data)
        this.workflowTransportStepId = workflowTransportStepId
        this.name = name
        this.text = text
        this.message = message
    }
}
