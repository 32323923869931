import React, { ReactComponentElement, JSXElementConstructor } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ConnectedComponent } from 'react-redux'
import Loader from 'components/visuals/loader'
import setTitle from 'helpers/methods/pageTitle'

/**
 * Get title when props.title is a function or string
 * @param {object} props props
 * @returns {string} Title
 */
const getTitle = props => (typeof props.title === 'function' ? props.title(props.computedMatch.params) : props.title)

/**
 * @typedef {object} _PrivateRouteProps
 * @property {boolean} isAuthenticated Is user authentificated
 * @property {boolean} isInit Is app init
 * @property {boolean=} isAuthorized Is user authorized to access this route
 * @property {string | function(object):string} title Page name
 * @property {ReactComponentElement | ConnectedComponent<any, any> | JSXElementConstructor<any>} component Component to render
 * @typedef {_PrivateRouteProps & RouteProps} PrivateRouteProps
 */

/**
 * Component for private route when we need to be authentified
 * @param {PrivateRouteProps} props props
 * @returns {JSX.Element} Element
 */
export function PrivateRoute(props) {
    setTitle(getTitle(props))
    const {
        isAuthenticated, isInit, isAuthorized, location,
    } = props
    return (
        <Route
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...{ ...props, component: undefined }}
            // eslint-disable-next-line no-nested-ternary
            render={routeProps => (isAuthenticated
                // eslint-disable-next-line no-nested-ternary
                ? isInit
                    ? isAuthorized === false
                        ? (
                            <Redirect
                                to={{
                                    pathname: '/',
                                    state: { from: location },
                                }}
                            />
                        )
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        : <props.component {...routeProps} />
                    : <Loader />
                : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                ))}
        />
    )
}

/**
 * @typedef {object} _PublicRouteProps
 * @property {string | function(object):string} title Page name
 * @property {ReactComponentElement | ConnectedComponent<any, any>} component Component to render
 * @typedef {_PublicRouteProps & RouteProps} PublicRouteProps
 */

/**
 * Component for private route when we need to be authentified
 * @param {PublicRouteProps} props props
 * @returns {JSX.Element} Element
 */
export function PublicRoute(props) {
    setTitle(getTitle(props))
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route {...props} />
    )
}
