import { Icon, Text } from '@fluentui/react'
import React from 'react'
import Divider from 'components/visuals/divider'
import styles from 'styles/components/containers/card.module.scss'
import classNames from 'classnames'

/**
 * Card item
 * @param {object} props props
 * @param {(string | import('react').ReactComponentElement)=} props.title Title
 * @param {string=} props.iconName Icon Name
 * @param {string=} props.className Classes name
 * @param {import('react').ReactNode=} props.children Children
 * @param {boolean=} [props.hasDividerMargin] hasDividerMargin
 * @param {boolean=} [props.hasLitleDividerMarging] hasDividerMargin
 * @param {import('react').ReactNode=} props.headerComponent HeaderComponent
 * @returns {JSX.Element} Element
 */
function Card({
    title = '', iconName = '', className = '', children = null, headerComponent = null, hasDividerMargin = true, hasLitleDividerMarging = false,
}) {
    return (
        <div className={classNames(styles.card, className)}>
            {title && (
                <>
                    <header>
                        <Text
                            variant="large"
                            block
                        >
                            <Icon iconName={iconName} />
                            {' '}
                            {title}
                        </Text>
                        {headerComponent}
                    </header>
                    <Divider
                        style={
                            // eslint-disable-next-line no-nested-ternary
                            !hasDividerMargin ? { marginBottom: '0' } : hasLitleDividerMarging ? { marginBottom: '1rem' } : {}
                        }
                    />
                </>
            )}
            {children}
        </div>
    )
}

export default Card
