import {
    MessageBar, MessageBarType, Text,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import React from 'react'
import styles from 'styles/components/pages/transports/anomalies-data.module.scss'

/**
 * @typedef {object} AnomaliesType
 * @property {string} name Name
 * @property {string} reason Reason
 */

/**
 * CompanyData
 * @param {object} props props
 * @param {AnomaliesType[]} props.items items
 * @param {object} props.lang Lang
 * @returns {JSX.Element} Returns
 */
export default function AnomaliesData({
    items, lang,
}) {
    const { tObj } = useTranslate({ lang })

    return (
        <div className={styles['anomalies-data']}>
            <ul className={styles['anomalies-data-list']}>
                {items.map((item, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={i}>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                        >
                            <Text>{item.name}</Text>
                            <Text>
                                {tObj(item.reason)}
                            </Text>
                        </MessageBar>
                    </li>
                ))}
            </ul>
        </div>
    )
}
