/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'

/**
 * DeliveryType Object
 */
export default class DeliveryType extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.addressTypeId addressTypeId
     * @param {string=} data.name name
     * @param {string=} data.shortName shortName
     */
    constructor({
        addressTypeId = 0,
        name = '',
        shortName = '',
        ...data
    } = {}) {
        super(data)
        this.addressTypeId = addressTypeId
        this.name = name
        this.shortName = shortName
    }
}
