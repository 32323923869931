import React, {
    useCallback, useMemo,
} from 'react'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, IconButton, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import Accessibility from 'requests/objects/accessibility'
import styles from 'styles/components/pages/companies/accessibilities/accessibility-list.module.scss'
import flattenObj from 'helpers/methods/flattenObj'
import parseJson from 'helpers/methods/parseJson'

/**
 * @callback EditAccessibilityCallback
 * @param {Accessibility} accessibility accessibility
 */

/**
 * @callback DeleteAccessibilityCallback
 * @param {Accessibility} accessibility accessibility
 */

/**
 * AccessibilityList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Accessibility[]} props.items items
 * @param {EditAccessibilityCallback} props.editAccessibility editAccessibility
 * @param {DeleteAccessibilityCallback} props.deleteAccessibility deleteAccessibility
 * @returns {JSX.Element} Element
 */
export default function AccessibilityList({
    lang,
    items,
    editAccessibility,
    deleteAccessibility,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    const accessibilities = useMemo(() => items.map(x => flattenObj(x)), [items])

    const ActionButtons = useCallback(
        accessibility => (
            <div className={styles['action-buttons']}>
                <IconButton
                    iconProps={{ iconName: 'Edit' }}
                    title={tKey('edit')}
                    onClick={() => editAccessibility(accessibility)}
                />
                <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={tKey('delete')}
                    onClick={() => deleteAccessibility(accessibility)}
                />
            </div>
        ), [deleteAccessibility, editAccessibility, tKey],
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: tKey('name'),
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            onRender: item => tObj(parseJson(item.name)),
        },
        {
            key: 'code',
            name: tKey('code'),
            fieldName: 'accessibilityTmsId',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'delete',
            minWidth: 50,
            maxWidth: 50,
            isResizable: false,
            onRender: ActionButtons,
        },
    ]), [tKey, ActionButtons, tObj])

    return (
        <div className={styles['accessibility-list']}>
            <ShimmeredDetailsList
                items={accessibilities}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props })}
            />
        </div>
    )
}
