import Document, { ErrorDocument } from 'requests/objects/document'
// eslint-disable-next-line import/no-cycle, import/named
import ApiHandler from 'requests/apiHandler'

/**
 * DocumentsHandler
 * @augments {ApiHandler<Document, ErrorDocument>}
 */
export default class DocumentsHandler extends ApiHandler {
    constructor() {
        super({ type: Document, errorType: ErrorDocument, key: 'documents' })
    }
}
