import Type from 'types/type'
import { Column } from 'types/column' // eslint-disable-line
import { tKey, tObj } from 'helpers/methods/translate'
import store from 'redux/store'
import parseJson from 'helpers/methods/parseJson'

/**
 * List of Columns
 * @type {Column[]}
 * @readonly
 */
const homeActualitiyColumns = [
    {
        fieldName: 'title',
        name: 'title',
        type: Type.STRING,
    },
    {
        fieldName: 'homeActualityStatus.name',
        name: 'status',
        type: Type.STRING,
        onRender: item => tObj.call({ props: { lang: store.getState().common?.lang } }, parseJson(item['homeActualityStatus.name'])),
    },
    {
        fieldName: 'startDate',
        name: 'inProgress',
        type: Type.STRING,
        onRender: item => (new Date(item.startDate)?.getTime() < new Date()?.getTime() && new Date(item.endDate)?.getTime() > new Date()?.getTime()
            ? tKey.call({ props: { lang: store.getState().common?.lang } }, 'active')
            : tKey.call({ props: { lang: store.getState().common?.lang } }, 'noActive')),
    },
    {
        fieldName: 'createdAt',
        name: 'createdAt',
        type: Type.DATE,
    },
]

export default homeActualitiyColumns
