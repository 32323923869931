/* eslint-disable max-classes-per-file */

/**
 * One Param Element
 */
export class ParamElement {
    /**
     * @param {object} data data
     * @param {(string | number)=} data.key key
     * @param {string=} data.text text
     * @param {string=} data.subText subText
     * @param {number[]=} data.optionalCountriesId [files] optionalCountriesId
     * @param {number[]=} data.requiredCountriesId [files] requiredCountriesId
     * @param {number[]=} data.articleIds [references] articleIds
     * @param {number[]=} data.supportIds [references] supportIds
     * @param {number[]=} data.types types
     * @param {string=} data.email email
     * @param {string=} data.shortName shortName
     * @param {boolean=} data.hasComfourDataRequired hasComfourDataRequired
     * @param {boolean=} data.hasRdvRequired hasRdvRequired
     * @param {boolean=} data.hasOtNumberRequired hasOtNumberRequired
     * @param {string=} data.storeNumber storeNumber
     * @param {boolean=} data.canCreateOrder canCreateOrder
     * @param {boolean=} data.isVisible isVisible
     * @param {boolean=} data.isWithoutReferences isWithoutReferences
     * @param {boolean=} data.isWithoutDimensions isWithoutDimensions
     * @param {number=} data.weight weight
     * @param {number=} data.footage footage
     * @param {boolean=} data.calcFootage calcFootage
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     * @param {number=} data.maxQuantity maxQuantity
     * @param {boolean=} data.isPlatform isPlatform
     * @param {boolean=} data.useStocks useStocks
     */
    constructor({
        key = 0,
        text = '',
        subText,
        optionalCountriesId = [],
        requiredCountriesId = [],
        articleIds = [],
        supportIds = [],
        types = [],
        email = '',
        shortName = '',
        hasComfourDataRequired = false,
        hasRdvRequired = false,
        hasOtNumberRequired = false,
        storeNumber = '',
        canCreateOrder = false,
        isVisible = false,
        isWithoutReferences = false,
        isWithoutDimensions = false,
        weight = null,
        footage = null,
        calcFootage = false,
        long = null,
        width = null,
        height = null,
        maxQuantity = null,
        isPlatform = false,
        useStocks = false,
    } = {}) {
        this.key = key
        this.text = text
        this.subText = subText
        this.optionalCountriesId = optionalCountriesId
        this.requiredCountriesId = requiredCountriesId
        this.articleIds = articleIds
        this.supportIds = supportIds
        this.types = types
        this.email = email
        this.shortName = shortName
        this.hasComfourDataRequired = hasComfourDataRequired
        this.hasRdvRequired = hasRdvRequired
        this.hasOtNumberRequired = hasOtNumberRequired
        this.storeNumber = storeNumber
        this.canCreateOrder = canCreateOrder
        this.isVisible = isVisible
        this.isWithoutReferences = isWithoutReferences
        this.isWithoutDimensions = isWithoutDimensions
        this.weight = weight
        this.maxQuantity = maxQuantity
        this.footage = footage
        this.calcFootage = calcFootage
        this.long = long
        this.width = width
        this.height = height
        this.isPlatform = isPlatform
        this.useStocks = useStocks

        if (this.subText)
            this.data = this.subText
    }
}

/**
 * All param
 */
export default class Param {
    /**
     * @param {object} data data
     */
    constructor(data = []) {
        /** @type {ParamElement[]} Profils */
        this.profiles = []

        /** @type {ParamElement[]} User Profils */
        this.userProfiles = []

        /** @type {ParamElement[]} Client Profils */
        this.clientProfiles = []

        /** @type {ParamElement[]} Sociétés */
        this.companies = []

        /** @type {ParamElement[]} Clients */
        this.clients = []

        /** @type {ParamElement[]} homeActualityStatus */
        this.homeActualityStatus = []

        /** @type {ParamElement[]} addressTypes */
        this.addressTypes = []

        /** @type {ParamElement[]} logisticConstraints */
        this.logisticConstraints = []

        /** @type {ParamElement[]} addresses */
        this.addresses = []

        /** @type {ParamElement[]} countries */
        this.countries = []

        /** @type {ParamElement[]} deliveryTypes */
        this.deliveryTypes = []

        /** @type {ParamElement[]} supports */
        this.supports = []

        /** @type {ParamElement[]} references */
        this.references = []

        /** @type {ParamElement[]} articles */
        this.articles = []

        /** @type {ParamElement[]} articles */
        this.orderStatuses = []

        /** @type {ParamElement[]} fileCategories */
        this.fileCategories = []

        /** @type {ParamElement[]} stores */
        this.stores = []

        /** @type {ParamElement[]} operatingCenters */
        this.operatingCenters = []

        /** @type {ParamElement[]} workflowSteps */
        this.workflowSteps = []

        /** @type {ParamElement[]} documentTypes */
        this.documentTypes = []

        /** @type {ParamElement[]} workflowTransportStep */
        this.workflowTransportSteps = []

        /** @type {ParamElement[]} workflowTransportStep */
        this.supportContactTypes = []

        /** @type {ParamElement[]} fileCategoryXmlCodes */
        this.fileCategoryXmlCodes = []

        /** @type {ParamElement[]} productTemperatures */
        this.productTemperatures = []

        /** @type {ParamElement[]} daysOfTheWeek */
        this.daysOfTheWeek = []

        /** @type {ParamElement[]} accessibilities */
        this.accessibilities = []

        /** @type {ParamElement[]} vehicleEquipments */
        this.vehicleEquipments = []

        /** @type {ParamElement[]} deliveryDateTypes */
        this.deliveryDateTypes = []

        /** @type {ParamElement[]} orderTypes */
        this.orderTypes = []

        // eslint-disable-next-line
        for (const param in data) {
            if (data[param]?.length)
                this[param] = data[param].map(x => new ParamElement(x))
        }
    }
}
