import React from 'react'
import styles from 'styles/components/visuals/required-display.module.scss'

/**
 * Loader component
 * @param {object} props props
 * @param {boolean=} [props.isReadOnly] isReadOnly
 * @param {object=} [props.style] style
 * @returns {JSX.Element} Element
 */
function RequiredDisplay({ isReadOnly = false, style = {} }) {
    if (isReadOnly)
        return null

    return (
        <div
            className={styles['required-display']}
            style={style}
        >
            *
        </div>
    )
}

export default RequiredDisplay
