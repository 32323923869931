import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import styles from 'styles/components/pages/companies/company-list.module.scss'
import useTranslate from 'helpers/hooks/useTranslate'
import {
    ConstrainMode, DetailsListLayoutMode, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import { Link } from 'react-router-dom'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilesHandler from 'requests/handlers/filesHandler'
import EFileFolder from 'types/files/enums/fileFolder'
import Company from 'requests/objects/company'
import EWorkflowStep from 'types/orders/enums/workflowStep'
import IsValidIcon from 'components/visuals/is-valid-icon'

/**
 * @callback SetCompanyOperationalCallback
 * @param {number} companyId companyId
 * @param {boolean} isOperational isOperational
 */

/**
 * CompanyList
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Company[]} props.companies companies
 * @param {Status} props.status status
 * @param {function(import('redux/slices/common').PayloadMessageBar):void} props.setMessageBar setMessageBar
 * @param {FilesHandler} props.filesHandler filesHandler
 * @returns {JSX.Element} Element
 */
export default function CompanyList({
    lang, companies, status, setMessageBar, filesHandler,
}) {
    const { tKey: translateKey } = useTranslate({ lang })
    const [imgSources, setImgSources] = useState({})

    /** @type {object} */
    const imgRefs = useRef({})

    useEffect(() => {
        (async () => {
            try {
                const res = await companies.reduce(async (promise, company) => {
                    const results = await promise
                    const fileId = company.logo?.fileId
                    if (fileId) {
                        imgRefs.current[fileId] = filesHandler.getFile(EFileFolder.CompanyLogo, fileId)
                        const img = await imgRefs.current[fileId].fetch()
                        if (img)
                            results[fileId] = window.URL.createObjectURL(img)
                    }
                    return results
                }, Promise.resolve({}))
                setImgSources(res)
            } catch (error) {
                setMessageBar({ isDisplayed: false })
                switch (error?.constructor) {
                    case CancelRequestError:
                    case UnauthorizedError:
                    case InvalidEntityError: break
                    case NotImplementedError:
                    default:
                        // eslint-disable-next-line no-console
                        console.error(error)
                        break
                }
            }
        })()
    }, [companies, setMessageBar, setImgSources, filesHandler])

    const renderLogo = useCallback(
        /**
         * @param {Company} company company
         * @returns {JSX.Element} Element
         */
        company => {
            if (imgSources[company.logo?.fileId])
                return (
                    <img
                        src={imgSources[company.logo.fileId]}
                        alt={company.logo.name}
                    />

                )
            return (<div />)
        },
        [imgSources],
    )

    /**
     * @param {boolean} isValid isValid
     * @returns {JSX.Element} Element
     */
    const renderIsValid = isValid => (
        <IsValidIcon
            isValid={isValid}
        />
    )

    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'name',
            name: translateKey('name'),
            fieldName: 'name',
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            className: styles['company-list-row'],
        },
        {
            key: 'exportFileFormat',
            name: translateKey('exportFileFormat'),
            fieldName: 'exportFileFormat',
            minWidth: 50,
            maxWidth: 250,
            isResizable: true,
            className: styles['company-list-row'],
        },
        {
            key: 'timeOrder',
            name: translateKey('timeOrder'),
            fieldName: 'timeOrder',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            className: styles['company-list-row'],
        },
        {
            key: 'isAddressValidationRequired',
            name: translateKey('isAddressValidationRequired'),
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            className: styles['company-list-row'],
            onRender: company => renderIsValid(company.companyRule.companyRuleWorkflowStepIds.includes(EWorkflowStep.AddressValidation)),
        },
        {
            key: 'isValidateExploitationRequired',
            name: translateKey('isValidateExploitationRequired'),
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            className: styles['company-list-row'],
            onRender: company => renderIsValid(company.companyRule.companyRuleWorkflowStepIds.includes(EWorkflowStep.OperatorsValidation)),
        },
        {
            key: 'logo',
            name: translateKey('logo'),
            fieldName: 'logo',
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            onRender: renderLogo,
        },
        {
            key: 'status',
            name: translateKey('status'),
            fieldName: 'isOperational',
            minWidth: 50,
            maxWidth: 200,
            isResizable: true,
            className: styles['company-list-row-button'],
            onRender: company => renderIsValid(company.isOperational),
        },
    ]), [translateKey, renderLogo])

    useEffect(() => () => {
        if (imgRefs?.current)
            Object.keys(imgRefs.current).forEach(key => imgRefs.current[key].cancel())
    }, [])

    return (
        <div className={styles['company-list']}>
            <ShimmeredDetailsList
                items={companies}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                enableShimmer={status === Status.PENDING}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                onRenderDetailsHeader={(props, defaultRender) => defaultRender({ ...props, styles: { root: { paddingTop: 0 } } })}
                // eslint-disable-next-line react/no-unstable-nested-components
                onRenderRow={(props, defaultRender) => (
                    <Link to={`/companies/${props.item?.companyId}`}>
                        <div className={styles['company-list-row']}>
                            {defaultRender(props)}
                        </div>
                    </Link>
                )}
            />
        </div>
    )
}
