import Order, { ErrorOrder } from 'requests/objects/order'
import ApiHandler from 'requests/apiHandler'
import DeliveryData from 'requests/objects/order/deliveryData'
import { ParamElement } from 'requests/objects/param'

/**
 * @template T
 * @typedef {{ fetch: () => Promise<T>; cancel: () => void; }} RequestApi
 */

/**
 * OrdersHandler
 * @augments {ApiHandler<Order, ErrorOrder>}
 */
export default class OrdersHandler extends ApiHandler {
    constructor() {
        super({ type: Order, errorType: ErrorOrder, key: 'orders' })
    }

    /**
     * Valid order
     * @param {number} orderId orderId param
     * @returns {RequestApi<Order>} Request
     */
    validOrder(orderId) {
        const request = this.initFetchRequest({ url: [orderId, 'valid'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Order(res.data.orders) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Valid order
     * @param {DeliveryData} deliveryData deliveryData
     * @param {number} orderId orderId param
     * @param {boolean} temporarySaved temporarySaved param
     * @returns {RequestApi<Order>} Request
     */
    updateDeliveryData(deliveryData, orderId, temporarySaved = false) {
        const request = this.initFetchRequest({
            url: [orderId, 'delivery-data'],
            method: 'PUT',
            params: { temporarySaved },
            data: deliveryData,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Order(res.data.orders) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Valid address
     * @param {number} orderId orderId param
     * @param {number} addressId addressId param
     * @returns {RequestApi<Order>} Request
     */
    validAddress(orderId, addressId) {
        const request = this.initFetchRequest({
            url: [orderId, 'valid-address', addressId],
            method: 'PATCH',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Order(res.data.orders) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Set new deliveryData order
     * @param {number} orderId orderId param
     * @param {number} deliveryDataId deliveryDataId param
     * @returns {RequestApi<Order>} Request
     */
    removeDeliveryData(orderId, deliveryDataId) {
        const request = this.initFetchRequest({
            url: [orderId, 'delivery-data', deliveryDataId],
            method: 'DELETE',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Order(res.data.order) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Set new deliveryData order
     * @param {number} orderId orderId param
     * @returns {RequestApi<DeliveryData>} Request
     */
    setNewDeliveryData(orderId) {
        const request = this.initFetchRequest({ url: [orderId, 'set-new-delivery-data'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new DeliveryData(res.data.deliveryData) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get delivery address list
     * @param {number} orderId orderId param
     * @returns {RequestApi<ParamElement[]>} Request
     */
    getDeliveryAddressList(orderId) {
        const request = this.initFetchRequest({ url: [orderId, 'delivery-address-list'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => res?.data?.params?.map(x => new ParamElement(x)) ?? [])
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Save temporarily
     * @param {number} orderId orderId param
     * @returns {RequestApi<{}>} Request
     */
    saveTemporarily(orderId) {
        const request = this.initFetchRequest({ url: [orderId, 'save-temporarily'] })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * sendEmails
     * @param {number} orderId orderId param
     * @param {number} deliveryDataId deliveryDataId param
     * @param {string[]} emails emails param
     * @returns {any} empty object
     */
    sendEmails(orderId, deliveryDataId, emails) {
        const request = this.initFetchRequest({
            url: [orderId, 'send-emails', deliveryDataId],
            method: 'POST',
            data: { emails },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(() => { })
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Print Etiquette
     * @param {number} orderId orderId param
     * @param {number}  nbPerPage nbPerPage
     * @returns {RequestApi<Blob>} Request
     */
    printEtiquette(orderId, nbPerPage) {
        const request = this.initFetchRequest({
            url: [orderId, 'print-etiquette'],
            responseType: 'arraybuffer',
            params: { nbPerPage },
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Print summary
     * @param {number} orderId orderId param
     * @returns {RequestApi<Blob>} Request
     */
    printSummary(orderId) {
        const request = this.initFetchRequest({
            url: [orderId, 'print-summary'],
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Blob([res.data]))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * printLoadingSheet
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @returns {RequestApi<{blob: Blob, fileName: string}>} Request
     */
    printLoadingSheet(params) {
        const request = this.initFetchRequest({
            url: ['print-loading-sheet'],
            params,
            responseType: 'arraybuffer',
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => ({
                    fileName: decodeURI(res.headers['content-disposition'])?.split('attachment;filename=')?.[1] ?? 'LOADING_SHEET-Transports-RAUD.xlsx',
                    blob: new Blob([res.data]),
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }

    /**
     * Get all
     * @param {import('axios').AxiosRequestConfig['params']=} params Params
     * @param {number} page page
     * @param {number} [recordsPerPage] recordsPerPage
     * @returns {import('requests/apiHandler').RequestPageApi<Order>} Request
     */
    getAllFromPage(params = {}, page = 1, recordsPerPage = 25) {
        const request = this.initFetchRequest({ params: { ...params, page, recordsPerPage } })

        return this.getRequestApi(
            () => request.fetchRequest
                .then(res => res.data).then(({
                    orders, totalRecords, totalPages,
                }) => ({
                    items: orders?.map(x => new Order(x)) ?? [],
                    totalRecords,
                    totalPages,
                }))
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
