import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Icon,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Text,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useRef, useState,
} from 'react'
import classNames from 'classnames'
import Status from 'types/status'
import useTranslate from 'helpers/hooks/useTranslate'
import styles from 'styles/components/pages/orders/[id]/client/delivery-step/advanced-file-input.module.scss'
import usePrevious from 'helpers/hooks/usePrevious'

/**
 * ImportDataModal
 * @param {object} props Props
 * @param {object} props.lang Lang
 * @param {Status} props.status status
 * @param {string} props.title title
 * @param {string} props.errorMessage errorMessage
 * @param {boolean} props.isVisible  isVisible
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsVisible  setIsVisible
 * @param {Function} props.onDownloadTemplate onDownloadTemplate
 * @param {Function} [props.onDownloadExample] onDownloadExample
 * @param {Function} props.onImport onImport
 * @param {React.Dispatch<React.SetStateAction<string>>} props.setErrorMessage setErrorMessage
 * @returns {JSX.Element} Element
 */
export default function ImportDataModal({
    lang, status, title, errorMessage, setErrorMessage, setIsVisible, onDownloadTemplate, onDownloadExample = undefined, onImport, isVisible,
}) {
    const { tKey, tObj } = useTranslate({ lang })

    /** Is a file dragging */
    const [isDragging, setIsDragging] = useState(false)
    /** File */
    const [file, setFile] = useState(/** @type {globalThis.File} */(null))

    const uploadFileInput = useRef(null)

    /**
     * On Change for input file
     */
    const onChange = useCallback(
        /**
         * @param {React.ChangeEvent<HTMLInputElement>} ev Event
         */
        ev => {
            ev.preventDefault()
            setErrorMessage(null)
            setFile(ev.target?.files?.[0])
        }, [setErrorMessage],
    )

    /**
     * On drop for drop zone
     */
    const onDrop = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()
            setErrorMessage(null)
            setIsDragging(false)
            setFile(ev.dataTransfer.items ? ev.dataTransfer.items[0]?.getAsFile() : ev.dataTransfer.files[0])
        }, [setErrorMessage],
    )

    /**
     * On drag over for drop zone
     */
    const onDragOver = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            setIsDragging(true)
        }, [],
    )

    /**
     * On drag leave for drop zone
     */
    const onDragLeave = useCallback(
        /**
         * @param {React.DragEvent<HTMLDivElement>} ev Event
         */
        ev => {
            ev.preventDefault()

            setIsDragging(false)
        }, [],
    )

    /**
     * on save
     */
    const onSave = useCallback(() => {
        onImport(file)
    }, [file, onImport])

    /**
     * Clear input
     */
    const clear = useCallback(() => {
        if (uploadFileInput?.current)
            uploadFileInput.current.value = null
        setFile(null)
        setErrorMessage(null)
        setIsVisible(false)
    }, [setErrorMessage, setIsVisible])

    const prevIsVisible = usePrevious(isVisible)

    // clear
    useEffect(() => {
        if (prevIsVisible !== isVisible && prevIsVisible && !isVisible)
            clear()
    }, [clear, isVisible, prevIsVisible])

    if (!isVisible)
        return null

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={() => clear()}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title,
            }}
            modalProps={{
                isBlocking: true,
            }}
            maxWidth="555px"
        >
            {errorMessage
                && (
                    <>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {(typeof errorMessage === 'string'
                                ? tKey(/** @type {any} */(errorMessage))
                                : tObj(errorMessage))
                                || errorMessage?.toString()}
                        </MessageBar>
                        <br />
                    </>
                )}

            <div className={classNames(styles['delivery-step-advanced-file-input'])}>
                <div
                    className={classNames(
                        styles['delivery-step-advanced-file-input-file'],
                        { [styles['is-dragging']]: isDragging },
                    )}
                    onDrop={ev => onDrop(ev)}
                    onDragOver={ev => onDragOver(ev)}
                    onDragLeave={ev => onDragLeave(ev)}
                    onClick={() => {
                        uploadFileInput.current.value = null
                        uploadFileInput.current.click()
                    }}
                    onKeyDown={() => {
                        uploadFileInput.current.value = null
                        uploadFileInput.current.click()
                    }}
                    role="button"
                    tabIndex={0}
                >
                    <Text>
                        <Icon
                            iconName="PageAdd"
                        />
                        <br />
                        <strong>{tKey('addAFile')}</strong>
                        <br />
                        {tKey('clickOrDragYourFileHere')}
                    </Text>
                    <input
                        type="file"
                        ref={uploadFileInput}
                        onChange={ev => onChange(ev)}
                    />
                </div>
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                <DefaultButton
                    onClick={() => onDownloadTemplate()}
                    disabled={status === Status.PENDING}
                    text={tKey('downloadTemplate')}
                />
                {!!onDownloadExample && (
                    <DefaultButton
                        onClick={() => onDownloadExample()}
                        disabled={status === Status.PENDING}
                        text={tKey('downloadExample')}
                    />
                )}
            </div>
            <br />
            {file?.name}
            <br />
            <DialogFooter>
                <PrimaryButton
                    onClick={onSave}
                    disabled={!file || status === Status.PENDING}
                >
                    {tKey('save')}
                    {' '}
                    {status === Status.PENDING && (
                        <>
                            &nbsp;&nbsp;
                            <Spinner
                                size={SpinnerSize.small}
                                labelPosition="right"
                            />
                        </>
                    )}
                </PrimaryButton>
                <DefaultButton
                    onClick={() => clear()}
                    disabled={status === Status.PENDING}
                    text={tKey('cancel')}
                />
            </DialogFooter>
        </Dialog>
    )
}
