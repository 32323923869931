/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'

/**
 * Reference Object
 */
export class Reference extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.referenceId referenceId
     * @param {string=} data.name name
     * @param {string=} data.referenceTmsId referenceTmsId
     */
    constructor({
        referenceId,
        name = '',
        referenceTmsId = '',
        ...data
    } = {}) {
        super(data)
        this.referenceId = referenceId
        this.name = name
        this.referenceTmsId = referenceTmsId
    }
}

/**
 * Article Object
 */
export class Article extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.articleId articleId
     * @param {string=} data.name name
     * @param {string=} data.articleTmsId articleTmsId
     * @param {string=} data.articleTypeTmsId articleTypeTmsId
     * @param {object[]=} data.references references
     * @param {number=} data.weight weight
     * @param {number=} data.footage footage float
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     */
    constructor({
        articleId,
        name = '',
        articleTmsId = '',
        articleTypeTmsId = '',
        references = [],
        weight,
        footage,
        long,
        width,
        height,
        ...data
    } = {}) {
        super(data)
        this.articleId = articleId
        this.name = name
        this.articleTmsId = articleTmsId
        this.articleTypeTmsId = articleTypeTmsId
        this.references = references?.map(x => new Reference(x)) ?? []
        this.weight = weight
        this.footage = footage
        this.long = long
        this.width = width
        this.height = height
    }
}

/**
 * Support Object
 */
export default class Support extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.supportId supportId
     * @param {string=} data.name name
     * @param {string=} data.supportTmsId supportTmsId
     * @param {string=} data.supportTypeTmsId supportTypeTmsId
     * @param {boolean=} data.isWithoutReferences isWithoutReferences
     * @param {object[]=} data.references references
     * @param {object[]=} data.articles articles
     * @param {number=} data.weight weight
     * @param {number=} data.footage footage float
     * @param {number=} data.long long
     * @param {number=} data.width width
     * @param {number=} data.height height
     * @param {number=} data.maxQuantity maxQuantity
     */
    constructor({
        supportId,
        name = '',
        supportTmsId = '',
        supportTypeTmsId = '',
        isWithoutReferences = false,
        references = [],
        articles = [],
        weight,
        footage,
        long,
        width,
        height,
        maxQuantity,
        ...data
    } = {}) {
        super(data)
        this.supportId = supportId
        this.name = name
        this.supportTmsId = supportTmsId
        this.supportTypeTmsId = supportTypeTmsId
        this.isWithoutReferences = isWithoutReferences
        this.articles = articles?.map(x => new Article(x)) ?? []
        this.references = references?.map(x => new Reference(x)) ?? []
        this.weight = weight
        this.footage = footage
        this.long = long
        this.width = width
        this.height = height
        this.maxQuantity = maxQuantity
    }
}
