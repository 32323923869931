import {
    useEffect,
    useState, useCallback,
} from 'react'
import {
    useJsApiLoader,
} from '@react-google-maps/api'

import useTranslate from 'helpers/hooks/useTranslate'
import usePrevious from 'helpers/hooks/usePrevious'

/**
 * useAddressModalMap
 * @param {object} params params
 * @param {boolean=} params.isEnable isEnable
 * @param {{lat: number; lng: number}=} params.mapMarker classicAddressMarker
 * @param {string} params.lang Lang
 * @returns {{
 *  isLoaded: boolean
 *  options: google.maps.MapOptions
 *  marker: { lat: number; lng: number; }
 *  setMarker: React.Dispatch<React.SetStateAction<{ lat: number; lng: number; }>>
 *  tKey: (key: import('types/translations').TranslationsType, args?: { [key: string]: string; }) => string,
 *  tObj: (obj: import('types/translations').TranslationsObjectType | string | [string], args?: { [key: string]: string; }) => string,
 *  zoom: number,
 *  setZoom: React.Dispatch<React.SetStateAction<number>>,
 *  onLoad: (map: google.maps.Map) => void | Promise<void>,
 *  onUnmount: (map: google.maps.Map) => void | Promise<void>,
 *  onZoomChanged: () => void,
 *  center: { lat: number; lng: number; }
 * }} Returns
 */
export default function useAddressModalMap({ isEnable, mapMarker, lang }) {
    const { tKey, tObj } = useTranslate({ lang })
    const [zoom, setZoom] = useState(6)
    const [map, setMap] = useState(null)
    const [marker, setMarker] = useState(mapMarker)
    const [center, setCenter] = useState({ lat: 46.227638, lng: 2.213749 })
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    })

    const prevMapMarker = usePrevious(mapMarker)

    const onLoad = useCallback(m => setMap(m), [])

    const onUnmount = useCallback(() => setMap(null), [])
    const onZoomChanged = useCallback(() => {
        if (map)
            setZoom(map.getZoom())
    }, [map])

    /** @type {google.maps.MapOptions} */
    const options = {
        maxZoom: 20,
        minZoom: 2,
        mapTypeControl: false,
        clickableIcons: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        zoomControl: false,
        fullscreenControl: false,
        mapTypeId: 'satellite',
        // gestureHandling: isMapDraggable ? undefined : 'none',
        styles: [
            {
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                elementType: 'labels',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [{ visibility: 'on' }],
            },
        ],
    }
    // Update marker when mapMarker change
    useEffect(() => {
        if (JSON.stringify(prevMapMarker) !== JSON.stringify(mapMarker)) {
            if (!isEnable)
                setCenter({ lat: mapMarker.lat || 46.227638, lng: mapMarker.lng || 2.213749 })
            setMarker(mapMarker)
        }
    }, [isEnable, prevMapMarker, mapMarker])

    return {
        isLoaded,
        options,
        marker,
        setMarker,
        tKey,
        tObj,
        zoom,
        onLoad,
        onUnmount,
        onZoomChanged,
        setZoom,
        center,
    }
}
