/* eslint-disable max-classes-per-file */

import Base from 'requests/objects/_base'

/**
 * OrderType Object
 */
export default class OrderType extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.orderTypeId orderTypeId
     * @param {string=} data.name name
     * @param {string=} data.logoName logoName
     */
    constructor({
        orderTypeId = 0,
        name = '',
        logoName = '',
        ...data
    } = {}) {
        super(data)
        this.orderTypeId = orderTypeId
        this.name = name
        this.logoName = logoName
    }
}
