import { Icon } from '@fluentui/react'
import Card from 'components/containers/card'
import useTranslate from 'helpers/hooks/useTranslate'
import React from 'react'
import styles from 'styles/components/pages/maintenance-portal-info.module.scss'

/**
 * MaintenancePortalInfo
 * @param {object} props props
 * @param {string} props.lang lang
 * @returns {JSX.Element} Returns
 */
export default function MaintenancePortalInfo({
    lang,
}) {
    const { tKey } = useTranslate({ lang })

    return (
        <Card className={styles['maintenance-portal-info']}>
            <span>
                <Icon
                    iconName="Warning"
                    color="red"
                    styles={{
                        root: {
                            color: 'red',
                        },
                    }}
                />
            </span>
            <span>{tKey('maintenance1')}</span>
            <span>{tKey('maintenance2')}</span>
            <span>{tKey('maintenance3')}</span>
        </Card>
    )
}
