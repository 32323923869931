import {
    ConstrainMode, DetailsListLayoutMode, Icon, SelectionMode, ShimmeredDetailsList,
} from '@fluentui/react'
import useTranslate from 'helpers/hooks/useTranslate'
import React, { useMemo, useState } from 'react'
import DeliveryData from 'requests/objects/order/deliveryData'
import styles from 'styles/components/pages/merchandise-data.module.scss'
import ECompany from 'types/companies/enums/company'

/**
 * Article data
 * @typedef {object} HandlingData
 * @property {string} label Label
 * @property {number} index index
 * @property {string=} reference reference
 * @property {string=} weight weight
 * @property {string=} footage footage
 * @property {number=} long long
 * @property {number=} width width
 * @property {number=} height height
 * @property {number=} quantity quantity
 * @property {string=} comment comment
 */

/**
 * StatusData
 * @param {object} props props
 * @param {string} props.lang lang
 * @param {number} props.companyId companyId
 * @param {DeliveryData[]} props.items items
 * @returns {JSX.Element} Returns
 */
export default function MerchandiseData({
    lang, companyId, items,
}) {
    const { tKey } = useTranslate({ lang })

    // const [deliveryDataId, setDeliveryDataId] = useState(null)
    // const [orderHandlingId, setOrderHandlingId] = useState(null)
    const [index, setIndex] = useState(null)
    /** @type {HandlingData[]} */
    const handlings = useMemo(() => items.reduce((previous, deliveryData) => {
        const res = [...previous]
        if (companyId === ECompany.Raud)
            deliveryData.deliveryContentData.orderHandlings.forEach(orderHandling => {
                orderHandling.orderSubHandlings.forEach(orderSubHandling => {
                    res.push({
                        label: orderSubHandling.article?.name,
                        index: orderSubHandling.orderSubHandlingId,
                        reference: orderSubHandling.reference,
                    })
                    res.push({
                        index: orderSubHandling.orderSubHandlingId,
                        weight: orderSubHandling.weight,
                        footage: orderSubHandling.footage,
                        long: orderSubHandling.long,
                        width: orderSubHandling.width,
                        height: orderSubHandling.height,
                        quantity: orderSubHandling.quantity,
                        comment: orderSubHandling.comment,
                    })
                })
            })
        return res
    }, []), [companyId, items])
    const columns = useMemo(() => /** @type {import('@fluentui/react').IColumn[]} */([
        {
            key: 'content',
            name: tKey('merchandises'),
            fieldName: 'content',
            minWidth: 250,
            maxWidth: 500,
            isResizable: true,
            // eslint-disable-next-line react/no-unstable-nested-components
            onRender: data => {
                if (data.index && data.label)
                    return (
                        <span
                            onClick={() => {
                                setIndex(prevSelectedIndex => (prevSelectedIndex !== data.index ? data.index : null))
                            }}
                            onKeyDown={() => null}
                            role="button"
                            tabIndex={0}
                            className={styles['handling-header']}
                        >
                            <Icon
                                iconName={index !== data.index ? 'ChevronRight' : 'ChevronDown'}
                            />
                            <div>{data.label}</div>
                            <div>{data.reference}</div>
                        </span>
                    )
                if (data.index === index && !data.label)
                    return (
                        <span
                            style={{ marginLeft: '4rem', textOverflow: 'ellipsis', overflow: 'hidden' }}
                            className={styles['handling-data']}
                        >
                            <div>
                                {data.weight}
                                kg
                            </div>
                            <div>
                                {data.footage}
                                {' '}
                                {tKey('linearMeterSuffix').toLocaleLowerCase()}
                            </div>
                            <div>
                                {' '}
                                {data.long}
                                x
                                {data.width}
                                x
                                {data.height}
                                {' '}
                                cm
                            </div>
                            {data.comment?.length ? (
                                <div>
                                    {' - '}
                                    {data.comment}
                                </div>
                            ) : null}
                        </span>
                    )
                //     if (data.deliveryDataId && !data.orderHandlingId)
                //         return (
                //             <span
                //                 onClick={() => {
                //                     setDeliveryDataId(prevSelectedOrderId => (prevSelectedOrderId !== data.deliveryDataId ? data.deliveryDataId : null))
                //                     setOrderHandlingId(null)
                //                 }}
                //                 onKeyDown={() => null}
                //                 role="button"
                //                 tabIndex={0}
                //                 style={{
                //                     cursor: 'pointer', width: '100%', display: 'block', margin: '-11px', padding: '11px',
                //                 }}
                //             >
                //                 <Icon
                //                     iconName={deliveryDataId !== data.deliveryDataId ? 'ChevronRight' : 'ChevronDown'}
                //                 />
                //                 {' '}
                //                 {data.deliveryContentData?.orderHandlings?.length ?? 0}
                //                 {' '}
                //                 (
                //                 {tKey('handlingUnit')}
                //                 )
                //             </span>
                //         )
                //     if (data.orderHandlingId && !data.orderSubHandlingId)
                //         return (
                //             <span
                //                 onClick={() => {
                //                     if (isRaud)
                // eslint-disable-next-line max-len
                //                         setOrderHandlingId(prevSelectedContentId => (prevSelectedContentId !== data.orderHandlingId ? data.orderHandlingId : null))
                //                 }}
                //                 onKeyDown={() => null}
                //                 role="button"
                //                 tabIndex={0}
                //                 style={{
                //                     display: 'flex', gap: '0.5rem', cursor: 'pointer', margin: '-11px -11px -11px 2rem', padding: '11px',
                //                 }}
                //             >
                //                 {isRaud && (
                //                     <Icon
                //                         iconName={orderHandlingId !== data.orderHandlingId ? 'ChevronRight' : 'ChevronDown'}
                //                     />
                //                 )}

                //                 <table style={{ tableLayout: 'fixed', width: '100%', maxWidth: '555px' }}>
                //                     <tbody>
                //                         {['ZZZ', 'VRA'].includes(data.support?.supportTmsId) ? (
                //                             <tr>
                //                                 <td>{data.support?.name}</td>
                //                                 <td>{data.comment}</td>
                //                             </tr>
                //                         ) : (
                //                             <tr className={styles['support-data']}>
                //                                 <td style={{ width: '130px', textAlign: 'left' }}>{tObj(parseJson(data.support?.name))}</td>
                //                                 <td style={{ width: '11px', textAlign: 'center' }}>{' - '}</td>
                //                                 {(isRaud || isPineau) && (<td style={{ width: '70px', textAlign: 'right' }}>{data.reference}</td>)}
                //                                 {(isPineau) && (<td style={{ width: '70px', textAlign: 'right' }}>todo</td>)}
                //                                 {(isRaud || isPineau) && (<td style={{ width: '11px' }}>{' - '}</td>)}
                //                                 {(isJetFreeze) && (
                //                                     <td
                //                                         style={{ width: '70px', textAlign: 'right' }}
                //                                     >
                //                                         {tObj(parseJson(data.productTemperature?.name))}
                //                                     </td>
                //                                 )}
                //                                 <td style={{ width: '70px', textAlign: 'right' }}>
                //                                     {data.weight}
                //                                     kg
                //                                 </td>
                //                                 {(isJetFreeze) && (
                //                                     <td style={{ width: '70px', textAlign: 'right' }}>
                //                                         {data.quantity}
                //                                         Q
                //                                     </td>
                //                                 )}
                //                                 {(isRaud) && (
                //                                     <td style={{ width: '70px', textAlign: 'right' }}>
                //                                         {data.footage}
                //                                         {tKey('linearMeterSuffix').toLocaleLowerCase()}
                //                                     </td>
                //                                 )}
                //                                 {(isRaud || isPineau) && (
                //                                     <td style={{ width: '150px', textAlign: 'right' }}>
                //                                         {data.long}
                //                                         x
                //                                         {data.width}
                //                                         x
                //                                         {data.height}
                //                                         {' '}
                //                                         cm
                //                                     </td>
                //                                 )}
                //                                 <td>{data.comment}</td>
                //                             </tr>
                //                         )}
                //                     </tbody>
                //                 </table>
                //             </span>
                //         )
                //     if (data.orderSubHandlingId)
                //         return (
                //             <span
                //                 style={{ marginLeft: '4rem', textOverflow: 'ellipsis', overflow: 'hidden' }}
                //                 className={styles['article-data']}
                //             >
                //                 <div>{data.article?.name}</div>
                //                 <div>{' - '}</div>
                //                 <div>{data.reference}</div>
                //                 <div>{' - '}</div>
                //                 <div>
                //                     {data.weight}
                //                     kg
                //                 </div>
                //                 <div>
                //                     {data.footage}
                //                     {' '}
                //                     {tKey('linearMeterSuffix').toLocaleLowerCase()}
                //                 </div>
                //                 <div>
                //                     {' '}
                //                     {data.long}
                //                     x
                //                     {data.width}
                //                     x
                //                     {data.height}
                //                     {' '}
                //                     cm
                //                 </div>
                //                 {data.comment?.length ? (
                //                     <div>
                //                         {' - '}
                //                         {data.comment}
                //                     </div>
                //                 ) : null}
                //             </span>
                //         )
                return null
            },
        },
    ]), [index, tKey])

    /** Item to be displayed */
    const filteredItems = useMemo(() => handlings.filter(x => {
        if (x.label)
            return true
        if (!x.label && x.index === index)
            return true
        return false
    }), [handlings, index])

    return (
        <div className={styles['merchandise-data']}>
            <ShimmeredDetailsList
                items={filteredItems}
                columns={columns}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => true}
                layoutMode={DetailsListLayoutMode.justified}
                constrainMode={ConstrainMode.unconstrained}
                enableUpdateAnimations
                isHeaderVisible={false}
            />
        </div>
    )
}
