import LogisticConstraint, { ErrorLogisticConstraint } from 'requests/objects/logisticConstraint'
import ApiHandler from 'requests/apiHandler'

/**
 * LogisticConstraintsHandler
 * @augments {ApiHandler<LogisticConstraint, ErrorLogisticConstraint>}
 */
export default class LogisticConstraintsHandler extends ApiHandler {
    constructor() {
        super({
            type: LogisticConstraint, errorType: ErrorLogisticConstraint, key: 'logistic-constraints', automicErrorMessageDisplay: false,
        })
    }
}
