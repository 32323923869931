import React, { PureComponent } from 'react'
import {
    TextField, PrimaryButton, SpinnerSize, Spinner, MessageBarType,
} from '@fluentui/react'
import history from 'helpers/history'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import { ErrorUser } from 'requests/objects/user'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { tKey, tObj } from 'helpers/methods/translate'
import parseJson from 'helpers/methods/parseJson'

/**
 * @augments {React.PureComponent<AppProps>}}
 */
export default class Signup extends PureComponent {
    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search)
        this.state = {
            /** @type {string} Signup Key */
            signupKey: queryParams.get('signupKey'),
            /** @type {string} Password */
            plainPassword: '',
            /** @type {string} Confirm Password */
            plainPasswordTwo: '',
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {ErrorUser} */
            errorField: new ErrorUser(),
        }

        this.tKey = tKey
        this.tObj = tObj
    }

    componentDidMount() {
        const {
            setBreadcrumb, setCommand, isAuthenticated, messageBar, setMessageBar,
        } = this.props

        setBreadcrumb([])
        setCommand([])

        if (messageBar.type !== MessageBarType.error)
            setMessageBar({ isDisplayed: false })

        if (isAuthenticated)
            history.push('/')
    }

    componentWillUnmount() {
        this.userHandlerSignup?.cancel()
    }

    signup() {
        const { setMessageBar, usersHandler } = this.props
        const { plainPassword, plainPasswordTwo, signupKey } = this.state

        this.setState({ status: Status.PENDING }, async () => {
            try {
                this.userHandlerSignup = usersHandler.signup({
                    plainPassword,
                    plainPasswordTwo,
                    signupKey,
                })
                await this.userHandlerSignup.fetch()
                history.push('/login')
                setMessageBar({
                    isDisplayed: true,
                    message: tKey('yourPasswordHasBeenReset'),
                    type: MessageBarType.success,
                })
            } catch (error) {
                this.setState({ status: Status.REJECTED }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorUser>} */(error).errorField })
                            break
                        default:
                            this.setState({ errorField: new ErrorUser() })
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    render() {
        const {
            plainPassword, plainPasswordTwo, status, errorField,
        } = this.state

        return (
            <form
                onSubmit={(ev => {
                    ev.preventDefault()
                    this.signup()
                })}
            >
                <TextField
                    label={this.tKey('password')}
                    placeholder={this.tKey('password')}
                    type="password"
                    value={plainPassword}
                    onChange={ev => this.setState({ plainPassword: /** @type {HTMLInputElement} */(ev.target).value })}
                    iconProps={{ iconName: 'PasswordField' }}
                    readOnly={status === Status.PENDING}
                    errorMessage={this.tObj(this.parseJson(errorField.plainPassword[0]))}
                    required
                />
                <TextField
                    label={this.tKey('confirmPassword')}
                    placeholder={this.tKey('confirmPassword')}
                    type="password"
                    value={plainPasswordTwo}
                    onChange={ev => this.setState({ plainPasswordTwo: /** @type {HTMLInputElement} */(ev.target).value })}
                    iconProps={{ iconName: 'PasswordField' }}
                    readOnly={status === Status.PENDING}
                    errorMessage={this.tObj(this.parseJson(errorField.plainPasswordTwo[0]))}
                    required
                />
                <br />
                <PrimaryButton
                    iconProps={{ iconName: 'FollowUser' }}
                    text={this.tKey('validate')}
                    type="submit"
                    disabled={status === Status.PENDING}
                >
                    {status === Status.PENDING && (
                        <>
                            &nbsp;&nbsp;
                            <Spinner size={SpinnerSize.small} />
                        </>
                    )}
                </PrimaryButton>
            </form>
        )
    }
}

Signup.prototype.tKey = tKey
Signup.prototype.tObj = tObj
Signup.prototype.parseJson = parseJson
