import React, { useCallback } from 'react'
import {
    DirectionalHint,
    Icon,
    TextField, TooltipDelay, TooltipHost,
} from '@fluentui/react'
import 'react-phone-input-2/lib/style.css'

/**
 * @typedef {object} TextFieldWithTooltipProps
 * @property {string=} tooltipMessage tooltipMessage
 * @property {string=} [tooltipIconName] tooltipIconName
 */

/**
 * Text Field With Tooltip
 * @param {TextFieldWithTooltipProps & import('@fluentui/react').ITextFieldProps} props props
 * @returns {JSX.Element} Element
 */
export default function TextFieldWithTooltip({
    tooltipMessage,
    tooltipIconName,
    ...props
}) {
    const {
        disabled,
        value,
        label,
        onChange,
        borderless,
        readOnly,
        errorMessage,
        placeholder,
        type,
        multiline,
        resizable,
        autoAdjustHeight,
        required,
        min,
    } = props

    const onRenderLabel = useCallback((renderProps, defaultRender) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {defaultRender(renderProps)}
            <div
                style={{
                    marginLeft: '6px', marginTop: '3px', fontSize: '16px', cursor: 'default',
                }}
            >
                <TooltipHost
                    content={tooltipMessage}
                    directionalHint={DirectionalHint.topCenter}
                    delay={TooltipDelay.zero}
                >
                    <Icon
                        iconName={tooltipIconName}
                    />
                </TooltipHost>
            </div>
        </div>
    ), [tooltipMessage, tooltipIconName])

    if (tooltipIconName)
        return (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                label={label}
                placeholder={placeholder}
                value={value}
                readOnly={readOnly}
                borderless={borderless}
                onChange={onChange}
                type={type}
                required={required}
                multiline={multiline}
                autoAdjustHeight={autoAdjustHeight}
                errorMessage={errorMessage}
                resizable={resizable}
                disabled={disabled}
                onRenderLabel={onRenderLabel}
                min={min}
            />
        )

    return (
        <TooltipHost
            content={tooltipMessage}
            directionalHint={DirectionalHint.topCenter}
            delay={TooltipDelay.zero}
        >
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                label={label}
                placeholder={placeholder}
                value={value}
                readOnly={readOnly}
                borderless={borderless}
                onChange={onChange}
                type={type}
                required={required}
                multiline={multiline}
                autoAdjustHeight={autoAdjustHeight}
                errorMessage={errorMessage}
                resizable={resizable}
                disabled={disabled}
                min={min}
            />
        </TooltipHost>

    )
}
