import ECompany from 'types/companies/enums/company'

/**
 * @typedef {object} CurrentCompany
 * @property {boolean} isRaud Is current company Raud
 * @property {boolean} isJetFreeze Is current company JetFreeze
 * @property {boolean} isPineau Is current company Pineau
 */

/**
 * Use Translate
 * @param {object} props props
 * @param {number=} props.companyId companyId
 * @returns {CurrentCompany} CurrentCompany
 */
export default function useCompany({ companyId = ECompany.Raud }) {
    return {
        isRaud: companyId === ECompany.Raud,
        isJetFreeze: companyId === ECompany.JetFreeze,
        isPineau: companyId === ECompany.Pineau,
    }
}
