import Base from 'requests/objects/_base'

/**
 * ProductTemperature Object
 */
export default class ProductTemperature extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.productTemperatureId productTemperatureId
     * @param {number=} data.productTemperatureTmsId productTemperatureTmsId
     * @param {string=} data.name name
     * @param {number=} data.companyId companyId
     */
    constructor({
        productTemperatureId,
        productTemperatureTmsId,
        name,
        companyId,
        ...data
    }) {
        super(data)
        this.productTemperatureId = productTemperatureId
        this.productTemperatureTmsId = productTemperatureTmsId
        this.name = name
        this.companyId = companyId
    }
}
