/* eslint-disable max-classes-per-file */

import Base, { ErrorBase } from 'requests/objects/_base'
import Company from 'requests/objects/company'
import Client from 'requests/objects/client'

/**
 * Support contact type Object
 */
export class SupportContactType {
    /**
     * @param {object} data data
     * @param {number=} data.supportContactTypeId supportContactTypeId
     * @param {string=} data.name name
     */
    constructor({
        supportContactTypeId,
        name,
    } = {}) {
        this.supportContactTypeId = supportContactTypeId
        this.name = name
    }
}

/**
 * Support contact Object
 */
export default class SupportContact extends Base {
    /**
     * @param {object} data data
     * @param {number=} data.supportContactId supportContactId
     * @param {number=} data.supportContactTypeId supportContactTypeId
     * @param {object=} data.supportContactType supportContactType
     * @param {string=} data.firstname firstname
     * @param {string=} data.lastname lastname
     * @param {string=} data.email email
     * @param {string=} data.phone phone
     * @param {number=} data.companyId companyId
     * @param {object=} data.company company
     * @param {number=} data.clientId clientId
     * @param {object=} data.client client
     */
    constructor({
        supportContactId = null,
        supportContactTypeId = 0,
        supportContactType,
        firstname = '',
        lastname = '',
        email = '',
        phone = '',
        companyId,
        company,
        clientId,
        client,
        ...data
    } = {}) {
        super(data)
        this.supportContactId = supportContactId
        this.supportContactTypeId = supportContactTypeId
        this.supportContactType = supportContactType ? new SupportContactType(supportContactType) : null
        this.firstname = firstname
        this.lastname = lastname
        this.email = email
        this.phone = phone
        this.companyId = companyId
        this.company = company ? new Company(company) : null
        this.clientId = clientId
        this.client = client ? new Client(client) : null
    }
}

/**
 * Support contact Object used to bind error message
 */
export class ErrorSupportContact extends ErrorBase {
    constructor() {
        super()
        this.supportContactTypeId = ''
        this.clientId = ''
        this.companyId = ''
        this.firstname = ''
        this.lastname = ''
        this.email = ''
        this.phone = ''
    }
}
