import Client, { ErrorClient } from 'requests/objects/client'
import ApiHandler from 'requests/apiHandler'
import Article from 'requests/objects/article'

/**
 * ClientsHandler
 * @augments {ApiHandler<Client, ErrorClient>}
 */
export default class ClientsHandler extends ApiHandler {
    constructor() {
        super({ type: Client, errorType: ErrorClient, key: 'clients' })
    }

    /**
     * Upsert article
     * @param {number} clientId address id
     * @param {Article} articleData contact data object
     * @returns {import('requests/apiHandler').RequestApi<Article>} Request
     */
    upsertArticle(clientId, articleData) {
        const request = this.initFetchRequest({
            url: [clientId, 'articles'],
            method: articleData.articleId ? 'PUT' : 'POST',
            data: articleData,
        })

        return this.getRequestApi(
            () => request.fetchRequest
                // eslint-disable-next-line new-cap
                .then(res => new Article(res.data.article) ?? {})
                .catch(err => {
                    throw this.handleError(err)
                }),
            request.cancelToken,
        )
    }
}
