import {
    DefaultButton,
    Dialog, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, TextField,
} from '@fluentui/react'
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import AddressesHandler from 'requests/handlers/addressesHandler'
import Status from 'types/status'
// eslint-disable-next-line import/named
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import useTranslate from 'helpers/hooks/useTranslate'
import Address, { ErrorAddress } from 'requests/objects/address'
import EAddressType from 'types/addresses/enums/addressType'

/**
 * Contact modal
 * @param {object} props Props
 * @param {number} props.addressId addressId
 * @param {EAddressType} props.addressType addressType
 * @param {boolean} props.isVisible isVisible
 * @param {(isVisible: boolean) => void} props.setIsVisible setIsVisible
 * @param {AddressesHandler} props.handler handler
 * @param {string} props.instructionsData instructionsData
 * @param {(instructions: string) => void} props.onChange Callback that return instructionsData result
 * @param {string} props.lang lang
 * @returns {JSX.Element} contact modal form to create/update a contact
 */
export default function InstructionsModal({
    addressId,
    addressType,
    isVisible,
    setIsVisible,
    handler,
    instructionsData = '',
    lang,
    onChange,
}) {
    const [instructions, setInstructions] = useState(instructionsData)
    const [errorMessage, setErrorMessage] = useState('')
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(null)
    const instructionsAddressId = useMemo(() => addressId, [addressId])
    const { tKey, tObj } = useTranslate({ lang })

    /** @type {React.MutableRefObject<import('requests/apiHandler').RequestApi<Address>>} */
    const updateAddressInstructionsHandler = useRef()

    const onSubmit = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            updateAddressInstructionsHandler.current = handler.updateAddressInstructions(instructions, instructionsAddressId)
            const address = await updateAddressInstructionsHandler.current.fetch()
            onChange(address.instructions)
            setStatus(Status.RESOLVED)
            setIsVisible(false)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorAddress>} */(error).errorField)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
                default:
                    setStatus(Status.REJECTED)
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }, [handler, onChange, instructions, instructionsAddressId, setStatus, setIsVisible])

    useEffect(() => setInstructions(instructionsData), [setInstructions, instructionsData])

    // On component did unmount
    useEffect(() => {
        if (!isVisible && errorField)
            setErrorField(null)
    }, [setErrorField, errorField, isVisible])

    // On component did unmount
    useEffect(() => () => {
        updateAddressInstructionsHandler?.current?.cancel()
    }, [])

    return (
        <div>
            <Dialog
                hidden={!isVisible}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: addressType === EAddressType.PickUp ? tKey('modalPickupInstructionTitle') : tKey('modalDeliveryInstructionTitle'),
                }}
                modalProps={{
                    isBlocking: true,
                }}
                maxWidth="700px"
                minWidth="555px"
            >
                {errorMessage
                    && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            truncated
                            onDismiss={() => setErrorMessage(null)}
                        >
                            {tObj(errorMessage)}
                        </MessageBar>
                    )}
                <form
                    onSubmit={ev => {
                        ev.preventDefault()
                        onSubmit()
                    }}
                />
                <TextField
                    label={addressType === EAddressType.PickUp ? tKey('writePickupInstruction') : tKey('writeDeliveryInstruction')}
                    value={instructions || ''}
                    onChange={(ev, newVal) => setInstructions(newVal.length > 255 ? newVal.slice(0, 255) : newVal)}
                    disabled={status === Status.PENDING}
                    errorMessage={tObj(errorField?.instructions)}
                    multiline
                />
                <br />
                <DialogFooter>
                    <DefaultButton
                        onClick={() => setIsVisible(false)}
                        text={tKey('cancel')}
                        disabled={status === Status.PENDING}
                    />
                    <PrimaryButton
                        onClick={e => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        text={tKey('save')}
                        disabled={status === Status.PENDING}
                    />
                </DialogFooter>
            </Dialog>
        </div>
    )
}
